import { Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TwistSettings from '../../TwistSettings.json'

const NewsLoading = ({ mini }) => {
  return (
    <Container fluid="md" data-testid="media-list-loading">
      <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb">
        <div className="news-section">
          <div className='row'>

            <div className='col-md-6 col-12 p-0 mt-2 news-card-carousel'>
              <Skeleton height={'100%'} width={'100%'} />
            </div>
            <div className='col-md-6 col-12'>
              <div className='row'>
                {[1, 1, 1, 1].map((news, index) => (
                  <div className='col-md-6 col-6 p-1' key={index}>
                    <div className='news-card'>
                      <Skeleton height={'100%'} width={'100%'} />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </SkeletonTheme>
    </Container>

  );
};

export default NewsLoading;

