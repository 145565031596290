import { createContext, useState } from "react";

export const sharedComponentsContext = createContext();

const SharedContext = ({ children }) => {
    const [initialSharedValues, setInitialSharedValues] = useState({
        NewsSearchQuery: [],
        seasonInfo: {
            activeSeason: { id: null },
            appActiveSeason: { id: null },
        },
        matchInfo: {},
        matchEvents: [],
        playerDetails: {
            currentTeam: {},
            teams: [],
            currentTeamTournaments: [],
            currentTeamActiveTournament: {}
        },
        refreshSubscriptionStatus: null, // need this for just render protected components, need reimplementation in the future
    })
    const { NewsSearchQuery, seasonInfo, matchInfo, matchEvents, playerDetails } = initialSharedValues;

    return (
        <sharedComponentsContext.Provider value={{
            NewsSearchQuery,
            seasonInfo,
            matchInfo,
            matchEvents,
            playerDetails,
            setInitialSharedValues
        }} >
            {children}
        </sharedComponentsContext.Provider>
    )
}

export default SharedContext;