import HttpHelper from './helper';


export const teamsEndpoints = {
    getAllTeams: (params) => HttpHelper.baseAxios
        .get(`/simple_teams`, { params })
        .then((res) => res?.data),

    getTeamDetails: (id) => HttpHelper.baseAxios
        .get(`/teams/${id}`)
        .then((res) => res?.data),

    getTeamStatistics: (params) => HttpHelper.baseAxios
        .get(`/teamStatistics`, { params })
        .then((res) => res?.data),

    getTopScorers: (params) => HttpHelper.baseAxios
        .get(`/top-scorers`, { params })
        .then((res) => res?.data),

    getTopAssists: (params) => HttpHelper.baseAxios
        .get(`/top-assists`, { params })
        .then((res) => res?.data),

    getTeamSquad: (team_id, params) => HttpHelper.baseAxios
        .get(`/teams/${team_id}/squad`, { params })
        .then((res) => res?.data),

};