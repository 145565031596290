import React from "react";
import { Container } from "react-bootstrap";
import api from "../../../../apis";
import SportsSectionHeader from "../../../../components/sportsSectionHeader/sportsSectionHeader.component";
import AllMedia from "../../reusable/media/all-melouk-media";

function MidoTactics() {
  return (
    <Container className='mb-3'>
      <SportsSectionHeader title='ميدو في الملعب' />
      <AllMedia
        per_page={4}
        getAPI={api.melouk.media.getMidoTactics}
        type='mido-tactics'
      />
    </Container>
  );
}

export default MidoTactics;
