import React from 'react'
import './loadingSpinner.css'


const LoadingSpinnner = ({ p, fs }) => {

    return (
        <div className={`d-flex flex-column justify-content-center p-${p} fs-${fs}  `}>
            <div className="lds-roller mx-auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
            <div className={`text-center text-light`}>
                {". . . . يتم التحميل "}
            </div>
        </div>
    )
}

export default LoadingSpinnner