import React, { useContext } from 'react';
import { Row, Col, Container } from 'reactstrap';
import TeamStatChart from './TeamStatChart';
import TeamOverviewStats from './TeamOverviewStats';
import TopScorers from '../topScorers/topScorers'
import TopAssists from '../topAssists/topAssists'
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Comparison from '../Comparison/Comparison'
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';
import TwistSettings from '../../TwistSettings.json'
import { sharedComponentsContext } from '../../context/shared-context';
import { useQuery } from '@tanstack/react-query';
import api from "../../apis";
import { logFailedApi } from '../../globalFn';

const apiFn = (params) => { return api.teams.getTeamStatistics(params) }

const TeamStatsDetail = ({
    TeamDetails,
    isFetchingTeamDetails,
    TeamDetailsFailed,
    sport_id,
    tournament_id,
    team_id,
}) => {

    const { seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);

    const { data: teamStatistics, isFetching: isFetchingTeamStatistics, isLoading: isLoadingTeamStatistics, isError: isErrorTeamStatistics } = useQuery({
        queryKey: ["teams-statistics", sport_id, activeSeason?.id, tournament_id, team_id],
        queryFn: () => apiFn({ season_id: activeSeason?.id, sport_id, team_id, tournament_id }),
        onError: () => logFailedApi("teams-statistics"),
        refetchOnWindowFocus: false,
    })

    return (
        <Container>
            <SeasonsDropdown tournament_id={tournament_id} />
            <Row>
                <Col xs={12} md={4}>
                    {
                        isErrorTeamStatistics ?
                            <ErrorMsg p={5} fs={'md'} msg={'Error.StatisticsError'} />
                            :
                            isFetchingTeamStatistics ?
                                <LoadingSpinnner p={5} fs={'md'} />
                                :
                                <div>
                                    <TeamStatChart TeamStatistics={teamStatistics?.data} />
                                    <div className='bg-black-color mt-3 fs-sm  p-4 d-flex justify-content-around text-light'>
                                        <div>
                                            <span className='mx-2'>{teamStatistics?.data.red_cards}</span>
                                            <span style={{ backgroundColor: 'red', padding: '7px', color: 'transparent' }}>1</span>
                                            <span className="fs-sm">كروت حمراء</span>
                                        </div>
                                        <div>
                                            <span className='mx-2'>{teamStatistics?.data.yellow_cards}</span>
                                            <span style={{ backgroundColor: 'yellow', padding: '7px', color: 'transparent' }}>1</span>
                                            <span className="fs-sm">كروت صفراء</span>
                                        </div>
                                    </div>
                                </div>
                    }
                    {TwistSettings["comparison"] &&
                        <div className="col-12 ml-auto mr-auto  mt-4 mx-md-0 text-center  fs-xs p-3" style={{ height: '300px' }}>
                            <Comparison />
                        </div>
                    }
                </Col>
                <Col xs={12} md={4}>
                    <TeamOverviewStats
                        sport_id={1}
                        season_id={activeSeason?.id}
                        tournament_id={tournament_id}
                        team_id={team_id}
                    />
                    <div className='bg-black-color white-color mt-3 p-3'>
                        <div className='p-1'>دقائق التهديف</div>
                        {
                            isErrorTeamStatistics ?
                                <ErrorMsg p={5} fs={'md'} msg={'Error.StatisticsError'} />
                                :
                                isFetchingTeamStatistics ?
                                    <LoadingSpinnner p={5} fs={'md'} />
                                    :
                                    <div className='row '>
                                        <div className='col-3'>
                                            {
                                                Object.keys(teamStatistics?.data.scoringIntervals).map((el, i) =>
                                                    <div key={el + i} className='mt-1 fs-sm p-1'>
                                                        {i * 15} - {el}'
                                                    </div>
                                                )
                                            }
                                        </div>
                                        <div className='col-9'>
                                            {
                                                Object.values(teamStatistics?.data.scoringIntervals).map((el, i) =>
                                                    <div key={el + i} style={{ width: `${el}%`, backgroundColor: 'var(--red)' }} className='mt-2 p-1 fs-xs text-light'>
                                                        <strong>{Math.round((teamStatistics?.data.goals_in * el) / 100)}</strong>
                                                    </div>
                                                )
                                            }
                                        </div>
                                    </div>
                        }
                    </div>

                </Col>
                <Col xs={12} md={4}>
                    {
                        isFetchingTeamDetails || TeamDetailsFailed ?
                            <div>
                                <div className="p-2 fs-md bg-black-color white-color ">هداف الفريق</div>
                                <LoadingSpinnner p={5} fs={'sm'} />
                            </div>
                            :
                            <TopScorers sport_id={1} season_id={activeSeason?.id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : ''} team_id={team_id} />
                    }

                    {
                        isFetchingTeamDetails || TeamDetailsFailed ?
                            <div className='mt-2'>
                                <div className='bg-black-color white-color p-2'>{"الترتيب"}</div>
                                <LoadingSpinnner p={5} fs={'sm'} />
                            </div>
                            :
                            <TopAssists sport_id={1} season_id={activeSeason?.id} tournament_id={TeamDetails.main_tournament !== null ? TeamDetails.main_tournament.id : ''} team_id={team_id} />
                    }
                </Col>
            </Row>
        </Container>
    )
}

export default TeamStatsDetail