import { Children, useDeferredValue, useState } from "react"
import { useHistory } from "react-router-dom";
import "./FullSearch.css";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import { getTeamOrPlayerService } from "../../globalFn";
import { useQuery } from "@tanstack/react-query";
import api from "../../apis";
import { logFailedApi } from "../../globalFn";

const apiFn = (keyword = ' ') => api.playerSearch.getPlayer({ keyword });

const FullSearch = () => {

  const history = useHistory();

  const [isMenuOpen, setIsMenuOpen] = useState(true)
  const [searchInputValue, setSearchInputValue] = useState('');

  const deferredSearchInputValue = useDeferredValue(searchInputValue);

  const { isFetching, data = [] } = useQuery({
    queryKey: ["player-search", deferredSearchInputValue],
    queryFn: () => apiFn(searchInputValue),
    onError: () => logFailedApi("player-search"),
    enabled: !!deferredSearchInputValue,
    select: (data) => data?.data,
    refetchOnWindowFocus: false,
  });

  const handleInputChange = (e) => {
    const inputValue = e.target?.value?.match(/[A-Za-z ]*[ء-ي]*/g)?.join("")
    inputValue ? setIsMenuOpen(true) : setIsMenuOpen(false);
    setSearchInputValue(inputValue);
  }

  const handleRowClick = async (sportId, type, id) => {
    const serviceId = await getTeamOrPlayerService(id, type)
    history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/${type}/${id}`, { previousPathname: history.location.pathname });
    setIsMenuOpen(!isMenuOpen)
  };

  const creatSearchCat = (arr) => {
    const catObj = {
      players: [],
      teams: [],
      tournaments: [],
    };
    for (const el of arr) {
      if (el.type === "player") {
        catObj.players.push(el);
      } else if (el.type === "team") {
        catObj.teams.push(el);
      } else if (el.type === "tournament") {
        catObj.tournaments.push(el);
      }

    }

    return catObj;
  };

  return (
    <div className="d-flex full-search">
      <input
        className="form-control me-1 full-search-input "
        type="search"
        placeholder="ابحث هنا......"
        aria-label="Search"
        value={searchInputValue}
        onChange={handleInputChange}
      />
      {isMenuOpen &&
        <ul
          className={`${!searchInputValue ? "d-none" : ""
            } full-search-menu text-light`}
        >
          {isFetching ? (
            <div className="text-center mt-5">
              <div className="spinner-border text-secondary fs-xs p-0" role="status">
                <span className="sr-only">Loading...</span>
              </div>
            </div>
          ) : data?.length === 0 ? (
            <li className="text-center p-5">لا يوجد نتائج</li>
          ) : (Children.toArray(
            Object.keys(creatSearchCat(data)).map((key, index) => (
              <li>
                <h5
                  className={`px-3 py-2 ${creatSearchCat(data)[key].length === 0 ? "d-none" : ""
                    } `}
                >
                  نتائج
                </h5>
                {creatSearchCat(data)[key].map((el) => (
                  <div
                    className="dropdown-item d-flex justify-content-between p-2 table-clickable-row text-light"
                    key={el.id * Math.random()}
                    onClick={() => handleRowClick(el.sport_id, el.type, el.id)}
                    style={{ cursor: "pointer" }}
                  >
                    <div>{el.name}</div>
                    <img
                      src={ImgURL(el.image)}
                      width="30px"
                      height="35px"
                      alt=""
                    />
                  </div>
                ))}
              </li>
            )))
          )}
        </ul>
      }
      {/* <i className="fa fa-search fs-lg  text-light"></i> */}
    </div>
  );
};

export default FullSearch;
