import HttpHelper from "./helper";

export const playstationEndpoints = {
    getUserInfo: (params, signal) =>
        HttpHelper.basePlaystationAxios
            .get(`/user-info`, { params, signal })
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),

    sendUserRegistrationInfo: (data) =>
        HttpHelper.basePlaystationAxios
            .post(`/register`, data)
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),
    sendMatchResultScreenshot: (match_id, data) =>
        HttpHelper.basePlaystationAxios
            .post(`/screenshot/${match_id}`, data)
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),
};