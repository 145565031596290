import React from "react";
import { Container, Col, Row } from "react-bootstrap";
import VideoPlayerComponent from "./VideoPlayerComponent";
import { convertFullDate } from "../../../../globalFn";
import UploadBtnComponent from "../buttons/UploadBtnComponent";
import ShareBtnComponent from "../buttons/ShareBtnComponent";
import RatingIconComponent from "../buttons/RatingIconComponent";
import UserImgAndRatingComponent from "../user/UserImgAndRatingComponent";
import "./VideoDetailsComponent.css";

const VideoDetailsComponent = (props) => {
  return (
    <Container fluid="lg" className="p-0 ">
      <VideoPlayerComponent videoSrc={props?.video?.video} />
      <div className="">
        {props?.type === "challenge" ? (
          <Row noGutters className="p-lg-4 p-2">
            <Col xs={12} md={9}>
              <small className="fs-xs color-tertiary-dark">
                {convertFullDate(props?.video?.fromDate, 'ar')}
              </small>
              <h4 className="fs-xm color-tertiary-light">
                {props?.video?.titleAr}
              </h4>
              <p className="m-auto fs-md color-tertiary">
                {props?.video?.descriptionAr}
              </p>
            </Col>
            <Col xs={12} lg={3} className="mt-lg-auto mt-3">
              <UploadBtnComponent
                challengeId={props?.video?.id}
                trials={
                  props?.video?.trials === undefined ? 2 : props?.video?.trials
                }
                approved={props?.video?.approved}
              />
            </Col>
          </Row>
        ) : props?.type === "users" ? (
          <Row>
            <Col xs={7} className="p-3 px-4 d-block">
              <UserImgAndRatingComponent
                userId={props?.video?.user.id}
                userImg={props?.video?.user.image}
                userName={props?.video?.user.username}
                userRate={props?.video?.vote}
              />
            </Col>
            <Col xs={4} className="m-auto">
              <div className="row">
                <RatingIconComponent
                  userId={props?.video?.user.id}
                  userVote={props?.video?.userVote}
                  userImg={props?.video?.user.image}
                  userName={props?.video?.user.username}
                  compTitle={props?.video?.competition.titleAr}
                  compId={props?.video?.id}
                  addRate={props?.addRate}
                  rateState={props?.rateState}
                />
                <ShareBtnComponent url={"/video/uploaded/" + props?.video?.id} />
              </div>
            </Col>
          </Row>
        ) : props?.type === "uploaded" ? (
          <Row noGutters className="p-lg-4 p-2">
            <Col xs={12}>
              <small className="fs-xs color-tertiary-dark">
                {convertFullDate(props?.video?.competition.fromDate, 'ar')}
              </small>
              <h4 className="fs-xm color-tertiary-light">
                {props?.video?.competition.title}
              </h4>
              <p className="m-auto fs-md color-tertiary">
                {props?.video?.competition.description}
              </p>
            </Col>
          </Row>
        ) : (
          <Row noGutters className="p-lg-4 p-2 video-details-title">
            <Col xs={12}>
              <small className="fs-xs">
                {convertFullDate(props?.video?.date, 'ar')}
              </small>
              <h4 className="fs-xm">
                {props?.video?.title}
              </h4>
              <p className="m-auto fs-md">
                {props?.video?.description}
              </p>
            </Col>
          </Row>
        )}
      </div>
    </Container>
  );
};

export default VideoDetailsComponent;
