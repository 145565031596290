import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ErrorMsg from "../ErrorPage/ErrorMsg";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import SampleArrowBtn from "../SampleArrowBtn/SampleArrowBtn";
import "./generalStatistics.css"
import api from "../../apis";
import { useQuery } from '@tanstack/react-query';
import { logFailedApi } from "../../globalFn";

const apiFn = (params) => {
  return api.tournament.getTournamentsNewStanding(params)
}

const GeneralStatistics = ({ setTournamentActiveTab, sport_id, service_id, season_id }) => {
  const [currentlyDisplayedElem, setCurrentlyDisplayedElem] = useState(null)
  const currentPageIndex = useRef(0)

  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ["standing", service_id, sport_id],
    queryFn: () => apiFn({ competition_type: 2, priority: true, season_id, service_id, sport_id, }),
    onSuccess: (data) => { setCurrentlyDisplayedElem(data.data[0]) },
    onError: () => logFailedApi("standing"),
    refetchOnWindowFocus: false,
  })


  const displayCurrentElemetn = (elementsArr, currentPage) => {
    let currentElement = elementsArr[currentPage]
    setCurrentlyDisplayedElem(currentElement)
  }

  const history = useHistory();
  const handleRowClick = (serviceId, sportId, id) => {
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/team/${id}`,
      { previousPathname: history.location.pathname }
    );
  };

  return (
    <div className="general-statistics bg-black-color white-color fs-xs">
      <div className="d-flex justify-content-between fs-md p-2">
        <div
          className="btn white-color table-clickable-row fs-sm"
          onClick={() => {
            history.push(
              `/${getServiceName(currentlyDisplayedElem.service_id)}${getSportRoute(
                sport_id,
                service_id
              )}/tournament/${currentlyDisplayedElem?.id}/stats`,
              { previousPathname: history.location.pathname }
            );
          }}
        >
          {currentlyDisplayedElem?.title}
        </div>
        <div style={{ direction: "rtl" }}>
          <SampleArrowBtn
            arrowDirection="left"
            onClick={() => {
              currentPageIndex.current++
              if (!isLoading) displayCurrentElemetn(data?.data, currentPageIndex.current)
            }}
            isDisabled={
              currentPageIndex.current === data?.data?.length - 1 || isLoading || isError
            }
          />

          <span className="mx-2">احصائيات</span>

          <SampleArrowBtn
            onClick={() => {
              currentPageIndex.current--
              if (!isLoading) displayCurrentElemetn(data?.data, currentPageIndex.current)
            }}
            isDisabled={currentPageIndex.current === 0}
          />
        </div>
      </div>
      <table className="responsive-table table-borderless bg-black-color table-striped table-dark white-color">
        <thead>
          <tr>
            <th scope="col">المركز</th>
            <th scope="col">الفريق</th>
            <th scope="col" className="text-nowrap">
              {"أهداف له"}
            </th>
            <th scope="col" className="text-nowrap">
              {"أهداف عليه"}
            </th>
            <th scope="col" className="text-nowrap">
              {"مباريات"}
            </th>
            <th scope="col" className="text-nowrap">
              {"تعادل"}
            </th>
          </tr>
        </thead>
        <tbody>
          {isError ? (
            <tr>
              <td colSpan={7} className="p-0">
                <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={"lg"} />
              </td>
            </tr>
          ) : (isLoading || isFetching) ? (
            <tr>
              <td colSpan={7} className="p-0">
                <LoadingSpinnner p={5} fs={"md"} />
              </td>
            </tr>
          ) : currentlyDisplayedElem?.data?.length === 0 ? (
            <tr>
              <td colSpan={7} className="p-0">
                <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={"lg"} />
              </td>
            </tr>
          ) : (
            currentlyDisplayedElem?.data?.map((team, i) =>
              i <= 6 ? (
                <tr
                  key={team.id}
                  onClick={() =>
                    handleRowClick(
                      currentlyDisplayedElem.service_id,
                      currentlyDisplayedElem.sport_id,
                      team.team_id
                    )
                  }
                  className="table-clickable-row-animated text-center"
                >
                  <td style={{ width: "20px" }}>{i + 1}</td>
                  <td
                    style={{ minWidth: "130px" }}
                    className={
                      window.document.body.dir === "rtl"
                        ? "text-right team-name"
                        : "text-left team-name"
                    }
                  >
                    <img src={ImgURL(team.team_logo)} width="30x" alt="team logo" />
                    <span className="mx-2 fs-xxs ">{team.team_name}</span>
                  </td>
                  <td>{team.goals_in}</td>
                  <td>{team.goals_out}</td>
                  <td>{team.played}</td>
                  <td>{team.draw}</td>
                </tr>
              ) : (
                null
              )
            )
          )}
          <tr>
            <td colSpan={7} className="justify-content-end p-1">
              <div className="d-flex justify-content-end">
                <button
                  className="btn btn-link fs-sm active-color swipe-animation"
                  onClick={() => {
                    history.push(
                      `/${getServiceName(currentlyDisplayedElem.service_id)}${getSportRoute(
                        sport_id,
                        service_id
                      )}/tournament/${currentlyDisplayedElem?.id}/stats`,
                      { previousPathname: history.location.pathname }
                    );
                  }}
                >
                  عرض الكل
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};


export default GeneralStatistics;
