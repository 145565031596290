import React, { useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import ErrorMsg from "../ErrorPage/ErrorMsg";
import "./generalStanding.css";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import SampleArrowBtn from "../SampleArrowBtn/SampleArrowBtn";
import api from "../../apis";
import { useQuery } from '@tanstack/react-query';
import { logFailedApi } from "../../globalFn";

const apiFn = (params) => {
  return api.tournament.getTournamentsNewStanding(params)
}

const GeneralStanding = ({ sport_id, service_id, season_id, }) => {

  const [currentlyDisplayedElem, setCurrentlyDisplayedElem] = useState(null)
  const currentPageIndex = useRef(0)

  const { isLoading, isError, data, isFetching } = useQuery({
    queryKey: ["standing", service_id, sport_id],
    queryFn: () => apiFn({ competition_type: 2, priority: true, season_id, service_id, sport_id, }),
    onSuccess: (data) => { setCurrentlyDisplayedElem(data.data[0]) },
    onError: () => logFailedApi("standing"),
    refetchOnWindowFocus: false,
  })

  const displayCurrentElemetn = (elementsArr, currentPage) => {
    let currentElement = elementsArr[currentPage]
    setCurrentlyDisplayedElem(currentElement)
  }

  const history = useHistory();
  const handleRowClick = (serviceId, sportId, type, id) => {
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/${type}/${id}`,
      { previousPathname: history.location.pathname }
    );
  };


  return (
    <table className="table-borderless bg-black-color table-striped table-dark white-color fs-xs">
      <thead>
        <tr>
          <th scope="col" style={{ direction: "rtl", minWidth: "fit" }}>
            <SampleArrowBtn
              arrowDirection="left"
              onClick={() => {
                currentPageIndex.current++
                if (!isLoading) displayCurrentElemetn(data?.data, currentPageIndex.current)
              }}
              isDisabled={
                currentPageIndex.current === data?.data?.length - 1 || isLoading || isError
              }
            />

            <span
              className="btn  white-color table-clickable-row fs-sm"
              onClick={() => {
                if (!isLoading && !isError) {
                  history.push(
                    `/${getServiceName(
                      currentlyDisplayedElem?.service_id
                    )}${getSportRoute(sport_id, service_id)}/tournament/${currentlyDisplayedElem?.id
                    }/standing`,
                    { previousPathname: history.location.pathname }
                  );
                }
              }
              }
              style={{ cursor: "pointer" }}
              data-testid="general-standing-tournament-name"
            >
              {currentlyDisplayedElem?.title}
            </span>

            <SampleArrowBtn
              onClick={() => {
                currentPageIndex.current--
                if (!isLoading) displayCurrentElemetn(data?.data, currentPageIndex.current)
              }}

              isDisabled={currentPageIndex.current === 0}
            />
          </th>
          <th scope="col" className="text-center">
            لعب
          </th>
          <th scope="col" className="text-center">
            فارق
          </th>
          <th scope="col" className="text-center">
            نقاط
          </th>
        </tr>
      </thead>

      <tbody>
        {isError ? (
          <tr>
            <td colSpan={7} className="p-0">
              <ErrorMsg msg="لا يوجد ترتيب" p={5} fs={"lg"} />
            </td>
          </tr>
        ) : (isLoading || isFetching) ? (
          <tr data-testid="general-standings-loading">
            <td colSpan={7} className="p-0">
              <LoadingSpinnner p={5} fs={"md"} />
            </td>
          </tr>
        ) : currentlyDisplayedElem?.data?.length === 0 ? (
          <tr>
            <td colSpan={7} className="p-0">
              <ErrorMsg msg="لا يوجد ترتيب" p={5} fs={"lg"} />
            </td>
          </tr>
        ) : (
          currentlyDisplayedElem?.data
            ?.sort((a, b) => b.point - a.point)
            .map((team, i) =>
              i <= 7 ? (
                <tr
                  key={team.id}
                  onClick={() =>
                    handleRowClick(
                      currentlyDisplayedElem.service_id,
                      currentlyDisplayedElem.sport_id,
                      "team",
                      team.team_id
                    )
                  }
                  className="table-clickable-row-animated "
                  data-testid="general-standings-teams-tournament-data-row"
                >
                  <th scope="row">
                    <span>{i + 1}</span>{" "}
                    <span className="mx-2">{team.team_name}</span>
                  </th>
                  <td className="text-center">{team.played}</td>
                  <td className="text-center">{team.difference}</td>
                  <td className="text-center">{team.point}</td>
                </tr>
              ) : (
                null
              )
            )
        )}
        <tr>
          <td colSpan={7} className="justify-content-end p-1">
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-link fs-sm active-color swipe-animation"
                onClick={() => {
                  history.push(
                    `/${getServiceName(currentlyDisplayedElem?.service_id)}${getSportRoute(
                      sport_id,
                      service_id
                    )}/tournament/${currentlyDisplayedElem?.id}/standing`,
                    { previousPathname: history.location.pathname }
                  );
                }}
                data-testid="general-standings-view-all-standings-button"
              >
                عرض الكل
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default GeneralStanding;
