import React, { useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import unknownPlayerImg from "../../../../assets/games/guess-player.svg";
import footballFieldImg3 from "../../../../assets/games/Football_field3.png";
import { ImgURL } from '../../../../globalData';
import SelectPlayer from './SelectPlayer';

function SoccerField({ squad, changePlayer }) {
    const [showSelectPlayerModal, setShowSelectPlayerModal] = useState(false);
    const [playerInfo, setPlayerInfo] = useState({ id: "", line: "" })

    const handleHideSelectPlayerModal = (selectedPlayer, defaultPlayerInfo) => {
        setShowSelectPlayerModal(false)
        if (selectedPlayer && defaultPlayerInfo) changePlayer(defaultPlayerInfo.id, defaultPlayerInfo.line, { id: selectedPlayer.id, image: selectedPlayer.image })
    }


    const renderPlayer = (player, line = 'gk') => {
        return <button
            className='palyer__positionButton'
            key={player.id}
            type="button"
            onClick={() => { setShowSelectPlayerModal(true); setPlayerInfo({ id: player.id, line }) }}
        >
            <img className='player__image' src={`${!!player.image ? ImgURL(player.image) : unknownPlayerImg}`} alt="" />
        </button >
    }

    const renderLine = (line) => <Row key={line} className={line}>
        {
            squad[line].map(player => <Col key={player.id}>
                {renderPlayer(player, line)}
            </Col>)
        }
    </Row>

    return (
        <Container className='SoccerField'>
            <SelectPlayer
                show={showSelectPlayerModal}
                onHide={handleHideSelectPlayerModal}
                playerInfo={playerInfo}
                squad={squad}
            />
            <figure>
                <img src={footballFieldImg3} alt="" />
            </figure>
            <Container className='players__positionContainer'>
                <Row className='gk'>
                    <Col>
                        {renderPlayer(squad.gk)}
                    </Col>
                </Row>
                {squad.df?.length > 0 && renderLine('df')}
                {squad.cdm?.length > 0 && renderLine('cdm')}
                {squad.cm?.length > 0 && renderLine('cm')}
                {squad.cam?.length > 0 && renderLine('cam')}
                {squad.fw?.length > 0 && renderLine('fw')}
            </Container>

        </Container>
    )
}

export default SoccerField