import React, { useState, useRef, useEffect, Fragment } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import QuizzesTimer from "./QuizzesTimer";
import api from "../../../../apis";
import { getUserPhoneNumbers } from "../../../../globalFn";
import GameSuccessView from "../../GameSuccessView/GameSuccessView";
import { sendGameReport } from "../helper";

function QuizzesModal(props) {
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [quizzesStarted, setQuizzesStarted] = useState(false);
  const [formPage, setFormPage] = useState(null);
  const [questionsLength, setQuestionsLength] = useState(null);
  const [isFetchingQuizzesQuestions, setIsFetchingQuizzesQuestions] =
    useState(false);
  const [quizzesErrors, setQuizzesErrors] = useState(null);
  const [quizzesData, setQuizzesData] = useState([]);
  const [numOfCorrectAswers, setNumOfCorrectAswers] = useState(0)
  const qustionChoices = ["0", "1", "2", "3"];

  const formInputsValues = useRef([])

  useEffect(() => {
    if (props.show) {
      sendGameReport("WorldCupQuizzes")
    }
  }, [props.show])

  const updateFormInputsValues = (questionIndex, answer) => {
    const formInputsValuesCopy = [...formInputsValues.current];
    const newQuestionInputValue = { ...formInputsValues.current[questionIndex], answer };
    formInputsValuesCopy.splice(questionIndex, 1, newQuestionInputValue);
    formInputsValues.current = formInputsValuesCopy
  }

  const handleChangeFormPage = (questionTimeOut = false) => {
    if (questionTimeOut) updateFormInputsValues(formPage, -1);
    if (formPage === questionsLength) handleSendAnswers();
    if (formPage < questionsLength) setFormPage((prevState) => prevState + 1);

  }

  const handleFormButtonClick = (questionIndex) => {
    // if (!formInputsValues[questionIndex].question_id || !formInputsValues[questionIndex].answer) return;
    handleChangeFormPage();
  };

  const handleInputChange = (e, index) => {
    const { value } = e.target;
    updateFormInputsValues(index, +value)
  };

  const handleSendAnswers = () => {
    const quiz_id = quizzesData.id;
    const msisdn = getUserPhoneNumbers()[0];
    const questionsIds = quizzesData?.questions?.map(question => question.id)
    const answersIds = formInputsValues.current?.map(value => value.answer);

    const answersBody = {
      msisdn: msisdn,
      quiz_id: quiz_id,
      question_id: questionsIds,
      answers: answersIds,
    }

    api.quizzesGame
      .sendQuizzesAnswers(answersBody)
      .then((res) => {
        if (res.data.status === 201) {
          setShowSuccessView(true);
          const setTimeOutID = setTimeout(() => {
            props.onHide();
            clearTimeout(setTimeOutID);
          }, 3000);
        }
      });
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formInputsValues.current.at(-1).question_id || !formInputsValues.current.at(-1).answer) return;
    handleSendAnswers();
  };

  const handleGetQuizzesQuestions = () => {
    setIsFetchingQuizzesQuestions(true);
    api.quizzesGame.getQuizzesQuestions().then((res) => {
      if (res?.status === 200) {
        setQuizzesData(res.data);
        setQuestionsLength(res.data.questions.length - 1);
        const initialValues = res.data.questions.map((question) => ({
          question_id: question.id,
          answer: "",
        }));
        formInputsValues.current = initialValues
      } else {
        setQuizzesErrors(res.message);
      }
      setIsFetchingQuizzesQuestions(false);
    });
  };


  return (
    <Modal
      {...props}
      size="xl"
      contentClassName={"quizzes__modal modal__content"}
      dialogClassName="modal__dialog"
    >
      <Modal.Header className="modal__header" closeButton />
      <Modal.Body className="show-grid">
        <Container>
          {showSuccessView ? (
            <GameSuccessView
            />
          ) : (
            <Row>
              <Col xs={12} className="modal__bodyTitle text-center text-white">
                {props.alAhlyPage && "أهلاوى صميم"}
                {!props.alAhlyPage && "اسئلة"}
              </Col>
              <Col
                xs={12}
                className="modal__bodyDescription text-center text-white"
              >
                {"اختبر معلوماتك بالإجابة عن الأسئلة التالية في الوقت المحدد. "}
              </Col>
              <Col xs={12} className="text-center">
                {quizzesStarted ? (
                  <QuizzesTimer
                    isFetchingQuizzesQuestions={isFetchingQuizzesQuestions}
                    quizzesErrors={quizzesErrors}
                    handleGetQuizzesQuestions={handleGetQuizzesQuestions}
                    setQuizzesStarted={setQuizzesStarted}
                    setFormPage={setFormPage}
                    handleChangeFormPage={handleChangeFormPage}
                    formPage={formPage}
                  />
                ) : (
                  <button
                    className="quizzes__modalButton--start"
                    onClick={() => {
                      handleGetQuizzesQuestions();
                      setQuizzesStarted(true);
                      setFormPage(0);
                    }}
                  >
                    ابدأ
                  </button>
                )}
              </Col>
              {quizzesStarted && !isFetchingQuizzesQuestions && !quizzesErrors && (
                <Col
                  xs={12}
                  className="modal__bodyQuizzes--questions d-flex align-items-center justify-content-center text-center text-white mt-5"
                >
                  <Form
                    className="d-flex flex-column w-50"
                    onSubmit={handleSubmit}
                  >
                    {quizzesData?.questions.sort((a, b) => a.id - b.id).map(
                      (quizQuestion, indexOne) =>
                        indexOne === formPage && (
                          <Fragment key={indexOne}>
                            <div key={quizQuestion.id}>
                              <div className="quiz__questionTitle mb-4">
                                {quizQuestion.question}
                              </div>
                              <div className="quiz__choices">
                                {qustionChoices.map((choiceNumber) => (
                                  <InputGroup key={choiceNumber}>
                                    <InputGroup.Radio
                                      className="input__quizRadio"
                                      id={quizQuestion.id + choiceNumber}
                                      name={quizQuestion.id}
                                      value={+choiceNumber}
                                      onChange={(e) => {
                                        handleInputChange(e, indexOne)
                                        handleFormButtonClick(indexOne)
                                      }}

                                    />
                                    <label
                                      htmlFor={quizQuestion.id + choiceNumber}
                                    >
                                      {quizQuestion[choiceNumber]}
                                    </label>
                                  </InputGroup>
                                ))}
                              </div>
                            </div>
                            <div className="quizzes__formButton--wrapper d-flex justify-content-center">
                              {/* <Button
                                disabled={!!formInputsValues[indexOne].answer ? false : true}
                                type="button"
                                className={`form__button ${questionsLength !== formPage ? "active__button" : ""
                                  } text-capitalize`}
                                onClick={() => handleFormButtonClick(indexOne)}
                              >
                                {"تأكيد"}
                              </Button> */}
                              {/* <Button
                                disabled={!!formInputsValues[indexOne].answer ? false : true}
                                type="submit"
                                className={`form__button ${questionsLength === formPage ? "active__button" : ""
                                  } text-capitalize`}
                                onClick={() => handleFormButtonClick(indexOne)}
                              >
                                {"تأكيد"}
                              </Button> */}
                            </div>
                          </Fragment>
                        )
                    )}
                  </Form>
                </Col>
              )}
              {quizzesStarted && !isFetchingQuizzesQuestions && quizzesErrors && (
                <Col xs={12} className="text-center text-danger my-5">
                  حدث خطا يرجي اعادة المحاولة
                </Col>
              )}
            </Row>
          )}
        </Container>
      </Modal.Body>
    </Modal>
  );
}

export default QuizzesModal;
