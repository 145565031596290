import { useEffect, useState, useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { useParams } from "react-router";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import RelatedComponent from "../../components/MediaDetails/Related/RelatedComponent";
import TagsComponent from "../../components/MediaDetails/Tags/TagsComponent";
import AllMedia from "../../components/News/AllMedia";
import GeneralStandings from "../../components/generalStandings/generalStandings";
import BodyComponent from "../../components/MediaDetails/Body/BodyComponent";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import TwistSettings from "../../TwistSettings.json";
import ErrorMsg from "../../components/ErrorPage/ErrorMsg";
import { sharedComponentsContext } from "../../context/shared-context";
import api from "../../apis";
import { useQuery } from "@tanstack/react-query";
import { logFailedApi } from "../../globalFn";
import TwistAds from "../../components/TwistAds";

const apiFn = (mediaId) => api.mediaList.getMediaDetails(mediaId);

const MediaDetails = ({ isSubscribed }) => {
  const {
    seasonInfo: { activeSeason },
  } = useContext(sharedComponentsContext);
  const { id } = useParams();
  const { serviceId, serviceName } = useServiceAndSport();
  const [showStandings, setShowStandings] = useState(true);
  const {
    isLoading,
    isError,
    data = {},
  } = useQuery({
    queryKey: ["media-details", id],
    queryFn: () => apiFn(id),
    onError: () => logFailedApi("media-details"),
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (serviceName === "othersports" || serviceName === "africa")
      setShowStandings(false);
  }, [id, serviceName]);

  if (!isLoading && isError) {
    return <ErrorMsg msg={"حدث خطأ ما"} p={1} fs={"sm"} />;
  }
  if (isLoading) {
    return (
      <Container className='mt-4' data-testid='media-details-loading'>
        <SkeletonTheme
          color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"}
          highlightColor='#ecebeb'>
          <Row>
            <Col xs={12} lg={8}>
              <Skeleton height={400} width={"100%"} />
              <Skeleton height={30} width={"100%"} className='mt-3' />
              <Skeleton height={30} width={"100%"} className='mt-3' />
              <Skeleton height={30} width={"100%"} className='mt-3' />
              <Skeleton height={30} width={"100%"} className='mt-3' />
              <Skeleton height={30} width={"100%"} className='mt-3' />
              <Skeleton height={30} width={"100%"} className='mt-3' />
            </Col>
            <Col xs={12} lg={4}>
              <Skeleton height={"100%"} width={"100%"} />
            </Col>
            <Col xs={12} className='my-3'>
              <Skeleton height={60} width={"100%"} className='mt-5' />
            </Col>
          </Row>
        </SkeletonTheme>
      </Container>
    );
  } else {
    return (
      <Container className='mt-4' data-testid='media-details-data'>
        <Row>
          <Col xs={12} lg={8}>
            <BodyComponent
              mediaSrc={data.media}
              mediaTitle={data.title}
              mediaDesc={data.description}
              mediaDate={data.created_at}
              mediaType={data.type}
              isSubscribed={isSubscribed}
            />
          </Col>
          {showStandings && (
            <Col xs={12} lg={4}>
              <RelatedComponent relatedMedia={data.related} />
              {serviceName === "local" ? (
                <SportsSectionHeader title='ترتيب الدوري' />
              ) : (
                <SportsSectionHeader title='ترتيب الدوري' />
              )}
              <GeneralStandings service_id={serviceId} />
              {/* <TwistAds>
                <TwistAds.IqosAdImage />
              </TwistAds> */}
            </Col>
          )}
          <Col xs={12} className='my-3'>
            <TagsComponent data={data} isLoading={isLoading} />

            {data.type === 1 ? (
              <div data-testid='media-details-type-news'>
                <div className='d-flex justify-content-center container mt-3'>
                  {/* <img src={CompoundAdd} height={250} width={"100%"} /> */}
                </div>
                <SportsSectionHeader title='أخر الأخبار' />
                <AllMedia
                  service_Id={serviceId}
                  season_id={activeSeason.id}
                  sport_id={1}
                  items_count={4}
                  type={1}
                  latest
                />
              </div>
            ) : (
              <div data-testid='media-details-type-vidos'>
                <SportsSectionHeader title='أحدث الفيديوهات' />
                <AllMedia
                  service_Id={serviceId}
                  sport_id={1}
                  season_id={activeSeason.id}
                  items_count={4}
                  type={2}
                  latest
                />
              </div>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
};

export default MediaDetails;
