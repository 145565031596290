import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import "./FitnessHomeMobComponent.css"

const FitnessHomeMobComponent = () => {

  const { pathname } = useLocation();
  return (
    <Container className="homeMobiContainer" fluid>
      <div className="mt-4">
        <p className="text-center text-white">
          {"أهلاً بيك في خدمة تويست فيتنس للياقة مع علي مظهر"}
        </p>
        <Link
          to={{ pathname: "/fitness/gym-workout", state: { previousPathname: pathname } }}
        >
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    {"تمرينات في الجيم"}
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    <IoIosArrowBack />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                {"تمرينات يومياً لمساعدتك معرفة تمرينك اليومي في الجيم"}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link
          to={{ pathname: "/fitness/home-workout", state: { previousPathname: pathname } }}
        >
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    {"تمرينات في المنزل"}
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    <IoIosArrowBack />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                {"لا تفوت الحلقات اليومية للتدريب المنزلي مع علي مظهر للياقة البدنية وإنقاص الوزن"}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link
          to={{ pathname: "/fitness/nutrition-tips", state: { previousPathname: pathname } }}
        >
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    {"برامج تغذية"}
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    <IoIosArrowBack />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                {"شاهد حلقاتنا اليومية عن التغذية الصحيحة للجسم"}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link
          to={{ pathname: "/fitness/aly-mazhar-tips", state: { previousPathname: pathname } }}
        >
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    {"نصائح علي مظهر"}
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    <IoIosArrowBack />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                {"تابع نصائح علي مظهر لتصحيح الأخطاء الشائعة في الرياضة"}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link
          to={{ pathname: "/fitness/ask", state: { previousPathname: pathname } }}
        >
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    {"اسأل علي مظهر"}
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    <IoIosArrowBack />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                {"ابعت سؤالك اواستفسارك الرياضي من هنا وهيوصل ل خبير الرياضة علي مظهر"}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
        <Link
          to={{ pathname: "/fitness/questions", state: { previousPathname: pathname } }}
        >
          <Card className="my-3">
            <Card.Body>
              <Card.Title className="fs-xm color-primary-light">
                <Row>
                  <Col xs={10}>
                    {"إجابات أسئلتكم"}
                  </Col>
                  <Col className="text-center  fs-xxm color-primary-light">
                    <IoIosArrowBack />
                  </Col>
                </Row>
              </Card.Title>
              <Card.Text className="fs-xs">
                {"شاهد علي مظهر وهو يجيب على أسئلتكم"}
              </Card.Text>
            </Card.Body>
          </Card>
        </Link>
      </div>
    </Container>
  );
};

export default FitnessHomeMobComponent;
