import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import getServiceAndSport from './helper';

export default function useServiceAndSport() {
    const [data, setData] = useState({
        route: '',
        serviceId: null,
        sportName: null,
        sportId: null,
        serviceName: '',
    })
    const { pathname } = useLocation();

    useEffect(() => {
        let route = '';
        let { serviceId, serviceName, sportId, sportName } = getServiceAndSport(pathname);
        if (serviceName) {
            route = '/' + serviceName
            route = route.replace('undefined', '').replace('//', '/').replace(/\/$/g, '')
            if (route === '/') { route = ''; }
            if (serviceName === 'othersports' && sportName) {
                route = route + '/' + sportName;
            }
        }
        setData({
            ...data,
            route,
            serviceId,
            serviceName,
            sportId,
            sportName,
        })

    }, [pathname, data.serviceName])

    return data;
}