import React, { useState } from "react";
import { Container } from "reactstrap";
import "react-calendar/dist/Calendar.css";
// import "./TournamentMatches.css";
import TournamentsMatchesMonthsCalendar from "../TournamentsMatchesMonthsCalendar";
import TournamentsMatchesList from "../TournamentsMatchesList";
import api from "../../apis";
import useServiceAndSport from "../../hooks/useServiceAndSport";

const todaysDateFormater = (date = new Date().toLocaleDateString('en-US')) => {
  let [month, day, year] = date.split("/")
  day = ("0" + day).slice(-2);
  month = ("0" + month).slice(-2);
  const formatedDated = `${year}-${month}-${day}`
  return formatedDated
}

function TournamentMatches({ sport_id, tournament_id, team_id }) {

  const [tournamentsMatches, setMatches] = useState([]);
  const [isFetchingTournamentsMatches, setIsFetchingTournamentsMatches] = useState(true);
  const [tournamentsMatchesFailed, setTournamentsMatchesFailed] = useState(false);
  const [selectedDate, setSelectedDate] = useState(todaysDateFormater());


  const { sportId } = useServiceAndSport();
  return <Container>
    <div className='row d-flex justify-content-center'>
      <div className='col-10 col-md-4 my-3'>
        <TournamentsMatchesMonthsCalendar
          getTournamentsMatchesAPI={sportId === 7 ? api.squash.getMatchDetails : api.matches.get}
          APIParams={{ sport_id, tournament_id, team_id }}
          setTournamentsMatches={setMatches}
          setIsFetchingTournamentsMatches={setIsFetchingTournamentsMatches}
          setTournamentsMatchesFailed={setTournamentsMatchesFailed}
          squashSports={sportId === 7}
          setSelectedDate={setSelectedDate}
        />
      </div>
      <TournamentsMatchesList
        tournamentsMatches={tournamentsMatches}
        isFetchingTournamentsMatches={isFetchingTournamentsMatches}
        tournamentsMatchesFailed={tournamentsMatchesFailed}
        todaysDate={todaysDateFormater()}
      />
    </div>
  </Container>
}

export default TournamentMatches
