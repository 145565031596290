import { Col, Modal, ProgressBar, Row } from 'react-bootstrap'
import { shortenName } from '../../globalFn'
import { ImgURL } from '../../globalData'

const statisticsKeys = {
    played: "لعب",
    win: "فوز",
    lose: "خساره",
    draw: "تعادل",
    goals_in: "أهداف له",
    goals_out: "عليه",
    goals_penalty: "أهداف الجزاء",
    clean_sheet: "شباك نظيفة",
    yellow_cards: "كروت صفراء",
    red_cards: "كروت حمراء",
    difference: "فارق الأهداف",
    point: "نقاط",
    passes: "تمريرات ناجحة",
    lost_balls: "كرات ضائعه",
    attempts: "محاولات على المرمى",
    chances: "فرص",
    dribbles: "المراوغات",
    fouls_in: "أخطاء عليه",
    fouls_out: "أخطاء له",
    ball_win: "استعاد الكرة",
    ball_lose: "خسر الكرة",
}

const showedStats = ['goals_in', 'goals_penalty', 'attempts', 'chances', 'dribbles', 'ball_win', 'ball_lose', 'fouls_out', 'fouls_in']

const getAverage = (ArrObj, from, to) => {
    const valuesArr = ArrObj.map((obj, i) => Object.values(obj).slice(from, to))

    const averagearr = []
    for (let i = 0; i < valuesArr.length; i++) {
        for (let x = 0; x < valuesArr[i].length; x++) {
            if (i === 0) {
                averagearr.push(valuesArr[i][x])
            }
            else {
                const item = averagearr[x] + valuesArr[i][x]
                averagearr.splice(x, 1, item)
            }

        }
    }
    return averagearr
}

const H2HModal = ({ show, onHide, H2HTeams }) => {
    const getTotalTeamsStatsDetails = (teamsStats, teamsStatsLength, key) => {
        if (teamsStatsLength < 0) return 0;
        let totalteamsStatsDetails = teamsStats[teamsStatsLength][key] + getTotalTeamsStatsDetails(teamsStats, teamsStatsLength - 1, key);
        return totalteamsStatsDetails;
    }

    return (
        <Modal show={show} onHide={onHide} closeButton>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <Row className='d-flex justify-content-around'>
                    {
                        H2HTeams.map(team =>
                            <Col className='H2H-modal-team mt-3' key={team.id}>
                                <div className='text-center'>
                                    <img src={ImgURL(team.team_logo)} alt="" />
                                    <div className='fs-md'>{shortenName(team.team_name, 12)}</div>
                                </div>
                                {
                                    Object.keys(team).slice(4, 25).map((key, i) =>
                                        showedStats.includes(key) &&
                                        <div className='mt-3' key={key}>
                                            <div className='d-flex justify-content-between'>
                                                <div className='fs-sm'>{statisticsKeys[key]}</div>
                                            </div>
                                            <ProgressBar
                                                now={team[key]}
                                                label={team[key]}
                                                max={getTotalTeamsStatsDetails(H2HTeams, H2HTeams.length - 1, key) || 1}
                                            />
                                        </div>
                                    )
                                }

                            </Col>)
                    }
                </Row>
            </Modal.Body>
        </Modal>
    )
}


export default H2HModal;