import placeHolderAvatar from "../src/assets/user.png"

const StatisticsKeys = () => {
    return {
        played: "لعب",
        win: "فوز",
        lose: "خساره",
        draw: "تعادل",
        goals_in: "عليه",
        goals_out: "أهداف له",
        goals_penalty: "أهداف الجزاء",
        clean_sheet: "شباك نظيفة",
        yellow_cards: "كروت صفراء",
        red_cards: "كروت حمراء",
        difference: "فارق الأهداف",
        point: "نقاط",
        passes: "تمريرات ناجحة",
        lost_balls: "كرات ضائعه",
        attempts: "محاولات على المرمى",
        chances: "فرص",
        dribbles: "المراوغات",
        fouls_in: "أخطاء عليه",
        fouls_out: "أخطاء له",
        ball_win: "استعاد الكرة",
        ball_lose: "خسر الكرة",
        appearance: "لعب",
        missed_penalty: "اضاع بنلتي",
        played_minutes: "دقائق اللعب",
        assists: "صانع أهداف",
        reversed_goals: "هدف في مرماه",
        goals: "أهداف"
    }
}

const IDS = {
    SERVICES: {
        PUBLIC: null,
        LOCAL: 7,
        INTERNATIONAL: 8,
        AFRICA: 9,
        OTHERSPORTS: 10,
        MELOUKELKORA: 14,
        FITNESS: 15,
    },
    SPORTS: {
        FOOTBALL: 1,
        TENNIS: 2,
        BASKETBALL: 3,
        HOCKEY: 4,
        VOLLEYBALL: 5,
        HANDBALL: 6,
        SQUASH: 7,
        SWIMMING: 8,
    },
    SECTIONS: {
        FL3AGLAELSALAMA: 4,
        ROADTOCHAMPIONSHIP: 10,
        FUN2FIT: 7,
        ADELSAAD: 9,
        AFRICASTORIES: 8,
    },
    PORTALS: {
        NEW_ET: 1,
        TWIST: 3,
    }
}

const ImgURL = (src, isPlayer) => {
    if (src === null || src === undefined) {
        return isPlayer ? placeHolderAvatar : ""
    }

    if (src?.startsWith("http")) {
        return src;
    } else {
        return process.env.REACT_APP_NEW_MEDIA_URL + src
    }
}


const changeStructure = (oldS) => {
    const newS = oldS;
    newS.manager_name = oldS.player_one
    newS.manager_name = oldS.player_one
    return newS
}

const globalBaseUrl = {
    current: process.env.REACT_APP_PERFORMANCE_BASE_URL
}

export { StatisticsKeys, IDS, ImgURL, changeStructure, globalBaseUrl };