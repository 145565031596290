import { useContext, useEffect } from "react";
import { Route, Switch } from "react-router-dom";
import "./App.css";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import TournamentsPage from "./Pages/Competition/Competition";
import MatchesPage from "./Pages/Matches/Matches";
import MatchPage from "./Pages/MatchPage/MatchPage.component";
import News from "./Pages/News/News";
import player from "./Pages/Player/player";
import Team from "./Pages/Team/Team";
import Tournament from "./Pages/Tournament/Tournament";
import Videos from "./Pages/Videos";
import Section from "./Pages/Section";
import OtherSports from "./Pages/OtherSports/OtherSports";
import TermsAndConditions from "./Pages/TermsAndConditions";
import PageNotFound from "./Pages/PageNotFound/PageNotFound";
import Subscription from "./Pages/Subscription/Subscription";
import useSubscriptionCookieValidator from "./hooks/useSubscriptionCookieValidator";
import Tennis from "./Pages/Tennis/Tennis";
import Handball from "./Pages/Handball/Handball";
import Squash from "./Pages/Squash/Squash";
import MediaDetails from "./Pages/MediaDetails/MediaDetails";
import TwistSettings from "./TwistSettings.json";
import AfterVisaPayment from "./components/AfterPaymentViews/AfterVisaPayment";
import AfterFawryPayment from "./components/AfterPaymentViews/AfterFawryPayment";
import FitnessLayout from "./apps/TwistFitness/layout/FitnessLayout";
import MeloukLayout from "./apps/melouk/layout";
import Home from "./Pages/Home/Home";
import International from "./Pages/International/International";
import Local from "./Pages/Local/Local";
import Africa from "./Pages/Africa/Africa";
import Games from "./components/Games";
import { sharedComponentsContext } from "./context/shared-context";
import { useQuery } from "@tanstack/react-query";
import api from "./apis";
// import Cookies from "universal-cookie";
import { logFailedApi } from "./globalFn";
import TwistPrivacy from "./components/TwistPrivacy";

const apiFn = (params) => api.seasons.getSeasons(params);

function App() {
  /////////////////////////////////////////////////////////////////////////////////
  ////////////////////// START OF REDIRECTION LOGIC ///////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////
  ////////////////////// test by sherif ///////////////////////////////
  // const cookies = new Cookies();
  // let vistesNum = cookies.get("Vistis");

  // ///in case an infinte refresh loop happens this could stop it
  // function handleRedirectionIfUsingHttps() {
  //   if (vistesNum === undefined) {
  //     vistesNum = 1;
  //     cookies.set("Vistis", vistesNum, {
  //       path: "/",
  //       maxAge: `${6}`,
  //       domain: window.location.hostname,
  //     });
  //   } else {
  //     vistesNum++;
  //     cookies.set("Vistis", vistesNum, {
  //       path: "/",
  //       maxAge: `${6}`,
  //       domain: window.location.hostname,
  //     });
  //   }
  // }

  // const originUrl = window.location.origin;
  // useEffect(() => {
  //   handleRedirectionIfUsingHttps();
  //   if (originUrl.includes("https:") && vistesNum < 2) {
  //     // window.location.href = 'https://05c4-197-46-105-164.ngrok-free.app/'
  //     // window.location.href = 'http://www.twistsports.com/international' // commented by waleed for now
  //     const url = window.location.href;
  //     const qrIndex = url.indexOf("?Qr=");
  //     window.location.href =
  //       qrIndex > 0
  //         ? `http://www.twistsports.com/${url.substring(qrIndex)}`
  //         : "http://www.twistsports.com";
  //   }
  // }, []);
  /////////////////////////////////////////////////////////////////////////////////
  //////////////////////  END OF REDIRECTION LOGIC ////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////

  useSubscriptionCookieValidator();
  const { setInitialSharedValues } = useContext(sharedComponentsContext);
  useQuery({
    queryKey: ["app-active-season"],
    queryFn: () => apiFn({ parent: 1 }),
    onSuccess: ({ data, lastSeason }) =>
      setInitialSharedValues((prev) => {
        return {
          ...prev,
          seasonInfo: {
            ...prev.seasonInfo,
            Seasons: data,
            activeSeason: lastSeason,
            appActiveSeason: lastSeason,
          },
        };
      }),
    onError: () => logFailedApi("app-active-season"),
    refetchOnWindowFocus: false,
  });

  const getTwistUIConfig = () => {
    if (TwistSettings["twist-ui"]) {
      document.body.classList.add("twist-ui");
    }
  };

  useEffect(() => {
    getTwistUIConfig();
  }, []);

  useEffect(() => {
    window.document.body.lang = "ar";
    window.document.documentElement.lang = "ar";
    document.body.dir = "rtl";
    document.title = TwistSettings["twist-ui"]
      ? "Twist Sports | تويست سبورتس"
      : "Etisalat Sports | اتصالات سبورتس";
  }, []);

  return (
    <div>
      <Switch>
        <Route
          exact
          path='/:serviceName/subscribe'
          render={(props) => <Subscription {...props} />}
        />

        {/* Home */}
        <Route exact path='/' component={Home} />
        <Route exact path='/news' component={News} />
        <Route exact path='/videos' component={Videos} />
        <Route exact path='/tournaments' component={TournamentsPage} />
        <Route exact path='/matches' component={MatchesPage} />
        <ProtectedRoute exact path='/games' component={Games} />

        {/* Sections */}
        <ProtectedRoute
          exact
          path='/sections/:sectionName/:subSectionName?/'
          component={Section}
        />

        {/* Services */}
        <ProtectedRoute exact path='/international' component={International} />
        <ProtectedRoute exact path='/local' component={Local} />
        <ProtectedRoute exact path='/africa' component={Africa} />
        <ProtectedRoute exact path='/othersports' component={OtherSports} />

        {/* Other Sports */}
        <ProtectedRoute exact path='/othersports/tennis' component={Tennis} />
        <ProtectedRoute exact path='/othersports/squash' component={Squash} />
        <ProtectedRoute
          exact
          path='/othersports/handball'
          component={Handball}
        />

        {/* Apps */}
        <ProtectedRoute path='/meloukelkora' component={MeloukLayout} />
        <ProtectedRoute path='/fitness' component={FitnessLayout} />

        {/* Shared Routes */}
        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/news'
          component={News}
        />
        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/videos'
          component={Videos}
        />
        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/tournaments'
          component={TournamentsPage}
        />
        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/matches'
          component={MatchesPage}
        />

        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/tournament/:id/:tab?'
          component={Tournament}
        />
        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/team/:id/:tab?'
          component={Team}
        />
        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/player/:id/:tab?'
          component={player}
        />
        <ProtectedRoute
          path='/:serviceName/:sportName?/match/:id'
          component={MatchPage}
        />
        <ProtectedRoute
          exact
          path='/:serviceName/:sportName?/media/:id'
          component={MediaDetails}
        />
        <Route
          exact
          path='/afterPayment'
          render={(props) => <AfterVisaPayment {...props} />}
        />
        <Route
          exact
          path='/afterFawryPayment'
          render={(props) => <AfterFawryPayment {...props} />}
        />
        <Route exact path='/terms-conditions' component={TermsAndConditions} />
        <Route exact path='/privacy' component={TwistPrivacy} />
        <Route path='*'>
          <PageNotFound />
        </Route>
      </Switch>
    </div>
  );
}

export default App;
