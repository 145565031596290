import React, { useState, useEffect } from "react";
import "./index.css"
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import guessPlayerImg from "../../../../../src/assets/games/guess-player.svg";
import Row from "react-bootstrap/Row";
import { ImgURL } from "../../../../globalData";
import GameSuccessView from "../../GameSuccessView/GameSuccessView";
import { getUserPhoneNumbers } from "../../../../globalFn";
import { sendGameReport } from "../helper";
import shirtIcon from '../../../../../src/assets/games/shirticon.png'
import playerIcon from '../../../../../src/assets/games/playerIcon.png'
import arrowIcon from '../../../../../src/assets/games/long-arrow-alt-right.svg'
import emptyShirtIcon from '../../../../../src/assets/games/icons8-t-shirtIconEmpty.png'
import api from "../../../../apis";
import { useQuery, useMutation } from "@tanstack/react-query";
import { handleOnShirtSelect, handleOnShirtUnselect } from "./utils";
import { logFailedApi } from "../../../../globalFn";

const apiGetFn = () => api.matchThePlayer.get()
const apiPostFn = (params) => api.matchThePlayer.post(params)

export const MatchThePlayer = ({ show, onHide }) => {

    const [showSuccessView, setShowSuccessView] = useState(false);
    const [panelNum, setPanelNum] = useState(1);
    const [playersArr, setPlayersArr] = useState([])
    const [shirtsArr, setShirtsArr] = useState([])

    const msisdn = getUserPhoneNumbers()?.[0]

    const { data = [], isLoading, isError: errorMsg } = useQuery({
        queryKey: ["match-the-player", msisdn],
        queryFn: () => apiGetFn(),
        onError: () => logFailedApi("match-the-player"),
        select: (data) => data.players.map((player) => (
            {
                ...player,
                isSelected: false,
                givenShirtNum: null,
                shirt_number: player.shirt_number == null ? 69 : player.shirt_number,
                player_ids: [data.player1_id, data.player2_id, data.player3_id],
                question_id: data.id
            }
        ))
    })

    const { mutate, isLoading: answerLoading, isError: answerError } = useMutation(
        apiPostFn,
        {
            onSuccess: () => {
                setShowSuccessView(true);
                const setTimeOutID = setTimeout(() => {
                    onHide();
                    clearTimeout(setTimeOutID);
                }, 2000);
            },
            onError: () => logFailedApi("match-the-player"),
        }
    )

    useEffect(() => {
        if (!isLoading) {
            const shirtsArr = data.sort(() => Math.random() - 0.5).map((player => (
                { num: player.shirt_number, isSelected: false }
            )))
            setShirtsArr(shirtsArr)
            setPlayersArr(data)
        }
    }, [isLoading])

    const handleSubmitAnswer = () => {
        if (panelNum == 1) setPanelNum(2)
        if (shirtsArr.every(shirt => shirt.isSelected == true)) {

            const playerIdsArr = data[0].player_ids

            const player1 = playersArr.filter((player) => {
                return player.id == playerIdsArr[0]
            })[0].givenShirtNum

            const player2 = playersArr.filter((player) => {
                return player.id == playerIdsArr[1]
            })[0].givenShirtNum

            const player3 = playersArr.filter((player) => {
                return player.id == playerIdsArr[2]
            })[0].givenShirtNum

            const answerBody = {
                msisdn: msisdn,
                match_the_players_id: data[0].question_id,
                player1,
                player2,
                player3,
            }

            mutate(answerBody)
        }
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            contentClassName={"modal__content"}
            dialogClassName="modal__dialog"
            className="match-the-player"
        >
            <Modal.Header className="modal__header pt-3 p-3" closeButton />
            <Modal.Body className="show-grid">
                <Container>
                    {showSuccessView
                        ? <GameSuccessView />
                        : panelNum == 1
                            ? (
                                <Row className=" justify-content-center">
                                    <Col
                                        xs={12}
                                        className="modal__bodyTitle text-center text-white m-2 d-flex flex-column align-items-center"
                                    >
                                        <h3 className="mb-4"> {"خمن رقم اللاعب !"}</h3>
                                        <p>
                                            {"وصّل كل لاعب بالقميص الصحيح له. "}
                                        </p>
                                        <div className="panel1-imgs mt-4 d-flex justify-content-center">
                                            <img className="mx-2" src={shirtIcon} alt="shirtIcon" />
                                            <img className="mx-1" src={arrowIcon} alt="arrowIcon" />
                                            <img className="mx-2" src={playerIcon} alt="playerIcon" />
                                        </div>
                                    </Col>
                                </Row>
                            )
                            : (
                                <Row className=" justify-content-center">
                                    <Col
                                        xs={12}
                                        className="modal__bodyTitle text-center text-white m-2"
                                    >
                                        <h3 className="mb-4"> {"خمن رقم اللاعب !"}</h3>
                                        <p className="mb-4"> {"اضغط على الرقم لاختيار أول لاعب من اليمين ثم اللاعب الثاني والثالث والضغط على تأكيد"}</p>
                                        <div className="mt-4 d-flex flex-column align-items-center">
                                            <ul className="player-wraper d-flex my-5">
                                                {playersArr.map((player) => {
                                                    return (
                                                        <li
                                                            key={player.id}
                                                            onClick={() => {
                                                                handleOnShirtUnselect(player.givenShirtNum, playersArr, shirtsArr, setPlayersArr, setShirtsArr)
                                                            }}
                                                            style={{ cursor: `${player.isSelected ? "pointer" : "default"}` }}
                                                        >
                                                            <img src={player.image ? ImgURL(player.image) : guessPlayerImg} alt={player.name} />
                                                            <div
                                                                style={{ display: `${player.isSelected ? "block" : "none"}` }}
                                                            >
                                                                <img src={emptyShirtIcon} alt="shirt icon" />
                                                                <span>{player.givenShirtNum}</span>
                                                            </div>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                            <ul className="numbers-wraper d-flex my-5">
                                                {shirtsArr.map((shirt) => {
                                                    return (
                                                        <li
                                                            key={shirt.num}
                                                            onClick={() => {
                                                                handleOnShirtSelect(shirt.num, playersArr, shirtsArr, setPlayersArr, setShirtsArr)
                                                            }}
                                                            style={{
                                                                display: `${!shirt.isSelected ? "block" : "none"}`
                                                            }}
                                                        >

                                                            <img src={emptyShirtIcon} alt="shirt icon" />
                                                            <span>{shirt.num}</span>
                                                        </li>
                                                    )
                                                })}
                                            </ul>
                                        </div>
                                    </Col>
                                </Row>
                            )
                    }
                </Container>
            </Modal.Body>
            {!showSuccessView && (
                <Modal.Footer className="modal__footer d-flex flex-column justify-content-center m-0">
                    <Button
                        className="text-capitalize"
                        onClick={handleSubmitAnswer}
                        style={{
                            backgroundColor: shirtsArr.every(shirt => shirt.isSelected == true)
                                ? "var(--tealish-green)"
                                : ""
                        }}
                    >
                        {isLoading ? (
                            <div
                                className="spinner-border text-light fs-xs p-0"
                                role="status"
                                data-testid="guessThePlayerGame-submitBtn-loading"
                            ></div>
                        ) : (
                            panelNum == 1 ? "ابدأ" : "تأكيد"
                        )}
                    </Button>
                    <p className="error-msg">{errorMsg}</p>
                </Modal.Footer>
            )}
        </Modal>
    )
}
