import React from "react";
import { Button, Container, ButtonGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import BannerWebComponent from "../../components/banner/BannerWebComponent";
import BannerMobComponent from "../../components/banner/BannerMobComponent";
import ArticleCardComponent from "../../components/article/ArticleCardComponent"

const NutritionTips = () => {

    const { pathname } = useLocation();
    return (
        <div>
            <div className="d-none d-xl-block">
                <BannerWebComponent
                    title={"نصائح التغذية"}
                    subTitle={"اتبع نصائح التغذية اليومية مع على مظهر"}
                />
            </div>
            <div className="d-xl-none">
                <Container>
                    <ButtonGroup className="w-100 nutrition-btn-grp">
                        <Button
                            className="btn w-50 mx-auto my-3 fs-xs active"
                            as={Link}
                            to={{ pathname: "/fitness/nutrition-tips", state: { previousPathname: pathname } }}
                        >
                            {"نصائح"}
                        </Button>
                        <Button
                            className="btn w-50 mx-auto my-3 fs-xs"
                            as={Link}
                            to={{ pathname: "/fitness/nutrition-episodes", state: { previousPathname: pathname } }}
                        >
                            {"حلقات"}
                        </Button>
                    </ButtonGroup>
                </Container>
                <BannerMobComponent
                    title={"نصائح التغذية"}
                    subTitle={"اتبع نصائح التغذية اليومية مع على مظهر"}
                />
            </div>
            <ArticleCardComponent />

        </div>
    );
};

export default NutritionTips