import React, { useRef, useEffect, useState } from "react";
import { useParams } from "react-router";
import VideoDetailsComponent from "../../components/videos/VideoDetailsComponent";
import VideoCardWebComponent from "../../components/videos/VideoCardWebComponent";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { logFailedApi } from "../../../../globalFn";

const apiFn = (contentType, key) => {
  if (key == "ali-mazhar-answers") return api.fitness.aliMazharContent.getAliAnswers()
  if (key == "ali-mazhar-tips") return api.fitness.aliMazharContent.getAliTipsOrVideos(contentType)
  if (key == "ali-mazhar-nutrition-episodes") return api.fitness.aliMazharContent.getAliTipsOrVideos(contentType)
}

const getDetails = (type, id, fetchedData) => {
  switch (type) {
    case "nutrition":
      return {
        video: fetchedData?.results?.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: fetchedData?.results?.filter(
          (video) => video.id !== parseInt(id, 10)
        ),
        bannerText: 'فيديوهات المتعلقة',
      };

    case "questions":
      return {
        video: fetchedData?.results?.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: fetchedData?.results?.filter((video) => video.id !== parseInt(id, 10)),
        bannerText: 'فيديوهات المتعلقة',
      };

    case "amt":
      return {
        video: fetchedData?.results?.filter(
          (video) => video.id === parseInt(id, 10)
        )[0],
        related: fetchedData?.results?.filter(
          (video) => video.id !== parseInt(id, 10)
        ),
        bannerText: "متسابقين آخرين",
      };
    default:
      return "test";
  }
};


const VideoDetails = () => {

  const [fetchedData, setFetchedData] = useState([])
  const { type, id } = useParams();
  const ref = useRef();

  const { isFetching: isFetchingAnswers } = useQuery({
    queryKey: ["ali-mazhar-answers"],
    queryFn: () => apiFn(null, "ali-mazhar-answers"),
    refetchOnWindowFocus: false,
    enabled: type == "questions",
    onSuccess: (data) => setFetchedData(data),
    onError: () => logFailedApi("ali-mazhar-answers"),
  })

  const { isFetching: isFetchingTips } = useQuery({
    queryKey: ["ali-mazhar-tips"],
    queryFn: () => apiFn("AMT", "ali-mazhar-tips"),
    refetchOnWindowFocus: false,
    enabled: type == "amt",
    onSuccess: (data) => setFetchedData(data),
    onError: () => logFailedApi("ali-mazhar-tips"),
  })

  const { isFetching: isFetchingEpisodes } = useQuery({
    queryKey: ["ali-mazhar-nutrition-episodes"],
    queryFn: () => apiFn("VIDEOS", "ali-mazhar-nutrition-episodes"),
    refetchOnWindowFocus: false,
    enabled: type == "nutrition",
    onSuccess: (data) => setFetchedData(data),
    onError: () => logFailedApi("ali-mazhar-nutrition-episodes"),
  })

  useEffect(() => {
    ref?.current?.scrollIntoView();
  }, [id, type]);

  if (isFetchingAnswers || isFetchingEpisodes || isFetchingTips) {
    return <div className="text-center text-white py-3">{"جاري التحميل ..."}</div>;
  } else {

    let content = getDetails(type, id, fetchedData);

    return (
      <div ref={ref}>
        <div ref={ref}>


          <VideoDetailsComponent
            type={type}
            video={content?.video}
          />
          {content?.related?.length > 0
            ? (
              <div>
                <VideoCardWebComponent
                  type={type}
                  colXsSize={12}
                  colMdSize={6}
                  colLgSize={4}
                  videos={content?.related.slice(0, 3)}
                />
              </div>
            )
            : null
          }

        </div>
      </div>
    );
  }
};

export default VideoDetails
