import React from "react";
import { Image } from "react-bootstrap";
// import twistLogo from "../../assets/img/twist-logo.png";
import etisalteLogo from "../../assets/img/transparentLogo.png";
import twistLogo from '../../assets/Twist-footer-logo-3-2023.png'
import TwistSettings from '../../TwistSettings.json'

export default function HeaderComponent({ oneServiceLoading }) {

  return (
    <div className="text-center">
      <Image src={TwistSettings["twist-ui"] ? twistLogo : etisalteLogo} height={100} className="mb-4" />
      {/* <p className="fs-xm mx-auto text-capitalize active-color mb-3">
        {TwistSettings["twist-ui"]?"انضم إلى مجتمع تويست الرياضي":"انضم إلى مجتمع اتصالات الرياضي"}
      </p> */}
      {!oneServiceLoading && <p className="fs-sm m-auto text-capitalize white-color">
        {
          !TwistSettings['subscription']
            ? "رقم الهاتف المحمول"
            : "ضع رقمك وسنرسل لك رمز تحقق"
        }
      </p>}
    </div>
  );
}
