import React, { useContext } from "react";
import GeneralStandings from "../../components/generalStandings/generalStandings";
import GeneralStatistics from "../../components/generalStatistics/generalStatistics";
import MatchesCalendar from "../../components/MatchesCalendar/MatchesCalendar";
import LatestNews from "../../components/News/LatestNews";
import TopStories from "../../components/News/TopStories";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import TournamentsCarousel from "../../components/TournamentsCarousel/TournamentsCarousel";
import NewsSearch from "../../components/News/NewsSearch";
import { IDS } from "../../globalData";
import AllMedia from "../../components/News/AllMedia";
import TwistSettings from "../../TwistSettings.json";
import OnDemand from "../../components/OnDemand";
import { sharedComponentsContext } from "../../context/shared-context";

const Local = () => {
  const { NewsSearchQuery, seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);

  return (
    <div className="container home-page ">
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
        </div>
      </div>

      <OnDemand>
        <MatchesCalendar
          sport_id={IDS.SPORTS.FOOTBALL}
          service_id={IDS.SERVICES.LOCAL}
          season_id={null}
          tournament_id={null}
        />
      </OnDemand>
      <SportsSectionHeader
        title="أهم الأحداث"
        navigateTo="/local/news"
      />
      <TopStories
        sport_id={IDS.SPORTS.FOOTBALL}
        service_id={IDS.SERVICES.LOCAL}
        season_id={null}
        items_count={4}
        media_type={1}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={true}
        latest={null}
        popular={null}
      />
      <hr></hr>

      <div className="row align-items-baseline">
        <div className="col-12 col-md-6 fs-xs">
          <SportsSectionHeader
            title="ترتيب الدوري"
          />
          <OnDemand>
            <GeneralStandings
              sport_id={IDS.SPORTS.FOOTBALL}
              service_id={IDS.SERVICES.LOCAL}
              season_id={null}
            />
          </OnDemand>
        </div>
        <div className="col-12 col-md-6 fs-md">
          <div className='text-light mt-5'>
            <SportsSectionHeader title={"اختر بطولة"} />
          </div>
          <OnDemand>
            <TournamentsCarousel
              sport_id={IDS.SPORTS.FOOTBALL}
              service_id={IDS.SERVICES.LOCAL}
            />
          </OnDemand>

        </div>
      </div>
      <div className="col-12">
        {/* <img src={Cokebanner} height={200} width={"100%"} className="mt-3" /> */}
      </div>
      <SportsSectionHeader
        title="أخر الأخبار"
        navigateTo="/local/news"
      />
      <OnDemand>
        <NewsSearch />
        <div className={`${NewsSearchQuery.length > 0 ? "d-none" : ""} my-2`}>
          <LatestNews
            sport_id={IDS.SPORTS.FOOTBALL}
            service_id={IDS.SERVICES.LOCAL}
            season_id={activeSeason.id}
            items_count={9}
            media_type={1}
            latest={true}
          />
        </div>
      </OnDemand>
      <hr />
      {TwistSettings["videos"] &&
        <><SportsSectionHeader
          title="أحدث الفيديوهات"
          navigateTo="/local/videos"
        />
          <OnDemand>
            <AllMedia
              sport_id={IDS.SPORTS.FOOTBALL}
              service_id={IDS.SERVICES.LOCAL}
              season_id={activeSeason.id}
              items_count={8}
              type={2}
              tournament_id={null}
              media_id={null}
              team_id={null}
              player_id={null}
              top_stories={null}
              latest={true}
              popular={null}
            />
          </OnDemand>
          <hr />
        </>
      }

      <SportsSectionHeader
        title="الأكثر تداولا"
        navigateTo="/local/news"
      />
      <OnDemand>
        <AllMedia
          sport_id={IDS.SPORTS.FOOTBALL}
          service_id={IDS.SERVICES.LOCAL}
          season_id={activeSeason.id}
          items_count={12}
          type={1}
          tournament_id={null}
          media_id={null}
          team_id={null}
          player_id={null}
          top_stories={null}
          latest={null}
          popular={true}
        />
      </OnDemand>
      <hr />
      <div className="row">
        <div className="col-12 col-md-6 mt-4">
          <OnDemand>
            <GeneralStatistics
              sport_id={IDS.SPORTS.FOOTBALL}
              service_id={IDS.SERVICES.LOCAL}
              season_id={null}
            />
          </OnDemand>
        </div>
      </div>
    </div>
  );
};

export default Local;
