import React from "react";
import TwistSettings from "../../TwistSettings.json";
import "./SampleArrow.css";

function SampleArrowBtn({
  arrowDirection = "right",
  onClick,
  styles,
  isDisabled,
  service_id
}) {
  let direction;
  arrowDirection === "right"
    ? (direction = "M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z")
    : (direction = "M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z")

  return (
    <button
      className={`reusableBtn animation swipe-animation btn ${arrowDirection == "left" ? "left" : ""} ${service_id === 9 ? 'disabled' : ""}`}
      style={styles}
      onClick={onClick}
      disabled={isDisabled}
    >
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="15"
          fill="red"
          className={`bi bi-chevron-${arrowDirection}`}
          viewBox="0 0 16 16"
        >
          <path
            stroke={TwistSettings["twist-ui"] ? "#62D86E" : "#e00800"}
            strokeWidth="3"
            fillRule="evenodd"
            d={direction}
          />
        </svg>
      </div>

    </button>
  );
}

export default SampleArrowBtn;
