import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import getServiceAndSport from '../helper';
import api from '../../apis';
import { getLoggingsParams } from './helper';
import Cookies from 'universal-cookie';


export default function useLogging(to_url) {
  const cookies = new Cookies();
  const { location: { pathname, state } } = useHistory()
  const { serviceId: service_id } = getServiceAndSport(pathname)

  const {
    ip_address,
    msisdn,
    section_id,
    user_session,
    source,
  } = getLoggingsParams()

  const sendLogFn = (ip) => {
    api.reports.sendLog({
      from_url: state?.previousPathname,
      ip_address: ip,
      msisdn,
      section_id,
      service_id,
      user_session,
      source,
      to_url: to_url ?? pathname,
    })
  }

  useEffect(() => {
    if (!ip_address) {
      const getIp = async () => {
        const ip = await api.reports.getIp();
        cookies.set('ip_address', ip, { path: "/", maxAge: `${60 * 60 * 12}` })
        sendLogFn(ip)
      }
      getIp()
    } else sendLogFn(ip_address)
  }, [pathname, section_id, source]);
  // }, [ip_address, source, service_id, pathname, section_id]);

  return null;
}