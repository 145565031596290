import { useContext } from 'react'
import { BarChart, Bar, Cell } from "recharts";
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';
import { makeOrderedColoredStats } from './helper';
import api from '../../apis';
import { useQuery } from '@tanstack/react-query';
import { sharedComponentsContext } from '../../context/shared-context';
import { logFailedApi } from '../../globalFn';

const tableTextAR = {
    "Player.Player": "الاعب",
    "Player.Nat": "الجنسيه",
    "Player.Age": "السن",
    "Player.Marketvalue": "القيمة السوقيه",
    "Player.Height": "الطول",
    "Player.Weight": "الوزن",
    "Player.DateOfBirth": "تاريخ الميلاد",
    "Player.CurrentLeague": "الدوري الحالي",
    "Player.TransferHistory": "تاريخ الانتقالات",
    "Player.Date": "الناريخ",
    "Player.Left": "غادر",
    "Player.Joined": "انضم",
    "Trans/Player.Loan": "اعاره/انتقال",
    "Player.Transfer": "انتقال",
    "Player.Loan": "اعاره",
    "Player.Fees": "السعر",
    "Player.MainPosition": "المركز الاساسي",
    "Player.Assists": "صناعة أهداف",
    "Player.MinutesPlayed": "دقائق اللعب",
    "Player.Appearance": "لعب",
    "StatsTable.PlayerStats": "احصائيات الاعب",
    "StatsTable.MissedPenalty": "اضاع بنلتي",
    "StatsTable.ReversedGoals": "هدف في مرماه",
    "StatsTable.Goals": "أهداف",
    "StatsTable.Prizes": "جوائز",
    "StatsTable.Stats": "احصائيات",
    "StatsTable.Position": "المركز",
    "StatsTable.Team": "الفريق",
    "StatsTable.Matches": "مباريات",
    "StatsTable.YellowCards": "كروت صفراء",
    "StatsTable.RedCards": "كروت حمراء",
    "StatsTable.Penalty": "ركلات جزاء",
    "StatsTable.Played": "لعب",
    "StatsTable.Wins": "فوز",
    "StatsTable.Losses": "خساره",
    "StatsTable.Draw": "تعادل",
    "StatsTable.GoalsIn": "أهداف له",
    "StatsTable.GoalsOut": "عليه",
    "StatsTable.GoalsDifference": "فارق الأهداف",
    "StatsTable.Difference": "فارق",
    "StatsTable.GoalsPenalty": "أهداف الجزاء",
    "StatsTable.CleanSheet": "شباك نظيفة",
    "StatsTable.Points": "نقاط",
    "StatsTable.Last5matches": "أخر 5 مباريات",
    "StatsTable.passes": "تمريرات ناجحة",
    "StatsTable.LostBalls": "كرات ضائعه",
    "StatsTable.Attempts": "محاولات على المرمى",
    "StatsTable.Chances": "فرص",
    "StatsTable.Dribbles": "المراوغات",
    "StatsTable.FoulsIn": "أخطاء عليه",
    "StatsTable.FoulsOut": "أخطاء له",
    "StatsTable.BallWin": "استعاد الكرة",
    "StatsTable.BallLose": "خسر الكرة",
    "StatsTable.ScoringIntervals": "دقائق التهديف",
    "StatsTable.TotalCross": "العرضيات"
}

const apiFn = (params) => api.player.getPlayerStatistics(params)

const PlayerStatsChart = ({
    sport_id,
    season_id,
    team_id,
    player_id,
}) => {
    const { playerDetails: { currentTeam, currentTeamActiveTournament } } = useContext(sharedComponentsContext)
    const { isLoading, isError, data = {} } = useQuery({
        queryKey: ["player-statistics", season_id, currentTeamActiveTournament?.tournament_id, currentTeam?.id, player_id],
        queryFn: () => apiFn({ season_id, tournament_id: currentTeamActiveTournament?.tournament_id, team_id: currentTeam?.id, player_id }),
        select: ({ data }) => data,
        onError: () => logFailedApi("player-statistics"),
        enabled: season_id !== null && !!currentTeamActiveTournament.id && !!currentTeam.id && !!player_id,
        refetchOnWindowFocus: false,
    });
    const playerStatisticsList = makeOrderedColoredStats(data);


    return (
        <div>
            {currentTeamActiveTournament?.id && <SeasonsDropdown tournament_id={currentTeamActiveTournament?.tournament_id} />}
            <div className='bg-light-black-color p-3 mx-3'> احصائيات الاعب</div>
            {isLoading
                ? <div className='mx-3'>
                    <LoadingSpinnner p={5} fs={'md'} />
                </div> :
                isError || !playerStatisticsList.length
                    ? <div className='mx-3'>
                        <ErrorMsg p={5} fs={'lg'} msg="لا يوجد احصائيات" />
                    </div>
                    : <div className='row bg-black-color justify-content-between justify-content-md-around align-items-end  p-md-2 mx-3'>
                        <div className='col-4 col-md-5 fs-xxs p-0'>
                            {
                                playerStatisticsList?.map(stat =>
                                    <div key={stat.name} className='d-flex justify-content-between my-3 mx-2 mx-md-0'>
                                        <div>
                                            <span style={{ minHeight: '15px', minWidth: '15px', background: `${stat.color}`, color: 'transparent' }}>.....</span>
                                            <span className='mx-2'>{tableTextAR[stat.name]}</span>
                                        </div>
                                        <div>
                                            {stat.value}
                                        </div>
                                    </div>)
                            }
                        </div>
                        <div className='col-7 d-flex flex-column align-items-end p-0'>
                            <BarChart width={230} height={500} data={playerStatisticsList}>
                                <Bar dataKey="value" fill='red'>
                                    {playerStatisticsList.map(({ color }) => (
                                        <Cell key={color} fill={color} width={35} />
                                    ))
                                    }
                                </Bar>
                            </BarChart>
                        </div>
                    </div>
            }
        </div>
    )
}

export default PlayerStatsChart;