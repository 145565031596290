import { Container, Image, Navbar, NavDropdown, Nav } from "react-bootstrap";
import React from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import fitnessLogo from "../../assets/fitnessOnly-logo.png"
import "./FitnessNavbarWeb.css";

const FitnessNavbarWeb = () => {

  const { pathname } = useLocation();

  return (
    <Container className=" navbar_2 color-danger d-none d-xl-block">
      <Navbar collapseOnSelect expand="lg">
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Brand
          to={{ pathname: "/fitness/home", state: { previousPathname: pathname } }}
        >
          <Image src={fitnessLogo} className="navbar-logo img-fluid " />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav>
            <NavLink to={{ pathname: "/fitness/home", state: { previousPathname: pathname } }} className="fs-sm my-auto mx-2" exact={true}>
              {"الرئيسية"}
            </NavLink>
            <NavLink to={{ pathname: "/fitness/gym-workout", state: { previousPathname: pathname } }} className="fs-sm my-auto mx-2">
              {"تمرينات في الجيم"}
            </NavLink>
            <NavLink to={{ pathname: "/fitness/home-workout", state: { previousPathname: pathname } }} className="fs-sm my-auto mx-2">
              {"تمرينات في المنزل"}
            </NavLink>
            <NavLink to={{ pathname: "/fitness/aly-mazhar-tips", state: { previousPathname: pathname } }} className="fs-sm my-auto mx-2">
              {"نصائح علي مظهر"}
            </NavLink>
            <NavLink to={{ pathname: "/fitness/ask", state: { previousPathname: pathname } }} className="fs-sm my-auto mx-2">
              {"اسأل علي مظهر"}
            </NavLink>
            <NavLink to={{ pathname: "/fitness/questions", state: { previousPathname: pathname } }} className="fs-sm my-auto mx-2">
              {"إجابات أسئلتكم"}
            </NavLink>
            <NavDropdown
              title={"برامج تغذية"}
              id="collasible-nav-dropdown"
              className="fs-sm"
            >
              <NavDropdown.Item
                as={Link}
                to={{ pathname: "/fitness/nutrition-tips", state: { previousPathname: pathname } }}
                className="fs-sm"
              >
                {"نصائح"}
              </NavDropdown.Item>
              <NavDropdown.Item
                as={Link}
                to={{ pathname: "/fitness/nutrition-episodes", state: { previousPathname: pathname } }}
                className="fs-sm"
              >
                {"حلقات"}
              </NavDropdown.Item>
            </NavDropdown>

          </Nav>
        </Navbar.Collapse>

      </Navbar>
    </Container>
  );
};
export default FitnessNavbarWeb;
