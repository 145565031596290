
import './lineupPlayer.css'
import { useHistory } from "react-router-dom";
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';
import customPlayerImg from '../../assets/games/playstation-user.svg';



const LineupPlayer = ({ el: player }) => {

    const history = useHistory();
    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/player/${id}`, { previousPathname: history.location.pathname });
    };

    return (
        <div className={`text-center fs-xxs line-up-player-item`} onClick={() => handleRowClick(player.services?.[0], player.sport_id, player.player_id)} style={{ cursor: "pointer" }}>
            <img src={player.image ? ImgURL(player.image) : customPlayerImg} className={`${!player.image ? 'lineup__playerImage' : ''}`} width="30px" height="30px" style={{ borderRadius: '50%' }} alt={player.player_name} />
            <div style={{ fontFamily: 'sans-serif' }}>{player.shirt_number} . {player.player_name?.split(" ")?.at(-1)}</div>
        </div>
    )
}

export default LineupPlayer 