export const privacyTermsAR = [
  "لغرض هذه الوثيقة، البيانات الشخصية تعني البيانات المتعلقة بشخص محدد أو يمكن تحديده، بشكل مباشر أو غير مباشر وذلك  عن طريق، على سبيل المثال لا الحصر: رقم الهوية (مثل بطاقة الرقم القومي أو رقم جواز السفر أو رقم التأمين الاجتماعي) أو أي بيانات تحدد الهوية من الناحية الجسدية أو الفسيولوجية أو العقلية أو الاقتصادية أو الثقافية أو الاجتماعية (مثل اسم العائلة والاسم الأول، تاريخ الميلاد، بيانات القياسات الحيوية، بصمات الأصابع، الحمض النووي ...).",

  "تكون العلاقة بين هذا التطبيق وعملائها علاقة خاصة ويحكمها قوانين حماية البيانات الشخصية واللوائح الخاصة بها.",

  "كافة البيانات الخاصة بك وكذلك الأنشطة هي بيانات خاصة. في حال قيام أي من العاملين علي هذا التطبيق بالاطلاع/الدخول عليها بأي وسيلة من خلال هذا التطبيق حسب متطلبات العمل، فإنه لن يقوم بالاحتفاظ أو إفشاء أي من تلك البيانات للغير إلا في الحالات التي تطلبها القوانين واللوائح السارية أو حسب هذه الشروط والاحكام.",

  "من المتفق عليه بيننا أنه في بعض الحالات قد يتطلب الأمر تتبع الأنشطة الخاصة بك على التطبيق لمساعدتك في الوصول لاحتياجاتك/اهدافك، وبالتالي سوف يتم  توفير هذا الخيار لكم في حال الموافقة عليه من جانبكم، علماً بأنه سوف يتم توفير الامكانية لكم لمسح تلك الأنشطة من الخوادم الخاصة بالشركة والتوقف عن التتبع في أي وقت تطلب فيه ذلك على أن لا يتعارض ذلك مع القوانين واللوائح السارية والتي تلزم الشركة بالاحتفاظ ببعض البيانات لمدد محددة.",

  "بيانات المستخدمين التي قد يتم معالجتها من خلال استخدام هذا التطبيق:",

  "البيانات التي يشاركها المستخدم من خلال التطبيق عن طريق ملء النماذج الموجودة على الخدمة أو عن طريق استخدام الخدمة، أو من خلال مراسلتنا عبر المكالمات أو البريد الإلكتروني. يتضمن ذلك المعلومات التي يتم إدخالها عند التسجيل و/أو الاشتراك في الخدمة، والإبلاغ عن مشكلة في الخدمة. قد تتضمن المعلومات التي تقدمها لنا اسمك وعنوانك وبريدك الإلكتروني ورقم هاتفك.",

  "قد نقوم بجمع معلومات حول استخدام التطبيق الخاص بك بما في ذلك، على سبيل المثال لا الحصر، التاريخ والوقت، وأوقات استجابة الصفحة، وأخطاء التطبيق، وطول الزيارات المقامة علي صفحات إلكترونية معينة، والأنشاط، ومعلومات عن التفاعل الصفحة (مثل التمرير والنقرات والتفاعلات مع خدمة العملاء لدينا وأي المعلومات المقدمة من قبل المستخدم.",

  "لأغراض حماية البيانات الشخصية، وتأكيداً علي التزام هذا التطبيق بقوانين حماية البيانات المصري، فإن الوسيلة الوحيدة لإرسال أي بيانات للغير تكون فقط من خلال الطلبات الموجهة من الجهات/السلطات الرسمية وفقاً لأحكام القوانين واللوائح السارية و/أو بموجب موافقتكم وفقاً لهذه الشروط والأحكام.",

  "من أجل تحسين الخدمة ولأغراض التسويق، قد يتم تحليل ومعالجة بياناتك الشخصية، ونشاطاتك والمعاملات التي تقوم بتنفيذها على التطبيق دون الاخلال بسرية معلوماتك أو البيانات الخاصة بك.",

  "لأغراض تقديم الخدمات محل هذا التطبيق  وكذلك لتحسين الخدمة، قد نقوم بتحويل جزء من بياناتك الشخصية للغير (مقدم خدمة من الباطن). كذلك سوف يتم اتخاذ كافة التدابير الوقائية الممكنة للتأكد من أن مقدم الخدمة من الباطن ملتزم بكافة القوانين واللوائح السارية ومتطلبات حماية البيانات الشخصية. موافقتك علة هذه الشروط والأحكام سوف تعد موافقة صريحة على نقل البيانات لمقدم الخدمة من الباطن.",

  "ستتم مشاركة معلومات البطاقة الائتمانية مباشرة مع البنك و بوابة الدفع الخاصة بنا و لن يتم تخزينها  بأي شكل.",

  "باستخدام هذا التطبيق، فهذا يعني موافقتك على أن إمكانية أعرض الإعلانات التي نعتقد أنها ستكون ذات صلة بك وباهتماماتك وذلك سواء من خلال التطبيق والمواقع الالكترونية و/أو الاتصالات الهاتفية و/أو الرسائل القصيرة.",

  "تستخدم الشركة بياناتك الشخصية للمساعدة في تحديد الإعلانات التي ستظهر لك .",

  "لن يتم بيع بياناتك الشخصية للمعلنين ولن تشارك المعلومات التي تحدد هويتك بشكل مباشر (مثل اسمك أو عنوان البريد الإلكتروني أو أي معلومات أخرى) مع المعلنين ما لم تمنحنا إذناً محدداً بذلك.",

  "بدلاً من ذلك، يمكن للمعلنين إخبارنا بأشياء مثل نوع الجمهور الذي يرغب في مشاهدة إعلاناتهم، ونقوم بعرض هذه الإعلانات على الأشخاص الذين قد يكونوا مهتمين. نزود المعلنين بتقارير حول أداء إعلاناتهم والتي قد تساعدهم على فهم كيفية تفاعل الأشخاص مع محتواهم.",

  "سيتم الاحتفاظ ببياناتك الشخصية (حسب التعريف أعلاه) بالسجلات الخاصة بالتطبيق، ومعالجتها واستلام المراسلات والحملات الترويجية والرسائل الشخصية بحماية كاملة لهويتك. وتعد موافقتك على هذه الشروط والاحكام موافقة صريحة على استقبال ما سبق. يمكنك طلب إلغاء البيانات الشخصية و/أو الخروج من الحملات الترويجية والرسائل الالكترونية والرسائل الشخصية من خلال البريد الالكتروني info@twistmena.com.",

  "جميع الأنشطة والتحليلات التي يتم إجراؤها على  التطبيق تتوافق مع قانون حماية البيانات الشخصية المصري ومعالجة البيانات علي حد علمنا.",

  "o أقر بموجب هذا أنني أطلعت على الشروط والأحكام الخاصة بحماية وسرية البيانات الشخصية من خلال هذا التطبيق وبقبولي وموافقتي على احتفاظهم ببياناتي الشخصية والحساسة ومعالجتها للأغراض المنصوص عليها بتلك الشروط والأحكام بما لا يخالف القوانين واللوائح السارية.",

  "o أقر بموجب هذا أنني أطلعت على الشروط والأحكام الخاصة بحماية وسرية البيانات الشخصية من خلال هذا التطبيق وبقبولي وموافقتي على تلقي رسائل الإعلانات والعروض من خلال البريد الإلكتروني و/أو الرسائل القصيرة و/أو المكالمات الهاتفية. ",

  "o أقر بأنني مسئول عن الاطلاع علي أي تعديلات أو تحديثات قد يتم إدخالها علي الشروط والأحكام محل الذكر وذلك بمجرد إشعاري بذلك من خلال التطبيق.",
];

export const privacyTermsEN = [
  "For purpose of this Agreement, Personal Data shall mean any information relating to an identified or identifiable individual; an identifiable person is one who can be identified, directly or indirectly, such as but not limited to; an identification number (e.g. National ID) or one or more factors specific to his personal information or social identity (e.g. Family Name and First Name, Date of Birth, Biometrics Data, Fingerprints, DNA…).",

  "Twist Sports App and its Clients/Subscribers (“you” and/or “Your”) relation is private and regulated by Data Protection Laws and regulations.",

  "All details about you and your activities are private. Should any of our corporate members had an access/knowledge by any means, via this App or else, to any of your Personal Data, he/she shall never keep a record or disclose any of this data to any third party except as requested by applied laws and regulations and/or as agreed according to this terms and conditions.",

  "You and us understand that in some cases tracking your activities on the App is needed to help you reaching your goal/aim/needs, accordingly we will avail this option to you at your consent, noting that we will keep for you the option to delete your activities from our servers any time you need and to stop tracking yours in future provided that the same shall be subject to applicable laws and regulations and regulators requirements to keep certain data for a specific time.",

  "Information we may collect from Twist Sports App users:",

  "Information that the user shares through the app by filling forms on the service or by using the service, or by corresponding with us via Calls or email. This includes information you provide when you register and/or subscribe to the service, report a problem with the service. The information you give us may include your name, address, email, and phone number.",

  "We may collect information about your app usage including but not limited to date and time, page response times, app errors, length of visits to certain pages, activity, page interaction information (such as scrolling, clicks, interactions with our customer service and any information provided by the user.",

  "For sake of Personal Data Privacy, this App is bounded by the applicable Egyptian Data Privacy Laws, accordingly the only way to submit any information to any third party can only be done based on official request from authorities pursuant to applicable laws and regulations and/or as agreed by you according to this terms and conditions.",

  "For the sake of service enhancement and marketing purpose, we can perform a processing and analysis to your Personal Data, activities and your transactions performed on the app without impacting your confidentiality or private information.",

  "For the sake of providing you the subscribed services under this  Application, and for the sake of service enhancement, we may transfer part of your Personal Data to a third party (“sub-service provider”). We shall also take all reasonable precautions to ensure that its sub-service provider is in compliant with the applicable laws and regulations and Data protection requirements.  Your acceptance to this terms and conditions shall be considered as an explicit approval to transfer your Personal Data.",

  "Credit card information will be shared directly with our bank and payment gateway and will not be stored  by any mean.",

  "By using this App, you agree that we can show you ads that we think will be relevant to you and your interests. Twist Sports App uses your Personal data to help determine which ads to show you whether through app and websites, telesales, SMSs. This App will not sell your Personal Data to advertisers, and will not share information that directly identifies you (such as your Name, E-mail Address or other contact information) with advertisers unless you give us specific permission. Instead, advertisers can tell us things like the kind of audience they want to see their ads, and we show those ads to people who may be interested. We provide advertisers with reports about the performance of their ads that help them understand how people are interacting with their content.",

  "Keeping your Personal Data (as defined above) in our records, processing it, receiving email campaigns, or personalized message will be done with complete identity protection and your acceptance to this terms and conditions shall be considered as an explicit approval to receive the above. you may request to, delete your Personal Data, opt-out from receiving email campaign or personalized messages by sending emails via info@twistmena.com.",

  "We confirm that all activities and analysis done on the application  is compliant to the Egyptian law for Data Privacy and Data Processing to the extent of our knowledge.",

  "o I hereby confirm that I read Data Privacy terms and conditions submitted via this App and confirm my acceptance to keep my Personal and Sensitive Data and processing it only for purposes mentioned thereon and in compliance with applicable laws and regulations.",

  "o I hereby confirm that I read  Data Privacy terms and conditions submitted via this App and confirm my acceptance to receive ads and email campaigns via SMS and/or phone Calls and/or e-mails.",

  "o I hereby acknowledge to consider any amendments/updates might be inserted to these terms and conditions once being notified via the App.",
];
