import axios from 'axios';

class HttpHelper {
  constructor() {
    this.apiBaseUrl = process.env.REACT_APP_PERFORMANCE_BASE_URL;
    this.apiSquashBaseUrl = process.env.REACT_APP_NEW_BASE_URL;
    this.apiMobi89Url = process.env.REACT_APP_89_BASE_URL;
    this.etisalatsportsUrl = process.env.REACT_APP_ETISALATSPORTS;
    this.apiPlaystationUrl = process.env.REACT_APP_PLAYSTATION_URL;
    this.paymobApiBaseUrl = process.env.REACT_APP_PAYMOB_API_BASE_URL;
    this.fawryApiBaseUrl = process.env.REACT_APP_FAWRY_API_BASE_URL;
    this.meloukApiBaseUrl = process.env.REACT_APP_MELOUK_BASE_URL;
    this.twistSubsBaseUrl = process.env.REACT_APP_SUBS_BASE_URL;
    this.fitnessBaseUrl = process.env.REACT_APP_FITNESS_BASE_URL


    this.oldGamesApiAxios = axios.create({
      baseURL: "https://api.appkora.com/apis",
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.baseAxios = axios.create({
      baseURL: this.apiBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.baseSquashAxios = axios.create({
      baseURL: this.apiSquashBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.mobi89Axios = axios.create({
      baseURL: this.apiMobi89Url,
      headers: {
        "Content-Type": "application/json",
        "application_key": process.env.REACT_APP_API_KEY,
        "Accept-Language": "ar"
      },
    });

    this.etisalatsportsAxios = axios.create({
      baseURL: this.etisalatsportsUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.basePlaystationAxios = axios.create({
      baseURL: this.apiPlaystationUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.paymobAxios = axios.create({
      baseURL: this.paymobApiBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.fawryAxios = axios.create({
      baseURL: this.fawryApiBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "Accept-Language": "ar"
      },
    });

    this.meloukAxios = axios.create({
      baseURL: this.meloukApiBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "Application-key": process.env.REACT_APP_MELOUK_APP_KEY,
        "Accept-Language": "ar"
      },
    });

    this.fitnessAxios = axios.create({
      baseURL: this.fitnessBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "apiKey": process.env.REACT_APP_FITNESS_API_KEY,
        "Accept-Language": "ar"
      },
    });

    this.twistSubsAxios = axios.create({
      baseURL: this.twistSubsBaseUrl,
      headers: {
        "Content-Type": "application/json",
        "application_key": process.env.REACT_APP_API_KEY,
        "Accept-Language": "ar"
      },
    });
  }
};

export default new HttpHelper();