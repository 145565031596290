import HttpHelper from './helper';

export const calendarEndpoints = {
  getCalender: (params) => HttpHelper.baseAxios
    .get(`/calendar`, { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getTeamCalender: (params) => HttpHelper.baseAxios
    .get(`/teamCalendar`, { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getHomeCalender: () => HttpHelper.baseAxios
    .get(`/homeCalendar`)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getOtherSportsCalender: (params) => HttpHelper.baseAxios
    .get(`/otherCalendar`, { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),
};