import HttpHelper from "./helper";

export const fanClubEndpoints = {
    getFavTeams: (params) =>
        HttpHelper.baseAxios
            .get(`/my_favourites`, { params })
            .then((res) => res?.data),

    postFavTeams: (inputData) =>
        HttpHelper.baseAxios
            .post(`/favourite`, inputData)
            .then((res) => res?.data),
};