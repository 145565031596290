import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Row } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import MatchCard from '../MatchCard/MatchCard';
import { useQueries } from '@tanstack/react-query';
import api from '../../apis';


const apiFn = (params) => api.matches.get(params)

function PlayerMatches({ sport_id, team_id }) {
    const [{ isLoading: isLoadingFixtures, isError: isErrorFixtures, data: Fixtures }, { isLoading: isLoadingResults, isError: isErrorDateResults, data: Results }] = useQueries({
        queries: [1, 2].map(date_type => {
            return {
                queryKey: ["player-matches", sport_id, team_id, date_type],
                queryFn: () => apiFn({ sport_id, team_id, date_type }),
                select: ({ data }) => data?.data
            }
        }),
        refetchOnWindowFocus: false,
    });


    return (
        <div className='mt-2'>

            {isLoadingFixtures || isLoadingResults ?
                <div>...loading</div>
                :
                isErrorFixtures || isErrorDateResults ?
                    <div>error</div>
                    :
                    <div className='mx-auto'>
                        <div className='fs-md white-color mb-2'>القادمه</div>
                        {!Fixtures.length ?
                            <div className='my-3 white-color bg-black-color p-5'>لا يوجد مباريات</div>
                            :
                            <Row xs={1} md={2} className='justify-content-between'>
                                {Fixtures.slice(0, 2).map(el =>
                                    <div key={el.id} className='col-12 col-md-6'>
                                        <MatchCard el={el} />
                                    </div>
                                )
                                }
                            </Row>
                        }
                        <div className='fs-md white-color my-2'>النتائج</div>
                        {!Results.length ?
                            <div className='my-3 white-color bg-black-color p-5'>لا يوجد مباريات</div>
                            :
                            <Row xs={1} md={2} className='justify-content-between'>
                                {Results.slice(0, 2).map(el =>
                                    <div key={el.id} className='col-12 col-md-6'>
                                        <MatchCard el={el} />
                                    </div>
                                )
                                }
                            </Row>
                        }
                    </div>
            }
        </div>
    )
}

export default PlayerMatches