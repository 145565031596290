import React from "react";
import BannerWebComponent from "../../components/banner/BannerWebComponent";
import BannerMobComponent from "../../components/banner/BannerMobComponent";
import VideoCardWebComponent from "../../components/videos/VideoCardWebComponent";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { logFailedApi } from "../../../../globalFn";

const apiFn = () => api.fitness.aliMazharContent.getAliAnswers()

const Questions = () => {

    const { data: aliMazharAnswers, isError, isLoading } = useQuery({
        queryKey: ["ali-mazhar-answers"],
        queryFn: () => apiFn(),
        onError: () => logFailedApi("ali-mazhar-answers"),
        refetchOnWindowFocus: false,
    })


    return (
        <div>

            <div className="d-none d-xl-block">
                <BannerWebComponent
                    title={"إجابات أسئلتكم"}
                    subTitle={"شاهد علي مظهر وهو يجيب على أسئلتكم"}
                />
            </div>
            <div className="d-xl-none">
                <BannerMobComponent
                    title={"إجابات أسئلتكم"}
                    subTitle={"شاهد علي مظهر وهو يجيب على أسئلتكم"}
                />
            </div>
            <VideoCardWebComponent
                type="questions"
                colXsSize={12}
                colMdSize={6}
                colLgSize={4}
                isLoading={isLoading}
                errMess={isError}
                videos={aliMazharAnswers?.results}
            />

        </div>
    );
};
export default Questions;
