import api from "../../../apis"
import { IDS } from "../../../globalData"
import { getLoggingsParams } from "../../../hooks/useLogging/helper"

export const sendGameReport = (to_url) => {
    const loggingParams = getLoggingsParams()
    api.reports.sendLog({
      from_url: "/international/tournament/726/overview",
      to_url,
      service_id: IDS.SERVICES.INTERNATIONAL,
      ...loggingParams
    })
}