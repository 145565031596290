import { homeWorkoutEndpoints } from "./homeWorkout"
import { gymWorkoutEndpoints } from "./gymWorkout";
import { favoritesEndpoints } from "./favorites";
import { doneEndpoints } from "./done";
import { aliMazharContentEndpoints } from "./aliMazharContent";
import { aliMazharAskEndpoints } from "./aliMazharAsk";

export const fitnessEndpoints = {
    homeWorkout: homeWorkoutEndpoints,
    gymWorkout: gymWorkoutEndpoints,
    favorites: favoritesEndpoints,
    done: doneEndpoints,
    aliMazharContent: aliMazharContentEndpoints,
    aliMazharAsk: aliMazharAskEndpoints,
};