import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const UploadBtnComponent = ({ challengeId, trials, approved }) => {
  if (approved === false && approved !== null) {
    return (
      <Button
        className="fs-sm primary-btn border-radius-8  py-md-2 px-md-3 w-100"
        disabled={true}
      >
        pending
      </Button>
    );
  } else {
    return (
      <Button
        as={Link}
        to={`/upload/${challengeId}/${trials}`}
        className="fs-sm primary-btn border-radius-8  py-md-2 px-md-3 w-100"
      >
        {trials === 1 ? "btn.replaceVideo" : "btn.upload"}
      </Button>
    );
  }
};

export default UploadBtnComponent;
