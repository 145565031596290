import React from "react";
import { Container } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import twistLogo from "../../assets/Twist-footer-logo-3-2023.png";
import etisalteLogo from "../../assets/img/transparentLogo.png";
import TwistSettings from "../../TwistSettings.json";
import "./FooterComponent.css";

const FooterComponent = () => {
  const { pathname } = useLocation();
  return (
    <Container
      fluid
      dir='rtl'
      className='footer'
      style={{
        backgroundImage: "url(/img/footer-new-bg.jpg)",
        backgroundSize: "cover",
        backgroundPosition: "center",
        position: "relative",
      }}>
      <div className='footer-overlay'></div>
      <div className='container'>
        <div className='row row-centered'>
          <div className='col-lg-4 col-xs-12 text-center'>
            {!TwistSettings["twist-ui"] && (
              <>
                <a href='https://apps.apple.com/eg/app/etisalat-sports/id1455787805'>
                  <img src='/img/appstore.png' width='100px' alt='App Store' />
                </a>
                <a href='https://play.google.com/store/apps/details?id=com.etisalat.sports'>
                  <img
                    src='/img/googleplay.png'
                    width='100px'
                    alt='Google Play'
                  />
                </a>
              </>
            )}
          </div>
          <div className='col-lg-4 col-xs-12  text-center my-5 logo d-flex flex-column align-items-center'>
            <Link to={{ pathname: "", state: { previousPathname: pathname } }}>
              <img
                src={TwistSettings["twist-ui"] ? twistLogo : etisalteLogo}
                width='230px'
                alt='Etisalat Sports'
              />
            </Link>
            <Link
              to={{
                pathname: "/privacy",
                state: { previousPathname: pathname },
              }}>
              <p className='privacy'>الشروط و الأحكام</p>
            </Link>
          </div>
          <div className='col-lg-4 col-xs-12 space green text-center'>
            {!TwistSettings["twist-ui"] && (
              <>
                <a href='https://www.youtube.com/user/EtisalatMasr'>
                  <i className='fa fa-youtube-play fa-lg green'></i>
                </a>
                <a href='https://eg.linkedin.com/company/etisalat-egypt'>
                  <i className='fa fa-linkedin fa-lg green'></i>
                </a>
                <a href='https://twitter.com/EtisalatEgypt'>
                  <i className='fa fa-twitter fa-lg green'></i>
                </a>
                <a href='https://www.instagram.com/etisalategypt/'>
                  <i className='fa fa-instagram fa-lg green'></i>
                </a>
                <a href='https://m.facebook.com/Etisalat-Egypt-141606109666/'>
                  <i className='fa fa-facebook fa-lg green'></i>
                </a>
                <Link
                  to={{
                    pathname: "/terms-conditions",
                    state: { previousPathname: pathname },
                  }}>
                  <p className='text-center fs-md mb-0'>الشروط و الاحكام</p>
                </Link>
              </>
            )}
          </div>
        </div>
      </div>
    </Container>
  );
};

export default FooterComponent;
