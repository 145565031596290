import { useState } from "react";
import { Modal } from "react-bootstrap";
import ComparisonSearch from "./ComparisonSearch";
import ComparisonPlayersResults from "./ComparisonPlayersResults";
import ComparisonTeamsResults from "./ComparisonTeamsResults";
import twistLogo from "../../assets/Twist-footer-logo-3-2023.png";

const ComparisonPopUp = ({ show, setShow }) => {
  const [comparisonSearch, setComparisonSearch] = useState(false);
  const [compare, setCompare] = useState(false);
  const [comparisonItems, setComparisonItems] = useState([]);
  const [type, setType] = useState("");

  return (
    <Modal
      className="comparison-modal"
      show={show}
      onHide={() => {
        setComparisonItems([]);
        setComparisonSearch(false);
        setCompare(false);
        setShow(false);
      }}
      closeButton
    >
      <Modal.Header className="p-2" closeButton></Modal.Header>
      <Modal.Body>
        <div
          className={`${comparisonSearch === true ? "d-none" : "d-flex"
            }  flex-column justify-content-center align-items-center p-5`}
        >
          <div
            className="btn bg-active-color etisalat-border px-3 py-2 px-md-5 py-md-3 text-light fs-md"
            onClick={() => {
              setType("TEAMS");
              setComparisonSearch(true);
            }}
          >
            قارن بين الفرق
          </div>
          <div
            className="btn bg-active-color etisalat-border px-3 py-2 px-md-5 py-md-3 mt-4 text-light fs-md"
            onClick={() => {
              setType("PLAYERS");
              setComparisonSearch(true);
            }}
          >
            قارن بين لاعبين
          </div>
        </div>
        {comparisonSearch && !compare ? (
          <ComparisonSearch
            setCompare={setCompare}
            setComparisonItems={setComparisonItems}
            type={type}
          />
        ) : (
          ""
        )}
        {compare && type === "PLAYERS" ? (
          <ComparisonPlayersResults comparisonItems={comparisonItems} />
        ) : (
          ""
        )}
        {compare && type === "TEAMS" ? (
          <ComparisonTeamsResults comparisonItems={comparisonItems} />
        ) : (
          ""
        )}
        <img src={twistLogo} className="comparsion-logo" alt="logo" />

      </Modal.Body>
    </Modal>
  );
};

export default ComparisonPopUp;
