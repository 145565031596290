import { useState, useDeferredValue, useContext } from 'react';
import { useQuery } from '@tanstack/react-query';
import api from '../../apis';
import SearchResultList from './SearchResultList';
import { sharedComponentsContext } from '../../context/shared-context';
import { logFailedApi } from '../../globalFn';

const apiFn = (keyword = ' ') => api.mediaList.getMediaList({ keyword });

const NewsSearch = () => {
    const { setInitialSharedValues } = useContext(sharedComponentsContext);
    const [searchInputValue, setSearchInputValue] = useState(undefined);
    const deferredSearchInputValue = useDeferredValue(searchInputValue);
    const { isFetching, error, data = [] } = useQuery({
        queryKey: ["news-search", deferredSearchInputValue],
        queryFn: () => apiFn(searchInputValue),
        enabled: deferredSearchInputValue !== undefined,
        select: (data) => data?.data?.data,
        onSuccess: (data) => setInitialSharedValues(prev => {
            return {
                ...prev,
                NewsSearchQuery: data ?? []
            }
        }),
        onError: () => logFailedApi("news-search"),
        refetchOnWindowFocus: false
    });

    const handleChange = (e) => setSearchInputValue(e.target?.value?.match(/[A-Za-z ]*[ء-ي]*/g)?.join(""));

    return (
        <div>
            <input
                type="search"
                onChange={handleChange}
                value={searchInputValue ?? ''}
                className="form-control me-2"
                placeholder="ابحث هنا......"
                aria-label="Search"
                style={{ maxWidth: '300px' }}
                data-testid="news-search-input"
            />
            <SearchResultList
                isFetchingNewsSearch={isFetching}
                NewsSearchFailed={error}
                NewsSearch={data || []}
                searchInputValue={searchInputValue}
            />
        </div>
    )
}

export default NewsSearch