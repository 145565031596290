import React from 'react';
import { useEffect } from 'react';
import { getSpecialMediaSectionData } from "../helper";
import MediaList from '../../News/MediaList';
import SportsSectionHeader from '../../sportsSectionHeader/sportsSectionHeader.component';

function EtisalatStudioSection({ service_id, currentState, dispatch, lang }) {
    useEffect(() => {
        getSpecialMediaSectionData(dispatch, { section_id: 3, page: 1, service_id }, lang)
    }, [service_id])

    return (
        <>
            <SportsSectionHeader title="ستوديو اتصالات" dataTestId="etisalat-studio-section-title" />
            <MediaList
                AllMedia={currentState.fetchedData}
                getByPage={(page) => getSpecialMediaSectionData(dispatch, { section_id: 3, page }, lang)}
                current_page={currentState.meta.current_page}
                last_page={currentState.meta.last_page}
                initialLoading={currentState.isLoading && currentState.fetchedData.length === 0}
                paginationLoading={currentState.isLoading && currentState.fetchedData.length > 0}
            />
            <hr />
        </>
    )
}

export default React.memo(EtisalatStudioSection)
