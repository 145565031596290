import React from 'react'
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TwistSettings from '../../../../TwistSettings.json'

const Loader = () =>{
    const loadingColumn = (i) => <Col key={i} md={5} className="riddle">
        <Skeleton height={60} />
    </Col>
    return (
        <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb">
        <Row className='justify-content-center' noGutters>
            {
              [1, 2, 3, 4].map(i => loadingColumn(i))
            }
          </Row>
        </SkeletonTheme>
    )
}

export default Loader

