import React from "react";
import { useHistory } from "react-router-dom";

const SportsSectionHeader = ({ title, className, navigateTo, dataTestId, arrowTitle }) => {
  const history = useHistory()
  return (
    <div data-testid={dataTestId} className={`${className} text-light fs-xxm my-3 mt-5 d-flex align-items-center justify-content-between`}>
      <strong>{title}</strong>
      {
        navigateTo &&
        <>
          <h3
            className=" m-0"
            data-testid="sports-section-header-navigateTo"
            onClick={() => history.push(navigateTo, { previousPathname: history.location.pathname })}
          >
            <div className="m-0 p-0 d-flex justify-content-center align-items-center ">
              <strong className="text-light pe-1" style={{ fontSize: "12px" }} >{arrowTitle}</strong>
              <strong className="swipe-animation" style={{ rotate: "180deg", fontSize: "20px" }}>{'➜'}</strong>
            </div>
          </h3>
        </>
      }
    </div>
  );
};

export default SportsSectionHeader;
