import { useContext } from 'react'
import MatchesCalendar from "../../components/MatchesCalendar/MatchesCalendar";
import LatestNews from "../../components/News/LatestNews";
import SportsSectionHeader from '../../components/sportsSectionHeader/sportsSectionHeader.component';
import TournamentsCarousel from "../../components/TournamentsCarousel/TournamentsCarousel";
import NewsSearch from "../../components/News/NewsSearch";
import { IDS } from '../../globalData';
import TopStories from '../../components/News/TopStories';
import TwistSettings from '../../TwistSettings.json'
import AllMedia from '../../components/News/AllMedia';
import OnDemand from '../../components/OnDemand';
import { sharedComponentsContext } from '../../context/shared-context';
import BasketBallEbentBanner from '../../assets/BasketBallEbentBanner.jpg'
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const OtherSports = () => {
  const { NewsSearchQuery } = useContext(sharedComponentsContext);
  const history = useHistory()

  return (
    <div className='container home-page '>
      <div className="d-flex justify-content-center row">
        <div className="col-12">
          {/* <img src={IphoneAdd} height={200} width={"100%"}/> */}
        </div>
      </div>
      <OnDemand>
        <MatchesCalendar service_id={IDS.SERVICES.OTHERSPORTS} season_id={null} tournament_id={null} />
      </OnDemand>
      <SportsSectionHeader title="أهم الأحداث" navigateTo="/othersports/news" />

      <TopStories
        sport_id={null}
        service_id={IDS.SERVICES.OTHERSPORTS}
        season_id={null}
        items_count={4}
        media_type={1}
        tournament_id={null}
        media_id={null}
        team_id={null}
        player_id={null}
        top_stories={true}
        latest={null}
        popular={null} />
      <hr></hr>

      {/* <section className="event-banner" onClick={() => history.push('/othersports/basketball/tournament/7475')}>
        <img src={BasketBallEbentBanner} alt="event banner" />
      </section> */}

      <div className='row align-items-baseline'>
        {/* <div className='col-12 col-md-6 fs-xs'>
          <SportsSectionHeader title="ترتيب الدوري" />
          <GeneralStandings service_id={IDS.SERVICES.OTHERSPORTS} season_id={null} />
        </div> */}
        <div className='col-12 col-md-12 fs-md'>
          <div className='text-light mt-5'>
            <SportsSectionHeader title={"اختر بطولة"} />
          </div>
          <OnDemand>
            <TournamentsCarousel service_id={IDS.SERVICES.OTHERSPORTS} />
          </OnDemand>
        </div>
      </div>
      <div className="col-12">
        {/* <img src={Cokebanner} height={200} width={"100%"} className="mt-3"/> */}
      </div>
      <SportsSectionHeader title="أخر الأخبار" navigateTo="/othersports/news" />
      <OnDemand>
        <NewsSearch />
        <div className={`${NewsSearchQuery?.length > 0 ? 'd-none' : ""} my-2`}>
          <LatestNews service_id={IDS.SERVICES.OTHERSPORTS} season_id={1} items_count={9} media_type={1} latest={true} />
        </div>
      </OnDemand>
      {
        TwistSettings['videos'] && <>
          <hr />
          <SportsSectionHeader title="أحدث الفيديوهات" navigateTo="/othersports/videos" />
          <OnDemand>
            <AllMedia service_id={IDS.SERVICES.OTHERSPORTS} items_count={8} type={2} latest />
          </OnDemand>
          <hr />
          <SportsSectionHeader title="الأكثر تداولا" navigateTo="/othersports/news" />
          <OnDemand>
            <AllMedia service_id={IDS.SERVICES.OTHERSPORTS} items_count={12} type={2} popular />
          </OnDemand>
        </>
      }
    </div>
  );
};

export default OtherSports;


