import { Container, Table } from 'reactstrap';
import "./SquashMatchDetails.css";
import api from '../../apis';
import { useQuery } from '@tanstack/react-query';
import { logFailedApi } from '../../globalFn'

const tennisStatisticsKeys = {
  score: 'النتيجة',
  aces: 'إرسالات ساحقة',
  double_faults: 'خطأ مزدوج',
  break_points_saved: 'إنقاذ نقاط الكسر',
  total: 'الكل',
  service_points_won: 'عدد النقاط الفائز بها',
  service_games_won: 'عدد الأشواط الفائز بها',
  first_serve_return_points: 'عدد النقاط من التصدي للإرسال الأول',
  second_serve_return_points: 'عدد النقاط من التصدي للإرسال الثاني',
  break_points_converted: 'عدد نقاط كسر إرسال المنافس',
  max_points_in_a_row: 'أكثر عدد نقاط بشكل متتالي',
  tiebreaks: 'أشواط كسر التعادل',
  first_serve: 'الإرسال الأول',
  second_serve: 'الإرسال الثاني',
  first_serve_points: 'النقاط من الإرسال الأول',
  second_serve_points: 'النقاط من الإرسال الثاني',
  service_games_played: '',
  receiver_points_won: 'نقاط حققها المستقبل',
  return_games_played: '',
  max_games_in_a_row: 'أكبر عدد فوز بالأشواط تواليا',
  total_points: 'اجمالي النقاط',
  penalty_score: "أهداف الجزاء"
}

const basketStatisticsKeys = {
  score: 'النقاط',
  free_throws: 'رميات حرة',
  pointers_2: 'نقطتان',
  pointers_3: 'ثلاثيات',
  field_goals: 'نقاط ملعوبة',
  rebounds: 'متابعات',
  timeouts: 'وقت مستقطع',
  fouls: 'أخطاء',
  target_shots: 'تصويبات على السلة',
  shots: 'تصويبات'

}

const apiFn = (params) => api.matches.getMatchStatistics(params);

const SquashMatchDetails = ({ matchId, sportName }) => {

  const { data: { team1, team2 } = {} } = useQuery({
    queryKey: ["match-statistics", matchId],
    queryFn: () => apiFn({ id: matchId }),
    onError: () => logFailedApi("match-statistics"),
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
  });
  const getSportStatisticsKeys = () => {
    if (sportName === 'tennis') return tennisStatisticsKeys;
    return basketStatisticsKeys
  }
  const sportStatistics = getSportStatisticsKeys()
  const statistics = Object.keys(team1?.statistics[0] ?? {}).filter((key) => !key.includes("precentage"))

  return (
    <Container className='text-white my-5  squashMatchDetails-page py-2'>
      <h6 className='text-center border-bottom py-3 d-flex justify-content-around'>
        نتائج المباراة
      </h6>
      <Table className='table-borderless bg-black-color table-striped table-dark white-color'>
        <tbody>
          <tr className='align-items-center justify-content-between' >
            <td className='text-center'>{team1?.statistics[0]['score']}</td>
            <td className='text-center pt-3'>{sportStatistics['score']}</td>
            <td className='text-center'>{team2?.statistics[0]['score']}</td>
          </tr>
          {
            statistics.map((key, i) => {
              return !!(team1.statistics[0][key] || team2.statistics[0][key]) && key !== 'score' &&
                <tr key={key} className='align-items-center justify-content-between' >
                  <td className='text-center'>{team1.statistics[0][key]}</td>
                  <td className='text-center pt-3'>{sportStatistics[key]}</td>
                  <td className='text-center'>{team2.statistics[0][key]}</td>
                </tr>

            })
          }

        </tbody>
      </Table>
      {/* <div className='justify-content-between d-flex px-4'>

        <div>{matchStatus.scores?.split(",")[3]?.split("-")[0]}</div>
        <div>الجوله الرابعه</div>
        <div>{matchStatus.scores?.split(",")[3]?.split("-")[1]}</div>
      </div>
      <div className='justify-content-between d-flex  px-4'>
        <div>{matchStatus.scores?.split(",")[2]?.split("-")[0]}</div>
        <div>الجوله الثالثة</div>
        <div>{matchStatus.scores?.split(",")[2]?.split("-")[1]}</div>
      </div>
      <div className='justify-content-between d-flex  px-4'>
        <div>{matchStatus.scores?.split(",")[1]?.split("-")[0]}</div>
        <div>الجوله الثانية</div>
        <div>{matchStatus.scores?.split(",")[1]?.split("-")[1]}</div>
      </div>
      <div className='justify-content-between d-flex  px-4'>
        <div>{matchStatus.scores?.split(",")[0]?.split("-")[0]}</div>
        <div>الجوله الاولي</div>
        <div>{matchStatus.scores?.split(",")[0]?.split("-")[1]}</div>
      </div> */}
    </Container >
  )
}

export default SquashMatchDetails;
