import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import VideoCardWebLoader from "./VideoCardWebLoader";
import ReplaceBtnComponent from "../../components/buttons/ReplaceBtnComponent";
import ShareBtnComponent from "../../components/buttons/ShareBtnComponent";
import RatingIconComponent from "../../components/buttons/RatingIconComponent";
import UserImgAndRatingComponent from "../../components/user/UserImgAndRatingComponent";
import "./VideoCardWebComponent.css";
import videoCover from "../../assets/ask.jpg"
import FavIconComponent from "../icons/FavIconComponent";

const RenderVideoCardWebCompoentn = ({ props }) => {

  if (props.isLoading || props.errMess) {
    return (
      <VideoCardWebLoader
        colXsSize={props.colXsSize}
        colMdSize={props.colMdSize}
        colLgSize={props.colLgSize}
      />
    );
  } else {
    return (
      <Container fluid="lg">
        {props.videos.length > 0 ? (
          <div className="videosCardContainer">
            <Row className="row-flex">
              {props.videos.map((video) => (
                <Col
                  xs={props.colXsSize}
                  md={props.colMdSize}
                  lg={props.colLgSize}
                  key={video.id}
                  className="my-2"
                >
                  <Card className="border-radius-10 h-100">
                    <Link to={`/fitness/video/${props.type}/${video.id}`}>
                      <div className="card-video-wraper">
                        <div className="play-btn box-shadow"></div>
                        <Card.Img
                          variant="top"
                          className="p-2 "
                          src={
                            typeof video.videoCover === "undefined"
                              ? videoCover
                              : process.env.REACT_APP_MEDIA_BASE_URL + video.videoCover
                          }
                        />
                      </div>
                    </Link>
                    {props.type === "users" ? (
                      <div>
                        <Card.Body className="px-2 py-2 d-flex">
                          <UserImgAndRatingComponent
                            userId={video.user.id}
                            userImg={video.user.image}
                            userName={video.user.username}
                            userRate={video.vote}
                          />
                        </Card.Body>
                        <Card.Footer>
                          <div className="row">
                            <RatingIconComponent
                              userId={video.user.id}
                              userVote={video.userVote}
                              userImg={video.user.image}
                              userName={video.user.username}
                              compTitle={video.competition.titleAr}
                              compId={video.id}
                              addRate={props.addRate}
                              rateState={props.rateState}
                            />
                            <ShareBtnComponent
                              url={"/video/user/" + video.user.id}
                            />
                          </div>
                        </Card.Footer>
                      </div>
                    ) : props.type === "uploaded" ? (
                      <Card.Body className="px-2 py-3">
                        <Card.Title className="fs-xm color-tertiary-light">
                          {video.competition.titleAr}
                        </Card.Title>
                        <Card.Text className="fs-md color-tertiary">
                          {video.competition.descriptionAr}
                        </Card.Text>
                        <Row className="m-auto">
                          <div
                            className={`mr-auto`}
                          >
                            <ReplaceBtnComponent
                              challengeId={video.competition.id}
                              trials={
                                video.trials === undefined ? 2 : video.trials
                              }
                            />
                          </div>
                          <div className="mx-2">
                            <ShareBtnComponent
                              btnType="primary-btn"
                              url={"/video/uploaded/" + video.id}
                            />
                          </div>
                        </Row>
                      </Card.Body>
                    ) : (
                      <Card.Body className="px-2 py-3">
                        <Card.Title className="fs-xm color-tertiary-light">
                          {video.title}
                        </Card.Title>
                        <Card.Text className="fs-md color-tertiary">
                          {video.description}
                        </Card.Text>
                        {props.type === "profile"
                          ? (
                            <Col className="d-flex  p-0 iconContainer">
                              <div onClick={() => props.handleFavorite(video)}>
                                <FavIconComponent isFav={video.favorite} />
                              </div>
                            </Col>
                          )
                          : null
                        }
                      </Card.Body>
                    )}
                  </Card>
                </Col>
              ))}
            </Row>
          </div>
        ) : (
          <p className="text-center fs-sm my-3 text-uppercase">
            {props.emptyMess}
          </p>
        )}
      </Container>
    );
  }
};

const VideoCardWebComponent = (props) => {
  return <RenderVideoCardWebCompoentn props={props} />;
};

export default VideoCardWebComponent;
