import HttpHelper from "./helper";

export const whereIsTheBallEndpoints = {
    get: () =>
        HttpHelper.baseAxios
            .get(`/ball-position`)
            .then((res) => res?.data.data),

    post: (inputData) =>
        HttpHelper.baseAxios
            .post(`/ball-position-answer`, inputData)
            .then((res) => res?.data.data),
};