import { ImgURL } from '../../../globalData';
import ErrorMsg from '../../ErrorPage/ErrorMsg';
import './SquadTable.css'


const SquadTable = ({ playersPositionArr, tableTitle, handleRowClick }) => (
    <div className='table-container mt-2'>
        <div className='table-title bg-light-black-color p-2 text-light w-100'>{tableTitle}</div>
        <table className="players-table table-borderless bg-black-color table-striped table-dark white-color">
            <thead>
                <tr className="fs-xxs">
                    <th scope="col">الاعب</th>
                    <th scope="col">الجنسيه</th>
                    <th scope="col">السن</th>
                    <th scope="col">القيمة السوقيه</th>
                </tr>
            </thead>
            <tbody>
                {
                    playersPositionArr.length === 0 ?
                        <tr>
                            <td colSpan={10} className='p-0'>
                                <ErrorMsg p={5} fs={'md'} msg="لا يوجد لاعبين" />
                            </td>
                        </tr>
                        :
                        playersPositionArr.map((player) =>
                            <tr key={player.id} onClick={() => handleRowClick(player.id)} className='table-clickable-row-animated'>
                                <td scope='row'>
                                    <div className='d-flex justify-content-start'>
                                        <img
                                            src={ImgURL(player.image, true)}
                                            width={35}
                                            height={35}
                                            className="border-radius-50"
                                            alt="player"
                                        />
                                        <div>
                                            <div className='fs-xs mx-1'>{player.name}</div>
                                            <div className='fs-xxs mx-1'>{player.position}</div>
                                        </div>
                                    </div>
                                </td>
                                <td>
                                    <img src={ImgURL(player.national_logo)} style={{ width: "30px", height: "20px" }} alt="national logo" />
                                </td>
                                <td>
                                    {
                                        player.age
                                    }
                                </td>
                                <td>
                                    {
                                        player.market_value
                                    }
                                </td>
                            </tr>
                        )
                }

            </tbody>
        </table>
    </div>
)

export default SquadTable