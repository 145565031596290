import { Table } from "react-bootstrap";
import { ImgURL } from "../../globalData";
import { useQuery } from "@tanstack/react-query";
import api from "../../apis";
import { logFailedApi } from "../../globalFn";
import TwistAds from "../TwistAds";

const statisticsKeys = {
  shots: "نسديدات",
  target_shots: "تسديدات علي المرمي",
  pass: "تمريرات",
  pass_accuracy: "دقة التمريرات",
  fouls: "أخطاء",
  offsides: "تسلل",
  corners: "ركنيات",
  red_cards: "كروت حمراء",
  yellow_cards: "كروت صفراء",
  // score: "أهداف",
  penalty_score: "أهداف الجزاء",
  possession: "الاستحواذ",
};

const apiFn = (params) => api.matches.getMatchStatistics(params);

function MatchStatistics({ matchId }) {
  const {
    isLoading,
    isError,
    data: { team1, team2 } = {},
  } = useQuery({
    queryKey: ["match-statistics", matchId],
    queryFn: () => apiFn({ id: matchId }),
    onError: () => logFailedApi("match-statistics"),
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
  });
  // const statistics = Object.keys(team1?.statistics[0] ?? {})?.filter(
  //   (key) => key !== "shots" || key !== "penalty_score")
  // ;

  return isLoading ? (
    <div>...loading</div>
  ) : isError ? (
    <div>لا يوجد احصائيات</div>
  ) : (
    <div className='container'>
      <div className='et-shadow p-4 fs-md'>
        {/* <div className='text-center pb-3'>إحصائيات المباراة</div> */}
        <Table className='table-borderless bg-black-color table-striped table-dark white-color'>
          <tbody>
            <tr className='d-table-row align-items-center justify-content-center'>
              <td className='d-table-cell text-center'>
                <img
                  src={ImgURL(team1.logo)}
                  style={{ width: "35px" }}
                  alt=''></img>
                <span className='text-center mx-2'>{team1.score}</span>
              </td>
              <td className='text-center pt-3'>الأهداف</td>
              <td className='d-table-cell text-center'>
                <span className='text-center mx-2'>{team2.score}</span>
                <img
                  src={ImgURL(team2.logo)}
                  style={{ width: "35px" }}
                  alt=''></img>
              </td>
            </tr>
            {Object.keys(statisticsKeys).map((key) => {
              return (
                key !== "pivot" &&
                !!(team1.statistics[0][key] || team2.statistics[0][key]) && (
                  <tr
                    key={key}
                    className='align-items-center justify-content-between'>
                    <td className='text-center'>{team1.statistics[0][key]}</td>
                    <td className='text-center pt-3'>{statisticsKeys[key]}</td>
                    <td className='text-center'>{team2.statistics[0][key]}</td>
                  </tr>
                )
              );
            })}
          </tbody>
        </Table>
        {/* <TwistAds>
          <TwistAds.OnlineShopAds />
        </TwistAds> */}
      </div>
    </div>
  );
}

export default MatchStatistics;
