import { useHistory } from "react-router-dom";
import { Container } from "react-bootstrap";
import ErrorMsg from "../../ErrorPage/ErrorMsg";
import LoadingSpinnner from "../../loadingSpinner/loadingSpinner";
import { getServiceName, getSportRoute } from '../../../Layout/helper';
import "./CupTournamentGroupsStanding.css";
import { ImgURL } from "../../../globalData";


const CupTournamentGroupsStanding = ({
  isLoading,
  isError,
  data,
  tournamentDetails,
}) => {
  const history = useHistory();

  const handleTeamClick = (serviceId, sportId, teamId) => {
    history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${teamId}`, { previousPathname: history.location.pathname });
  }

  return (
    <Container>
      <div className="bg-black-color grey-color fs-xs mt-3 row">
        {isLoading ? (
          <div colSpan={7} className="p-0">
            <LoadingSpinnner p={4} fs={"sm"} />
          </div>
        ) :
          isError || !data.length ? (
            <div colSpan={7} className="p-0">
              <ErrorMsg msg="لا يوجد ترتيب" p={4} fs={"md"} />
            </div>
          ) : (
            data.map((group, index) => (
              <div className="col-12 col-lg-6" key={group[0].id}>
                <div className="groups__standingWrapper  mb-3">
                  <h2 className="fs-md text-white">
                    <span>المجموعة</span>
                    <span className="px-1">{index + 1}</span>
                  </h2>
                  <table className="groups__standing table table-borderless text-white">
                    <thead>
                      <tr>
                        <th scope="col" className="">
                          الفرق
                        </th>
                        <th scope="col">لعب</th>
                        <th scope="col">فوز</th>
                        <th scope="col">تعادل</th>
                        <th scope="col">خساره</th>
                        <th scope="col">نقاط</th>
                      </tr>
                    </thead>
                    <tbody>
                      {group.map((team) => (
                        <tr
                          data-testid="tournament-group-tr"
                          key={team.id}
                          className="row-hover"
                          onClick={() => handleTeamClick(tournamentDetails.services?.[0], tournamentDetails.sport_id, team.team_id)}
                        >
                          <td>
                            <img
                              src={ImgURL(team.team_logo)}
                              style={{ width: 25, height: 25 }}
                              alt="team logo"
                            />
                            <span className="px-2">{team.team_name}</span>
                          </td>
                          <td>{team.played}</td>
                          <td>{team.win}</td>
                          <td>{team.draw}</td>
                          <td>{team.lose}</td>
                          <td>{team.point}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            ))
          )}
      </div>
    </Container>
  );
};

export default CupTournamentGroupsStanding;
