import { useState } from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";
import { useHistory } from "react-router-dom";
import "./tournamentsCarousel.css";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import SampleArrowBtn from '../../components/SampleArrowBtn/SampleArrowBtn'
import { useContext } from "react";
import { sharedComponentsContext } from "../../context/shared-context";
import api from "../../apis";
import { useQuery } from "@tanstack/react-query";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import { logFailedApi } from "../../globalFn";

const apiFn = (sportId, params) => {
  return sportId === 7 ?
    api.squash.getTournaments() :
    api.tournament.getTournaments(params)
};

const customizedSquashDataFn = (data) => {
  const customizedSquashData = [...data].map((tournament) => {
    return {
      ...tournament,
      services: [10],
      sport_id: 7,
      title: tournament.name_ar,
    }
  })
  return customizedSquashData
}

function TournamentsCarousel({ sport_id, service_id }) {

  const history = useHistory();
  const [tournamentsArr, setTournamentsArr] = useState([])
  const { seasonInfo: { appActiveSeason } } = useContext(sharedComponentsContext);
  const { isLoading, isError, data = [] } = useQuery({

    queryKey: ["tournamets", appActiveSeason.id, service_id, sport_id],
    queryFn: () => apiFn(sport_id, { sport_id, service_id, season_id: appActiveSeason.id, priority: 1 }),
    select: (data) => {
      return sport_id === 7 ?
        customizedSquashDataFn(data?.data) :
        data?.data?.data
    },
    onSuccess: (data) => setTournamentsArr([...data, ...addDummyData(data?.length)]),
    onError: () => logFailedApi("tournamets"),
    enabled: sport_id !== null && service_id !== null && !!appActiveSeason.id,
    refetchOnWindowFocus: false,
  });

  const handleRowClick = (serviceId, sportId, id) => {
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/tournament/${id}`,
      { previousPathname: history.location.pathname }
    );
  };

  const settings = {
    infinite: false,
    draggable: false,
    touchMove: false,
    speed: 900,
    slidesToShow: service_id === 9 ? 4 : 3,
    rows: [9, 10].includes(service_id) ? 1 : 2,
    slidesToScroll: 1,
    nextArrow: <SampleArrowBtn arrowDirection="left" service_id={service_id}
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        right: "-12px",
        zIndex: "15",
        width: "30px",
        opacity: "1",
        color: "white"
      }}
    />,
    prevArrow: <SampleArrowBtn service_id={service_id}
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "6px",
        border: "unset",
        position: "absolute",
        top: "0px",
        height: "100%",
        left: "-12px",
        zIndex: "15",
        width: "30px",
        opacity: "1",
        color: "white"
      }}
    />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const idGenerator = () => Math.floor(Math.random() * 1000000);

  const addDummyData = (originalLength) => {
    if (originalLength > 6) return []
    const dummyObj = {
      title: "الدوري المصري الممتاز",
      description: "الدوري المصري الممتاز هو دوري كرة قدم مصري، ويُعتبر الدوري الأعلى في مصر، ويُشرف عليه الاتحاد المصري لكرة القدم، ويقام بنظام دورة روبن، ويتأهل البطل والوصيف إلى دوري أبطال أفريقيا، فيما يتأهل المركز الثالث وأحيانًا الرابع إلى كأس الكونفيدرالية الإفريقية ويهبط آخر ثلاث فرق في ترتيب الدوري إلى دوري الدرجة الثانية",
      weeks: 34,
      logo: "https://tipsscore.com/resb/league/egypt-premier-league.png",
      type: "League",
      team_type: "Team",
      created_at: "2022-05-31 16:05:06",
      country: {
        id: 7,
        title: "مصر",
        flag: "/assets/uploads/Location/2022/09/06/63173f34a9df8.png",
        continentId: 1,
        continent: "أفريقيا"
      },
      services: [
        7
      ],
      sport_id: 1,
      continent: {
        id: 1,
        name: "أفريقيا"
      }
    }

    const dummyData = []
    let numOfDummyData;

    if (service_id === 9) { numOfDummyData = 4 - originalLength }
    else { numOfDummyData = 6 - originalLength }

    for (let i = 0; i <= numOfDummyData; i++) {
      dummyData.push(dummyObj)
    }
    return dummyData
  }

  if (isLoading) { return <div className="loader h-100 pt-5"> <LoadingSpinnner p={5} fs={"md"} /></div > }

  return (
    <Container className="tournament-carousel-component start-carousel-at-right">
      <Slider {...settings}>
        {!tournamentsArr.length || isError ? (
          <div className="my-5 text-white text-center">لا يوجد دورات</div>
        ) : (tournamentsArr?.map((el, i) => (
          <div
            style={{ backgroundColor: "red" }}
            key={el.id ? el.id : idGenerator()}
            className={`text-light tournament-carousel-card d-flex flex-column bg-primary-color align-items-center justify-content-center table-clickable-row rounded ${i >= data?.length ? "dummy-card" : ""}`}
            onClick={() => handleRowClick(el.services?.[0], el.sport_id, el.id)}
          >
            <div className="rounded-circle p-2">
              <img src={ImgURL(el.image || el.logo)} alt="tournament" />
            </div>
            <div className="mt-2 text-center fs-xs">
              {el.title || el.name_ar}
            </div>
          </div>
        )
        )
        )}
      </Slider>
    </Container>
  );
}
export default TournamentsCarousel;
