import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import api from '../../../../apis';
import SportsSectionHeader from '../../../../components/sportsSectionHeader/sportsSectionHeader.component'
import AllMedia from '../../reusable/media/all-melouk-media';
import AskMido from '../mido-diaries/ask-mido';
import Riddle from '../mido-riddles/riddle';
import Loader from '../mido-riddles/loader';


function MeloukHome({ isSubscribed }) {


  const [loading, setLoading] = useState(true)
  const [question, setQuestion] = useState({})

  useEffect(() => {
    api.melouk.questions.getMidoQuestions()
      .then(res => {
        if (res.status === 200) {
          setQuestion(res.data?.[0])
          setLoading(false)
        }
      })
  }, [])

  return <Container className="mb-3">
    {/* 
    <SportsSectionHeader title="ميدو تاكتيكس" />
    <AllMedia
      per_page={4}
      getAPI={api.melouk.media.getMidoTactics}
      type="mido-tactis"
    /> */}

    <SportsSectionHeader title="اسأل ميدو" />
    <AskMido isSubscribed={isSubscribed} />

    <hr />

    <SportsSectionHeader title="يوميات ميدو" />
    <AllMedia
      per_page={4}
      getAPI={api.melouk.media.getMidoDiaries}
      type="mido-dairies"
    />

    <hr />

    <SportsSectionHeader title="لغز ميدو" />
    {loading && <Loader />}
    <Riddle question={question} isSubscribed={isSubscribed} />

    <hr />

    <SportsSectionHeader title="حدث في مثل هذا اليوم" />
    <AllMedia
      per_page={4}
      getAPI={api.melouk.media.getHappenedThisDay}
      type="happened-this-day"
    />
  </Container>
}

export default MeloukHome