import React, { useState, useEffect } from "react";
import { Container, Image, Col, Form, Button, Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { getUserPhoneNumbers } from "../../../../globalFn";
import AskLoader from "./AskLoader";
import alyMazharImg from "../../assets/ask.jpg"
import "./AskComponent.css";

const AskComponent = (props) => {

  const [isAsked, setIsAsked] = useState(props.isAsked)
  const [isLoading, setIsLoading] = useState("إرسال")
  const [question, setQuestion] = useState("")
  const [alertMessage, setAlertMessage] = useState("تم إرسال سؤالك بنجاح")
  const [alertColor, setAlertColor] = useState("success")

  const handleQuestionChange = (event) => {
    setQuestion(event.target.value);
  }


  useEffect(() => {
    setIsAsked(props.isAsked)
  }, [props.isAsked])


  const submitQuestion = (event) => {
    let msisdn = getUserPhoneNumbers()?.[0];
    setIsLoading("برجاء الانتظار..");
    event.preventDefault();

    axios({
      method: "post",
      url: process.env.REACT_APP_FITNESS_BASE_URL + process.env.REACT_APP_POST_QUESTION,
      headers: {
        "Content-Type": "application/json",
        apiKey: process.env.REACT_APP_FITNESS_API_KEY,
      },
      params: {
        userId: 1,
        question: question,
        mobile: msisdn,
      },
    })
      .then(
        (response) => {
          if (response.data.status === 200) {
            setIsAsked(true)
            setAlertMessage("تم إرسال سؤالك بنجاح")
            setAlertColor("success")
          } else {
            setAlertMessage("الرجاء المحاولة مرة أخرى")
            setAlertColor("danger")
          }
        },
        (error) => {
          setAlertMessage("حدث خطأ")
          setAlertColor("danger")

          var errmess = new Error(error.message);
          throw errmess;
        }
      )
      .catch((error) => error.message);
  };

  return (
    (props.isLoading || props.errMess)
      ? <AskLoader />
      : <Container className="my-5 askWeb">
        <Col xs={12} lg={5} className="m-auto text-center p-0">
          <Image
            src={alyMazharImg}
            width={150}
            className="rounded-circle p-2"
          />
          <h5 className="text-uppercase fs-xxm text-center my-3">
            {"اسأل علي مظهر"}
          </h5>
          <p className="text-center fs-md">
            {"ابعت سؤالك اواستفسارك الرياضي من هنا وهيوصل ل خبير الرياضة علي مظهر"}
          </p>
          <p className="fs-sm text-center">
            {"لديك سؤال واحد في اليوم"}
          </p>
          <Alert
            className="p-2 fs-md"
            show={isAsked}
            variant={alertColor}
            transition={true}
          >
            {alertMessage}
          </Alert>
          <Form className="text-center" onSubmit={submitQuestion}>
            <Form.Group
              className={`${isAsked ? "d-none" : "d-block"}`}
            >
              <Form.Control
                as="textarea"
                rows={5}
                className="border-radius-16 fs-md p-3"
                value={question}
                onChange={handleQuestionChange}
              />
            </Form.Group>

            <p className="fs-sm text-center mt-2">
              {"تابع صفحة اتصالات على الفيسبوك وهتلاقي الرد على سؤالك فيها."}
            </p>

            <Button
              type="submit"
              value="Submit"
              className={`m-auto py-2 py-md-3 fs-md w-75 text-uppercase ${isAsked ? "d-none" : "d-block"}`}
            >
              {isLoading}
            </Button>
          </Form>
          <Link
            to="/fitness/home"
            className="fs-xm text-center mt-3 d-block"
          >
            {"اقرأ الأسئلة الشائعة"}
          </Link>
        </Col>
      </Container>

  )
}

export default AskComponent
