import { useParams, useHistory } from "react-router-dom";
import LoadingSpinnner from "../loadingSpinner/loadingSpinner";
import ErrorMsg from "../ErrorPage/ErrorMsg";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import { useQuery } from "@tanstack/react-query";
import api from "../../apis";
import { logFailedApi } from "../../globalFn";

const apiFn = (player_id) => api.player.getTransferHistory(player_id);

const TransferHistory = () => {
  const history = useHistory();
  const id = useParams().id;
  const { isLoading, isError, data = [] } = useQuery({
    queryKey: ["player-transfer-history", id],
    queryFn: () => apiFn(id),
    onError: () => logFailedApi("player-transfer-history"),
    select: ({ data }) => data,
    refetchOnWindowFocus: false,
  });

  const handleRowClick = (serviceId, sportId, id) => {
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId
      )}/team/${id}`,
      { previousPathname: history.location.pathname }
    );
  };

  return (
    <div>
      <div className="bg-black-color p-4 white-color">
        تاريخ الانتقالات
      </div>
      <div className="bg-black-color white-color fs-xs">
        <table className="table-borderless bg-black-color table-striped table-dark white-color col-12">
          <thead>
            <tr style={{ padding: "10px" }} className="text-center">
              <th scope="col">الموسم</th>
              <th scope="col">الناريخ</th>
              <th scope="col">غادر</th>
              <th scope="col">انضم</th>
              <th scope="col">اعاره/انتقال</th>
              <th scope="col">السعر</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan="6">
                  <LoadingSpinnner p={5} fs={"md"} />
                </td>
              </tr>
            ) :
              isError || !data.length ? (
                <tr>
                  <td colSpan="6">
                    <ErrorMsg p={5} fs={"md"} msg="لا يوجد احصائيات" />
                  </td>
                </tr>
              ) : (
                data.map((trans, i) => (
                  <tr key={trans.id} className="text-center p-2">
                    <td>{trans.season}</td>
                    <td>{trans.join_date}</td>
                    {trans.from_team !== null ? (
                      <td
                        onClick={() =>
                          handleRowClick(
                            trans.from_team.services?.[0],
                            trans.from_team.sport_id,
                            trans.from_team.id
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={ImgURL(trans.from_team.logo)}
                          width="30px"
                          className="mx-1"
                          alt=""
                        />{" "}
                        <span>{trans.from_team.name}</span>
                      </td>
                    ) : (
                      <div className="mt-2">لا يوجد</div>
                    )}
                    {trans.to_team !== null ? (
                      <td
                        onClick={() =>
                          handleRowClick(
                            trans.from_team.services?.[0],
                            trans.from_team.sport_id,
                            trans.to_team.id
                          )
                        }
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={ImgURL(trans.to_team.logo)}
                          width="30px"
                          className="mx-1"
                          alt=""
                        />{" "}
                        <span>{trans.to_team.name}</span>
                      </td>
                    ) : (
                      <div className="mt-2">لا يوجد</div>
                    )}
                    <td>
                      {trans.contract_type}
                    </td>
                    <td>{trans.price}</td>
                  </tr>
                ))
              )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default TransferHistory;
