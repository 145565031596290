import React from "react";
import { ImgURL } from "../../globalData";

const PlayerInfoBar = ({ playerDetails }) => {

  return (
    <div className="bg-black-color p-2 p-md-4 ">
      {
        <div className="d-flex justify-content-center text-center text-light fs-xxs">
          <div className="px-md-5 px-2 ">
            <div className="white-color">الجنسيه</div>
            <div className="d-flex text-center align-items-center">
              <img
                src={ImgURL(playerDetails?.nationality?.flag)
                }
                width={30}
                alt="nationality"
              />
              <div className="m-2">{playerDetails?.nationality?.title}</div>
            </div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="white-color">
            تاريخ الميلاد
            </div>
            <div>{playerDetails?.birth_date}</div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="white-color">الطول</div>
            <div>{playerDetails?.height}</div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="white-color">الوزن</div>
            <div>{playerDetails?.weight}</div>
          </div>
          <div className="border-right border-secondary px-md-5 px-2">
            <div className="white-color">
            الدوري الحالي
            </div>
            <div>{playerDetails?.current_team?.tournaments[0]?.title}</div>
          </div>
        </div>
      }
    </div>
  );
};

export default PlayerInfoBar;
