import moment from "moment";
import Cookies from "universal-cookie";
import api from "./apis";
import { globalBaseUrl } from "./globalData";
import TwistSettings from './TwistSettings.json';
import { getLoggingsParams } from "./hooks/useLogging/helper";

// Using the log api for logging failed apis into the DB
export function logFailedApi(apiName = "") {
  const { ip_address, msisdn, user_session, } = getLoggingsParams()

  api.reports.sendLog({
    ip_address,
    msisdn,
    user_session,
    from_url: apiName,
    to_url: "Failed",
  })
}

//Converts shape of date to be with dashes
//Ex: 2022-08-22 01:00:00 ===> 2022-8-21
// Only used in <Riddle/> component
export function addDashesToDate(date) {
  var dateObj = new Date(date);
  var month = dateObj.getUTCMonth() + 1; //months from 1-12
  var day = dateObj.getUTCDate();
  var year = dateObj.getUTCFullYear();

  return year + '-' + month + '-' + day;
}

//Converts a raw date to a readable format in EN or AR
// Ex: 2023-05-15 19:00:24 ==> Monday, 15 May 2023
export function convertFullDate(date, lang = "ar") {
  const daysEn = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ],
    monthsEn = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ],
    monthsAr = [
      "يناير",
      "فبراير",
      "مارس",
      "إبريل",
      "مايو",
      "يونيو",
      "يوليو",
      "أغسطس",
      "سبتمبر",
      "أكتوبر",
      "نوفمبر",
      "ديسمبر",
    ],
    daysAr = [
      "اﻷحد",
      "اﻷثنين",
      "الثلاثاء",
      "اﻷربعاء",
      "الخميس",
      "الجمعة",
      "السبت",
    ],
    day = moment(date).format("DD"),
    year = moment(date).year();
  if (lang === "en") {
    return `${daysEn[moment(date).day()]}, ${day} ${monthsEn[moment(date).month()]
      } ${year} `;
  } else if (lang === "ar") {
    return `${daysAr[moment(date).day()]}, ${day} ${monthsAr[moment(date).month()]
      } ${year} `;
  }
}

//Truncates a few word from the name if it has too many words
// Ex: Atletico Madrid ==> Atletico
export const shortenName = (title, wordLength = 12) => {
  if (!title) return '';
  const titleArr = title.split(" ")
  const newTitle = []
  let temp = 0
  for (let i = 0; i < titleArr.length; i++) {
    const newtemp = titleArr[i].length + temp
    if (newtemp < wordLength) {
      newTitle.push(titleArr[i])
      temp = newtemp
    }
  }
  return `${newTitle.join(' ')}`
}

//returns the user's phone number from that are saved in the cookies
export const getUserPhoneNumbers = () => {
  const cookies = new Cookies();
  const allCookies = cookies.getAll();
  const { public: publicService, local, africa, international, othersports, meloukelkora, fitness } = allCookies;
  const foundPhoneNumbers = [local, africa, international, othersports, meloukelkora, fitness, publicService].filter(ele => ele).map(ele => ele.split(",")[1]);
  return foundPhoneNumbers;
}

//Reasigns the globalBaseUrl obj in globalData.js folder a new URL if needed
export const defineCurrentBaseUrl = () => {
  const { REACT_APP_NEW_BASE_URL } = process.env;
  if (!TwistSettings['current-base-url-performane']) globalBaseUrl.current = REACT_APP_NEW_BASE_URL;
}
defineCurrentBaseUrl()

//An endpoint that takes a type player OR team and thrir ids to return their respective service number
export const getTeamOrPlayerService = async (id, type) => {
  const res = await api.service.get({ id, type });
  const { services } = res?.data
  return services[0];
}


//A count down to a certain date
export function countDown(date, pos) {
  var now = moment(new Date()), //todays date
    end = moment("2021-05-12 12:00:00"), // another date
    duration = moment.duration(end.diff(now)),
    days = duration.asDays().toFixed(),
    digits = days.toString().split("");

  if (duration < 0) {
    digits.unshift("0", "0");
  }

  if (digits.length === 1) {
    digits.unshift("0");
  }
  return digits[pos];
}