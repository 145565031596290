import React from 'react'
import { Container } from "react-bootstrap";
const TermsAndConditions = () => {
  return (
    <Container className='text-white py-5'>
        <h6>طريقه الأشتراك في التحدى :</h6>
        <ol>
            <li type="1">بمجرد الإتصال على رقم #5150* علي شبكة شركه اتصالات واشترك العميل سوف يستقبل يومياً أخبار الأهلي والزمالك قبل قمة الدوري المصري وفرصه للفوز بلاي ستاشن 5 او fifa CD و 1000 جنيهاً مصرياً او fifa CD و 500 جنيهاً مصرياً او تيشرت منتخب مصر مقسم على 7 فائزين إذا حصل علي أعلي نسب نقاط في آخر شهر رمضان يلعب المتنافسان ضد بعضهما البعض، وفي حالة التساوي مع متنافس آخر في النقاط</li>
        </ol>
        <h6>طريقة اللعب :</h6>
        <ol>
            <li>يتم تحديد منافس لكل مشترك، ينسخ اللاعب اسم المنافس ويضيفه لمواجهته في مباراة مدتها 15 دقيقة، وبعد انتهاء المباراة يرفع كلا اللاعبين صورة توضح النتيجة لتتم إضافة نقاطها للفائز.--</li>
        </ol>
        <h6>الشروط والأحكام الخاصة :</h6>
        <ol>
            <li>يشترط ان يكون العميل الفائز هو الحائز الفعلي والرسمي للخط كما يقر ان كافة البيانات المتعلقة بالخط الفائز صحيحة، وفي حالة فوز أي عميل بالجائزة وثبوت تقديمه لأي بيانات أو معلومات غير صحيحة، فان اتصالات مصر تحتفظ بكامل الحق في استبعاده من قائمة الفائزين وحجب الجائزة عنه ويخضع تقدير ما أذا كانت البيانات المقدمة من العميل صحيحه من عدمه لمطلق تقدير اتصالات مصر.</li>
            <li>إذا كان العميل الفائز يعتبر تحت السن القانوني يستوفى وجود ولى الامر معه لاستلام الجائزة علي ان يكون معه بطاقة شخصية و شهادة ميلاد للفائز لإثبات النسب.</li>
            <li>يقوم العملاء الفائزين باستلام الجائزة من الاماكن التي سيتم توجيههم اليها وقت اعلامهم بالفوز.</li>
          <li>يجب على المتحدي عند استلامه للجائزة أن يقوم بتقديم أصل بطاقة الرقم القومي الخاص به للاطلاع عليه من قبل الشركة مع حقها في الاحتفاظ بصورة من بطاقة الرقم القومي، وإلا فلن يتمكن من الحصول على الجائزة، كما أنه يتعين على العميل الفائز أن يقوم بالتوقيع على اقرار باستلامه الجائزة.</li>
            <li>يجب على المتحدي عند استلامه للجائزة أن يقوم بتقديم أصل بطاقة الرقم القومي الخاص به للاطلاع عليه من قبل الشركة مع حقها في الاحتفاظ بصورة من بطاقة الرقم القومي، وإلا فلن يتمكن من الحصول على الجائزة، كما أنه يتعين على العميل الفائز أن يقوم بالتوقيع على اقرار باستلامه الجائزة.</li>
            <li>يجب على الفائز ان يحمل خط اتصالات.</li>
            <li>في حالة ارسال رسالة نصية للفائز وعدم الرد من جهته برقم هاتفه في موعد أقصاه 3 ساعات سيتم اختيار فائز آخر.</li>
            <li>في حالة الاتصال بالفائز وعدم الرد بعد ثلاث محاولات سيتم اختيار فائز آخر.</li>
            <li>لا يحق لأي من موظفي اتصالات مصر وأقاربهم حتى الدرجة الرابعة أن يشتركوا (بشكل مباشر أو غير مباشر) في التحدي أو أن يفوز أي منهم بأي من الجوائز.</li>
            <li>يقوم العميل الفائز باستلام الجائزة ويتم تسليمه خلال أسبوع من وقت إعلان فوزهم، وفي حالة عدم الالتزام بالمدة المحددة سيتم فوراً اختيار فائز آخر.</li>
            <li>تقوم اتصالات مصر بالإعلان عن الفائزين من خلال مختلف وسائل الاعلام و يحق لها ان تقوم بوضع صور أو فيديو أو أسم الفائز في الاعلانات الدعائية الخاصة بالشركة و التي تعرض في جميع الوسائل الإعلامية و مواقع التواصل الاجتماعي و لا يجوز للفائز الرجوع بأي حقوق أو مطالبات مالية أو دعاوي تتعلق بنشر صور أو فيديو أو أسم العميل الفائز، و يحق لاتصالات مصر ان تطلب من الفائزين الظهور في وسائل الإعلام المختلفة التي تحددها الشركة للإعلان عن التحدي، ويعتبر قبول العميل لهذا الشرط شرطا أساسياً لاستحقاقه للجائزة.</li>
        
        </ol>
    </Container>
  )
}

export default TermsAndConditions