import {
  AdidasAds,
  BlackFridayAdImage,
  EmeraldAdImage,
  FlyDubaiAdImage,
  IqosAdImage,
  NetAdImage,
  OnlineShopAds,
  SpAdImage,
} from "./Advertisers";
import "./index.css";

export default function TwistAds({ children }) {
  return <div className='TwistAds'>{children}</div>;
}

TwistAds.OnlineShopAds = OnlineShopAds;
TwistAds.FlyDubaiAdImage = FlyDubaiAdImage;
TwistAds.EmeraldAdImage = EmeraldAdImage;
TwistAds.BlackFridayAdImage = BlackFridayAdImage;
TwistAds.AdidasAds = AdidasAds;
TwistAds.NetAdImage = NetAdImage;
TwistAds.IqosAdImage = IqosAdImage;
TwistAds.SpAdImage = SpAdImage;
