import React from "react";
import { Row, Col, Card, Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function VideoCardWebLoader(props) {
  return (
    <Container className="videosCardContainer" fluid="lg">
      <SkeletonTheme color="#004f90" highlightColor="#fff">
        <Row>
          <Col xs={props.colXsSize} md={props.colMdSize} lg={props.colLgSize}>
            <Card className="border-radius-10 my-3">
              <Skeleton height={200} />
              <Card.Body className="px-2 py-3">
                <Card.Title className="fs-18 color-7EBD19">
                  <Skeleton count={1} />
                </Card.Title>
                <Card.Text className="fs-16">
                  <Skeleton count={3} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={props.colXsSize} md={props.colMdSize} lg={props.colLgSize}>
            <Card className="border-radius-10 my-3">
              <Skeleton height={200} />
              <Card.Body className="px-2 py-3">
                <Card.Title className="fs-18 color-7EBD19">
                  <Skeleton count={1} />
                </Card.Title>
                <Card.Text className="fs-16">
                  <Skeleton count={3} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={props.colXsSize} md={props.colMdSize} lg={props.colLgSize}>
            <Card className="border-radius-10 my-3">
              <Skeleton height={200} />
              <Card.Body className="px-2 py-3">
                <Card.Title className="fs-18 color-7EBD19">
                  <Skeleton count={1} />
                </Card.Title>
                <Card.Text className="fs-16">
                  <Skeleton count={3} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={props.colXsSize} md={props.colMdSize} lg={props.colLgSize}>
            <Card className="border-radius-10 my-3">
              <Skeleton height={200} />
              <Card.Body className="px-2 py-3">
                <Card.Title className="fs-18 color-7EBD19">
                  <Skeleton count={1} />
                </Card.Title>
                <Card.Text className="fs-16">
                  <Skeleton count={3} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={props.colXsSize} md={props.colMdSize} lg={props.colLgSize}>
            <Card className="border-radius-10 my-3">
              <Skeleton height={200} />
              <Card.Body className="px-2 py-3">
                <Card.Title className="fs-18 color-7EBD19">
                  <Skeleton count={1} />
                </Card.Title>
                <Card.Text className="fs-16">
                  <Skeleton count={3} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={props.colXsSize} md={props.colMdSize} lg={props.colLgSize}>
            <Card className="border-radius-10 my-3">
              <Skeleton height={200} />
              <Card.Body className="px-2 py-3">
                <Card.Title className="fs-18 color-7EBD19">
                  <Skeleton count={1} />
                </Card.Title>
                <Card.Text className="fs-16">
                  <Skeleton count={3} />
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </SkeletonTheme>
    </Container>
  );
}
