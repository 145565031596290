import React from "react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { ReactComponent as Gym } from '../../assets/gym.svg'
import { ReactComponent as Workout } from '../../assets/workout.svg'
import { ReactComponent as Diet } from '../../assets/diet.svg'
import { ReactComponent as Conversation } from '../../assets/conversation.svg'
import { ReactComponent as Request } from '../../assets/request.svg'
import { Link, useLocation } from "react-router-dom";
import "./FitnessHomeWebComponent.css";

const FitnessHomeWebComponent = () => {
  const { pathname } = useLocation();

  return (
    <Container className="homeWebContainer">
      <div className="mt-4">
        <p className="text-center text-white">
          {"أهلاً بيك في خدمة تويست فيتنس للياقة مع علي مظهر"}
        </p>
        <Col xs={12} className="py-5">
          <Row className="justify-content-center m-auto row-flex">
            <Col xs={4} className="my-5">
              <Link
                to={{ pathname: "/fitness/gym-workout", state: { previousPathname: pathname } }}>
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Gym />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      {"تمرينات في الجيم"}
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      {"تمرينات يومياً لمساعدتك معرفة تمرينك اليومي في الجيم"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link
                to={{ pathname: "/fitness/home-workout", state: { previousPathname: pathname } }}
              >
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Workout />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      {"تمرينات في المنزل"}
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      {"لا تفوت الحلقات اليومية للتدريب المنزلي مع علي مظهر للياقة البدنية وإنقاص الوزن"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link
                to={{ pathname: "/fitness/nutrition-tips", state: { previousPathname: pathname } }}
              >
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Diet />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      {"برامج تغذية"}
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      {"شاهد حلقاتنا اليومية عن التغذية الصحيحة للجسم"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link
                to={{ pathname: "/fitness/aly-mazhar-tips", state: { previousPathname: pathname } }}
              >
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Diet />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      {"نصائح علي مظهر"}
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      {"تابع نصائح علي مظهر لتصحيح الأخطاء الشائعة في الرياضة"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">

              <Link
                to={{ pathname: "/fitness/ask", state: { previousPathname: pathname } }}>
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Conversation />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      {"اسأل علي مظهر"}
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      {"ابعت سؤالك اواستفسارك الرياضي من هنا وهيوصل ل خبير الرياضة علي مظهر"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
            <Col xs={4} className="my-5">
              <Link
                to={{ pathname: "/fitness/questions", state: { previousPathname: pathname } }}
              >
                <Card className="m-auto h-100">
                  <div className="circle-icon">
                    <Request />
                  </div>
                  <Card.Body className="mt-5">
                    <Card.Title className="text-center">
                      {"إجابات أسئلتكم"}
                    </Card.Title>
                    <Card.Text className="text-center m-2 fs-md">
                      {"شاهد علي مظهر وهو يجيب على أسئلتكم"}
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Link>
            </Col>
          </Row>
        </Col>
      </div>
    </Container>
  );
};

export default FitnessHomeWebComponent;
