import { useState } from "react";
import ComparisonPopUp from './ComparisonPopUp'
import "./Comparison.css"
import comparisonIcon from "../../assets/comparison-icon.png"

const Comparison = () => {
  const [show, setShow] = useState(false)

  return (

    <div className=' comparison bg-black-color white-color fs-xs text-center' >
      <div className={`comparison-content-wrapper d-flex flex-column`}>
        <div>
          <h6 className="comparison-title" >مقارنات</h6>
          <p className={`comparison-description w-75`}>مقارنة تويست سبورتس هي أداة تتيح لك إنشاء مقارنات إحصائية بين اللاعبين والفرق المتنافسة في كل الدوري المتاحة والمواسم المختلفة وفقا لقاعدة بيانات تتسع لأكثر من 3500 فريقا و81 ألف لاعبا في كرة القدم.</p>
        </div>
        <div onClick={() => setShow(true)} className={`comparison-action`}>
          <img src={comparisonIcon} width={30} height={30} alt='' />
          <span>ابدا المقارنة</span>
        </div>
      </div>
      <ComparisonPopUp show={show} setShow={setShow} />
    </div>
  )
}

export default Comparison 