import { useContext } from 'react'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { Dropdown, Row } from 'react-bootstrap'
import ErrorMsg from '../ErrorPage/ErrorMsg'
import { makeOrderedSeasonStats } from './helper'
import { sharedComponentsContext } from '../../context/shared-context';
import api from '../../apis';
import { useQuery } from "@tanstack/react-query";
import { logFailedApi } from '../../globalFn';

const tableTextAR = {
    "Player.Player": "الاعب",
    "Player.Nat": "الجنسيه",
    "Player.Age": "السن",
    "Player.Marketvalue": "القيمة السوقيه",
    "Player.Height": "الطول",
    "Player.Weight": "الوزن",
    "Player.DateOfBirth": "تاريخ الميلاد",
    "Player.CurrentLeague": "الدوري الحالي",
    "Player.TransferHistory": "تاريخ الانتقالات",
    "Player.Date": "الناريخ",
    "Player.Left": "غادر",
    "Player.Joined": "انضم",
    "Trans/Player.Loan": "اعاره/انتقال",
    "Player.Transfer": "انتقال",
    "Player.Loan": "اعاره",
    "Player.Fees": "السعر",
    "Player.MainPosition": "المركز الاساسي",
    "Player.Assists": "صناعة أهداف",
    "Player.MinutesPlayed": "دقائق اللعب",
    "Player.Appearance": "لعب",
    "StatsTable.PlayerStats": "احصائيات الاعب",
    "StatsTable.MissedPenalty": "اضاع بنلتي",
    "StatsTable.ReversedGoals": "هدف في مرماه",
    "StatsTable.Goals": "أهداف",
    "StatsTable.Prizes": "جوائز",
    "StatsTable.Stats": "احصائيات",
    "StatsTable.Position": "المركز",
    "StatsTable.Team": "الفريق",
    "StatsTable.Matches": "مباريات",
    "StatsTable.YellowCards": "كروت صفراء",
    "StatsTable.RedCards": "كروت حمراء",
    "StatsTable.Penalty": "ركلات جزاء",
    "StatsTable.Played": "لعب",
    "StatsTable.Wins": "فوز",
    "StatsTable.Losses": "خساره",
    "StatsTable.Draw": "تعادل",
    "StatsTable.GoalsIn": "أهداف له",
    "StatsTable.GoalsOut": "عليه",
    "StatsTable.GoalsDifference": "فارق الأهداف",
    "StatsTable.Difference": "فارق",
    "StatsTable.GoalsPenalty": "أهداف الجزاء",
    "StatsTable.CleanSheet": "شباك نظيفة",
    "StatsTable.Points": "نقاط",
    "StatsTable.Last5matches": "أخر 5 مباريات",
    "StatsTable.passes": "تمريرات ناجحة",
    "StatsTable.LostBalls": "كرات ضائعه",
    "StatsTable.Attempts": "محاولات على المرمى",
    "StatsTable.Chances": "فرص",
    "StatsTable.Dribbles": "المراوغات",
    "StatsTable.FoulsIn": "أخطاء عليه",
    "StatsTable.FoulsOut": "أخطاء له",
    "StatsTable.BallWin": "استعاد الكرة",
    "StatsTable.BallLose": "خسر الكرة",
    "StatsTable.ScoringIntervals": "دقائق التهديف",
    "StatsTable.TotalCross": "العرضيات"
}

const apiFn = (params) => api.player.getPlayerSeasonStatistics(params)

const PlayerStatsTable = ({ player_id }) => {
    const { playerDetails: { currentTeam, teams, currentTeamTournaments, currentTeamActiveTournament }, setInitialSharedValues } = useContext(sharedComponentsContext);
    const { isLoading, isError, data = {} } = useQuery({
        queryKey: ["player-season-statistics",],
        queryFn: () => apiFn({ sport_id: 1, tournament_id: currentTeamActiveTournament?.tournament_id, team_id: currentTeam?.id, player_id }),
        select: ({ data }) => data,
        onError: () => logFailedApi("player-season-statistics"),
        enabled: !!currentTeamActiveTournament.tournament_id && !!currentTeam.id && !!player_id,
        refetchOnWindowFocus: false,
    });
    const handleSetCurrentTeam = (team) => {
        setInitialSharedValues(prev => {
            return {
                ...prev,
                playerDetails: {
                    ...prev.playerDetails,
                    currentTeam: team,
                    currentTeamTournaments: team.tournaments,
                    currentTeamActiveTournament: team.tournaments.sort((a, b) => a.type - b.type)[0]
                }
            }
        })
    }
    const handleSetCurrentTournament = (tournament) => {
        setInitialSharedValues(prev => {
            return {
                ...prev,
                playerDetails: {
                    ...prev.playerDetails,
                    currentTeamActiveTournament: tournament
                }
            }
        })
    }

    const seasonsKeys = Object.keys(data);
    const PlayerSeasonStats = makeOrderedSeasonStats(data)

    return isError
        ? <div className='mt-3'>
            <ErrorMsg p={5} fs={'md'} msg="لا يوجد احصائيات" />
        </div>
        : <div className='mt-3'>
            <Row className='d-flex  justify-content-start'>
                <Dropdown className='col-auto'>
                    <Dropdown.Toggle variant="secondary" id={`dropdown-button-drop-down`} drop='down' disabled={isLoading}>
                        {currentTeam?.name}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {teams?.map(team => <div key={team.id}><Dropdown.Item className='text-light' onClick={() => handleSetCurrentTeam(team)}>{team.name}</Dropdown.Item></div>)}
                    </Dropdown.Menu>
                </Dropdown>
                <Dropdown className='col-auto'>
                    <Dropdown.Toggle variant="secondary" id={`dropdown-button`} drop='down' disabled={isLoading}>
                        {currentTeamActiveTournament?.title}
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {currentTeamTournaments?.map(tour => <div key={tour.id}><Dropdown.Item className='text-light' onClick={() => handleSetCurrentTournament(tour)} >{tour.title}</Dropdown.Item></div>)}
                    </Dropdown.Menu>
                </Dropdown>
            </Row>
            {isLoading
                ? <div className='mt-3'>
                    <div className='bg-black-color p-3'>احصائيات</div>
                    <LoadingSpinnner p={5} fs={'md'} />
                </div>
                : <table className="responsive-table table-borderless bg-black-color table-striped table-dark white-color mt-3">
                    <thead>
                        <tr>
                            <th style={{ padding: '20px' }}>احصائيات</th>
                            {seasonsKeys.map(season => <th key={season} scope="col" style={{ padding: '20px' }} className='text-nowrap'>{season.split("-").sort((a, b) => a - b).join(" - ")}</th>)}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            PlayerSeasonStats[seasonsKeys?.[0]]?.map(stat => <tr key={stat.name} className='fs-sm'>
                                <td>{tableTextAR[stat.name]}</td>
                                {seasonsKeys?.map(seasonKey => <td key={seasonKey}>{PlayerSeasonStats[seasonKey].find(stat2 => stat2.name === stat.name).value}</td>)}
                            </tr>)
                        }
                    </tbody>
                </table>
            }
        </div>
}


export default PlayerStatsTable

