import React from 'react'
import { useHistory } from "react-router-dom";
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';
import { useQuery } from '@tanstack/react-query';
import api from "../../apis";
import { logFailedApi } from '../../globalFn';

const apiFn = (params) => { return api.teams.getTopAssists(params) }

const TopAssists = ({ sport_id, season_id, tournament_id, team_id }) => {

    const history = useHistory();

    const { data: topAssists, isFetching, isLoading, isError } = useQuery({
        queryKey: ["top-assist", sport_id, season_id, tournament_id, team_id],
        queryFn: () => apiFn({ sport_id, season_id, tournament_id, team_id }),
        onError: () => logFailedApi("top-assist"),
        refetchOnWindowFocus: false,
    })

    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/player/${id}`, { previousPathname: history.location.pathname });
    }

    return (
        <div className='bg-black-color white-color fs-xs mt-2'>
            <div className='d-flex justify-content-between fs-md p-2'>
                <div>صناعة الأهداف</div>
            </div>
            <table className="top__assistsTable table-borderless bg-black-color table-dark white-color">
                <thead>
                    <tr className='text-center'>
                        <th scope="col"></th>
                        <th scope="col">لعب</th>
                        <th scope="col">صناعة أهداف</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        isError ?
                            <tr>
                                <td colSpan={3} className='p-0'>
                                    <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                                </td>
                            </tr>
                            :
                            isFetching ?
                                <tr>
                                    <td colSpan={7} className='p-0'>
                                        <LoadingSpinnner p={4} fs={"sm"} />
                                    </td>
                                </tr>
                                :
                                topAssists?.data.length === 0 ?
                                    <tr>
                                        <td colSpan={3} className='p-0'>
                                            <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                                        </td>
                                    </tr>
                                    :
                                    topAssists?.data.map(
                                        (player, i) => i <= 4 &&
                                            <tr key={player.id} onClick={() => handleRowClick(player.services?.[0], player.sport_id, player.id)} style={{ cursor: "pointer" }} className='table-clickable-row-animated'>
                                                <th scope="row"><img src={ImgURL(player.image)} width='30px' height="30px" className='mx-2' style={{ borderRadius: '50%' }} alt="" /><span>{player.name}</span></th>
                                                <td className='text-center' >{player.played}</td>
                                                <td className='text-center'>{player.assissts}</td>
                                            </tr>
                                    )
                    }

                </tbody>
            </table>
        </div>
    )
}

export default TopAssists