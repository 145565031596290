import { Route, useLocation } from "react-router-dom";
import Cookies from 'universal-cookie';
import { getUserPhoneNumbers } from "../../globalFn";


function ProtectedRoute({ component: Component, ...rest }) {
  const { pathname } = useLocation();
  const serviceName = pathname.split("/")[1];
  const cookies = new Cookies();

  const isAllowed = () => {
    //Temp logic to make Games work with subscribtion
    if (serviceName.includes('games')) {

      if (getUserPhoneNumbers()[0] !== undefined) {
        return true
      }
      else {
        return false
      }
    }
    else {

      if (cookies.get(serviceName) !== undefined) {
        return true
      }
      else {
        return false
      }

    }
  }



  return (
    <Route
      {...rest}
      render={(props) => {
        if (isAllowed()) {
          return <Component {...props} isSubscribed={true} />;
        } else {
          return <Component {...props} isSubscribed={false} />;
        }
      }}
    />
  );
}

export default ProtectedRoute;
