import React, { useEffect, useState } from 'react'
import { Col, Row, Tab, Tabs } from 'react-bootstrap';
import { useHistory } from "react-router-dom";
import { ImgURL } from '../../globalData';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import playerImage from '../../../src/assets/user-default.png';
import api from "../../apis"
import './index.css';

function OtherSportsPlayers({ tournamentId, sportName, id }) {
    const history = useHistory();
    const [menPlayers, setMenPlayers] = useState([]);
    const [womenPlayers, setWomenPlayers] = useState([]);
    const [playersLoading, setPlayersLoading] = useState(false);


    useEffect(() => {
        setPlayersLoading(true);
        api.squash.getSquashTournamentPlayers(id).then(res => {
            if (res?.status === 200) {
                const womenPlayers = [], menPlayers = [];
                for (const player of res?.data) {
                    if (player.competition === "M") menPlayers.push(player)
                    if (player.competition === "W") womenPlayers.push(player)
                }
                setWomenPlayers(womenPlayers);
                setMenPlayers(menPlayers);
                setPlayersLoading(false);
            }
        })
    }, [id]);

    const renderPlayers = (players = []) => <Row>
        {
            playersLoading ?
                < LoadingSpinnner p={5} fs={'md'} />
                : players?.length > 0
                    ? React.Children.toArray(players?.map((st, index) => <Col
                        xs={12}
                        md={3}
                        className="mb-3"
                    >
                        <div
                            className="et-card"
                            onClick={() => history.push(`/othersports/${sportName}/player/${st?.id}/overview`, { previousPathname: history.location.pathname })}
                        >
                            <div className='team'>
                                <img src={`${st?.image ? ImgURL(st?.image) : playerImage}`} alt="" />
                                <div>{st?.name_ar ?? st?.name_en ?? 'اسم اللاعب'}</div>
                            </div>
                            <div className="flag">
                                {st?.country?.flag && <img src={`${ImgURL(st?.country?.flag)}`} alt="" />}
                            </div>
                        </div>
                    </Col>
                    ))
                    : <ErrorMsg msg={'لا يوجد لاعبون'} p={5} fs={"lg"} />
        }
    </Row >

    return <section className='clubs players container'>
        <Tabs defaultActiveKey="men" className="gender-tabs justify-content-center">
            <Tab eventKey="men" title={<span>رجال</span>}>
                <div className="">{renderPlayers(menPlayers)}</div>
            </Tab>
            <Tab eventKey="women" title={<span>سيدات</span>}>
                <div className="">{renderPlayers(womenPlayers)}</div>
            </Tab>
        </Tabs>
    </section>;
}

export default OtherSportsPlayers