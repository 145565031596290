import HttpHelper from "./helper";

export const matchThePlayerEndpoints = {
    get: () =>
        HttpHelper.baseAxios
            .get(`/match_the_players`)
            .then((res) => res?.data.data),

    post: (inputData) =>
        HttpHelper.baseAxios
            .post(`/match_the_players_answers`, inputData)
            .then((res) => res?.data.data),
};