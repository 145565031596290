import React, { useEffect, useContext } from 'react'
import { useState } from 'react'
import { Col, ProgressBar, Row } from 'react-bootstrap'
import { shortenName } from '../../globalFn'
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import api from '../../apis'
import SeasonsDropdown from '../SeasonsDropdown/SeasonsDropdown';
import { sharedComponentsContext } from '../../context/shared-context';

const ComparisonTeamsResults = ({ comparisonItems }) => {
    const { seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);
    const [isFetchingTeamStats, setIsFetchingTeamStats] = useState(false)
    const [teamsStats, setTeamsStats] = useState([])
    const [errorTeamStats, setErrorTeamStats] = useState(null)

    const getTeamStatistics = async (sport_id, season_id, tournament_id, team_id) => {
        return await api.teams.getTeamStatistics({ sport_id, season_id, tournament_id, team_id })
            .then(async resp => {
                if (resp.status === 200) {
                    const teamDetails = await api.teams.getTeamDetails(team_id).then(res => res.data);
                    const data = { teamDetails, ...resp.data };
                    return data
                } else {
                    setErrorTeamStats(resp.message)
                    return null;
                }
            })
            .catch(error => {
                setErrorTeamStats(error)
                return null;
            })
    }

    const loadData = async (teamsIds, season_id) => {
        const data = []
        data.push(await getTeamStatistics(null, season_id, null, teamsIds[0]));
        data.push(await getTeamStatistics(null, season_id, null, teamsIds[1]));
        setTeamsStats(data);
    }

    useEffect(() => {
        setIsFetchingTeamStats(true)
        loadData(comparisonItems.map(t => t.id), activeSeason?.id)
        setIsFetchingTeamStats(false)
    }, [])

    const statisticsKeys = {
        played: "لعب",
        win: "فوز",
        lose: "خساره",
        draw: "تعادل",
        goals_in: "عليه",
        goals_out: "أهداف له",
        goals_penalty: "أهداف الجزاء",
        clean_sheet: "شباك نظيفة",
        yellow_cards: "كروت صفراء",
        red_cards: "كروت حمراء",
        difference: "فارق الأهداف",
        point: "نقاط",
        passes: "تمريرات ناجحة",
        lost_balls: "كرات ضائعه",
        attempts: "محاولات على المرمى",
        chances: "فرص",
        dribbles: "المراوغات",
        fouls_in: "أخطاء عليه",
        fouls_out: "أخطاء له",
        ball_win: "استعاد الكرة",
        ball_lose: "خسر الكرة",
    }

    const showedStats = [
        'clean_sheet',
        'difference',
        'draw',
        'goals_in',
        'goals_out',
        'goals_penalty',
        'lose',
        'played',
        'red_cards',
        'win',
        'yellow_cards',
    ];

    const getTotalteamsStatsDetails = (teamsStats, teamsStatsLength, key) => {
        if (teamsStatsLength < 0) return 0;
        let totalteamsStatsDetails = +teamsStats[teamsStatsLength][key] + getTotalteamsStatsDetails(teamsStats, teamsStatsLength - 1, key);
        return totalteamsStatsDetails;
    }

    return (
        <div>
            <div className='row justify-content-around'>
                {
                    comparisonItems.map(team =>
                        <div className='col-4 text-center'>
                            <img src={ImgURL(team.logo)} width={60} height={60} alt="" />
                            <div className='fs-md'>{shortenName(team.name, 12)}</div>
                        </div>)
                }

            </div>
            {
                isFetchingTeamStats ?
                    <div className='mt-5'>
                        <LoadingSpinnner p={5} fs={'md'} />
                    </div>
                    :
                    <Row className='d-flex justify-content-around items-stats-comparison'>
                        {
                            teamsStats.map(Team =>
                                <Col xs={5} key={Team.teamDetails?.id}>
                                    <SeasonsDropdown
                                        tournament_id={Team.teamDetails?.main_tournament.id}
                                        onChange={(season) => loadData(teamsStats.map(t => t.teamDetails?.id), season?.id)}
                                        classes="fs-xs"
                                    />
                                    {
                                        Object.keys(Team).filter(prop => prop !== 'teamDetails').slice(0, 14).map((key, i) =>
                                            showedStats.includes(key) &&
                                            <div className='mt-3'>
                                                <div className='d-flex justify-content-between'>
                                                    <div className='fs-xs'>{statisticsKeys[key]}</div>
                                                </div>
                                                <ProgressBar
                                                    now={+Team[key]}
                                                    label={+Team[key]}
                                                    max={getTotalteamsStatsDetails(teamsStats, teamsStats.length - 1, key) || 1}
                                                />
                                            </div>

                                        )
                                    }

                                </Col>)
                        }
                    </Row>
            }
        </div>
    )
}


export default ComparisonTeamsResults;
