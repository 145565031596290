import { useState } from "react";
import { Container, Tab, Tabs } from "react-bootstrap";
import { withRouter } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import MatchEvents from "../../components/MatchEvents/MatchEvents";
import MatchHeader from "../../components/MatchHeader/MatchHeader";
import MatchLineup from "../../components/MatchLineup/MatchLineup.component";
import MatchStatistics from "../../components/MatchStatistics/MatchStatistics";
import SquashMatchDetails from "../../components/SquashMatchDetails/SquashMatchDetails";
import { IDS } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import useServiceGuard from "../../hooks/useServiceGuard";
import "./MatchPage.css";
import { useContext } from "react";
import { sharedComponentsContext } from "../../context/shared-context";
import { useQuery } from "@tanstack/react-query";
import api from "../../apis";
import MatchHeaderLoader from "../../components/MatchHeader/matchHeaderLoader";
import { logFailedApi } from "../../globalFn";

const squashMatchStatus = {
  NOT_STARTED: 1,
  STARTED: 2,
  POSTPONED: 3,
  CANCELLED: 4,
  FINISHED: 5,
  Complete: 5,
}

const customizedData = (data, sportName) => {
  if (sportName !== 'squash') return data;
  const { player1, player_one_image, player_one, player2, player_two_image, player_two, status, tournament, result } = data;
  return {
    ...data,
    services: [10],
    sport_id: 7,
    team1: {
      id: player1,
      is_favourite: false,
      logo: player_one_image,
      title: player_one,
      services: [10],
      sport_id: 7,
      team_name: player_one,
      score: result ? result.split("-")[0] : 0,

    },
    team2: {
      id: player2,
      is_favourite: false,
      logo: player_two_image,
      title: player_two,
      services: [10],
      sport_id: 7,
      team_name: player_two,
      score: result ? result.split("-")[1] : 0,
    },
    tournament: { id: tournament, services: [10] },
    matchStatus: squashMatchStatus[status],
    matchRoundInfo: ""
  }
}

const apiFn = (params, sportName) => {
  if (sportName !== 'squash') return api.matches.get(params);
  return api.squash.getMatchDetails(params)
}

const LiveMatchPage = ({ match }) => {

  const individualSports = ['squash', 'tennis', 'basketball'];
  const [key, setKey] = useState(individualSports.includes(match.url?.split("/")[2]) ? ("squash") : ("lineup"));
  const { sportName, sportId } = useServiceAndSport();
  const isFootball = sportId === IDS.SPORTS.FOOTBALL || sportId === undefined
  const isIndividual = individualSports.includes(sportName);
  const { setInitialSharedValues } = useContext(sharedComponentsContext);

  const { isLoading, isError, data = {}, refetch } = useQuery({
    queryKey: ["match-info", match.url],
    queryFn: () => apiFn({ id: match.params.id }, sportName),
    select: ({ data }) => sportName == 'squash'
      ? customizedData(data?.[0], sportName)
      : customizedData(data?.data?.[0], sportName
      ),
    onSuccess: (data) => { setInitialSharedValues(prev => ({ ...prev, matchInfo: data })) },
    onError: () => logFailedApi("match-info"),
    enabled: sportName !== null,
    refetchOnWindowFocus: false,
  });

  useServiceGuard(isLoading, data?.services);

  return isLoading ?
    <MatchHeaderLoader /> :
    !Object.keys(data).length || (!isLoading && isError) ? (
      <ErrorPage />
    ) : (
      <div className="match-page">
        <MatchHeader
          matchInfo={data}
          matchId={match.params.id}
          refetch={refetch}
        />
        {!isFootball && isIndividual &&
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            variant="tabs"
            className="d-flex justify-content-center"
          >
            {sportName !== "squash" && <Tab eventKey="squash" title="تفاصيل">
              <SquashMatchDetails matchStatus={data?.matchStatus} data={data} matchId={match.params.id} sportName={sportName} />
            </Tab>}
          </Tabs>}
        {!isIndividual && isFootball &&
          <Tabs
            id="controlled-tab-example"
            activeKey={key}
            onSelect={(k) => setKey(k)}
            variant="tabs"
            className="d-flex justify-content-center"
          >
            <Tab eventKey="lineup" title="التشكيل">
              <Container>
                <MatchLineup matchInfo={data} matchId={match.params.id} />
              </Container>
            </Tab>
            <Tab eventKey="live" title="مباشر">
              <MatchEvents matchId={match.params.id} isLoadingMatchInfo={isLoading} dataMatchInfo={data} />
            </Tab>
            {/* Commented For Now Maybe Will Be Used Later */}
            {/* <Tab eventKey="min by min" title="دقيقة بـ دقيقة">
                  <MinBymin />
                </Tab> */}
            <Tab eventKey="stats" title="الاحصائيات">
              <MatchStatistics matchId={match.params.id} />
            </Tab>
          </Tabs>
        }
      </div>
    );
};

export default withRouter(LiveMatchPage);

