import React, { useState } from "react";
import ScrollToTop from "./ScrollToTop";
import MobileSideMenu from "./MobileSideMenu";
import HeaderNav from "./HeaderNav";
import SideMenu from "./SideMenu";
import TeamsCarousel from "./TeamsCarousel";
import FooterComponent from "./Footer";
import { useLocation } from "react-router-dom";

function Layout({ children }) {
  const { pathname } = useLocation()
  const [showMobileSideMenu, setShowMobileSideMenu] = useState(false);
  return (
    <>
      <ScrollToTop />
      <MobileSideMenu
        showMobileSideMenu={showMobileSideMenu}
        setShowMobileSideMenu={setShowMobileSideMenu}
      />
      <HeaderNav />
      {
        !pathname.includes("/fitness") &&
        !pathname.includes("/meloukelkora") &&
        <TeamsCarousel />
      }
      <SideMenu showMobileSideMenu={!showMobileSideMenu} />
      <div className="flex-grow-1">{children}</div>
      <FooterComponent />
    </>
  );
}

export default Layout;
