import React from 'react'
import { Button, Col, Row, Tab, Tabs } from 'react-bootstrap'
import './choose-plan.css';

function ChoosePlan({ setStep, userChoicesRef }) {

  const handlePlanClick = (chosenPlan) => {
    userChoicesRef.current.push(chosenPlan)
    setStep("4")
  }

  return <div className="choose-plan">
    <h3 className='text-center mb-4'>إختر خطة اشتراك</h3>
    <Tabs>
      <Tab eventKey="daily" title="يومي">
        <Row className='my-4'>
          <Col>
            <div className="card p-2">
              <h6>1 جنية</h6>
              <Button type="button" onClick={() => handlePlanClick("يومي-1 جنية")}>إختر الخطة</Button>
            </div>
          </Col>
          <Col>
            <div className="card p-2">
              <h6>1 جنية</h6>
              <Button type="button" onClick={() => handlePlanClick("يومي-1 جنية")} >إختر الخطة</Button>
            </div>
          </Col>
          <Col>
            <div className="card p-2">
              <h6>1 جنية</h6>
              <Button type="button" onClick={() => handlePlanClick("يومي-1 جنية")}>إختر الخطة</Button>
            </div>
          </Col>
        </Row>
      </Tab>
      <Tab eventKey="monthly" title="شهري">
        <Row className='my-4'>
          <Col>
            <div className="card p-2">
              <h6>20 جنية</h6>
              <Button type="button" onClick={() => handlePlanClick("شهري-20 جنية")}>إختر الخطة</Button>
            </div>
          </Col>
          <Col>
            <div className="card p-2">
              <h6>20 جنية</h6>
              <Button type="button" onClick={() => handlePlanClick("شهري-20 جنية")}>إختر الخطة</Button>
            </div>
          </Col>
          <Col>
            <div className="card p-2">
              <h6>20 جنية</h6>
              <Button type="button" onClick={() => handlePlanClick("شهري-20 جنية")}>إختر الخطة</Button>
            </div>
          </Col>
        </Row>
      </Tab>
    </Tabs>
  </div>
}

export default ChoosePlan