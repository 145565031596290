export const handleOnShirtSelect = (num, playersArr, shirtsArr, setPlayersArr, setShirtsArr) => {

    const isShirtAlreadyChosen = playersArr.some((player) => player.givenShirtNum == num)

    if (isShirtAlreadyChosen) return

    playersArr.some(player => {
        if (player.isSelected === false) {
            player.givenShirtNum = num;
            player.isSelected = true;
            return true
        }
        return false;
    })

    shirtsArr.map(shirt => shirt.num == num ? shirt.isSelected = true : shirt.isSelected)

    setPlayersArr([...playersArr])
    setShirtsArr([...shirtsArr])
}


export const handleOnShirtUnselect = (num, playersArr, shirtsArr, setPlayersArr, setShirtsArr) => {
    if (!num) return

    playersArr.some(player => {
        if (player.givenShirtNum === num) {
            player.givenShirtNum = null
            player.isSelected = false
            return true
        }
        return false
    })

    shirtsArr.map(shirt => shirt.num == num ? shirt.isSelected = false : shirt.isSelected)

    setPlayersArr([...playersArr])
    setShirtsArr([...shirtsArr])
}