import api from "../../../../apis";

export const getArabicMediaType = (type) => {
  switch (type) {
    case "happened-this-day":
      return "حدث في مثل هذا اليوم";
    case "mido-dairies":
      return "يوميات ميدو";
    case "mido-tactics":
      return "ميدو في الملعب";
    case "mido-answers":
      return "إجابات ميدو";
    default:
      return "undefined";
  }
};

export const getMediaTypeAPI = (type) => {
  switch (type) {
    case "happened-this-day":
      return api.melouk.media.getOneHappenedThisDay;
    case "mido-dairies":
      return api.melouk.media.getOneMidoDiaries;
    case "mido-tactics":
      return api.melouk.media.getOneMidoTactics;
    case "mido-answers":
      return api.melouk.media.getOneMidoAnswers;
    default:
      return "undefined";
  }
};
