import React, { useState, useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import guessThePlayerImg from '../../assets/games/anonymous.png';
import matchThePlayerImg from '../../assets/games/match-the-player.png'
import whereIsTheBallImg from '../../assets/games/where-is-the-ball.png'
import worldCupQuizzesImg from '../../assets/games/FIFA_World_Cup_trophy580.png';
import elAhlyGameImg from '../../assets/games/ElAhlyLogoNoStars.png'
import './games.css';
import GuessThePlayerModal from './Modals/GuessThePlayerModal';
import QuizzesModal from './Modals/QuizzesModal';
import TeamOfTheCompetition from './Modals/TeamOfTheCompetition';
import PlayerOfTheCompetitionModal from './Modals/PlayerOfTheCompetition';
import PlaystationFormModal from './Modals/PlaystationFormModal';
import PlaystationMatchDetailsModal from './Modals/PlaystationMatchDetails';
import Cookies from 'universal-cookie';
import { useHistory, useLocation } from 'react-router-dom';
import api from '../../apis';
import { ImCheckmark } from 'react-icons/im';
import TwistSettings from '../../TwistSettings.json'
import { getUserPhoneNumbers } from '../../globalFn';
import { WhereIsTheBall } from './Modals/WhereIsTheBall';
import { MatchThePlayer } from './Modals/MatchThePlayer';
import { Fragment } from 'react';
import { useQuery } from '@tanstack/react-query';
import { GiTwoCoins } from 'react-icons/gi'
import RequireSubscription from '../require-subscription';
import { logFailedApi } from '../../globalFn';

const userPointsApiFn = (params) => api.games.totalPoints.getPoints(params)

const Games = ({ isSubscribed }) => {
  const [showMacthThePlayerModal, setShowMacthThePlayerModal] = useState(false);
  const [showWhereIsTheBallModal, setShowWhereIsTheBallModal] = useState(false);
  const [teamOfTheCompetition, setTeamOfTheCompetition] = useState(false);
  const [guessThePlayerModal, setGuessThePlayerModal] = useState(false);
  const [quizzesModal, setQuizzesModal] = useState(false);
  const [showPlayerOfTheCompetitionModal, setShowPlayerOfTheCompetitionModal] = useState(false);
  const [showPlaystationFormModal, setShowPlaystationFormModal] = useState(false);
  const [showPlaystationMatchDetailsModal, setShowPlaystationMatchDetailsModal,] = useState(false);
  const [phone, setPhone] = useState('');
  const [userPlaystationInfoData, setUserPlaystationInfoData] = useState(null);
  const [isFetchingUserPlaystationInfoData, setIsFetchingUserPlaystationInfoData,] = useState(false);
  const [userPlaystationInfoError, setUserPlaystationInfoError] = useState('');
  const [isSendingUserPlaystationRegistrationInfo, setIsSendingUserPlaystationRegistrationInfo,] = useState(false);
  const [userPlaystationRegistrationError, setUserPlaystationRegistrationError,] = useState('');
  const [submittedGames, setSubmittedGames] = useState({});
  const [isSubscribedState, setIsSubscribedState] = useState(isSubscribed)
  const { pathname } = useLocation();
  const history = useHistory();

  const alAhlyPage = pathname.includes('6631')

  const { data: userTotalPoints, isLoading: userTotalPointsLoading, } = useQuery({
    queryFn: () => userPointsApiFn({ msisdn: getUserPhoneNumbers()[0] }),
    queryKey: ['user-total-points'],
    onError: () => logFailedApi('user-total-points'),
    enabled: getUserPhoneNumbers()[0] !== undefined
  })
  const gamesData = [
    {
      title: 'أين الكرة ؟',
      imgSrc: whereIsTheBallImg,
      gameName: "whereIsTheBall",
      onClick: (isSubmitted) => setShowWhereIsTheBallModal(isSubmitted)
    },
    {
      title: 'خمن رقم اللاعب',
      imgSrc: matchThePlayerImg,
      gameName: "matchThePlayer",
      onClick: (isSubmitted) => setShowMacthThePlayerModal(isSubmitted)
    },
    // {
    //   title: 'فريق المسابقة',
    //   imgSrc: teamOfTheCompetitionImg,
    //   gameName: "teamOfCompetition",
    //   onClick: (isSubmitted) => setTeamOfTheCompetition(isSubmitted)
    // },
    {
      title: "خمن اللاعب",
      imgSrc: guessThePlayerImg,
      gameName: "guessThePlayer",
      onClick: (isSubmitted) => setGuessThePlayerModal(isSubmitted),
    },
    // {
    //   title: "لاعب المسابقة",
    //   imgSrc: playerOfTheCompetitionImg,
    //   gameName: "playerOfCompetition",
    //   onClick: (isSubmitted) => setShowPlayerOfTheCompetitionModal(isSubmitted),
    // },
    {
      title: "أهلاوى صميم",
      imgSrc: elAhlyGameImg,
      gameName: "quiz",
      onClick: (isSubmitted) => setQuizzesModal(isSubmitted),
    },
    {
      title: "اسئلة",
      imgSrc: worldCupQuizzesImg,
      gameName: "quiz",
      onClick: (isSubmitted) => setQuizzesModal(isSubmitted),
    },
  ];

  const getUserPlaystationInfo = () => {
    setIsFetchingUserPlaystationInfoData(true);
    api.playstation.getUserInfo({ phone }).then((res) => {
      setIsFetchingUserPlaystationInfoData(false);
      if (res.status === 200) {
        setUserPlaystationInfoData(res.data);
        handleShowPlaystationModal(res.data);
      } else {
        setUserPlaystationInfoError(res.message);
      }
    });
  };

  const sendUserPlaystationRegistrationInfo = (data) => {
    const {
      userName: name,
      playStationName: playstation_name,
      userPhoneNumber,
    } = data;
    const mobile = `2${userPhoneNumber}`;
    setPhone(mobile);
    setIsSendingUserPlaystationRegistrationInfo(true);
    api.playstation
      .sendUserRegistrationInfo({ name, playstation_name, mobile })
      .then((res) => {
        if (res.status === 200) {
          setShowPlaystationFormModal(false);
        } else {
          setUserPlaystationRegistrationError(res.message);
        }
      });
  };

  const handleShowPlaystationModal = (response) => {
    if (!Object.keys(response).length) {
      setShowPlaystationFormModal(true);
    } else {
      setShowPlaystationMatchDetailsModal(true);
    }
  };

  const handlePlaystationButtonClick = () => {
    if (!phone) {
      history.push({
        pathname: '/international/subscribe',
        state: { previousPathname: pathname },
      });
    } else {
      getUserPlaystationInfo();
    }
  };

  const getSubmittedGames = async () => {
    const cookies = new Cookies();
    //we're only checking cookies for local subscription which is not sclable
    const msisdn = TwistSettings["subscription"] ? cookies.get('local')?.split(',')[1] : getUserPhoneNumbers()[0];
    if (msisdn) {
      await api.games.getSubmittedGames({ msisdn }).then(res => {
        if (res.status === 200) setSubmittedGames(res.data);
      })
    }
  }

  useEffect(() => {
    const cookies = new Cookies();
    //we're only checking cookies for local subscription which is not sclable
    const phone = TwistSettings["subscription"] ? cookies.get('local')?.split(',')[1] : getUserPhoneNumbers()[0];
    setPhone(phone);
    getSubmittedGames()
  }, []);

  useEffect(() => {
    if (getUserPhoneNumbers()[0] === undefined) return
    setIsSubscribedState(true)
  }, [getUserPhoneNumbers()[0]])

  return (
    <Container className='games'>
      <RequireSubscription isSubscribed={isSubscribed}>

        <div className='main-description'>
          <h4 className='py-2'>{'ألعاب تويست سبورتس'}</h4>
          <p>
            {"اختبر معلوماتك مع تويست سبورتس."}
          </p>
          {getUserPhoneNumbers()[0] && <div className='user-points'>
            <h5>{"النقاط"}</h5>
            <div>
              {userTotalPointsLoading ? <p>{"......"}</p> : <><GiTwoCoins />{userTotalPoints?.data?.points}</>}
            </div>
          </div>}
        </div>
        {
          TwistSettings['playstation-game'] && <div className='games-page-title'>
            <h5>مسابقة البلايستيشن</h5>
            <p>
              اشترك في مونديال بلايستيشن اتصالات سبورتس ونافس أصحابك والمشتركين في الخدمة خلال كأس العالم.
              <span className='mx-1'>اعرف التفاصيل من الشروط والأحكام</span>
            </p>
            <button
              className='playstaion__button btn bg-white'
              onClick={() => {
                handlePlaystationButtonClick();
              }}
            >
              {isFetchingUserPlaystationInfoData ? (
                <div
                  className='spinner-border text-center text-danger fs-xs p-0'
                  role='status'
                />
              ) : "ابدأ"}
            </button>
            {!!userPlaystationInfoError && !isFetchingUserPlaystationInfoData && (
              <div className='text-danger mt-2'>حدث خطا يرجي اعادة المحاولة</div>
            )}
          </div>
        }
        <PlaystationMatchDetailsModal
          show={showPlaystationMatchDetailsModal}
          onHide={() => setShowPlaystationMatchDetailsModal(false)}
          userPlaystationInfoData={userPlaystationInfoData}
        />

        <Row className='justify-content-center w-75 m-auto'>
          {gamesData.map(({ title, imgSrc, onClick, gameName }) => {
            return <Fragment key={title}>
              {alAhlyPage && title === "أهلاوى صميم" &&
                <Col xs={12} sm={6} lg={gamesData.length === 1 ? 12 : 3} key={title} className="mb-3">
                  <div className={`game-card ${submittedGames[gameName] ? "submitted" : ""}`} onClick={() => onClick(!submittedGames[gameName])}>
                    <div className="title ">
                      <h6 className="">{title}</h6>
                    </div>
                    <div className="image__wrapper">
                      <img src={imgSrc} alt={title} className='al-ahly' />
                      {submittedGames[gameName] &&
                        <ImCheckmark className="submitted__icon" />
                      }
                    </div>
                  </div>
                </Col>}
              {!alAhlyPage && title !== "أهلاوى صميم" &&
                <Col xs={12} sm={6} lg={gamesData.length === 1 ? 12 : 4} key={title} className="my-3">
                  <div className={`game-card ${submittedGames[gameName] ? "submitted" : ""}`} onClick={() => onClick(!submittedGames[gameName])}>
                    <div className="title ">
                      <h6 className="">{title}</h6>
                    </div>
                    <div className="image__wrapper">
                      <img src={imgSrc} alt={title} />
                      {submittedGames[gameName] &&
                        <ImCheckmark className="submitted__icon" />
                      }
                    </div>
                  </div>
                </Col>}
            </Fragment>
          })}
        </Row>

        <PlaystationFormModal
          show={showPlaystationFormModal}
          onHide={() => setShowPlaystationFormModal(false)}
          sendUserPlaystationRegistrationInfo={
            sendUserPlaystationRegistrationInfo
          }
          userPlaystationRegistrationError={userPlaystationRegistrationError}
          isSendingUserPlaystationRegistrationInfo={
            isSendingUserPlaystationRegistrationInfo
          }
        />

        <WhereIsTheBall
          show={showWhereIsTheBallModal}
          onHide={() => { getSubmittedGames(); setShowWhereIsTheBallModal(false); }}
        />

        <MatchThePlayer
          show={showMacthThePlayerModal}
          onHide={() => { getSubmittedGames(); setShowMacthThePlayerModal(false); }}
        />

        <TeamOfTheCompetition
          show={teamOfTheCompetition}
          onHide={() => { getSubmittedGames(); setTeamOfTheCompetition(false); }}
        />
        <GuessThePlayerModal
          show={guessThePlayerModal}
          onHide={() => { getSubmittedGames(); setGuessThePlayerModal(false); }}
        />
        <QuizzesModal
          alAhlyPage={alAhlyPage}
          show={quizzesModal}
          onHide={() => { getSubmittedGames(); setQuizzesModal(false); }}
        />
        <PlayerOfTheCompetitionModal
          show={showPlayerOfTheCompetitionModal}
          onHide={() => { getSubmittedGames(); setShowPlayerOfTheCompetitionModal(false); }}
        />
      </RequireSubscription >
    </Container >
  );
};

export default Games;
