import React from 'react'
import { useParams } from 'react-router-dom';
import SectionMediaList from './SectionMediaList';

const Section = () => {
  const {sectionName, subSectionName} = useParams();

  return <SectionMediaList sectionName={sectionName} subSectionName={subSectionName} />
}

export default Section