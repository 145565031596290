import { useParams } from "react-router-dom";
import { Container } from 'react-bootstrap';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import api from '../../apis';
import { useQuery } from '@tanstack/react-query';
import { logFailedApi } from "../../globalFn";

const apiFn = (tournament_id) => api.tournament.getTournamentHistory(tournament_id)


const TournamentHistory = () => {
    const id = useParams().id;
    const { isLoading, isError, data = {} } = useQuery({
        queryKey: ["tournament-history", id],
        queryFn: () => apiFn(id),
        onError: () => logFailedApi("tournament-history"),
        select: ({ data }) => data,
        refetchOnWindowFocus: false,
    });

    return (
        <Container>
            {isLoading ?
                <div className='mt-2'>
                    <LoadingSpinnner p={5} fs={'md'} />
                </div> :
                isError || !Object.keys(data).length ?
                    <div className='mt-2'>
                        <ErrorMsg p={5} fs={'lg'} msg="لايوجد معلومات" />
                    </div>
                    :
                    <div>
                        <div className='my-3 bg-black-color p-5 white-color'>
                            {data.description}
                        </div>
                        <div className='bg-black-color white-color fs-xs mt-3 row p-1'>
                            <table className="table-borderless bg-black-color table-striped table-dark white-color col-12">
                                <thead>
                                    <tr style={{ padding: '10px' }} className="text-center">
                                        <th>المركز</th>
                                        <th scope="col" className={window.document.body.dir === 'rtl' ? 'text-right' : 'text-left'}>الفريق</th>
                                        <th scope="col">البطل</th>
                                        {/* <th scope="col">الوصيف</th> */}
                                        <th scope="col">مواسم الفوز</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        data.winners.length === 0 ?
                                            <tr>
                                                <td colSpan={15} className='p-0'>
                                                    <ErrorMsg msg="لايوجد معلومات" p={5} fs={'lg'} />
                                                </td>
                                            </tr>
                                            :
                                            data.winners.map(
                                                (team, i) =>
                                                    <tr key={team.id} className="text-center">
                                                        <td style={{ width: '25px' }}>{i + 1}</td>
                                                        <td className={window.document.body.dir === 'rtl' ? 'text-right team-name' : 'text-left team-name'}>
                                                            <img src={ImgURL(team.logo)} style={{ width: '20px' }} alt="team_logo" />
                                                            <span className="mx-2 fs-xxs ">{team.team_name}</span>
                                                        </td>
                                                        <td>{team.count}</td>
                                                        {/* <td>tbd</td> */}
                                                        <td>{team.seasons}</td>
                                                    </tr>
                                            )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
            }
        </Container>
    )
}

export default TournamentHistory