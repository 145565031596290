import React from 'react'


const ErrorMsg = ({ msg, p, fs, m }) => {
    return (
        <div className={`m-${m}  p-${p} fs-${fs} bg-light-black-color text-center text-light`} data-testid="general-standings-error-message">
            {msg}
        </div>
    )
}

export default ErrorMsg