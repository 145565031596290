import React from "react";
import BannerWebComponent from "../../components/banner/BannerWebComponent";
import BannerMobComponent from "../../components/banner/BannerMobComponent";
import VideoCardWebComponent from "../../components/videos/VideoCardWebComponent"
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { logFailedApi } from "../../../../globalFn";

const apiFn = (contentType) => api.fitness.aliMazharContent.getAliTipsOrVideos(contentType)

const AlyMazharTipsPage = () => {

  const { data: aliMazharTips, isError, isLoading, refetch } = useQuery({
    queryKey: ["ali-mazhar-tips"],
    queryFn: () => apiFn("AMT"),
    onError: () => logFailedApi("ali-mazhar-tips"),
    refetchOnWindowFocus: false,
  })


  return (
    <div>
      <div className="d-none d-xl-block">
        <BannerWebComponent
          title={"نصائح علي مظهر"}
          subTitle={"تابع نصائح علي مظهر لتصحيح الأخطاء الشائعة في الرياضة "}
        />
      </div>
      <div className="d-xl-none">
        <BannerMobComponent
          title={"نصائح علي مظهر"}
          subTitle={"تابع نصائح علي مظهر لتصحيح الأخطاء الشائعة في الرياضة "}
        />
      </div>
      <VideoCardWebComponent
        type="amt"
        colXsSize={12}
        colMdSize={6}
        colLgSize={4}
        isLoading={isLoading}
        errMess={isError}
        videos={aliMazharTips?.results}
      />
    </div>
  );
};
export default AlyMazharTipsPage;
