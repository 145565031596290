import React from 'react'
import './FitnessIntro.css'
import mazharImg from "../../assets/ali-mazhar-fitness.jpeg"

function FitnessIntro() {

    return (
        <div className='container fitness-page'>
            <div className="d-flex flex-column align-items-center justify-content-center row">
                <div className="col-md-8 text-center my-4">
                    <h3 className=' white-color'>
                        مرحبا بك في تويست فيتنس مع علي مظهر
                    </h3>
                </div>

                <div className='d-flex justify-content-center align-items-start'>
                    <img src={mazharImg} alt="Ali Mzahar" />
                </div>

                {/* <div className='fitness-video d-flex justify-content-center'>
                <video className='col-md-8' controls>
                    <source src="http://fitness.etisalatsports.com/vid/EtisalatFitness_IntroVideo.mp4" type="video/mp4"/>
                </video>
            </div> */}

                {/* <div className='col-md-6 my-5 px-4 d-flex justify-content-center'>
                <button className='fitness-start-btn bg-active-color px-3'>
                    { t("fitness.startButton")}
                </button>
            </div> */}
            </div>
        </div>
    )
}

export default FitnessIntro