import { InView } from 'react-intersection-observer';
import "./OnDemand.css"


function OnDemand({ children }) {
    return (
        <InView threshold={0.5} triggerOnce={true}>
            {({ inView, ref }) => (
                <div ref={ref} className={`component ${inView ? "fade-in-effect" : ""}`}>
                    {inView && children}
                </div>
            )}
        </InView>
    )
}

export default OnDemand