import React from "react";
import { Container, Accordion, Card } from "react-bootstrap";
import ReactPlayer from "react-player";
import DoneIconComponent from "../icons/DoneIconComponent";
import FavIconComponent from "../icons/FavIconComponent";
import { ProgressBar } from "react-bootstrap";
import { IoIosArrowDown } from "react-icons/io";
import { GiStopwatch } from "react-icons/gi";
import "./WorkoutMobComponent.css";
import WorkoutMobLoader from "./WorkoutMobLoader";

const WorkoutMobComponent = (props) => {

  if (props.isLoading || props.errMess) {
    return <WorkoutMobLoader />;
  } else {
    return (
      <Container className="workOutMobi p-0" fluid>
        <ProgressBar now={props.progressBar} label={`${props.progressBar}%`} />
        {props.videos.map((video) =>
          video.type === "workout" ? (
            <Accordion defaultActiveKey="0" className="m-2" key={video.id}>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey="1" className="p-2">
                  <h6 className="fs-md color-primary-light">
                    {video.title}
                  </h6>
                  <p className="fs-sm mb-1">
                    {video.description}
                  </p>
                </Accordion.Toggle>
                <div className="d-flex ">
                  <div className="d-flex ">
                    <div onClick={() => props.handleFavorite(video)}>
                      <FavIconComponent
                        isFav={video.favorite}
                        favLoading={props.favLoading}
                      />
                    </div>
                    <div onClick={() => props.addDone(video)}>
                      <DoneIconComponent
                        isDone={video.done}
                        doneLoading={props.doneLoading}
                      />
                    </div>
                  </div>
                  <div className="mobi-arrow p-1">
                    <IoIosArrowDown className="fs-xxm color-primary-light" />
                  </div>
                </div>

                <Accordion.Collapse eventKey="1">
                  <Card.Body>
                    <ReactPlayer
                      stopOnUnmount={false}
                      light={
                        process.env.REACT_APP_MEDIA_BASE_URL + video.videoCover
                      }
                      playIcon={<div className="play-btn"></div>}
                      className="react-player"
                      url={process.env.REACT_APP_MEDIA_BASE_URL + video.video}
                      width="100%"
                      height="100%"
                      controls={true}
                      config={{
                        file: {
                          attributes: {
                            controlsList: "nodownload", //<- this is the important bit
                          },
                        },
                      }}
                    />
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ) : (
            <Card className="m-2 border-0" key={video.id}>
              <h6 className="fs-md m-2 text-center">
                <GiStopwatch className="fs-xm mx-1 mb-1" />
                {video.title}
              </h6>
            </Card>
          )
        )}
      </Container>
    );
  }
};

export default WorkoutMobComponent;
