import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { translateFormationToSquad } from "./helper";
import SoccerField from "./SoccerField";
import api from "../../../../apis";
import GameSuccessView from "../../GameSuccessView/GameSuccessView";
import { getUserPhoneNumbers } from "../../../../globalFn";
import { sendGameReport } from "../helper";

function TeamOfTheCompetition(props) {
  const [showSuccessView, setShowSuccessView] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [squad, setSquad] = useState([]);
  const [currentFormation, setCurrentFormation] = useState("4-3-3");

  const msisdn = getUserPhoneNumbers()[0]

  const changePlayer = (newPlayerId, line, newPlayer) => {
    const newSquad = { ...squad };
    if (line === "gk") {
      newSquad.gk = newPlayer;
    } else {
      newSquad[line] = newSquad[line].map((oldPlayer) =>
        oldPlayer.id === newPlayerId ? newPlayer : oldPlayer
      );
    }
    setSquad(newSquad);
  };

  const updateFormation = (formation) => {
    setCurrentFormation(formation);
    const newSquad = translateFormationToSquad(formation, squad);
    setSquad(newSquad);
  };

  useEffect(() => {
    const defaultSquad = translateFormationToSquad("4-3-3");
    setSquad(defaultSquad);
  }, []);

  useEffect(() => {
    if (props.show) {
      sendGameReport("TheTeamOfCompetition")
    }
  }, [props.show])

  const handleSubmitAnswer = () => {
    let errorMsgFlag = false;

    const goal_keeperIds = squad.gk.id.toString();

    const defendersIds = squad.df
      .reduce((acc, player) => (acc += player.id + ","), "")
      .slice(0, -1);

    const midfieldersIds = [...squad.cdm, ...squad.cm, ...squad.cam]
      .reduce((acc, player) => (acc += player.id + ","), "")
      .slice(0, -1);

    const attackersIds = squad.fw
      .reduce((acc, player) => (acc += player.id + ","), "")
      .slice(0, -1);

    const dataBody = {
      msisdn: msisdn,
      goal_keeper: goal_keeperIds,
      defenders: defendersIds,
      midfielders: midfieldersIds,
      attackers: attackersIds,
      formation: currentFormation,
    };

    Object.entries(dataBody).forEach((entry) => {
      if (
        entry[0] === "goal_keeper" ||
        entry[0] === "defenders" ||
        entry[0] === "midfielders" ||
        entry[0] === "attackers"
      ) {
        entry[1].split(",").forEach((id) => {
          if (id.match(/[^0-9]/gi)) {
            setErrorMsg("Please fill all postitions");
            errorMsgFlag = true;
          }
        });
      }
    });

    if (errorMsgFlag) return;

    async function sendAnswer() {
      setIsLoading(true);
      setErrorMsg("");

      let res = await api.teamOfTheCompetitionGame.postAnswer(dataBody);
      if (res.data.status === 201) {
        setIsLoading(false);
        setShowSuccessView(true);
        const setTimeOutID = setTimeout(() => {
          props.onHide();
          clearTimeout(setTimeOutID);
        }, 2000);
      } else {
        setErrorMsg(res.message);
        setIsLoading(false);
      }
    }
    sendAnswer();
  };

  return (
    <Modal
      {...props}
      size="md"
      className="TeamOfTheCompetition"
      contentClassName={"modal__content"}
      dialogClassName="modal__dialog"
    >
      <Modal.Header className="modal__header" closeButton />
      <Modal.Body className="show-grid">
        {showSuccessView ? (
          <GameSuccessView />
        ) : (
          <Container>
            <Row>
              <Col>
                <h3 className="text-center">فريق المسابقة </h3>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="text-center mb-5">{"يمكنك اختيار أفضل 11 لاعب في البطولة من وجهة نظرك. "}</p>
              </Col>
            </Row>
            <Row>
              <Col>
                <h6 className="text-center">إختر التشكيله</h6>
              </Col>
            </Row>
            <Row className="tactics">
              <Col>
                <button
                  className={`${currentFormation === "3-5-2" ? "active" : ""}`}
                  onClick={() => updateFormation("3-5-2")}
                >
                  3-5-2
                </button>
              </Col>
              <Col>
                <button
                  className={`${currentFormation === "4-4-2" ? "active" : ""}`}
                  onClick={() => updateFormation("4-4-2")}
                >
                  4-4-2
                </button>
              </Col>
              <Col>
                <button
                  className={`${currentFormation === "3-4-3" ? "active" : ""}`}
                  onClick={() => updateFormation("3-4-3")}
                >
                  3-4-3
                </button>
              </Col>
              <Col>
                <button
                  className={`${currentFormation === "4-3-3" ? "active" : ""}`}
                  onClick={() => updateFormation("4-3-3")}
                >
                  4-3-3
                </button>
              </Col>
              <Col>
                <button
                  className={`${currentFormation === "4-2-3-1" ? "active" : ""
                    }`}
                  onClick={() => updateFormation("4-2-3-1")}
                >
                  4-2-3-1
                </button>
              </Col>
            </Row>
            <Row>
              <Col>
                <SoccerField squad={squad} changePlayer={changePlayer} />
              </Col>
            </Row>
          </Container>
        )}
      </Modal.Body>
      {!showSuccessView && (
        <Modal.Footer className="modal__footer d-flex flex-column justify-content-center">
          <Button
            className="text-capitalize"
            onClick={handleSubmitAnswer}
            disabled={isLoading}
          >
            {isLoading ? (
              <div
                className="spinner-border text-light fs-xs p-0"
                role="status"
                data-testid="teamOfTheCompetitionGame-submitBtn-loading"
              ></div>
            ) : (
              "تأكيد"
            )}
          </Button>
          <span className="error-msg">{errorMsg}</span>
        </Modal.Footer>
      )}
    </Modal>
  );
}

export default TeamOfTheCompetition;
