import React from 'react';
import { useEffect } from 'react';
import { getSpecialMediaSectionData } from "../helper";
import MediaList from '../../News/MediaList';
import SportsSectionHeader from '../../sportsSectionHeader/sportsSectionHeader.component';
import ErrorMsg from '../../ErrorPage/ErrorMsg';

function FanClubNewsSection({ msisdn, currentState, dispatch, lang }) {
    useEffect(() => {
        if (!msisdn) return;
        getSpecialMediaSectionData(dispatch, { msisdn, page: 1 }, lang)
    }, [msisdn])

    return (
        <>
            <SportsSectionHeader title="أخبار نادي المشجعين" />

            {currentState.fetchedData.length == 0 
                ?<ErrorMsg msg="لا يوجد معلومات عن الاندية" p={4} fs={'md'} />
                :<MediaList
                    AllMedia={currentState.fetchedData}
                    getByPage={(page) => getSpecialMediaSectionData(dispatch, { msisdn, page }, lang)}
                    current_page={currentState.meta.current_page}
                    last_page={currentState.meta.last_page}
                    initialLoading={currentState.isLoading && currentState.fetchedData.length === 0}
                    paginationLoading={currentState.isLoading && currentState.fetchedData.length > 0}
                />
            }
            <hr/>
        </>
    )
}

export default React.memo(FanClubNewsSection)
