import { useHistory } from "react-router-dom";
import './TournamentStanding.css'
import { Container } from 'react-bootstrap';
import H2HModal from './H2HModal.jsx';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Vs from '../../assets/Vs.png'
import { ImgURL } from '../../globalData';
import useServiceAndSport from '../../hooks/useServiceAndSport';
import TwistSettings from "../../TwistSettings.json";
import { useState } from "react";


const TourStandingDetails = ({ isLoading, isError, data }) => {
    const history = useHistory();
    const { route } = useServiceAndSport();
    const [showH2HTeamsShowModal, setShowH2HTeamsModal] = useState(false)
    const [H2HTeams, setH2HTeams] = useState([])

    const handleRowClick = (id) => history.push(`${route}/team/${id}`, { previousPathname: history.location.pathname });

    const handleComparisonButtonClick = () => H2HTeams.length > 1 && setShowH2HTeamsModal(true);

    const handleComparisonModalHide = () => {
        setShowH2HTeamsModal(false);
        setH2HTeams([]);
    }

    const handleToggleH2HTeams = (item) => {
        let newH2HTeams;
        const itemIndex = H2HTeams.findIndex(team => team.id === item.id)
        if (itemIndex === -1) newH2HTeams = [...H2HTeams, item];
        else newH2HTeams = H2HTeams.filter(team => team.id !== item.id);
        setH2HTeams(newH2HTeams)
    }

    const handleCheck = (e, team) => {
        e.stopPropagation()
        handleToggleH2HTeams(team)
        if (H2HTeams.length === 3) setShowH2HTeamsModal(true)
    }


    return (
        <Container>
            <H2HModal show={showH2HTeamsShowModal} onHide={handleComparisonModalHide} H2HTeams={H2HTeams} />
            <div className='bg-black-color white-color fs-xs mt-3 row'>
                <table className="responsive-table tournament-standing table-borderless bg-black-color table-striped table-dark white-color col-12">
                    <thead>
                        <tr style={{ padding: '10px' }} className="text-center">
                            <th>المركز</th>
                            <th scope="col" className={window.document.body.dir === 'rtl' ? 'text-right' : 'text-left'}>الفريق</th>
                            <th scope="col">لعب</th>
                            <th scope="col">فوز</th>
                            <th scope="col">خساره</th>
                            <th scope="col">تعادل</th>
                            <th scope="col">أهداف له</th>
                            <th scope="col">عليه</th>
                            <th scope="col">فارق الأهداف</th>
                            <th scope="col">نقاط</th>
                            {TwistSettings["comparison"] &&
                                <th scope="col">Vs</th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        {isLoading ?
                            <tr>
                                <td colSpan={11} className='p-0'>
                                    <LoadingSpinnner p={4} fs={'md'} />
                                </td>
                            </tr> :
                            isError || !data.length ?
                                <tr>
                                    <td colSpan={11} className='p-0'>
                                        <ErrorMsg msg="لا يوجد ترتيب" p={5} fs={'lg'} />
                                    </td>
                                </tr>
                                :
                                data.sort((a, b) => b.point - a.point).map(
                                    (team, i) => (
                                        <tr key={team.id} onClick={() => handleRowClick(team.team_id)} className={`table-clickable-row-animated text-center`}>
                                            <td style={{ width: '25px' }}>{i + 1}</td>
                                            <td style={{ minWidth: '130px' }} className={window.document.body.dir === 'rtl' ? 'text-right team-name' : 'text-left team-name'}><img src={ImgURL(team.team_logo)} style={{ width: '20px' }} alt="" /><span className="mx-1 mx-md-2 text-nowrap fs-xxs ">{team.team_name}</span></td>
                                            <td>{team.played}</td>
                                            <td>{team.win}</td>
                                            <td>{team.lose}</td>
                                            <td>{team.draw}</td>
                                            <td>{team.goals_in}</td>
                                            <td>{team.goals_out}</td>
                                            <td>{team.difference}</td>
                                            <td>{team.point}</td>
                                            {TwistSettings["comparison"] &&
                                                <td onClick={(e) => e.stopPropagation()}>
                                                    <div className="form-check d-flex justify-content-center">
                                                        <input className="form-check-input " type="checkbox" value={i} checked={H2HTeams.filter(ele => ele.id === team.id).length} id={team.id} onClick={(e) => { handleCheck(e, team) }}></input>
                                                    </div>
                                                </td>
                                            }
                                        </tr>
                                    )
                                )
                        }
                    </tbody>
                </table>
            </div>
            {TwistSettings["comparison"] &&
                <div className='text-center vs-button'>
                    <button className='btn  mt-1' disabled={H2HTeams.length < 2} onClick={handleComparisonButtonClick}><img src={Vs} width={90} alt="" /></button>
                </div>
            }
        </Container>
    )
}

export default TourStandingDetails;
