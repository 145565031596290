import React from "react";
import { Button, Container, ButtonGroup } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import BannerWebComponent from "../../components/banner/BannerWebComponent";
import BannerMobComponent from "../../components/banner/BannerMobComponent";
import VideoCardWebComponent from "../../components/videos/VideoCardWebComponent";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { logFailedApi } from "../../../../globalFn";

const apiFn = (contentType) => api.fitness.aliMazharContent.getAliTipsOrVideos(contentType)

const NutritionEpisodes = () => {

    const { pathname } = useLocation();

    const { data: aliMazharEpisodes, isError, isLoading } = useQuery({
        queryKey: ["ali-mazhar-nutrition-episodes"],
        queryFn: () => apiFn("VIDEOS"),
        onError: () => logFailedApi("ali-mazhar-nutrition-episodes"),
        refetchOnWindowFocus: false,
    })


    return (
        <div>
            <div className="d-none d-xl-block">
                <BannerWebComponent
                    title={"حلقات"}
                    subTitle={"شاهد حلقاتنا اليومية عن التغذية الصحيحة للجسم"}
                />
            </div>
            <div className="d-xl-none">
                <Container>
                    <ButtonGroup className="w-100 nutrition-btn-grp">
                        <Button
                            className="btn w-50 mx-auto my-3 fs-xs"
                            as={Link}
                            to={{ pathname: "/fitness/nutrition-tips", state: { previousPathname: pathname } }}
                        >
                            {"نصائح"}
                        </Button>
                        <Button
                            className="btn w-50 mx-auto my-3 fs-xs active"
                            as={Link}
                            to={{ pathname: "/fitness/nutrition-episodes", state: { previousPathname: pathname } }}
                        >
                            {"حلقات"}
                        </Button>
                    </ButtonGroup>
                </Container>
                <BannerMobComponent
                    title={"حلقات"}
                    subTitle={"شاهد حلقاتنا اليومية عن التغذية الصحيحة للجسم"}
                />
            </div>
            <VideoCardWebComponent
                type="nutrition"
                colXsSize={12}
                colMdSize={6}
                colLgSize={4}
                isLoading={isLoading}
                errMess={isError}
                videos={aliMazharEpisodes?.results}
            />

        </div>
    );
};

export default NutritionEpisodes;
