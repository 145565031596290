import React from 'react'
import { Container, Col, Image, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./FitnessHeader.css";
import fitnessLogo from "../../assets/fitness-logo.png"

const FitnessHeader = () => {
    return (
        <Container className="py-3 navbar_1" fluid>
            <Row>
                <Col xs={12} lg={12}>
                    <Link to="/fitness/home">
                        <Image
                            src={fitnessLogo}
                            className="navbar-logo img-fluid d-block m-auto"
                        />
                    </Link>
                </Col>
            </Row>
        </Container>
    )
}

export default FitnessHeader
