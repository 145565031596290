import React from 'react'
import Modal from 'react-bootstrap/Modal';
import Cookies from "universal-cookie";
import PhoneComponent from '../../../Subscription/PhoneComponent/PhoneComponent';
import TwistSettings from "../../../../TwistSettings.json"

function UserPhoneNumModal(props) {

    const handleSubmitPhoneNumber = (phoneNumber) => {
        const cookies = new Cookies();
        cookies.set('public', `user,20${phoneNumber}`, {
            path: "/",
            maxAge: `${60 * 60 * 12}`,
        });
        props.onHide();
    }


    return (
        <Modal
            {...props}
            backdrop="static"
            keyboard={false}
            size="lg"
            centered
            className='user__phoneNumber--modal'
        >
            <Modal.Header className=' d-flex flex-column align-items-center justify-content-center text-capitalize text-center'>
                <span>برجاء ادخال رقم هاتفك المحمول</span>
                {TwistSettings['twist-ui'] && <span>لن يتم خصم أي رصيد عند تسجيل الدخول</span>}
                
            </Modal.Header>
            <Modal.Body>
                <PhoneComponent publicUserPhoneModal={true} handleSubmitPhoneNumber={handleSubmitPhoneNumber} />
            </Modal.Body>
        </Modal>
    );
}

export default UserPhoneNumModal
