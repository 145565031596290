
import { useState } from 'react'
import { useHistory } from "react-router-dom";
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { getServiceName, getSportRoute } from '../../Layout/helper';
import { ImgURL } from '../../globalData';

const TournamentOverviewStand = ({
    isLoading,
    isError,
    data,
    sport_id,
    season_id,
    tournament_id,
    team_id,
    toLocalTab,
    competitionType,
}) => {

    const history = useHistory();
    const [currentGroup, setCurrentGroup] = useState(0);

    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${id}/overview`, { previousPathname: history.location.pathname });
    }

    const getTeamStand = (Arr, id) => {
        Arr.sort((a, b) => b.point - a.point)
        const index = Arr.flat().findIndex(el => el.team_id === Number(id));
        const newIndex = index - 2 < 0 ? 0 : index - 2
        const newLastIndex = index - 2 < 0 ? 4 : index + 2
        const newStanding = Arr.flat().map((el, i) => ({ ...el, index: i })).slice(newIndex, newLastIndex)
        return (
            newStanding.map(
                (team, i) =>
                    <tr key={team.id} style={{ backgroundColor: `${team.team_id === Number(id) ? 'var(--red)' : ''}` }}>
                        <th scope="row">{team.index + 1}</th>
                        <th>{team.team_name}</th>
                        <td>{team.played}</td>
                        <td>{team.difference}</td>
                        <td>{team.point}</td>
                        <td>{team.difference}</td>
                        <td>{team.point}</td>
                    </tr>
            ))
    }

    const handleRenderStanding = () => {
        const cupOrLeagueTeamsArr = competitionType === "cup" ?
            data.slice(currentGroup, currentGroup + 1)[0]
            : data;
        return (
            cupOrLeagueTeamsArr.sort((a, b) => b.point - a.point).map(
                (team, i) => i <= 7 && (
                    <tr key={team.id} onClick={() => handleRowClick(team.services?.[0], team.sport_id, team.team_id)} className='table-clickable-row-animated text-center'>
                        <td >{i + 1}</td>
                        <td className='text-right'><img src={ImgURL(team.team_logo)} style={{ width: '20px' }} alt="team logo" /><span className="mx-2 fs-xxs ">{team.team_name}</span></td>
                        <td>{team.win}</td>
                        <td>{team.lose}</td>
                        <td>{team.draw}</td>
                        <td>{team.difference}</td>
                        <td>{team.point}</td>
                    </tr>
                )
            )
        )
    }

    return (
        <>
            {!!data.length &&
                <div className='bg-black-color white-color fs-xs'>
                    <div className='d-flex justify-content-between fs-md p-2'>
                        {competitionType === "cup" ?
                            <>
                                <div className='d-flex align-items-center'>
                                    <button className={`swipe-animation fa fa-chevron-right btn white-color`} disabled={currentGroup === Object.values(data).length - 1} aria-hidden="true" onClick={() => setCurrentGroup(currentGroup + 1)}></button>
                                    <div style={{ cursor: 'pointer' }} className='btn white-color table-clickable-row fs-md px-3' onClick={() => toLocalTab('stats')}>
                                        <span className='px-1 text-capitalize'>
                                            {/* {groups[currentGroup].split(" ")[0].toLowerCase()} */}
                                            المجموعة
                                        </span>
                                        <span className='px-1'>{currentGroup + 1}</span>
                                    </div>
                                    <button className={`swipe-animation fa fa-chevron-left btn white-color`} disabled={currentGroup === 0} aria-hidden="true" onClick={() => setCurrentGroup(currentGroup - 1)}></button>
                                </div>
                            </> :
                            <>
                                <div>{"الترتيب"}</div>
                                <div style={{ direction: 'rtl' }}>
                                    <button className={`swipe-animation fa fa-chevron-left btn white-color`} aria-hidden="true" onClick={() => toLocalTab('standing')}></button>
                                </div>
                            </>

                        }
                    </div>
                    <div className='responsive-table mb-3'>
                        <table className="table-borderless bg-black-color table-striped table-dark white-color">
                            <thead>
                                <tr>
                                    <th scope="col" className="text-center">المركز</th>
                                    <th scope="col">الفريق</th>
                                    <th scope="col" className="text-center">فوز</th>
                                    <th scope="col" className="text-center">خساره</th>
                                    <th scope="col" className="text-center">تعادل</th>
                                    <th scope="col" className="text-center">فارق</th>
                                    <th scope="col" className="text-center">نقاط</th>
                                </tr>
                            </thead>
                            <tbody>
                                {isLoading ?
                                    <tr>
                                        <td colSpan={7} className='p-0'>
                                            <LoadingSpinnner p={4} fs={'sm'} />
                                        </td>
                                    </tr> :
                                    isError || !data.length ?
                                        <tr>
                                            <td colSpan={7} className='p-0'>
                                                <ErrorMsg msg="لا يوجد ترتيب" p={4} fs={'md'} />
                                            </td>
                                        </tr>
                                        :
                                        team_id ?
                                            getTeamStand(data, team_id)
                                            :
                                            handleRenderStanding()
                                }

                            </tbody>
                        </table>
                    </div>
                </div >
            }
        </>
    )
}


export default TournamentOverviewStand
