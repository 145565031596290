import ErrorMsg from '../ErrorPage/ErrorMsg';
import { AiOutlineStar, AiTwotoneStar } from 'react-icons/ai';
import "./FanClub.css";
import api from '../../apis/index'
import SpecialMedia from '../SpecialMedia';
import { getUserPhoneNumbers } from '../../globalFn';
import { IDS, ImgURL } from '../../globalData'
import TwistSettings from '../../TwistSettings.json'
import FanClubLoader from './FanClubLoader/FanClubLoader';
import worldCupLogo from "../../assets/games/fan-club-logo.svg";
import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import { logFailedApi } from '../../globalFn';

const apiFn = (params) => api.fanClub.getFavTeams(params);
const apiFnTwo = (params) => api.fanClub.postFavTeams(params)

function FanClub({
    isLoading,
    isError,
    data,
    subscriptionModal
}) {
    const queryClient = useQueryClient();
    const msisdn = getUserPhoneNumbers()[0];
    const standingHalfTeamsLength = Math.trunc(data.flat().length / 2);

    const { data: favData = [] } = useQuery({
        queryKey: ["favorite-teams", subscriptionModal],
        queryFn: () => apiFn({
            phone: msisdn,
            favouriteable_type: ["Team"],
            portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET,
        }),
        onError: () => logFailedApi("favorite-teams"),
        select: ({ data }) => data,
        refetchOnWindowFocus: false,
    });

    const { mutate } = useMutation(apiFnTwo, {
        onSuccess: ({ data }) => queryClient.setQueryData(["favorite-teams", subscriptionModal], (oldQueryData) => {
            const foundFavTeam = !!oldQueryData?.data?.find(ele => ele?.favouriteable_id === data?.favouriteable_id);
            if (foundFavTeam) {
                const updatedData = oldQueryData?.data.filter(ele => ele.favouriteable_id !== data?.favouriteable_id)
                return {
                    ...oldQueryData,
                    data: updatedData
                }
            }
            return {
                ...oldQueryData,
                data: [...oldQueryData.data, data]
            }
        }),
        onError: () => logFailedApi("favorite-teams"),
    })

    const handleToggleFavTeam = (teamId) => {
        mutate({
            phone: msisdn,
            favouriteable_type: "Team",
            portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET,
            favouriteable_id: teamId
        })
    }

    const renderTeams = (start, to) => {
        return (
            <div className='teams__wrapper'>
                {data.flat().slice(start, to).map(team =>
                (<div className='team d-flex' key={team.team_id}>
                    <div className='fav__iconWrapper'>
                        {favData.find(fav => fav.favouriteable_id === team.team_id) ?
                            <AiTwotoneStar className={`fav__iconBold text-white`} onClick={() => handleToggleFavTeam(team.team_id)} /> :
                            <AiOutlineStar className={`fav__icon text-white`} onClick={() => handleToggleFavTeam(team.team_id)} />
                        }
                    </div>
                    <figure className='team__info d-flex flex-column mb-0'>
                        <img src={ImgURL(team.team_logo)} alt="team logo" />
                        <figcaption className='text-white text-nowrap'>{team.team_name}</figcaption>
                    </figure>
                </div>
                ))}
            </div>
        )
    }

    return (
        <section className='fan__club'>
            <div className="container">
                {isError || !data.length ?
                    <div colSpan={7} className='p-0'>
                        <ErrorMsg msg="لا يوجد معلومات عن الاندية" p={4} fs={'md'} />
                    </div>
                    :
                    <div className="wrapper">
                        {isLoading ? <FanClubLoader /> : renderTeams(0, standingHalfTeamsLength)}
                        <img src={worldCupLogo} className='world__cupLogo' alt="" />
                        {isLoading ? <FanClubLoader /> : renderTeams(standingHalfTeamsLength)}
                    </div>
                }
                <hr />
                <SpecialMedia msisdn={msisdn} />
            </div>
        </section>
    )
}

export default FanClub
