import NewsLoading from "./NewsLoader";
import NewsCarousel from "./NewsCarousel";
import './newsSection.css'
import MediaCard from "./MediaCard";
import api from "../../apis";
import { useQuery } from "@tanstack/react-query";
import { Container } from "react-bootstrap";
import { logFailedApi } from "../../globalFn";

const apiFn = (params) => api.mediaList.getMediaList(params);

function LatestNews({
  sport_id,
  season_id,
  service_id,
  items_count,
  media_type,
  tournament_id,
  media_id,
  team_id,
  player_id,
  top_stories,
  latest,
  popular,
}) {

  const { isLoading, isError, data = [] } = useQuery({
    queryKey: ["media-latest-news", sport_id, service_id, season_id, tournament_id, team_id, player_id, media_type],
    queryFn: () => apiFn({
      sport_id,
      service_id,
      season_id,
      items_count,
      type: media_type,
      tournament_id,
      media_id,
      team_id,
      player_id,
      top_stories,
      latest,
      popular,
    }),
    onError: () => logFailedApi("media-latest-news"),
    select: ({ data }) => data?.data,
    enabled: service_id !== null,
    refetchOnWindowFocus: false,
  });


  return (isLoading ?
    <NewsLoading /> :
    isError ?
      <div className='p-5 bg-light-black-color fs-lg text-light text-center'>{media_type === 1 ? "لا يوجد اخبار" : "لا يوجد فيديوهات"}</div>
      : (
        <Container fluid="md">
          <div className="news-section">
            <div className='row'>
              <div className='col-md-6 col-12 p-0 mt-2'>
                <NewsCarousel
                  isLoading={isLoading}
                  MediaList={data.slice(0, 4)}
                  type={1}
                />
              </div>
              <div className='col-md-6 col-12'>
                <div className='row'>
                  {data.slice(0, 4).map((news) => (
                    <div className='col-md-6 col-6 p-1' key={news.id}>
                      <MediaCard news={news} type={1} />
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className='row'>
              {data.slice(4, 8).map((news) => (
                <div className='col-md-3 col-6 p-1' key={news.id}>
                  <MediaCard news={news} type={1} />
                </div>
              ))}
            </div>
          </div>
        </Container>
      )
  );
}

export default LatestNews;