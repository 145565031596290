import Cookies from "universal-cookie";
import { IDS } from "../globalData";

const cookies = new Cookies();
const services = ['local', 'international', 'africa', 'othersports', 'meloukelkora', 'fitness'];

export default function getServiceAndSport(pathname) {
  const sports = ["tennis", "handball", "squash", "basketball"];

  let serviceId = undefined
  let serviceName = pathname.split("/")[1];
  let sportId = undefined
  let sportName = undefined

  if (services.includes(serviceName)) {
    serviceId = IDS.SERVICES[serviceName.toUpperCase()];
    if (serviceName === 'othersports') {
      sportName = pathname.split("/")[2];
      sportId = IDS.SPORTS[sportName?.toUpperCase()];
      if (!sports.includes(sportName)) {
        sportName = undefined;
      }
    }
  } else {
    serviceName = undefined
  }

  return { serviceId, serviceName, sportId, sportName }
}

export const getSubsCookiesInfo = () => {
  const msisdns = new Set();
  const servicesIdsHaveMsisdns = new Set();

  services.forEach(s => {
    const msisdn = cookies.get(s)?.split(",")?.[1]
    if (msisdn && msisdn.match(/2[0-9]{11}/)) {
      msisdns.add(msisdn)
      servicesIdsHaveMsisdns.add(IDS.SERVICES[s.toUpperCase()])
    }
  })
  return { msisdns, servicesIdsHaveMsisdns: [...servicesIdsHaveMsisdns] };
}

export const getRemainingSecondsTillMidnight = () => {
  const dateNow = new Date();
  let dateToMidnight = new Date();
  dateToMidnight.setUTCHours(0, 0, 0, 0);
  dateToMidnight.setDate(dateToMidnight.getDate() + 1);
  return Math.abs(dateToMidnight.getTime() - dateNow.getTime()) / 1000;
}

export const grantAccessToAllServices = (msisdn) => {
  const maxAge = getRemainingSecondsTillMidnight()
  const services = ['local', 'international', 'africa', 'othersports', 'meloukelkora', 'fitness'];

  services.map((s) => {

    cookies.set(
      s,
      `${msisdn},${msisdn}`,
      {
        path: "/",
        maxAge,
        domain: window.location.hostname
      }
    )
  })
}