import React, { useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import { Link, useLocation } from 'react-router-dom'
import { ImgURL } from '../../globalData'
import { shortenName, convertFullDate } from '../../globalFn'
import { getServiceName, getSportRoute } from '../../Layout/helper'
import PredictAndWin from '../PredictAndWin'
import { handleShowPenaltyScore } from './helper';
import './matchCard.css'

const MatchCard = ({ el, direction }) => {
  const { pathname } = useLocation()
  const [showPredictAndWin, setShowPredictAndWin] = useState(false);

  const getTime = (el) => {
    if (el.date === null) {
      return <div data-testid="match_card_child_unspecified_time">وقت غير محدد</div>
    } else {
      const minutesAndSeconds = el.date?.split(' ')[1]?.slice(0, 5);
      return <div data-testid="match_child_card_time">
        {
          minutesAndSeconds
            ? minutesAndSeconds
            : "-- / --"
        }
      </div >

    }
  }
  const renderStatus = (m) => {
    //for squash data only .. XW
    const squashMatchStatus = {
      "Not Started": "لم تبدأ",
      "Complete": "انتهت",
    }

    if (pathname.includes("squash")) {
      return <div data-testid="match_card_parent_status_and_time">
        <div>{squashMatchStatus[m.status]}</div>
      </div>
    }

    if (m.matchStatus === 1) {
      return <div data-testid="match_card_parent_status_and_time">
        {getTime(m)}
        <div>{m.status}</div>
      </div>
    } else {
      return <div data-testid="match_card_parent_status">{m.status}</div>
    }
  }


  return (
    <>
      <Link to={{ pathname: `/${getServiceName(el.services?.[0])}${getSportRoute(el.sport_id, el.services?.[0])}/match/${el.id}`, state: { previousPathname: pathname } }} data-testid="match-card">
        <div className="matches match-card text-center match-slide py-4" key={el.id} style={{ direction }}>
          <Col xs={12} className="text-light border-left border-right">
            <Row className='ms-0'>
              <Col xs={4} md={3} lg={3} className=" my-auto d-flex justify-content-around align-items-center ">
                <div className='d-flex flex-column align-items-center'>
                  <div className='match-carousel-rounded-white'>
                    <img src={ImgURL(el.team1?.logo)} width="38px" height="38px" className='rounded-circle' alt="team" />
                  </div>
                  <div className="text-nowrap fs-xs mt-2">{shortenName(el.team1?.title, 12)}</div>
                </div>

              </Col>
              <Col xs={4} md={6} lg={6} className=" d-flex flex-column align-items-center">
                <div className="status-bar bg-active-color py-1 py-md-2 text-nowrap text-light fs-xxs">
                  {renderStatus(el)}
                </div>
                <div className='row d-flex flex-row justify-content-between'>
                  <div className='col-1 d-flex flex-column align-items-center'>
                    <span>{el.status === "لم تبدأ" ? "" : el.team1?.score}</span>
                    {handleShowPenaltyScore(el.team1, el.team2) && <span className='fs-sm' data-testid="match-card-home-team-penalty-score">({el.team1?.penalty_score})</span>}
                  </div>
                  <div className='col-1 d-flex flex-column align-items-center'>
                    <span>{el.status === "لم تبدأ" ? "" : el.team2?.score}</span>
                    {handleShowPenaltyScore(el.team1, el.team2) && <span className='fs-sm' data-testid="match-card-away-team-penalty-score">({el.team2?.penalty_score})</span>}
                  </div>
                </div>
                {pathname.includes("/matches") && <span className='match-date'>{convertFullDate(el.date)}</span>}
              </Col>
              <Col xs={4} md={3} lg={3} className="my-auto d-flex justify-content-around align-items-center">
                <div className='d-flex flex-column align-items-center'>
                  <div className='match-carousel-rounded-white'>
                    <img src={ImgURL(el.team2?.logo)} width="38px" height="38px" className='rounded-circle' alt="team" />
                  </div>
                  <div className=" text-nowrap fs-xs mt-2">{shortenName(el.team2?.title, 12)}</div>
                </div>
              </Col>
            </Row>
          </Col>
          {el.tournament?.title && <Col xs={12} className="d-flex mt-2 justify-content-center">
            <h6 className="mx-2 my-auto fs-sm white-color">{el.tournament?.title}</h6>
          </Col>}
        </div>
      </Link>
      {
        el?.tournament?.id === 726 && el.matchStatus === 1 &&
        <>
          <div className="predict__andWin--ButtonWrapper d-flex justify-content-center py-2">
            <button className='predict__andWin--Button text-capitalize' onClick={() => setShowPredictAndWin(true)} data-testid="predict-and-win-button">توقع النتيجة</button>
          </div>
          <PredictAndWin el={el} show={showPredictAndWin} onHide={() => setShowPredictAndWin(false)} />
        </>
      }
    </>
  )
}

export default MatchCard