import HttpHelper from "../../helper";

export const doneEndpoints = {
    addDone: (params) =>
        HttpHelper.fitnessAxios
            .post(`/workout/add/done`, null, { params })
            .then((res) => res?.data),

    removeDone: (params) =>
        HttpHelper.fitnessAxios
            .post(`/workout/remove/done`, null, { params })
            .then((res) => res?.data),
};