import React from 'react'
import { Container } from 'react-bootstrap'
import api from '../../../../apis';
import SportsSectionHeader from '../../../../components/sportsSectionHeader/sportsSectionHeader.component'
import AllMedia from '../../reusable/media/all-melouk-media';
import AskMido from './ask-mido';

function MidoDiaries({ isSubscribed }) {

  return <Container className="mb-3">
    <SportsSectionHeader title="يوميات ميدو" />
    <AllMedia
      per_page={4}
      getAPI={api.melouk.media.getMidoDiaries}
      type="mido-dairies"
    />
    {/* <hr /> */}
    {/* <SportsSectionHeader title="اسأل ميدو" />
    <AskMido isSubscribed={isSubscribed} /> */}
    {/* <SportsSectionHeader title="إجابات ميدو" /> */}
    {/* <AllMedia
      per_page={4}
      getAPI={api.melouk.media.getMidoAnswers}
      type="mido-answers"
    /> */}

  </Container>
}

export default MidoDiaries