import HttpHelper from './helper';


export const tournamentEndpoints = {
    getTournaments: (params) => HttpHelper.baseAxios
        .get('/tournaments', { params })
        .then((res) => res?.data),

    getTournamentsDetails: (id, params) => HttpHelper.baseAxios
        .get(`/tournaments/${id}`, { params })
        .then((res) => res?.data),

    getTournamentsStanding: (params) => HttpHelper.baseAxios
        .get(`/standing`, { params })
        .then((res) => res?.data),

    getTournamentsNewStanding: (params) => HttpHelper.baseAxios
        .get(`/newStanding`, { params })
        .then((res) => res?.data),

    getTournamentHistory: (tournament_id) => HttpHelper.baseAxios
        .get(`/tournament-history/${tournament_id}`)
        .then((res) => res?.data),

    getTournamentTree: (params) => HttpHelper.baseAxios
        .get('/tournament/tree', { params })
        .then((res) => res?.data)
};