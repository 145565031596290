import React, { useEffect, useContext } from "react";
import { Container, Nav, Tab } from "react-bootstrap";
import { useHistory, useParams } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import RequireSubscription from "../../components/require-subscription";
import TeamInfo from "../../components/TeamInfo/TeamInfo";
import TeamOverview from "../../components/TeamOverview/TeamOverview";
import TeamSquad from "../../components/TeamSquad/TeamSquad";
import TeamStatsDetail from "../../components/TeamStatistics/TeamStatsDetail";
import TournamentMatches from "../../components/TournamentMatches/TournamentMatches";
import { ImgURL } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import Player from "../Player/player";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import TwistSettings from '../../TwistSettings.json'
import "./Team.css";
import AllMedia from "../../components/News/AllMedia";
import Games from "../../components/Games";
import Gallery from "../../components/Gallery";
import { sharedComponentsContext } from "../../context/shared-context";
import { useQuery } from '@tanstack/react-query';
import api from "../../apis";
import { logFailedApi } from "../../globalFn";

const apiFn = (id) => { return api.teams.getTeamDetails(id) }

const Team = ({ isSubscribed }) => {
  const { seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);
  const { route, sportName, sportId } = useServiceAndSport()
  const id = useParams().id;
  const tab = useParams().tab || "overview";
  const history = useHistory();

  const { data: teamDetailsData, isFetching, isLoading, isError } = useQuery({
    queryKey: ["teams-details", id, sportName, sportId],
    queryFn: () => apiFn(id),
    onError: () => logFailedApi("teams-details"),
    refetchOnWindowFocus: false,
  })

  const handelTabClick = (tab) => {
    // change team to player for squash
    history.push(`${route}/team/${id}/${tab}`, { previousPathname: history.location.pathname });
  };

  useEffect(() => {
    if (teamDetailsData?.data?.main_tournament?.id === 726) {
      document.body.classList.add("worldCup-styles");
    }

    if (teamDetailsData?.data?.id === 6631) {
      document.body.classList.add("alAhly-page-styles");
    }

    return () => {
      document.body.classList.remove("worldCup-styles");
      document.body.classList.remove("alAhly-page-styles");
    };
  }, [teamDetailsData?.data?.main_tournament?.id, teamDetailsData?.data?.id]);

  return (
    <div>
      {isError ? (
        <ErrorPage error="لا يوجد معلومات عن النادي" />
      ) : (isFetching || isLoading) ? (
        <HeaderInfoComponentLoader />
      ) : sportName === "squash" ? (<Player />) : (
        <div className="Team-details-header-container" data-testid="team-details-wrapper">
          <div className="overlay"></div>
          <div
            className="Team-details-header"
            style={{
              backgroundImage: `url(${ImgURL(teamDetailsData?.data?.background
              )})`,
            }}
          ></div>
          <div className="Team-details-header-desc text-center">
            <img src={ImgURL(teamDetailsData?.data?.logo)} alt="" />
            <div className="text-light text-center mt-1 fs-lg">
              <strong>{teamDetailsData?.data?.name}</strong>
            </div>
          </div>
          {!!teamDetailsData?.data?.champions.length &&
            <div
              className="d-flex justify-content-around align-items-center Team-details-header-champions p-3 "
              onClick={() => {
                handelTabClick("info");
              }}
              style={{ cursor: "pointer" }}
            >
              {teamDetailsData?.data?.champions.map((el, index) => (
                <div key={index} style={{ position: "relative" }} className="mx-2">
                  <img src={ImgURL(el.logo)} alt="" />
                  <div className="text-light champions-times text-center">
                    x{el.count}
                  </div>
                </div>
              ))}
            </div>
          }
          {
            teamDetailsData?.data?.market_value && <div
              className="Team-details-header-market text-light p-3 p-3 text-center"
              onClick={() => {
                handelTabClick("players");
              }}
              style={{ cursor: "pointer" }}
            >
              <span>{teamDetailsData?.data?.market_value}</span>
            </div>
          }
        </div>
      )}
      <div className={`${!isSubscribed ? 'd-flex justify-content-center flex-column align-items-center' : ''}`}>
        <RequireSubscription isSubscribed={id === "6631" ? isSubscribed : true}>
          {sportName !== "squash" && <Tab.Container
            id="left-tabs-example"
            activeKey={tab}
            mountOnEnter={true}
            unmountOnExit={true}
          >
            <Nav variant="tabs" className="mb-3 flex-nowrap fs-sm">
              <div className="d-flex justify-content-center mx-auto">
                <Nav.Item>
                  <Nav.Link
                    eventKey="overview"
                    onClick={() => handelTabClick("overview")}
                  >
                    نظرة عامة
                  </Nav.Link>
                </Nav.Item>
                <RequireSubscription isSubscribed={isSubscribed} >
                  <Nav.Item>
                    <Nav.Link
                      eventKey="matches"
                      onClick={() => handelTabClick("matches")}
                    >
                      المباريات
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    {id === "6631" && TwistSettings["world-cup-games-and-fan-club"] &&
                      <Nav.Link
                        eventKey="games"
                        onClick={() => handelTabClick("games")}
                      >
                        الألعاب
                      </Nav.Link>}
                  </Nav.Item>
                  <Nav.Item>
                    {teamDetailsData?.data?.sport_id === 2 ? " " :
                      <Nav.Link
                        eventKey="players"
                        onClick={() => handelTabClick("players")}
                      >
                        اللاعبين
                      </Nav.Link>}
                  </Nav.Item>
                  {teamDetailsData?.data?.sport_id === 2 || id !== '6631' ? " " :
                    <Nav.Item>
                      <Nav.Link
                        eventKey="gallery"
                        onClick={() => handelTabClick("gallery")}
                      >
                        صور
                      </Nav.Link>
                    </Nav.Item>
                  }
                  <Nav.Item>
                    <Nav.Link eventKey="news" onClick={() => handelTabClick("news")}>
                      أخر الأخبار
                    </Nav.Link>
                  </Nav.Item>
                  {
                    TwistSettings['videos'] && <>
                      <Nav.Item>
                        <Nav.Link
                          eventKey="videos"
                          onClick={() => handelTabClick("videos")}
                        >
                          أحدث الفيديوهات
                        </Nav.Link>
                      </Nav.Item>
                    </>
                  }
                  <Nav.Item>
                    <Nav.Link
                      eventKey="standing"
                      onClick={() => handelTabClick("standing")}
                    >
                      احصائيات
                    </Nav.Link>
                  </Nav.Item>
                </RequireSubscription>
                {teamDetailsData?.data?.sport_id === 2 ? " " :
                  <Nav.Item>
                    <Nav.Link eventKey="info" onClick={() => handelTabClick("info")}>
                      معلومات النادي
                    </Nav.Link>
                  </Nav.Item>
                }
              </div>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="overview">
                <TeamOverview
                  toLocalTab={handelTabClick}
                  season_id={activeSeason?.id}
                  isFetchingTeamDetails={isFetching}
                  TeamDetailsFailed={isError}
                  TeamDetails={teamDetailsData?.data}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="matches">
                <TournamentMatches sport_id={1} team_id={id} />
              </Tab.Pane>

              <Tab.Pane eventKey="players">
                <TeamSquad sport_id={1} team_id={id} />
              </Tab.Pane>

              {teamDetailsData?.data?.sport_id === 2 || id !== '6631' ? " " :
                <Tab.Pane eventKey="gallery">
                  <Gallery team_id={id} TeamDetails={teamDetailsData?.data} />
                </Tab.Pane>
              }

              <Tab.Pane eventKey="news">
                <Container>
                  <AllMedia
                    sport_id={1}
                    season_id={activeSeason?.id}
                    team_id={id}
                    items_count={16}
                    type={1}
                    latest={true}
                  />
                </Container>
              </Tab.Pane>

              {
                TwistSettings['videos'] && <>
                  <Tab.Pane eventKey="videos">
                    <Container>
                      <AllMedia
                        sport_id={1}
                        season_id={activeSeason?.id}
                        team_id={id}
                        items_count={16}
                        type={2}
                        latest
                      />
                    </Container>
                  </Tab.Pane>
                </>
              }

              <Tab.Pane eventKey="standing">
                <TeamStatsDetail
                  team_id={id}
                  TeamDetails={teamDetailsData?.data}
                  isFetchingTeamDetails={isFetching}
                  TeamDetailsFailed={isError}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="info">
                <TeamInfo
                  TeamDetails={teamDetailsData?.data}
                  isFetchingTeamDetails={isFetching}
                  TeamDetailsFailed={isError}
                />
              </Tab.Pane>

              <Tab.Pane eventKey="games">
                <Games />
              </Tab.Pane>

            </Tab.Content>
          </Tab.Container>}
        </RequireSubscription>
      </div>
    </div >
  );
};

export default Team;
