import onlineShopAdImage from "../../assets/online-shop-ads.png";
import flyDubaiAdImage from "../../assets/fly-dubai-ads.png";
import blackFridayAdImage from "../../assets/black-friday-ads.png";
import emeraldAdImage from "../../assets/emerald-ads.png";
import netAdImage from "../../assets/net-ads.png";
import iqosAdImage from "../../assets/iqos-ads.png";
import spAdImage from "../../assets/sp-ads.png";
import adidasAdImage from "../../assets/adidas-ads.png";

export function OnlineShopAds() {
  return (
    <figure>
      <img src={onlineShopAdImage} alt='' />
    </figure>
  );
}
export function FlyDubaiAdImage() {
  return (
    <figure>
      <img src={flyDubaiAdImage} alt='' />
    </figure>
  );
}
export function EmeraldAdImage() {
  return (
    <figure>
      <img src={emeraldAdImage} alt='' />
    </figure>
  );
}

export function BlackFridayAdImage() {
  return (
    <figure>
      <img src={blackFridayAdImage} alt='' />
    </figure>
  );
}

export function AdidasAds() {
  return (
    <figure>
      <img src={adidasAdImage} alt='' />
    </figure>
  );
}
export function NetAdImage() {
  return (
    <figure>
      <img src={netAdImage} alt='' />
    </figure>
  );
}

export function IqosAdImage() {
  return (
    <figure>
      <img src={iqosAdImage} alt='' />
    </figure>
  );
}

export function SpAdImage() {
  return (
    <figure>
      <img src={spAdImage} alt='' />
    </figure>
  );
}
