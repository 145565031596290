import React, { useEffect, useState } from 'react'
import { Container } from 'react-bootstrap'
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import api from '../../../../apis';
import SportsSectionHeader from '../../../../components/sportsSectionHeader/sportsSectionHeader.component'
import TwistSettings from '../../../../TwistSettings.json'
import './index.css'

const loader = () => <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb">
  <Skeleton height={20} />
</SkeletonTheme>

function Fantasy() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    api.melouk.fantasy.getRanking()
      .then(res => {
        if (res.status === 200) {
          setData(res.data)
        }
        setLoading(false)
      })
  }, [])

  return <Container className="fantasy mb-3">
    <SportsSectionHeader title="دورى الأساطير" />
    <table className="responsive-table tournament-statistics table-borderless bg-black-color table-striped table-dark white-color col-12">
      <thead>
          <tr style={{ padding: '10px' }} className="text-center">
              <th>الترتيب</th>
              <th scope="col" style={{minWidth: '130px'}}>إسم الفريق</th>
              <th scope="col" className='text-nowrap'>نقاط الجوله</th>
              <th scope="col" className='text-nowrap'>مجموع النقاط</th>
          </tr>
      </thead>
      <tbody>
        {
          loading && <tr className='text-center'>
            <td style={{ width: '25px' }}>{loader()}</td>
            <td>
              <div>{loader()}</div>
              <div>{loader()}</div>
            </td>
            <td>{loader()}</td>
            <td>{loader()}</td>
          </tr>
        }
        {
          data.map(r => <tr key={r.id} className='text-center'>
            <td style={{ width: '25px' }}>{r.rank + 1}</td>
            <td>
              <div>{r.team_name}</div>
              <div>{r.name}</div>
            </td>
            <td>{r.game_week_points}</td>
            <td>{r.total_points}</td>
          </tr>)
        }
        
      </tbody>
  </table>
  </Container>
}

export default Fantasy