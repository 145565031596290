import React from "react";
import { Route } from "react-router-dom";
import Fantasy from "../pages/fantasy";
import HappenedThisDay from "../pages/happened-this-day";
import MeloukHome from "../pages/home";
import MidoDiaries from "../pages/mido-diaries";
import MidoRiddles from "../pages/mido-riddles";
import MidoTactics from "../pages/mido-tactics";
import MeloukMediaDetails from "../reusable/melouk-media-details";
import MeloukHeader from "./melouk-header";

function MeloukLayout({ isSubscribed }) {
  return (
    <>
      <MeloukHeader />
      <Route path='/meloukelkora/home'>
        <MeloukHome isSubscribed={isSubscribed} />
      </Route>
      <Route path='/meloukelkora/mido-diaries'>
        <MidoDiaries isSubscribed={isSubscribed} />
      </Route>
      <Route path='/meloukelkora/mido-tactis'>
        <MidoTactics />
      </Route>
      <Route path='/meloukelkora/happened-this-day'>
        <HappenedThisDay />
      </Route>
      <Route path='/meloukelkora/mido-riddles'>
        <MidoRiddles isSubscribed={isSubscribed} />
      </Route>
      {/* <Route path="/meloukelkora/fantasy"><Fantasy /></Route> */}
      <Route path='/meloukelkora/media/:type/:id'>
        <MeloukMediaDetails isSubscribed={isSubscribed} />
      </Route>
    </>
  );
}

export default MeloukLayout;
