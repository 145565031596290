import React from "react";
import { Container } from "react-bootstrap";
import FitnessHomeWebComponent from "../../components/FitnessHome/FitnessHomeWebComponent";
import FitnessHomeMobComponent from "../../components/FitnessHome/FitnessHomeMobComponent";

const FitnessHomePage = () => {
  return (
    <div>
      <div className="d-none d-lg-block">
        <Container className="intro-section" fluid>
          <FitnessHomeWebComponent />
        </Container>
      </div>
      <div className="d-lg-none">
        <FitnessHomeMobComponent />
      </div>
    </div>
  );
};

export default FitnessHomePage;
