import React from 'react';
import { useHistory } from "react-router-dom";
import { Container } from 'reactstrap';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import useServiceAndSport from '../../hooks/useServiceAndSport';
import SquadTable from './SquadTable/SquadTable';
import { useQuery } from '@tanstack/react-query';
import api from "../../apis";
import './TeamSquad.css'
import { logFailedApi } from '../../globalFn';


const apiFn = (team_id, params) => { return api.teams.getTeamSquad(team_id, params) }


const TeamSquad = ({ sport_id, season_id, tournament_id, team_id }) => {
    const { route } = useServiceAndSport()
    const history = useHistory();

    const { data: teamSquad, isFetching, isError } = useQuery({
        queryKey: ["team-squad", sport_id, season_id, tournament_id, team_id],
        queryFn: () => apiFn(team_id, { sport_id, season_id, tournament_id }),
        onError: () => logFailedApi("team-squad"),
        refetchOnWindowFocus: false,
    })

    const handleRowClick = (id) => {
        history.push(`${route}/player/${id}`, { previousPathname: history.location.pathname });
    }

    const TeamSquadTableTitles = {
        goal_keeper: "حراس المرمي",
        defense: "الدفاع",
        midfield: "وسط الملعب",
        attack: "الهجوم",
    }

    return (
        isError ?
            <ErrorMsg p={5} fs={'md'} msg="لا يوجد لاعبين" />
            :
            isFetching ?
                <div>...loading</div>
                :
                <Container className='squad-tables-container d-flex justify-content-center flex-wrap gap-2 mb-5'>
                    <div>
                        <SquadTable playersPositionArr={teamSquad?.data.players.goal_keeper} tableTitle={TeamSquadTableTitles.goal_keeper} handleRowClick={handleRowClick} />
                        <SquadTable playersPositionArr={teamSquad?.data.players.midfield} tableTitle={TeamSquadTableTitles.midfield} handleRowClick={handleRowClick} />
                    </div>
                    <div>
                        <SquadTable playersPositionArr={teamSquad?.data.players.defense} tableTitle={TeamSquadTableTitles.defense} handleRowClick={handleRowClick} />
                        <SquadTable playersPositionArr={teamSquad?.data.players.attack} tableTitle={TeamSquadTableTitles.attack} handleRowClick={handleRowClick} />
                    </div>
                </Container>
    )
}

export default TeamSquad