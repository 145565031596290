import { Row, Col } from "react-bootstrap";
import "./MatchHeader.css";
import Countdown from "react-countdown";
import { convertFullDate } from "../../globalFn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/free-regular-svg-icons";
import { useHistory } from "react-router-dom";
import RadialTimer from "../matchRadialTimer/RadialTimer";
import { IDS, ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import whistle from "../../assets/whistle-icon2.png";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { eventsWithGoal, OwnGoal } from "./helper";
import { handleShowPenaltyScore } from "../MatchCard/helper";
import stadiumIcon from "../../assets/img/stadium.svg";
import calendarIcon from "../../assets/img/calendar.svg";
import api from "../../apis";
import { useQuery } from "@tanstack/react-query";
import { useContext } from "react";
import { sharedComponentsContext } from "../../context/shared-context";
import handball from "../../assets/handballBanner.webp";
import tennis from "../../assets/tennisBanner.webp";
import squash from "../../assets/squashBanner.webp";
import basketball from "../../assets/basketballBanner.webp";
import football from "../../assets/matchBackground.png";
import { logFailedApi } from "../../globalFn";

function convertDateFormat(originalDate) {
  const date = new Date(originalDate);
  // Extract year, month, and day
  const year = date.getUTCFullYear();
  const month = ("0" + (date.getUTCMonth() + 1)).slice(-2);
  const day = ("0" + date.getUTCDate()).slice(-2);
  // Extract hour, minute, and second // Note that +3 for winter time and +4 for summer time
  const hour = ("0" + (date.getUTCHours() + 3)).slice(-2);
  const minute = ("0" + date.getUTCMinutes()).slice(-2);
  const second = ("0" + date.getUTCSeconds()).slice(-2);
  // Format the date string
  const formattedDate = `${year}-${month}-${day} ${
    hour - 1
  }:${minute}:${second}`;
  return formattedDate;
}

const apiFn = (params) => api.matches.getMatchEvents(params);

const MatchHeader = ({ matchId, matchInfo }) => {
  const othersportsBannersSrc = {
    handball,
    tennis,
    squash,
    basketball,
  };

  const {
    matchStatus,
    matchRoundInfo,
    tournament,
    stadium,
    referee_name,
    team1,
    team2,
    date,
    refetch,
  } = matchInfo;
  const { setInitialSharedValues } = useContext(sharedComponentsContext);
  const history = useHistory();
  const { sportName } = useServiceAndSport();
  const sport_id = IDS.SPORTS[sportName?.toUpperCase() ?? "FOOTBALL"];
  const isFootball = sport_id === IDS.SPORTS.FOOTBALL;

  const {
    isLoading,
    isError,
    data = [],
  } = useQuery({
    queryKey: ["match-events", matchId, sportName, sport_id],
    queryFn: () => apiFn({ match_id: matchId }),
    select: ({ data }) => data,
    onSuccess: (data) =>
      setInitialSharedValues((prev) => ({ ...prev, matchEvents: data })),
    onError: () => logFailedApi("match-events"),
    enabled: sportName !== null,
    refetchOnWindowFocus: false,
  });

  const parseDate = convertFullDate(date, "ar");

  const handleRowClick = (serviceId, sportId, id) => {
    const individualSports = ["squash", "tennis"];
    const teamOrPlayerUrl = individualSports.includes(sportName)
      ? "player"
      : "team";
    history.push(
      `/${getServiceName(serviceId)}${getSportRoute(
        sportId,
        serviceId,
      )}/${teamOrPlayerUrl}/${id}/overview`,
      { previousPathname: history.location.pathname },
    );
  };

  const getTime = () => {
    return date?.split(" ")[1]?.split(":")?.slice(0, 2)?.join(":");
  };

  const getTimer = () => {
    if (
      Date.now() < Date.parse(date) &&
      Date.parse(date) - Date.now() <= 3600000
    ) {
      // const d = date.split(" ")?.join("T");
      const d = date;
      return (
        <div>
          <Countdown
            date={Date.parse(d)}
            zeroPadDays={false}
            onComplete={() => refetch()}
          />
        </div>
      );
    }
    if (matchStatus === 4) {
      return <div>ملغي</div>;
    }
    if (matchStatus === 3) {
      return <div>موجل</div>;
    }
    if (matchStatus === 2 && !matchRoundInfo?.length) {
      return <div> بدات</div>;
    }
    if (matchStatus === 1 && !matchRoundInfo?.length) {
      return <div>لم تبدا</div>;
    }
    if (matchStatus === 5 || matchRoundInfo?.[0]?.event_id === 11) {
      return <div data-testid='match-state'>انتهت</div>;
    }
    if (matchStatus === 2 && matchRoundInfo?.[0]?.event_id === 10) {
      return <div>بداية الضربات الترجيحية</div>;
    }
    if (matchStatus === 2 && matchRoundInfo?.[0]?.event_id === 8) {
      return (
        <div>
          <div>نهاية الشوط الثاني الاضافي </div>
          <div>30:00</div>
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.[0]?.event_id === 7) {
      // const d = matchRoundInfo[0].created_at?.split(" ")?.join("T");
      const d = convertDateFormat(matchRoundInfo[0]?.created_at);
      return (
        <div>
          <Countdown date={Date.parse(d) + 900000} renderer={round2extra} />
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.[0]?.event_id === 6) {
      return (
        <div>
          <div>نهاية الشوط الاضافي الاول</div>
          <div>15:00</div>
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.[0]?.event_id === 5) {
      // const d = matchRoundInfo[0].created_at?.split(" ")?.join("T");
      const d = convertDateFormat(matchRoundInfo[0]?.created_at);
      return (
        <div>
          <Countdown date={Date.parse(d) + 900000} renderer={round1extra} />
        </div>
      );
    } else if (matchStatus === 2 && matchRoundInfo?.[0]?.event_id === 4) {
      return (
        <RadialTimer
          isLoading={isLoading}
          isError={isError}
          matchEvents={data}
          isFootball={isFootball}
          initialStart={90}
          basicRoundTime={90}
          // initialStart={45 * 60 * 1000}
          child={
            <div className='fs-xxs p-3'>
              <div>نهاية الشوط الثاني</div>
              <div>90:00</div>
              {(matchRoundInfo[0].event_time?.split("+")[1] ||
                matchRoundInfo[0].time?.split("+")[1]) && (
                <div>
                  {matchRoundInfo[0].event_time?.split("+")[1] ??
                    matchRoundInfo[0].time?.split("+")[1]}{" "}
                  دقيقه وقت بدل ضائع
                </div>
              )}
            </div>
          }
        />
      );
    } else if (matchStatus === 2 && matchRoundInfo?.[0]?.event_id === 3) {
      // const d = matchRoundInfo[0].created_at?.split(" ")?.join("T");
      const d = convertDateFormat(matchRoundInfo[0]?.created_at);
      return (
        <RadialTimer
          isLoading={isLoading}
          isError={isError}
          matchEvents={data}
          isFootball={isFootball}
          initialStart={Date.parse(d) + 2700000 - Date.now()}
          basicRoundTime={90}
          child={<Countdown date={Date.parse(d) + 2700000} renderer={round2} />}
        />
      );
    } else if (matchStatus === 2 && matchRoundInfo[0]?.event_id === 2) {
      return (
        <RadialTimer
          isLoading={isLoading}
          isError={isError}
          matchEvents={data}
          isFootball={isFootball}
          initialStart={45}
          basicRoundTime={45}
          // initialStart={45 * 60 * 1000}
          child={
            <div className='fs-xxs p-3'>
              <div>نهاية الشوط الاول</div>
              <div>45:00</div>
              {(matchRoundInfo[0].event_time?.split("+")[1] ||
                matchRoundInfo[0].time?.split("+")[1]) && (
                <div>
                  {matchRoundInfo[0].event_time?.split("+")[1] ??
                    matchRoundInfo[0].time?.split("+")[1]}{" "}
                  دقيقه وقت بدل ضائع
                </div>
              )}
            </div>
          }
        />
      );
    } else if (matchStatus === 2 && matchRoundInfo[0]?.event_id === 1) {
      // } else if (matchStatus === 2 || matchRoundInfo[0]?.event_id === 1) {
      // const d = matchRoundInfo[0]?.created_at?.split(" ")?.join("T");
      const d = convertDateFormat(matchRoundInfo[0]?.created_at);
      return (
        <RadialTimer
          isLoading={isLoading}
          isError={isError}
          matchEvents={data}
          isFootball={isFootball}
          initialStart={Date.parse(d) + 2700000 - Date.now()}
          basicRoundTime={45}
          child={<Countdown date={Date.parse(d) + 2700000} renderer={round1} />}
        />
      );
    }
  };

  const round1 = ({ hours, minutes, seconds, completed, api, formatted }) => {
    const minLeft = 44;
    if (completed && isFootball) {
      // Render a completed state
      return <span>45:00</span>;
    } else {
      // Render a countdown
      return (
        <div className='mt-3'>
          <div>الشوط الاول</div>
          {isFootball && (
            <div>
              {parseInt(formatted.seconds) !== 0 ? (
                <span>{60 - parseInt(formatted.seconds)}</span>
              ) : (
                <span>0</span>
              )}
              <span> : </span>
              {parseInt(formatted.minutes) !== 15 ? (
                <span>{minLeft - parseInt(formatted.minutes)}</span>
              ) : (
                <span>0</span>
              )}
            </div>
          )}
          <div>
            {" "}
            <span className='mx-1'>{team2.score}</span>-
            <span className='mx-1'>{team1.score}</span>
          </div>
        </div>
      );
    }
  };

  const round1extra = ({
    hours,
    minutes,
    seconds,
    completed,
    api,
    formatted,
  }) => {
    const minLeft = 14;
    if (completed && isFootball) {
      // Render a completed state
      return <span>15:00</span>;
    } else {
      // Render a countdown
      return (
        <div>
          <div>بداية</div>
          <div> الشوط الاول الاضافي</div>
          {isFootball && (
            <div>
              {parseInt(formatted.seconds) !== 0 ? (
                <span>{60 - parseInt(formatted.seconds)}</span>
              ) : (
                <span>0</span>
              )}
              <span> : </span>
              {parseInt(formatted.minutes) !== 15 ? (
                <span>{minLeft - parseInt(formatted.minutes)}</span>
              ) : (
                <span>0</span>
              )}
            </div>
          )}
          {/* {
            (isFootball && parseInt(formatted.minutes) !== 15) && <div>
              {minLeft - parseInt(formatted.minutes)}:
              {60 - parseInt(formatted.seconds)}
            </div>
          } */}
        </div>
      );
    }
  };

  const round2 = ({ hours, minutes, seconds, completed, api, formatted }) => {
    const minLeft = 89;
    if (completed && isFootball) {
      // Render a completed state
      return <span>90:00</span>;
    } else {
      return (
        <div className='mt-3'>
          <div>الشوط الثاني</div>
          {isFootball && (
            <div>
              {parseInt(formatted.seconds) !== 0 ? (
                <span>{60 - parseInt(formatted.seconds)}</span>
              ) : (
                <span>0</span>
              )}
              <span> : </span>
              {parseInt(formatted.minutes) !== 15 ? (
                <span>{minLeft - parseInt(formatted.minutes)}</span>
              ) : (
                <span>0</span>
              )}
            </div>
          )}
          <div>
            {" "}
            <span className='mx-1'>{team2.score}</span>-
            <span className='mx-1'>{team1.score}</span>
          </div>
        </div>
      );
    }
  };

  const round2extra = ({
    hours,
    minutes,
    seconds,
    completed,
    api,
    formatted,
  }) => {
    const minLeft = 29;
    if (completed && isFootball) {
      // Render a completed state
      return <span>30:00</span>;
    } else {
      return (
        <div>
          <div>بداية</div>
          <div> الشوط الثاني الاضافي</div>
          {isFootball && (
            <div>
              {parseInt(formatted.seconds) !== 0 ? (
                <span>{60 - parseInt(formatted.seconds)}</span>
              ) : (
                <span>0</span>
              )}
              <span> : </span>
              {parseInt(formatted.minutes) !== 15 ? (
                <span>{minLeft - parseInt(formatted.minutes)}</span>
              ) : (
                <span>0</span>
              )}
            </div>
          )}
        </div>
      );
    }
  };

  const renderTeam = (t, i) => (
    <>
      {i === 2 && (
        <>
          {handleShowPenaltyScore(team1, team2) && (
            <span
              className='fs-lg px-1 match-header-penalty-score'
              data-testid='match-header-away-team-penalty-score'>
              ({team2.penalty_score})
            </span>
          )}
          <div
            className='fs-xl match-header-score'
            data-testid='match-header-away-team-score'>
            {t?.score}
          </div>
        </>
      )}
      <div
        data-testid={`match-header-${
          i === 1 ? "home" : "away"
        }-team-image-and-title-wrapper`}>
        <div
          className='mb-3'
          onClick={() =>
            handleRowClick(tournament.services[0], t?.sport_id, t?.id)
          }
          style={{ cursor: "pointer" }}>
          <img
            className='match-header-team-img '
            src={ImgURL(t?.logo)}
            alt=''
            data-testid={`match-header-${i === 1 ? "home" : "away"}-team-image`}
          />
          <div
            className='fs-sm text-center mt-2'
            data-testid={`match-header-${
              i === 1 ? "home" : "away"
            }-team-title`}>
            {t?.title}
          </div>
        </div>
      </div>
      {i === 1 && (
        <>
          <div
            className='fs-xl match-header-score'
            data-testid='match-header-home-team-score'>
            {t?.score}
          </div>
          {handleShowPenaltyScore(team1, team2) && (
            <span
              className='fs-lg px-1 match-header-penalty-score'
              data-testid='match-header-home-team-penalty-score'>
              ({team1.penalty_score})
            </span>
          )}
        </>
      )}
    </>
  );

  const renderManager = (manager_name, manager_image) => (
    <div>
      {manager_name && (
        <>
          <div className='white-color fs-xs manager__name'>
            <img
              src={manager_image ? ImgURL(manager_image) : "/icons/manager.svg"}
              className='mx-2'
              alt=''
              height={30}
              width={30}
              data-testid='match-header-team-manager-image'
            />
            <span data-testid='match-header-team-manager-name'>
              {manager_name}
            </span>
          </div>
          <hr className='p-0 m-0 mt-2' />
        </>
      )}
    </div>
  );

  const renderScorers = (team) => {
    const goalsArr = data?.filter(
      (event) =>
        (eventsWithGoal
          .filter((id) => id !== OwnGoal)
          .includes(event.event_id) &&
          event.team_id === team.id) ||
        (event.event_id === OwnGoal && event.team_id !== team.id),
    );
    return (
      <div className='team1Scorers-container d-flex flex-wrap justify-content-center'>
        <div className='team1Scorers d-flex flex-column align-items-start'>
          {goalsArr?.map((goal) => (
            <div
              className='scorer white-color fs-xs pt-1 text-nowrap px-2'
              key={goal.id}>
              <img
                src={ImgURL(goal.event_image)}
                className='mx-2'
                alt=''
                height={30}
                width={30}
              />
              {` ${goal.event_time} د  -  ${goal.playerOne_name}`}
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        backgroundImage: `url(${
          sportName ? othersportsBannersSrc[sportName] : football
        })`,
      }}
      className='match-header text-light p-2'>
      <div className='match-header-team-info p-3'>
        <div className='d-flex justify-content-start align-items-center mb-3'>
          <div className='time-container d-flex align-items-center'>
            <b className='d-flex align-items-center'>
              <FontAwesomeIcon icon={faClock} size='1x' />
            </b>
            <b>
              <span
                className='parse__time mx-1'
                data-testid='match-header-time'>
                {getTime()}
              </span>
            </b>
          </div>
          <div className='date-container mx-1'>
            <b>
              <img src={calendarIcon} width='25px' height='20px' alt='' />
              <span
                className='parse__date px-1 text-nowrap'
                data-testid='match-header-date'>
                {parseDate}
              </span>
            </b>
          </div>
          <div className='stadium-name-container'>
            {" "}
            <img src={stadiumIcon} width='25px' height='20px' alt='' />{" "}
            <b className='stadium__name px-1 text-nowrap'>
              {stadium?.title ? stadium?.title : `- - - -`}
            </b>
          </div>
          <div className='referee-name-container mx-1'>
            {" "}
            <img src={whistle} width='25px' height='20px' alt='' />{" "}
            <b className='referee-name px-1 text-nowrap'>
              {referee_name ? referee_name : `- - - -`}
            </b>
          </div>
        </div>
        <Row
          className='d-flex align-items-center justify-content-start fs-xm text-center'
          data-testid='match-header-info-wrapper'>
          <Col
            xs={4}
            className='d-flex justify-content-center align-items-center'
            data-testid='team-home-info-wrapper'>
            {renderTeam(team1, 1)}
          </Col>
          <Col xs={4} md={4} className='p-2' data-testid='match-state-wrapper'>
            <div className='fs-md d-flex justify-content-center match-timer'>
              {getTimer()}
            </div>
          </Col>
          <Col
            xs={4}
            className='d-flex justify-content-center align-items-center'
            data-testid='team-away-info-wrapper'>
            {renderTeam(team2, 2)}
          </Col>
        </Row>
        <Row className='d-flex align-items-start justify-content-start fs-xm text-center'>
          <Col
            xs={5}
            md={4}
            className='d-flex flex-column justify-content-center align-items-center p-0'>
            {renderManager(team1?.manager_name, team1?.manager_image)}
            {isFootball && renderScorers(team1)}
          </Col>
          <Col xs={1} md={4}></Col>
          <Col
            xs={5}
            md={4}
            className='d-flex flex-column justify-content-center align-items-center p-0'>
            {renderManager(team2?.manager_name, team2?.manager_image)}
            {isFootball && renderScorers(team2)}
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default MatchHeader;
