import HttpHelper from './helper';
import TwistSettings from '../TwistSettings.json'
import { IDS } from '../globalData';

export const sectionsEndpoints = {
  getSectionVideos: (params = {}) => HttpHelper.baseAxios
    .get('/media', { params: { ...params, portal_id: TwistSettings['twist-ui'] ? IDS.PORTALS.TWIST : IDS.PORTALS.NEW_ET } })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),
};
