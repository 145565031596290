import React from "react";
import SpinnerLoader from "../../components/loaders/SpinnerLoader";
import { FaCheckCircle, FaRegCheckCircle } from "react-icons/fa";

const DoneIconComponent = ({ isDone, doneLoading }) => {
  return (
    <div className="doneContainer">
      {doneLoading ? (
        <SpinnerLoader />
      ) : isDone ? (
        <FaCheckCircle className="m-2" color="#62D86E" />
      ) : (
        <FaRegCheckCircle className="m-2" color="#62D86E" />
      )}
    </div>
  );
};

export default DoneIconComponent;
