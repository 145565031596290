import HttpHelper from "./helper";

export const predictAndWinEndpoints = {
    getUserPrediction: (params, signal) =>
        HttpHelper.baseAxios
            .get(`/polls/get`, { params, signal })
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),

    sendUserPrediction: (data) =>
        HttpHelper.baseAxios
            .post(`/polls`, data)
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),
};