import React from 'react'
import { Container } from 'react-bootstrap'
import api from '../../../../apis';
import SportsSectionHeader from '../../../../components/sportsSectionHeader/sportsSectionHeader.component'
import AllMedia from '../../reusable/media/all-melouk-media';

function HappenedThisDay() {

  return <Container className="mb-3">
    <SportsSectionHeader title="حدث في مثل هذا اليوم" />
    <AllMedia
      per_page={4}
      getAPI={api.melouk.media.getHappenedThisDay}
      type="happened-this-day"
    />
  </Container>
}

export default HappenedThisDay