import HttpHelper from "../../helper";

export const favoritesEndpoints = {
    getHomeFavs: (msisdn) =>
        HttpHelper.fitnessAxios
            .get(`/workout/get/home/favorites/${msisdn}`)
            .then((res) => res?.data),

    getGymFavs: (msisdn) =>
        HttpHelper.fitnessAxios
            .get(`/workout/get/gym/favorites/${msisdn}`)
            .then((res) => res?.data),

    addFav: (params) =>
        HttpHelper.fitnessAxios
            .post(`/workout/add/favorite`, null, { params })
            .then((res) => res?.data),

    removeFav: (params) =>
        HttpHelper.fitnessAxios
            .post(`/workout/remove/favorite`, null, { params })
            .then((res) => res?.data),
};