import React from "react";
import ReactPlayer from "react-player";
import { Row } from "react-bootstrap";

const VideoPlayerComponent = (props) => {
  return (
    <Row noGutters className="h-100 my-2">
      <div className="player-wrapper">
        <ReactPlayer
          stopOnUnmount={false}
          playIcon={<div className="play-btn"></div>}
          className="react-player"
          url={process.env.REACT_APP_MEDIA_BASE_URL + props.videoSrc}
          width="100%"
          height="100%"
          controls={true}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload", //<- this is the important bit
              },
            },
          }}
        />
      </div>
    </Row>
  );
};

export default VideoPlayerComponent;
