import React from "react";
import ReactPlayer from "react-player";
import "./index.css";

export default function VideoPlayer({ loading, src }) {
  if (loading) {
    return <div></div>;
  } else {
    return (
      <div className="player-wrapper ">
        <ReactPlayer
          stopOnUnmount={false}
          className="react-player "
          url={process.env.REACT_APP_MELOUK_MEDIA_BASE_URL + src}
          width="100%"
          height="100%"
          controls={true}
          config={{
            file: {
              attributes: {
                controlsList: "nodownload",
              },
            },
          }}
        />
      </div>
    );
  }
}
