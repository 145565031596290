import React from "react";
import { Container } from "reactstrap";
import NewsLoading from "../../../../components/News/NewsLoader";
import MeloukMediaCard from "../melouk-media-card";
import { Link, useLocation } from "react-router-dom";

function MeloukMediaList({
  initialLoading,
  allMedia,
  current_page,
  last_page,
  getByPage,
  paginationLoading,
  type
}) {


  const { pathname } = useLocation()
  const isMeloukHomePage = pathname.includes("/meloukelkora/home")


  return initialLoading ? (
    <NewsLoading />
  ) : allMedia?.length === 0 ? (
    <div className="p-5 bg-light-black-color fs-lg text-light text-center">
      عفواً ، لا يوجد فيديوهات
    </div>
  ) : (
    <Container fluid="md">
      <div className="news-section">
        <div className="row">
          {allMedia?.map((news) => (
            <div
              key={news.id}
              className="col-6 col-md-3 p-1"
              data-testid="media-list-match-card-wrapper"
            >
              <MeloukMediaCard news={news} type={type} />
            </div>
          ))}
        </div>
        <div className="text-center mt-3">
          {
            isMeloukHomePage
              ? <Link
                to={`/meloukelkora/${type}`}
                className="load__moreButton btn bg-active-color text-light fs-sm mx-1"
              >
                <div className="mx-1 mx-lg-0 text-nowrap">
                  شاهد المزيد
                </div>
              </Link>
              : <button
                className="load__moreButton btn bg-active-color text-light fs-sm mx-1"
                onClick={() => getByPage(current_page + 1)}
                disabled={current_page >= last_page}
              >
                {paginationLoading ? (
                  <div
                    className="spinner-border text-light fs-xs p-0"
                    role="status"
                    data-testid="media-list-pagination-loading"
                  >
                    <span className="sr-only">Loading...</span>
                  </div>
                ) : (
                  <div className="mx-1 mx-lg-0 text-nowrap">
                    حمل المزيد
                  </div>
                )}
              </button>
          }

        </div>
      </div>
    </Container>
  );
}

export default MeloukMediaList;
