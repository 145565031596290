import { Container } from "react-bootstrap";
import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ItemsCarousel from "react-items-carousel";
import "./NavbarMob2Component.css";

const NavbarMob2Component = () => {
  const { pathname } = useLocation();

  const [activeItemIndex, setActiveItemIndex] = useState(0);
  return (
    <Container className="py-2 navBarMob2 d-xl-none" fluid>
      <div>
        <div className="slider">
          <div className="slides">
            <div id="slide-1">
              <Link className="fs-xm" to={{ pathname: "/fitness/home", state: { previousPathname: pathname } }}>
                {"الرئيسية"}
              </Link>
            </div>
            <div id="slide-2">
              <Link className="fs-xm" to={{ pathname: "/fitness/gym-workout", state: { previousPathname: pathname } }}>
                {"تمرينات في الجيم"}
              </Link>
            </div>
            <div id="slide-3">
              <Link className="fs-xm" to={{ pathname: "/fitness/home-workout", state: { previousPathname: pathname } }}>
                {"تمرينات في المنزل"}
              </Link>
            </div>
            <div id="slide-4">
              <Link className="fs-xm" to={{ pathname: "/fitness/aly-mazhar-tips", state: { previousPathname: pathname } }} >
                {"نصائح علي مظهر"}
              </Link>
            </div>
            <div id="slide-5">
              <Link className="fs-xm" to={{ pathname: "/fitness/ask", state: { previousPathname: pathname } }}>
                {"اسأل علي مظهر"}
              </Link>
            </div>
            <div id="slide-6">
              <Link className="fs-xm" to={{ pathname: "/fitness/questions", state: { previousPathname: pathname } }}>
                {"إجابات أسئلتكم"}
              </Link>
            </div>
            <div id="slide-7">
              <Link className="fs-xm" to={{ pathname: "/fitness/nutrition-tips", state: { previousPathname: pathname } }}>
                {"برامج تغذية"}
              </Link>
            </div>
          </div>
        </div>
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={3}
        ></ItemsCarousel>
      </div>
    </Container>
  );
};

export default NavbarMob2Component;
