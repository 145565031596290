import React from 'react'
import { Route, useLocation } from 'react-router-dom'
import FitnessHeader from '../components/FitnessHeader/FitnessHeader'
import FitnessNavbarWeb from '../components/FitnessNavbar/FitnessNavbarWeb'
import NavbarMob2Component from '../components/FitnessNavbar/NavbarMob2Component'
import FitnessHomePage from '../pages/home/FitnessHomePage'
import GymWorkoutPage from '../pages/gymWorkout/GymWorkoutPage'
import HomeWorkout from '../pages/homeWorkout/HomeWorkoutPage'
import AlyMazharTipsPage from '../pages/alyMazharTips/AlyMazharTipsPage'
import VideoDetails from '../components/VideoDetails/VideoDetails'
import AskAlyMazharPage from '../pages/askAlyMazhar/AskAlyMazharPage'
import AlyMazharAnswers from '../pages/alyMazharAnswers/AlyMazharAnswersPage'
import NutritionTipsPage from '../pages/nutritionTips/NutritionTipsPage'
import NutritionEpisodesPage from '../pages/nutritionEpisodes/NutritionEpisodesPage'
import "../assets/css/general.css";
import "react-responsive-modal/styles.css";
import RequireSubscription from '../../../components/require-subscription'
import FitnessIntro from '../../../Pages/Fitness/FitnessIntro'


const FitnessLayout = ({ isSubscribed }) => {
  const { pathname } = useLocation();
  return (
    <>
      {!isSubscribed && <FitnessIntro />}

      <div className={!isSubscribed ? `text-center d-flex justify-content-center my-2` : ""}>

        <RequireSubscription isSubscribed={isSubscribed}>

          {pathname.match(/^\/fitness\/home$/)
            ? <FitnessHeader />
            : <>
              <FitnessNavbarWeb />      {/* displayed on large screens by bootstrap*/}
              <NavbarMob2Component />   {/* displayed on small screens by bootstrap*/}
            </>
          }
          <Route path="/fitness/home"><FitnessHomePage /></Route>
          <Route path="/fitness/gym-workout"><GymWorkoutPage /></Route>
          <Route path="/fitness/home-workout"><HomeWorkout /></Route>
          <Route path="/fitness/aly-mazhar-tips"><AlyMazharTipsPage /></Route>
          <Route path="/fitness/video/:type/:id" ><VideoDetails /></Route>
          <Route path="/fitness/ask" ><AskAlyMazharPage /></Route>
          <Route path="/fitness/questions" ><AlyMazharAnswers /></Route>
          <Route path="/fitness/nutrition-tips" ><NutritionTipsPage /></Route>
          <Route path="/fitness/nutrition-episodes" ><NutritionEpisodesPage /></Route>

        </RequireSubscription>

      </div>
    </>
  )
}

export default FitnessLayout
