import HttpHelper from './helper';

export const teamGalleryEndpoints = {
    getGalleryList: (team_id, signal) => HttpHelper.baseAxios
        .get(`/galleryList/${team_id}`, { signal })
        .then((res) => res?.data)
        .catch((err) => err?.response?.data),

    getTeamGallery: (list_id, signal) => HttpHelper.baseAxios
        .get(`/teamGallery/${list_id}`, { signal })
        .then((res) => res?.data)
        .catch((err) => err?.response?.data),
};