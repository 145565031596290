import React, { useRef } from "react";
import BannerWebComponent from "../../components/banner/BannerWebComponent";
import BannerMobComponent from "../../components/banner/BannerMobComponent";
import WorkoutWebComponent from "../../components/workouts/WorkoutWebComponent";
import WorkoutMobComponent from "../../components/workouts/WorkoutMobComponent";
import { getUserPhoneNumbers } from "../../../../globalFn";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { logFailedApi } from "../../../../globalFn";

const apiFn = (params, key) => {
    if (key == "gym-workout") return api.fitness.gymWorkout.getGymWorkout(params)

    if (key == "add-favorite") return api.fitness.favorites.addFav(params)
    if (key == "remove-favorite") return api.fitness.favorites.removeFav(params)

    if (key == "add-done") return api.fitness.done.addDone(params)
    if (key == "remove-done") return api.fitness.done.removeDone(params)
}

const GymWorkoutPage = () => {

    const msisdn = getUserPhoneNumbers()?.[0];
    const favParams = useRef({ videoId: null, msisdn: null })
    const doneParams = useRef({ videoId: null, msisdn: null })

    const { data: gymWorkout, isError, isFetching, isLoading, refetch: refetchGymWorkout } = useQuery({
        queryKey: ["gym-workout", msisdn],
        queryFn: () => apiFn(msisdn, "gym-workout"),
        onError: () => logFailedApi("gym-workout"),
        refetchOnWindowFocus: false,
    })


    const { isFetching: isFetchingAddFav, refetch: refetchAddFav } = useQuery({
        queryKey: ["add-favorite"],
        queryFn: () => apiFn(favParams.current, "add-favorite"),
        onSuccess: () => refetchGymWorkout(),
        onError: () => logFailedApi("add-favorite"),
        refetchOnWindowFocus: false,
        enabled: false,
    })

    const { isFetching: isFetchingRemoveFav, refetch: refetchRemoveFav } = useQuery({
        queryKey: ["remove-favorite"],
        queryFn: () => apiFn(favParams.current, "remove-favorite"),
        onSuccess: () => refetchGymWorkout(),
        onError: () => logFailedApi("remove-favorite"),
        refetchOnWindowFocus: false,
        enabled: false,
    })

    const { isFetching: isFetchingAddDone, refetch: refetchAddDone } = useQuery({
        queryKey: ["add-done"],
        queryFn: () => apiFn(doneParams.current, "add-done"),
        onSuccess: () => refetchGymWorkout(),
        onError: () => logFailedApi("add-done"),
        refetchOnWindowFocus: false,
        enabled: false,
    })

    const { isFetching: isFetchingRemoveDone, refetch: refetchRemoveDone } = useQuery({
        queryKey: ["remove-done"],
        queryFn: () => apiFn(doneParams.current, "remove-done"),
        onSuccess: () => refetchGymWorkout(),
        onError: () => logFailedApi("remove-done"),
        refetchOnWindowFocus: false,
        enabled: false,
    })

    const isFavLoading = isFetchingRemoveFav || isFetchingAddFav || isFetching

    const handleFavorite = (video) => {

        const params = {
            workoutId: video.id,
            msisdn: msisdn,
        }

        favParams.current = { ...params }

        if (favParams.current.workoutId) {
            if (video.favorite) refetchRemoveFav()
            else { refetchAddFav() }
        }
    }

    const isDoneLoading = isFetchingRemoveDone || isFetchingAddDone || isFetching

    const handleDone = (video) => {
        const params = {
            workoutId: video.id,
            msisdn: msisdn,
        }

        doneParams.current = { ...params }

        if (doneParams.current.workoutId) {
            if (video.done) refetchRemoveDone()
            else { refetchAddDone() }
        }
    }

    const calcProgressbar = () => {
        let progressBar = 0
        let doneVids = gymWorkout?.results.filter((video) => video.done === 1).length
        let totalVids = gymWorkout?.results.filter((video) => video.type === "workout").length

        progressBar = Math.floor((doneVids / totalVids) * 100)
        return progressBar
    }

    return (
        <div>
            <div className="d-none d-xl-block">
                <div>
                    <BannerWebComponent
                        show={true}
                        title={"تمرينات في الجيم"}
                        subTitle={"تمرينات يومياً لمساعدتك معرفة تمرينك اليومي في الجيم"}
                    />
                    <WorkoutWebComponent
                        progressBar={calcProgressbar()}
                        isLoading={isLoading}
                        errMess={isError}
                        videos={gymWorkout?.results}

                        addDone={handleDone}
                        doneLoading={isDoneLoading}

                        handleFavorite={handleFavorite}
                        favLoading={isFavLoading}

                        initialVideo={gymWorkout?.results.filter((video) => video.type === "workout")[0]}

                    />
                </div>
            </div>
            <div className="d-xl-none">
                <BannerMobComponent
                    show={true}
                    title={"تمرينات في الجيم"}
                    subTitle={"تمرينات يومياً لمساعدتك معرفة تمرينك اليومي في الجيم"}
                />
                <WorkoutMobComponent
                    progressBar={calcProgressbar()}
                    isLoading={isLoading}
                    errMess={isError}
                    videos={gymWorkout?.results}

                    addDone={handleDone}
                    doneLoading={isDoneLoading}

                    handleFavorite={handleFavorite}
                    favLoading={isFavLoading}
                />
            </div>
        </div>
    );
};

export default GymWorkoutPage;
