import React, { useState, useEffect } from "react";
import "./index.css"
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import { ImgURL } from "../../../../globalData";
import GameSuccessView from "../../GameSuccessView/GameSuccessView";
import { getUserPhoneNumbers } from "../../../../globalFn";
import { sendGameReport } from "../helper";
import api from "../../../../apis";
import { useQuery, useMutation } from "@tanstack/react-query";
import { logFailedApi } from "../../../../globalFn";

const apiGetFn = () => api.whereIsTheBall.get()
const apiPostFn = (params) => api.whereIsTheBall.post(params)

export const WhereIsTheBall = ({ show, onHide }) => {

    const [showSuccessView, setShowSuccessView] = useState(false);
    const [selectedValue, setSelectedValue] = useState(null);

    const msisdn = getUserPhoneNumbers()?.[0]

    const { data, isLoading, isError } = useQuery({
        queryKey: ["where-is-the-ball", msisdn],
        queryFn: () => apiGetFn(),
        onError: () => logFailedApi("where-is-the-ball"),
    })

    const { mutate, isLoading: answerLoading, isError: answerError } = useMutation(
        apiPostFn,
        {
            onSuccess: () => {
                setShowSuccessView(true);
                const setTimeOutID = setTimeout(() => {
                    onHide();
                    clearTimeout(setTimeOutID);
                }, 2000);
            },
            onError: () => logFailedApi("where-is-the-ball"),
        }
    )


    const ballPositions = [...Array(data?.predictions_count).keys()]

    const answer = {
        answer: selectedValue,
        ball_positions_id: data?.id,
        msisdn: msisdn,
    }

    const handleOnBtnClick = (e) => {
        const value = +e.target.value + 1
        setSelectedValue(value)
    }

    const handleSubmitAnswer = () => {
        mutate(answer)
    }

    return (
        <Modal
            show={show}
            onHide={onHide}
            size="xl"
            contentClassName={"modal__content"}
            dialogClassName="modal__dialog"
            className="where-is-the-ball"
        >
            <Modal.Header className="modal__header pt-3 p-3" closeButton />
            <Modal.Body className="show-grid">
                <Container>

                    {isLoading && <div className="text-center">تحميل...</div>}

                    {!answerLoading && !answerError && showSuccessView && <GameSuccessView />}

                    {!isLoading && !isError && !showSuccessView &&
                        <Row className=" justify-content-center">
                            <Col
                                xs={12}
                                className="modal__bodyTitle text-center text-white m-2"
                            >
                                <h3 className="mb-4"> {"هل يمكنك معرفة المكان الصحيح للكرة في الصور التالية؟"}</h3>
                                <img src={ImgURL(data.image)} alt="game image" />
                                <div className="mt-4 d-flex flex-column d-md-block ">
                                    {ballPositions.map(positionNum => {
                                        return (
                                            <button
                                                key={positionNum}
                                                className={`position-btn my-2 ${selectedValue == positionNum + 1 ? "selected" : ""}`}
                                                value={positionNum}
                                                onClick={(e) => { handleOnBtnClick(e) }}
                                            >
                                                {positionNum + 1}
                                            </button>
                                        )
                                    })}
                                </div>
                            </Col>
                        </Row>
                    }
                </Container>
            </Modal.Body>
            <Modal.Footer className="modal__footer d-flex flex-column justify-content-center m-0">
                {!showSuccessView &&
                    <Button
                        className="text-capitalize"
                        onClick={handleSubmitAnswer}
                        disabled={isError || isLoading || answerLoading || answerError}
                    >
                        {(answerLoading || isLoading) &&
                            <div
                                className="spinner-border text-light fs-xs p-0"
                                role="status"
                                data-testid="guessThePlayerGame-submitBtn-loading"
                            />
                        }
                        {!isLoading && !isError && !answerLoading && !answerError && "تأكيد"}
                        {!isLoading && (isError || answerError) && "حدث خطأ"}
                    </Button>
                }
            </Modal.Footer>

        </Modal>
    )
}