import NewsLoading from "./NewsLoader";
import NewsCarousel from "./NewsCarousel";
import './newsSection.css'
import MediaCard from "./MediaCard";
import ErrorMsg from "../ErrorPage/ErrorMsg";
import api from "../../apis";
import { useQuery } from "@tanstack/react-query";
import { Container, Row } from "react-bootstrap";
import { logFailedApi } from "../../globalFn";

const apiFn = (params) => api.mediaList.getMediaList(params);

function TopStories({ sport_id, season_id, service_id, items_count, media_type, tournament_id, media_id, team_id, player_id, top_stories, latest, popular }) {

  const { isLoading, isError, data = [] } = useQuery({
    queryKey: ["media-top-stories", sport_id, service_id, season_id, tournament_id, team_id, player_id, media_type],
    queryFn: () => apiFn({
      sport_id,
      service_id,
      season_id,
      items_count,
      type: media_type,
      tournament_id,
      media_id,
      team_id,
      player_id,
      top_stories,
      latest,
      popular,
    }),
    onError: () => logFailedApi("media-top-stories"),
    select: ({ data }) => data?.data,
    enabled: service_id !== null,
    refetchOnWindowFocus: false,
  });


  return (isLoading ?
    <NewsLoading />
    : isError || !data.length ?
      <ErrorMsg p={5} fs={'lg'} msg="لا يوجد اخبار" />
      : (
        <Container fluid="md">
          <div className="news-section">
            <Row>
              <div className='col-md-6 col-12 p-0 mt-2'>
                <NewsCarousel
                  isLoading={isLoading}
                  MediaList={data.slice(0, 4)}
                  type={1}
                  sportId={sport_id}
                />
              </div>
              <div className='col-md-6 col-12'>
                <Row>
                  {data.slice(0, 4).map((news) => (
                    <div className='col-md-6 col-6 p-1' key={news.id} >
                      <MediaCard news={news} type={1} sportId={sport_id} />
                    </div>
                  ))}
                </Row>
              </div>
            </Row>
          </div>
        </Container >
      )
  );
}

export default TopStories;

