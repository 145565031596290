import HttpHelper from './helper';

export const paymobEndpoints = {
  authenticationRequest: (data) => HttpHelper.paymobAxios
    .post(`/auth/tokens`, data)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  orderRegistration: (data) => HttpHelper.paymobAxios
    .post(`/ecommerce/orders`, data)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  paymentKeyRequest: (data) => HttpHelper.paymobAxios
    .post(`/acceptance/payment_keys`, data)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  transactionDetails: (transactionId, authToken) => HttpHelper.paymobAxios
    .get(`/acceptance/transactions/${transactionId}`, { headers: { "Authorization": `Bearer ${authToken}` } })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data)
};