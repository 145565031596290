import React,{ useEffect, useState } from 'react'
import api from '../../apis'
import { getUserPhoneNumbers } from '../../globalFn'

const UserPoints = () => {

    const [points, setPoints] = useState(0)
    const [errMsg, setErrMsg] = useState("")

    const msisdn = getUserPhoneNumbers()[0]

    useEffect(()=> {
        const abortcontroller = new AbortController()
        api.games.points.getPoints({msisdn:msisdn},abortcontroller.signal)
            .then((res) => {
                if(res.status === 200){
                    setPoints(res.data)
                    setErrMsg("")
                }else{
                    setErrMsg("حدث خطأ")
                }
            } )

        return () => {abortcontroller.abort()}
    },[])

  return (
    <p>{!!errMsg ? errMsg : `النقاط ${points}`}</p>
  )
}

export default UserPoints