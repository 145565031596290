import React, { useEffect, useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import "./PinComponent.css";
export default function PinComponent({ children, handleCheckPin }) {
  const formik = useFormik({
    initialValues: {
      userPinNumber: "",
    },
    validationSchema: yup.object({
      userPinNumber: yup
        .string()
        .matches(/[0-9]/g, "رقم التأكيد غير صحيح")
        .length(5, "رقم التأكيد مكون من 5 أرقام")
        .required("يرجي إدخال رقم التأكيد"),
    }),
    onSubmit: (values) => values.userPinNumber,
  });

  return (
    <Form className="pin__numberForm mt-5" onSubmit={formik.handleSubmit} data-testid="subscription-pin-form">
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="fs-md white-color">
          {"رمز التأكيد"}
        </Form.Label>
        <Form.Control
          autoFocus
          type="tel"
          className="fs-md"
          inputMode="numeric"
          autoComplete="off"
          pattern="[0-9]*"
          placeholder="xxxxx"
          name="userPinNumber"
          data-testid="subscription-pin-input"
          {...formik.getFieldProps("userPinNumber")}
        />

        {formik.touched.userPinNumber && formik.errors.userPinNumber && (
          <Form.Text className="text-danger">
            {formik.errors.userPinNumber}
          </Form.Text>
        )}
        <Form.Text className="text-danger" data-testid="subscription-pin-error-message">
          {children}
        </Form.Text>
      </Form.Group>
      <Button
        variant="danger"
        type="submit"
        onClick={() => handleCheckPin(formik.values.userPinNumber)}
        className="cust-btn-primary bg-active-color w-100"
        data-testid="subscription-submit-pin"
        disabled={
          Object.keys(formik.errors).length || !formik.values.userPinNumber
            ? true
            : false
        }
      >
        تأكيد
      </Button>
    </Form>
  );
}
