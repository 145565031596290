import { useRef } from "react";
import BannerWebComponent from "../../components/banner/BannerWebComponent";
import BannerMobComponent from "../../components/banner/BannerMobComponent";
import WorkoutWebComponent from "../../components/workouts/WorkoutWebComponent";
import WorkoutMobComponent from "../../components/workouts/WorkoutMobComponent";
import { getUserPhoneNumbers } from "../../../../globalFn";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { logFailedApi } from "../../../../globalFn";

const apiFn = (params, key) => {
  if (key == "home-workout") return api.fitness.homeWorkout.getHomrWorkout(params)

  if (key == "add-favorite") return api.fitness.favorites.addFav(params)
  if (key == "remove-favorite") return api.fitness.favorites.removeFav(params)

  if (key == "add-done") return api.fitness.done.addDone(params)
  if (key == "remove-done") return api.fitness.done.removeDone(params)
}

const HomeWorkoutPage = () => {

  const msisdn = getUserPhoneNumbers()?.[0];
  const favParams = useRef({ videoId: null, msisdn: null })
  const doneParams = useRef({ videoId: null, msisdn: null })

  const { data: homeWorkout, isError, isFetching, isLoading, refetch: refetchHomeWorkout } = useQuery({
    queryKey: ["home-workout", msisdn],
    queryFn: () => apiFn(msisdn, "home-workout"),
    onError: () => logFailedApi("home-workout"),
    refetchOnWindowFocus: false,
  })


  const { isFetching: isFetchingAddFav, refetch: refetchAddFav } = useQuery({
    queryKey: ["add-favorite"],
    queryFn: () => apiFn(favParams.current, "add-favorite"),
    onSuccess: () => refetchHomeWorkout(),
    onError: () => logFailedApi("add-favorite"),
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { isFetching: isFetchingRemoveFav, refetch: refetchRemoveFav } = useQuery({
    queryKey: ["remove-favorite"],
    queryFn: () => apiFn(favParams.current, "remove-favorite"),
    onSuccess: () => refetchHomeWorkout(),
    onError: () => logFailedApi("remove-favorite"),
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { isFetching: isFetchingAddDone, refetch: refetchAddDone } = useQuery({
    queryKey: ["add-done"],
    queryFn: () => apiFn(doneParams.current, "add-done"),
    onSuccess: () => refetchHomeWorkout(),
    onError: () => logFailedApi("add-done"),
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const { isFetching: isFetchingRemoveDone, refetch: refetchRemoveDone } = useQuery({
    queryKey: ["remove-done"],
    queryFn: () => apiFn(doneParams.current, "remove-done"),
    onSuccess: () => refetchHomeWorkout(),
    onError: () => logFailedApi("remove-done"),
    refetchOnWindowFocus: false,
    enabled: false,
  })

  const isFavLoading = isFetchingRemoveFav || isFetchingAddFav || isFetching

  const handleFavorite = (video) => {

    const params = {
      workoutId: video.id,
      msisdn: msisdn,
    }

    favParams.current = { ...params }

    if (favParams.current.workoutId) {
      if (video.favorite) refetchRemoveFav()
      else { refetchAddFav() }
    }
  }

  const isDoneLoading = isFetchingRemoveDone || isFetchingAddDone || isFetching

  const handleDone = (video) => {
    const params = {
      workoutId: video.id,
      msisdn: msisdn,
    }

    doneParams.current = { ...params }

    if (doneParams.current.workoutId) {
      if (video.done) refetchRemoveDone()
      else { refetchAddDone() }
    }
  }


  const calcProgressbar = () => {
    let progressBar = 0
    let doneVids = homeWorkout?.results.filter((video) => video.done === 1).length
    let totalVids = homeWorkout?.results.filter((video) => video.type === "workout").length

    progressBar = Math.floor((doneVids / totalVids) * 100)
    return progressBar
  }


  return (
    <div>
      <div className="d-none d-xl-block">
        <BannerWebComponent
          show={true}
          title={"تمرينات في المنزل"}
          subTitle={" لا تفوت الحلقات اليومية للتدريب المنزلي مع علي مظهر للياقة البدنية وإنقاص الوزن"}
        />
        <WorkoutWebComponent
          progressBar={calcProgressbar()}
          isLoading={isLoading}
          errMess={isError}
          videos={homeWorkout?.results}

          addDone={handleDone}
          doneLoading={isDoneLoading}

          handleFavorite={handleFavorite}
          favLoading={isFavLoading}

          initialVideo={homeWorkout?.results.filter((video) => video.type === "workout")[0]}
        />
      </div>
      <div className="d-xl-none">
        <BannerMobComponent
          show={true}
          title={"تمرينات في المنزل"}
          subTitle={" لا تفوت الحلقات اليومية للتدريب المنزلي مع علي مظهر للياقة البدنية وإنقاص الوزن"}
        />
        <WorkoutMobComponent
          progressBar={calcProgressbar()}
          isLoading={isLoading}
          errMess={isError}
          videos={homeWorkout?.results}

          addDone={handleDone}
          doneLoading={isDoneLoading}

          handleFavorite={handleFavorite}
          favLoading={isFavLoading}
        />
      </div>
    </div>
  );
};

export default HomeWorkoutPage;
