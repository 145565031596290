import React from 'react'
import twistLogo from "../../assets/img/twist-logo.png"
import etisalteLogo from "../../assets/img/transparentLogo.png"
import { Link, useLocation } from 'react-router-dom'
import TwistSettings from '../../TwistSettings.json'
import "./errorpage.css"

const ErrorPage = ({ error }) => {
    const { pathname } = useLocation()
    return (
        <div className='row p-0' style={{ height: "400px", width: "100%" }} data-testid="player-error-message">
            <div className='col-3 p-0'></div>
            <div className='col-6 p-0 d-flex flex-column justify-content-center align-items-center mt-0' style={{ height: "100%" }}>
                <img src={TwistSettings["twist-ui"] ? twistLogo : etisalteLogo} alt="logo"></img>
                <div className='text-center fs-lg white-color'>{error}</div>
                <div className='text-light text-center fs-lg btn-link'><Link to={{ pathname: '/', state: { previousPathname: pathname } }}>اضغط هنا للعوده للرئيسيه</Link></div>
            </div>
            <div className='col-3 p-0'></div>
        </div>
    )
}

export default ErrorPage