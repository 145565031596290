import HttpHelper from "./helper";

export const quizzesGameEndpoints = {
    getQuizzesQuestions: (params, signal) =>
        HttpHelper.baseAxios
            .get(`/quiz`, { params, signal })
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),

    sendQuizzesAnswers: (data) =>
        HttpHelper.baseAxios
            .post(`/quizAnswers`, data)
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),
};