import React from "react";
import { Link, useLocation } from "react-router-dom";
import { isActive } from "../../../Layout/helper";
import "./melouk.header.css";

function MeloukHeader() {
  const { pathname } = useLocation();

  return (
    <div className='melouk-header'>
      <nav className='navbar navbar-dark navbar-expand fs-sm'>
        <div className='collapse navbar-collapse' id='navbarSupportedContent'>
          <ul className='navbar-nav text-center '>
            <li className='ms-2'>
              <Link
                className={`nav-link nav-link-fade-up nav-item ${isActive(
                  pathname === "/meloukelkora/home",
                )}`}
                to={{
                  pathname: "/meloukelkora/home",
                  state: { previousPathname: pathname },
                }}>
                الرئيسية
              </Link>
            </li>
            <li className='ms-2'>
              <Link
                className={`nav-link nav-link-fade-up nav-item ${isActive(
                  pathname === "/meloukelkora/mido-diaries",
                )}`}
                to={{
                  pathname: "/meloukelkora/mido-diaries",
                  state: { previousPathname: pathname },
                }}>
                يوميات ميدو
              </Link>
            </li>
            <li className='ms-2'>
              <Link
                className={`nav-link nav-link-fade-up nav-item ${isActive(
                  pathname === "/meloukelkora/mido-riddles",
                )}`}
                to={{
                  pathname: "/meloukelkora/mido-riddles",
                  state: { previousPathname: pathname },
                }}>
                ألغاز الأساطير
              </Link>
            </li>
            <li className='ms-2'>
              <Link
                className={`nav-link nav-link-fade-up nav-item ${isActive(
                  pathname === "/meloukelkora/mido-tactis",
                )}`}
                to={{
                  pathname: "/meloukelkora/mido-tactis",
                  state: { previousPathname: pathname },
                }}>
                ميدو في الملعب
              </Link>
            </li>
            <li className='ms-2'>
              <Link
                className={`nav-link nav-link-fade-up nav-item ${isActive(
                  pathname === "/meloukelkora/happened-this-day",
                )}`}
                to={{
                  pathname: "/meloukelkora/happened-this-day",
                  state: { previousPathname: pathname },
                }}>
                حدث في مثل هذا اليوم
              </Link>
            </li>
            {/* <li className='ms-2'>
              <Link
                className={`nav-link nav-link-fade-up nav-item ${isActive(
                  pathname === "/meloukelkora/fantasy",
                )}`}
                to={{
                  pathname: "/meloukelkora/fantasy",
                  state: { previousPathname: pathname },
                }}>
                دورى الأساطير
              </Link>
            </li> */}
          </ul>
        </div>
      </nav>
    </div>
  );
}

export default MeloukHeader;
