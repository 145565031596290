import MediaCard from '../MediaCard';

function SearchResultList({ searchInputValue, isFetchingNewsSearch, NewsSearchFailed, NewsSearch }) {

    return (
        <div className={`${!searchInputValue ? 'd-none' : ''} text-light`}>
            {isFetchingNewsSearch === true &&
                <div className='text-center' data-testid="news-search-loading">
                    <div className="lds-roller mx-auto"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                    <div className={`text-center text-light`}>
                        يتم التحميل ...
                    </div>
                </div>
            }

            {isFetchingNewsSearch === false && (NewsSearchFailed || NewsSearch.length === 0) &&
                <div className='text-center p-5' data-testid="news-search-no-result">لايوجد نتائج</div>
            }

            {isFetchingNewsSearch === false && !!NewsSearch.length &&
                <div className='row news-section mt-3'>
                    {NewsSearch.map(news =>
                        <div className='col-4 col-md-3 p-1' key={news.id} data-testid="news-search-media-card-wrapper">
                            <MediaCard news={news} type={1} />
                        </div>)
                    }
                </div>
            }
        </div>
    )
}

export default SearchResultList;