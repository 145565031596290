export const makeOrderedColoredStats = (playerStats) => {
    return [
        {name: `StatsTable.Played`, order: 1, value: playerStats.played, color:'#e6194b'},
        {name: `StatsTable.Goals`, order: 3, value: playerStats.goals, color:'#3cb44b'},
        {name: `Player.Assists`, order: 4, value: playerStats.assisst, color:'#ffe119'},
        {name: `StatsTable.RedCards`, order: 12, value: playerStats.red_cards, color:'#4363d8'},
        {name: `StatsTable.YellowCards`, order: 11, value: playerStats.yellow_cards, color:'#f58231'},
        {name: `Player.MinutesPlayed`, order: 2, value: playerStats.played_minutes, color:'#911eb4'},
        {name: `StatsTable.Attempts`, order: 5, value: playerStats.attempts, color:'#46f0f0'},
        {name: `StatsTable.BallLose`, order: 8, value: playerStats.ball_lose, color:'#f032e6'},
        {name: `StatsTable.BallWin`, order: 7, value: playerStats.ball_win, color:'#bcf60c'},
        {name: `StatsTable.Dribbles`, order: 6, value: playerStats.dribbles, color:'#fabebe'},
        {name: `StatsTable.FoulsIn`, order: 9, value: playerStats.fouls_in, color:'#008080'},
        {name: `StatsTable.FoulsOut`, order: 10, value: playerStats.fouls_out, color:'#e6beff'},
        {name: `StatsTable.TotalCross`, order: 13, value: playerStats.total_cross, color:'#a1ba6a'},
        // {name: `StatsTable.GoalsPenalty`, order: 9999, value: playerStats.goals_penalty, color:'#9a6324'},
        // {name: `Player.MissedPenalty`, order: 9999, value: playerStats.missed_penalty, color:'#fffac8'},
        // {name: `Player.ReversedGoals`, order: 9999, value: playerStats.reversed_goals, color:'#800000'}
    ].sort((a, b) => a.order > b.order ? 1 : -1)
}

export const makeOrderedSeasonStats = (PlayerSeasonStatistics = {}) => {
    const seasonsKeys = Object.keys(PlayerSeasonStatistics);
    const newStats = PlayerSeasonStatistics;
    seasonsKeys.forEach(seasonsKey => {
        newStats[seasonsKey] = makeOrderedColoredStats(PlayerSeasonStatistics[seasonsKey])
    })
    return newStats;
}