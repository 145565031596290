import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import VideoPlayerComponent from "../../components/videos/VideoPlayerComponent";
import PerfectScrollbar from "react-perfect-scrollbar";
import DoneIconComponent from "../icons/DoneIconComponent";
import FavIconComponent from "../icons/FavIconComponent";
import { GiStopwatch } from "react-icons/gi";
import WorkoutWebLoader from "./WorkoutWebLoader";
import { ProgressBar } from "react-bootstrap";
import "./WorkoutWebComponent.css";

const WorkoutWebComponent = (props) => {
  const [videoSrc, setVideoSrc] = useState(
    props.initialVideo?.video
  );

  useEffect(() => {
    setVideoSrc(props.initialVideo?.video)
  }, [props.initialVideo])

  let handleVideo = (video) => {
    setVideoSrc(video);
  };

  if (props.isLoading || props.errMess) {
    return <WorkoutWebLoader />;
  } else {
    return (
      <Container fluid className="workOutWeb p-0">
        <ProgressBar now={props.progressBar} label={`${props.progressBar}%`} />
        <Row noGutters>
          <Col xs={7}>
            <VideoPlayerComponent videoSrc={videoSrc} />
          </Col>
          <Col className="playlist-container">
            <PerfectScrollbar>
              <ul className="list-group">
                {props.videos.map((video) =>
                  video.type === "workout" ? (
                    <li className="list-group-item mb-1 d-flex" key={video.id}>
                      <Col xs={10} onClick={() => handleVideo(video.video)}>
                        <h6 className="fs-sm">
                          {video.title}
                        </h6>
                        <p className="fs-md">
                          {video.description}
                        </p>
                      </Col>
                      <Col className="d-flex m-auto p-0 iconContainer">
                        <div onClick={() => props.handleFavorite(video)}>
                          <FavIconComponent
                            isFav={video.favorite}
                            favLoading={props.favLoading}
                          />
                        </div>
                        <div onClick={() => props.addDone(video)}>
                          <DoneIconComponent
                            isDone={video.done}
                            doneLoading={props.doneLoading}
                          />
                        </div>
                      </Col>
                    </li>
                  ) : (
                    <li
                      className="list-group-item rest mb-1 d-flex break-li"
                      key={video.id}
                    >
                      <h6 className="fs-md m-2 text-center">
                        <GiStopwatch className="fs-xm mx-1 mb-1" />
                        {video?.title}
                      </h6>
                    </li>
                  )
                )}
              </ul>
            </PerfectScrollbar>
          </Col>
        </Row>
      </Container>
    );
  }
};

export default WorkoutWebComponent;
