import React from 'react'
import { useState } from 'react';
import { Alert, Col, Row } from 'react-bootstrap';
import { AiOutlineClockCircle } from 'react-icons/ai';
import api from '../../../../../apis';
import RequireSubscription from '../../../../../components/require-subscription';
import { addDashesToDate, getUserPhoneNumbers } from '../../../../../globalFn';
import './index.css'
import { useLocation, Link } from 'react-router-dom';

function Riddle({ question, isSubscribed }) {
    const [choiceId, setChoiceId] = useState('');

    const [sendLoading, setSendLoading] = useState(false)
    const [sendSuccess, setSendSuccess] = useState(false)
    const [sendError, setSendError] = useState(null)

    const { pathname } = useLocation()
    const isMeloukHomePage = pathname.includes("/meloukelkora/home")
    const isMeloukHappendOnThisDayPage = pathname.includes("/happened-this-day")

    const sendAnswer = () => {
        setSendLoading(true)
        api.melouk.questions.sendMidoAnswer({
            choice_id: choiceId,
            phone: getUserPhoneNumbers()?.[0],
            question_id: question.id,
            type: "event"
        }).then(res => {
            if (res.status === 200) {
                setSendSuccess(true)
                setSendError(null)
            } else {
                setSendSuccess(false)
                setSendError(JSON.stringify(res.result))
            }
            setSendLoading(false)
        })
    }

    return <Row className='justify-content-center' noGutters>
        <Col className="riddle">
            {
                question.date && <small className='text-light'>
                    <AiOutlineClockCircle className="ml-1" />
                    {addDashesToDate(question.date, "ar")}
                </small>
            }
            <p className='text-light text-center'>
                {question.riddle}
            </p>
            <Row className='justify-content-center' noGutters>
                {
                    question.question?.choices?.map(c => <Col md={6} key={c.id} className="text-center">
                        <button
                            className={`choice ${choiceId === c.id ? 'selected' : ''}`}
                            onClick={() => setChoiceId(c.id)}
                        >
                            {c.choice}
                        </button>
                    </Col>)
                }
            </Row>
            <Row className='justify-content-center' noGutters>
                <RequireSubscription isSubscribed={isSubscribed}>
                    <Col md={6} className="text-center">
                        {
                            isMeloukHomePage
                                ? <Link to={"/meloukelkora/mido-riddles"} className="send">
                                    العبالآن
                                </Link>
                                : <button
                                    className="send"
                                    onClick={sendAnswer}
                                    disabled={sendLoading}
                                >
                                    {
                                        sendLoading
                                            ? 'جاري الإرسال ...'
                                            : 'إرسل'
                                    }
                                </button>
                        }

                    </Col>
                </RequireSubscription>
                <Col md={12}>
                    {sendSuccess && <Alert variant="success" className="fs-sm mt-2">
                        {isMeloukHappendOnThisDayPage ? "تم ارسال الإجابة" : "تم ارسال سؤال لميدو"}
                    </Alert>}
                    {sendError && <Alert variant="danger" className="fs-sm mt-2">{sendError}</Alert>}
                </Col>
            </Row>
        </Col>
    </Row>
}

export default Riddle