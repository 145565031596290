export const getPortalSubsName = (portalName) => {
  if (portalName === 'local') return 'Local_Football';
  if (portalName === 'international') return 'International_Football';
  if (portalName === 'africa') return 'CAN';
  if (portalName === 'othersports') return 'OTHERSPORTS';
  if (portalName === 'meloukelkora') return 'MELOUK_ELKOORA';
  if (portalName === 'fitness') return 'Be_Fit';
  return 'not_found'
}

export const checkedPinStatus = {
  [-2]: "الرمز الذي ادخلتة غير صحيح.",
  [-4]: 'عفواً، لقد استنفذت عدد المحاولات! الرجاء المحاولة مرة أخرى.'
}

export const EtSubs = process.env.REACT_APP_ET_SUB;