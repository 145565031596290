import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { GoCalendar } from "react-icons/go";
import { convertFullDate } from "../../../../globalFn";
import "./BannerWebComponent.css";

const BannerWebComponent = (props) => {
  var today = new Date();

  return (
    <Container fluid className="banner-container">
      <Container className="py-3">
        <Col>
          <Row>
            <Col xs={9} className="p-0">
              <h4 className=" text-uppercase fs-xxm">
                {props.title}
              </h4>
              <p className="fs-xs m-auto">
                {props.subTitle}
              </p>
            </Col>
            <Col className="p-0 m-auto">
              {props.show ? (
                <div className="d-flex">
                  <GoCalendar className="fs-xxl" color="#62D86E" />
                  <div className="mx-1">
                    <p className="fs-xs m-auto">
                      {"تمرينات اليوم"}
                    </p>
                    <p className="fs-sm color-primary-light m-auto">
                      {convertFullDate(today, 'ar')}
                    </p>
                  </div>
                </div>
              ) : null}
            </Col>
          </Row>
        </Col>
      </Container>
    </Container>
  );
};

export default BannerWebComponent;
