import HttpHelper from "../../helper";

export const questionsEndpoints = {
  getMidoQuestions: (params, signal) =>
    HttpHelper.meloukAxios
      .get(`/mido-riddles`, {params, signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  sendMidoQuestion: (data, signal) =>
    HttpHelper.meloukAxios
      .post(`/ask-mido`, data, {signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  sendMidoAnswer: (data, signal) =>
    HttpHelper.meloukAxios
      .post(`/answer`, data, {signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
};