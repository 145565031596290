import React from "react";
import { Col, Row } from "react-bootstrap";
import { AiOutlineClockCircle } from "react-icons/ai";
import { addDashesToDate } from "../../../../../globalFn";
import { questionDataStrucutreUpdating } from "../../../pages/mido-riddles/helper";
import Riddle from "../../../pages/mido-riddles/riddle";
import "./index.css";

export default function MediaContent({ loading, data, type, isSubscribed }) {
  if (loading) {
    return <div></div>;
  } else {
    return (
      <Row noGutters className="p-lg-4 p-2">
        <Col xs={12} md={9}>
          <small className="text-light fs-xs">
            <AiOutlineClockCircle className="fs-md ml-1" />
            {addDashesToDate(data?.date, "ar")}
          </small>
          <h4 className="fs-xm font-weight-bold text-light">
            {data?.title ?? data?.question}
          </h4>
          <p className="m-auto fs-md text-light">
            {data?.description}
          </p>
        </Col>
        <div className="riddles_happened_this_day text-center mt-5">
          {type === "happened-this-day" && (
            data?.latest ? (
              <Riddle question={questionDataStrucutreUpdating(data.question)} isSubscribed={isSubscribed} />
            ) : (
              <div>
                <h6>{data?.question?.question}</h6>
                <h6>
                  <span>الاجابة :</span>
                  {data?.right_answer?.choice}
                </h6>
              </div>
            )
          )}
        </div>
      </Row>
    );
  }
}
