import React, { useEffect, useState, useRef } from "react";
import { useParams } from "react-router";
import { Container } from "react-bootstrap";
import MediaContent from "./media-content";
import VideoPlayer from "./video-player";
import { getArabicMediaType, getMediaTypeAPI } from "./helper";
import SportsSectionHeader from "../../../../components/sportsSectionHeader/sportsSectionHeader.component";
import MeloukMediaList from "../media/melouk-media-list";
import RequireSubscription from "../../../../components/require-subscription";

const MeloukMediaDetails = ({isSubscribed}) => {
  const { type, id } = useParams();
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);

  const fetchData = () => {
    setLoading(true)
    getMediaTypeAPI(type)(id)
      .then(res => {
        if (res.status === 200) {
          setData(res.data);
        }
        setLoading(false)
      })
  }
  
  useEffect(() => {
    fetchData()
  }, [type, id]);

  return (
    <Container className="mb-3">
      <SportsSectionHeader title={getArabicMediaType(type)} />
      <div className="border-radius-15 p-2 box-shadow mb-3  mt-2">
        <RequireSubscription isSubscribed={isSubscribed}>
          <VideoPlayer
            loading={loading}
            src={data?.video}
          />
        </RequireSubscription>
        <MediaContent
          loading={loading}
          data={data}
          type={type}
          isSubscribed={isSubscribed}
        />
      </div>
      {data?.related ? (
        <MeloukMediaList
          initialLoading={loading}
          allMedia={data?.related}
          current_page={1}
          last_page={1}
          type={type}
        />
      ) : null}
    </Container>
  );
};

export default MeloukMediaDetails;
