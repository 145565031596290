import React from 'react'
import { Col, Row } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TwistSettings from '../../TwistSettings.json'

const matchesCalendarLoader = () =>{
    const loadingColumns = () => <>
        <Col xs={3}>
            <Skeleton height={50} width={50} className="rounded-circle" />
        </Col>
        <Col xs={6}>
            <Skeleton height={50} width={150} />
        </Col>
        <Col xs={3}>
            <Skeleton height={50} width={50} className="rounded-circle" />
        </Col>
        <Col xs={12} className="mt-4">
            <Row>
                <Col />
                <Col xs={6}>
                    <Skeleton height={10} width={150} />
                </Col>
                <Col />
            </Row>
        </Col>
    </>
    return (
        <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb">
            <div className='d-flex p-4 bg-light-black-color'>    
                <Row className='text-light mx-auto'>
                    {loadingColumns()}
                </Row>
                <Row className='text-light mx-4 d-none d-md-flex'>
                    {loadingColumns()}
                </Row>
                <Row className='text-light d-none d-md-flex'>
                    {loadingColumns()} 
                </Row>
            </div>
        </SkeletonTheme>
    )
}

export default matchesCalendarLoader

