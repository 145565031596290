import { useContext, useEffect } from 'react';
import api from '../apis';
import Cookies from "universal-cookie";
import { useState } from 'react';
import TwistSettings from '../TwistSettings.json'
import { getUserPhoneNumbers } from '../globalFn';
import { grantAccessToAllServices } from './helper';
import { sharedComponentsContext } from '../context/shared-context';
import { useHistory } from "react-router-dom";
import { logFailedApi } from '../globalFn';

export default function useHeaderEnrichement(serviceName) {
  const [isWifi, setIsWifi] = useState(null);
  const [oneServiceLoading, setoneServiceLoading] = useState(true)
  const [isSubscribedToOneService, setisSubscribedToOneService] = useState(null)
  const { setInitialSharedValues } = useContext(sharedComponentsContext)
  const cookies = new Cookies();
  const history = useHistory();

  // mahran test number "201142323706"
  // this obj stores what services the user is subed in
  const servicesObj = {
    "local": false,
    "international": false,
    "africa": false,
    "othersports": false,
    "meloukelkora": false,
    "fitness": false,
  }

  const checkSubs = (serviceName, msisdn, isGame = false) => {
    // msisdn = "201142323706"
    if (isGame) {
      Promise.all([
        api.subscription["local"]?.checkSubsByMsisdn(msisdn)
          .then(res => res.status == 1 ? servicesObj["local"] = true : servicesObj["local"] = false),

        api.subscription["international"]?.checkSubsByMsisdn(msisdn)
          .then(res => res.status == 1 ? servicesObj["international"] = true : servicesObj["international"] = false),

        api.subscription["africa"]?.checkSubsByMsisdn(msisdn)
          .then(res => res.status == 1 ? servicesObj["africa"] = true : servicesObj["africa"] = false),

        api.subscription["othersports"]?.checkSubsByMsisdn(msisdn)
          .then(res => res.status == 1 ? servicesObj["othersports"] = true : servicesObj["othersports"] = false),

        api.subscription["meloukelkora"]?.checkSubsByMsisdn(msisdn)
          .then(res => res.status == 1 ? servicesObj["meloukelkora"] = true : servicesObj["meloukelkora"] = false),

        api.subscription["fitness"]?.checkSubsByMsisdn(msisdn)
          .then(res => res.status == 1 ? servicesObj["fitness"] = true : servicesObj["fitness"] = false),
      ])
        .then(res => {
          let firstServiceFound = Object.keys(servicesObj).find(service => servicesObj[service] == true)

          if (firstServiceFound) {
            setisSubscribedToOneService(true)
            setoneServiceLoading(false)
            api.subscription[firstServiceFound]?.updateSubsToken(msisdn)
              .then((tokenRes) => {
                if (tokenRes?.status === 200) {
                  cookies.set(
                    firstServiceFound,
                    `${tokenRes.token},${msisdn}`,
                    { path: "/", maxAge: `${60 * 60 * 12}`, domain: window.location.hostname }
                  )
                  setInitialSharedValues(prev => ({ ...prev, refreshSubscriptionStatus: !prev.refreshSubscriptionStatus }))
                }
              })
          }
          else {
            setisSubscribedToOneService(false)
            setoneServiceLoading(false)
            cookies.remove(serviceName, { path: '/', domain: window.location.hostname })
            setInitialSharedValues(prev => ({ ...prev, refreshSubscriptionStatus: true }))
          }
        })
    }
    else {
      api.subscription[serviceName]?.checkSubsByMsisdn(msisdn)
        .then((res) => {
          if (res.status === 1) {
            setisSubscribedToOneService(true)
            setoneServiceLoading(false)
            api.subscription[serviceName]?.updateSubsToken(msisdn)
              .then((tokenRes) => {
                if (tokenRes?.status === 200) {
                  cookies.set(
                    serviceName,
                    `${tokenRes.token},${msisdn}`,
                    { path: "/", maxAge: `${60 * 60 * 12}`, domain: window.location.hostname }
                  )
                  setInitialSharedValues(prev => ({ ...prev, refreshSubscriptionStatus: true }))
                }
              })
          }
          else { // res.status === -6
            setisSubscribedToOneService(false)
            setoneServiceLoading(false)
            cookies.remove(serviceName, { path: '/', domain: window.location.hostname })
            setInitialSharedValues(prev => ({ ...prev, refreshSubscriptionStatus: true }))
          }
        })
    }

  }

  useEffect(() => {
    if (!TwistSettings['subscription']) {
      if (getUserPhoneNumbers().length > 0) {
        grantAccessToAllServices(getUserPhoneNumbers()[0])
        setisSubscribedToOneService(true)
      } else {
        setisSubscribedToOneService(false)
      }
      setoneServiceLoading(false)
    }
    else if (serviceName == "games") {
      let callsWithFails = 0;
      const apiFn = () => {
        api.subscription.getHeaderEnrichement()
          .then((param) => {
            if (param) {
              setIsWifi(false)

              api.subscription.decryptSubParam(param).then((decryptedRes) => {
                if (decryptedRes?.status === 200) {
                  checkSubs("local", decryptedRes?.user, true)
                }
                else {
                  const retryTimer = setInterval(() => {
                    apiFn()
                    clearInterval(retryTimer)
                  }, 1000);
                }
              })
            }
            else {
              setIsWifi(true)
              checkSubs("local", undefined)
            }
          })
          .catch(() => {
            if (callsWithFails < 6) {
              logFailedApi("/HeaderEnrichemnt")
              callsWithFails++;
              const retryTimer = setInterval(() => {
                apiFn()
                clearInterval(retryTimer)
              }, 1000);
            }
          })
      }
      apiFn()
    }
    else {
      let callsWithFails = 0;
      const apiFn = () => {
        api.subscription.getHeaderEnrichement()
          .then((param) => {
            if (param) {
              setIsWifi(false)
              api.subscription[serviceName]?.decryptSubParam(param)
                .then((decryptedRes) => {
                  if (decryptedRes?.status === 200) {
                    checkSubs(serviceName, decryptedRes?.user)
                  }
                  else {
                    const retryTimer = setInterval(() => {
                      apiFn()
                      clearInterval(retryTimer)
                    }, 500);
                  }
                })
            } else {
              setIsWifi(true)
              const msisdn = cookies.get(serviceName)?.split(",")?.[1]
              checkSubs(serviceName, msisdn)
            }
          })
          .catch(() => {
            if (callsWithFails < 6) {
              logFailedApi("/HeaderEnrichemnt")
              callsWithFails++;
              const retryTimer = setInterval(() => {
                apiFn()
                clearInterval(retryTimer)
              }, 1000);
            }
          })
      }
      apiFn()
    }
  }, [])

  return { isWifi, oneServiceLoading, isSubscribedToOneService };
}