import React from 'react'
import { useHistory } from "react-router-dom";
import { PieChart } from 'react-minimal-pie-chart';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';
import "./topScorer.css"
import { useQuery } from '@tanstack/react-query';
import api from "../../apis";
import { logFailedApi } from '../../globalFn';

const apiFn = (params) => { return api.teams.getTopScorers(params) }


const TopScorers = ({ sport_id, season_id, tournament_id, team_id }) => {

    const { data: topScorers, isFetching, isError } = useQuery({
        queryKey: ["top-scorers", sport_id, season_id, tournament_id, team_id],
        queryFn: () => apiFn({ sport_id, season_id, tournament_id, team_id }),
        onError: () => logFailedApi("top-scorers"),
        refetchOnWindowFocus: false,
    })

    const history = useHistory();
    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/player/${id}`, { previousPathname: history.location.pathname });
    }

    const goalsData = (Arr) => {
        const newData = Arr[0]
        return [{ value: newData.goals, color: '#9EC41B' }]
    }

    const ratioData = (Arr) => {
        const newData = Arr[0]
        return [{ value: newData.ratio, color: '#9EC41B' }]
    }

    return (
        <div className='bg-black-color white-color fs-xs  mb-2' onClick={() => handleRowClick(topScorers?.data[0].services?.[0], topScorers?.data[0].sport_id, topScorers?.data[0]?.id || topScorers?.data[0]?.player_id)} style={{ cursor: "pointer" }}>
            <div className="p-2 fs-md">هداف الفريق</div>
            {
                isError ?
                    <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                    :
                    isFetching ?
                        <LoadingSpinnner p={4} fs={"sm"} />
                        :
                        topScorers?.data.length === 0 ?
                            <ErrorMsg msg="لايوجد معلومات" p={5} fs={'md'} />
                            :
                            <div className='p-2'>
                                <div className='my-2 d-flex flex-column align-items-center'>
                                    <img src={ImgURL(topScorers?.data[0].image)} style={{ width: '50px', borderRadius: '50%' }} alt="player" />
                                    <div className='mt-2'>{topScorers?.data[0].name}</div>
                                </div>
                                <div className='d-flex justify-content-center text-center'>
                                    <div className='d-flex flex-column align-items-center me-3'>
                                        <div className='mb-1'>لعب</div>
                                        <div style={{ border: '4px solid #9EC41B', borderRadius: '50%', height: '42px', width: '42px' }} className='pt-2 m-0'> {topScorers?.data[0].played} </div>
                                    </div>
                                    <div className='pie-chart-box' style={{ width: '100px', overflow: 'hidden', position: 'relative' }}>
                                        <div className='mb-1'>أهداف</div>
                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: "translateX(-50%)" }}>{topScorers?.data[0].goals}</div>
                                        <PieChart data={goalsData(topScorers?.data)} lineWidth={20} radius={19} viewBoxSize={[48, 48]} center={[25, 10]} totalValue={topScorers?.data[0].played} background={'#A4A4A4'} />
                                    </div>
                                    <div className='pie-chart-box' style={{ width: '100px', overflow: 'hidden', position: 'relative' }}>
                                        <div className='mb-1'>النسبه</div>
                                        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: "translateX(-50%)" }} className='fs-xs'>{topScorers?.data[0].ratio}%</div>
                                        <PieChart data={ratioData(topScorers?.data)} lineWidth={20} radius={19} viewBoxSize={[48, 48]} center={[25, 10]} totalValue={100} background={'#A4A4A4'} />
                                    </div>
                                </div>
                            </div>
            }
        </div>
    )
}

export default TopScorers