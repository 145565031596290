import Skeleton, { SkeletonTheme } from "react-loading-skeleton"
import { Col, Row, Container } from "react-bootstrap"

const MatchHeaderLoader = () => {
    return (
        <SkeletonTheme color="rgba(255,255,255,0.0)" highlightColor="#ecebeb" >
            <div className='match-header text-light font-light-ar' data-testid="match-header-loader">
                <Row className="match-header-team-info d-flex align-items-center justify-content-around fs-xm">
                    <Col xs={4} className='d-flex justify-content-center align-items-center'>
                        <Skeleton height={100} width={100} className="rounded-circle" />
                    </Col>
                    <Col xs={4} md={4}>
                        <Skeleton height={150} width={130} className='fs-sm text-center' />
                    </Col>
                    <Col xs={4} className='d-flex justify-content-center align-items-center'>
                        <Skeleton height={100} width={100} className="rounded-circle" />
                    </Col>
                </Row>
            </div>
        </SkeletonTheme>
    )
}

export default MatchHeaderLoader