import axios from 'axios';
import HttpHelper from './helper';
import TwistSettings from '../TwistSettings.json'
import { IDS } from '../globalData';

export const reportsEndpoints = {
  getIp: () => axios.create({
    baseURL: 'https://api.ipify.org/',
    headers: {
      "Content-Type": "application/json",
    }
  }).get('?format=json')
    .then((res) => res?.data.ip)
    .catch(() => "error"),

  sendLog: (params = {}) => HttpHelper.baseAxios
    .post('/portal/log', null, { params: { ...params, portal_id: IDS.PORTALS.TWIST } })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),
};