import { Col, Nav, Tab } from 'react-bootstrap';
import MatchesCarouselComponent from "../MatchesCarousel/MatchesCarouselComponent";
import "./MatchesCalendar.css";
import MatchesCalendarLoader from './MatchesCalendarLoader';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import api from '../../apis';
import { useLocation } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { logFailedApi } from '../../globalFn';


const apiFn = (params) => {
  const { tournament_id, season_id, team_id, pathname, sport_id, service_id } = params;
  if (sport_id === 7) return api.squash.getMatchCalender()
  if (team_id) return api.calendar.getTeamCalender({ team_id })
  if (pathname === "/") return api.calendar.getHomeCalender()
  if (pathname === "/othersports") return api.calendar.getOtherSportsCalender()
  return api.calendar.getCalender({ tournament_id, season_id, sport_id, service_id })
}

function MatchesTabComponent({
  sport_id,
  service_id,
  season_id,
  tournament_id,
  team_id,
  showSquashTab
}) {
  const { pathname } = useLocation()
  const { isLoading, isError, data = {} } = useQuery({
    queryKey: ["matches-calendar", pathname, season_id],
    queryFn: () => apiFn({ tournament_id, season_id, team_id, pathname, sport_id, service_id }),
    onError: () => logFailedApi("matches-calendar"),
    select: ({ data }) => {
      if (sport_id === 7) {
        let customizedData = {};
        for (const dayMatches in data) {
          const customizedDayMatches = data?.[dayMatches].map(match => {
            match.sport_id = 7;
            match.services = [10];
            return match;
          })
          customizedData = { ...customizedData, [dayMatches]: customizedDayMatches }
        }
        return customizedData;
      }

      if (team_id) {
        const { today, next, previous } = data;
        return { yesterday: previous, today, tomorrow: next };
      }

      let sortedDataByRank = {}
      for (const dayMatches in data) {
        const sortedDayMatches = data[dayMatches]?.sort((a, b) => b.rank - a.rank);
        sortedDataByRank = { ...sortedDataByRank, [dayMatches]: sortedDayMatches }
      }
      const liveMatches = data?.today?.filter(match => match.matchStatus === 2)
      const customizedData = { ...sortedDataByRank, liveMatches }
      return customizedData;
    },
    refetchOnWindowFocus: false,
  });


  return (
    <div className="my-4 matches-calendar">
      <Tab.Container id="left-tabs-example" defaultActiveKey="today">
        <Col>
          <Nav className='d-flex justify-content-center'>
            <Nav.Item className=" mx-2 mb-3" >
              <Nav.Link eventKey="yesterday" className='px-2'>
                <div className="text-center fs-xs mt-2"><strong>{team_id ? "السابقة" : "أمس"}</strong></div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item className=" mx-2 mb-3">
              <Nav.Link eventKey="today" className="  text-center fs-xs days_tab px-2">
                <div className="text-center fs-xs mt-2"><strong>اليوم</strong></div></Nav.Link>
            </Nav.Item>
            <Nav.Item className={` mx-2 mb-3`} >
              <Nav.Link eventKey="tomorrow" className=" text-center fs-xs days_tab px-2">
                <div className="text-center fs-xs mt-2"><strong>{team_id ? "القادمة" : "غدا"}</strong></div></Nav.Link>
            </Nav.Item>
            {data?.liveMatches?.length > 0 && <Nav.Item className={` mx-2 mb-3`} >
              <Nav.Link eventKey="live" className="live-matches text-center fs-xs days_tab px-1 pt-1">
                <div className="text-center fs-xs mt-2"><strong>{"مباشر"}</strong></div></Nav.Link>
            </Nav.Item>}
          </Nav>
        </Col>
        <div className="my-auto"  >
          <Tab.Content >
            <Tab.Pane eventKey="yesterday" >
              {isLoading ? <MatchesCalendarLoader /> :
                isError || !Object.keys(data).length ?
                  <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" /> :
                  <MatchesCarouselComponent
                    showSquashTab={showSquashTab}
                    matches={data?.yesterday}
                  />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="today"  >
              {isLoading ? <MatchesCalendarLoader /> :
                isError || !Object.keys(data).length ?
                  <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" /> :
                  <MatchesCarouselComponent showSquashTab={showSquashTab} matches={data?.today} />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="tomorrow">
              {isLoading ? <MatchesCalendarLoader /> :
                isError || !Object.keys(data).length ?
                  <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" /> :
                  <MatchesCarouselComponent
                    showSquashTab={showSquashTab}
                    matches={data?.tomorrow}
                  />
              }
            </Tab.Pane>
            <Tab.Pane eventKey="live">
              {isLoading ? <MatchesCalendarLoader /> :
                isError || !Object.keys(data).length ?
                  <ErrorMsg p={5} fs={'md'} msg="لا يوجد مباريات" /> :
                  <MatchesCarouselComponent
                    showSquashTab={showSquashTab}
                    matches={data?.liveMatches}
                  />
              }
            </Tab.Pane>
          </Tab.Content>
        </div>
      </Tab.Container>
    </div>
  )
}


export default MatchesTabComponent;
