import React from "react";
import { GiShare } from "react-icons/gi";
import "./ShareBtnComponent.css";

const ShareBtnComponent = (props) => {
  return (
    <div className="shareContainer text-white p-0 ">
      <div
        onClick={() =>
          window.open(
            "https://www.facebook.com/sharer/sharer.php?u=" +
              process.env.REACT_APP_PUBLIC_URL +
              props.url,
            "pop",
            "width=600, height=400, scrollbars=no"
          )
        }
        className={`${
          props.btnType === "primary-btn"
            ? "btn btn-primary primary-btn w-100"
            : "basic m-1"
        } fs-xs `}
      >
        <GiShare
          className={`fs-xxm ml-1`}
        />
        شارك
      </div>
    </div>
  );
};

export default ShareBtnComponent;
