import React, { useState, useEffect } from "react";
import MeloukMediaList from "./melouk-media-list";

function AllMedia({
  per_page,
  getAPI,
  type
}) {

  const [isFetchLoading, setIsFetchLoading] = useState(true);
  const [isPaginationLoading, setIsPaginationLoading] = useState(false);
  const [allMedia, setAllMedia] = useState([]);
  const [pages, setPages] = useState({ currentPage: 1, lastPage: null });

  const controller = new AbortController()
  const getByPage = (page) => {
    callMediaApi(page);
  };

  const callMediaApi = (page) => {
    if (!page) setIsFetchLoading(true);

    setIsPaginationLoading(true);
    getAPI({
      per_page,
      page
    }, controller.signal)
      .then((res) => {
        if (res !== "canceled") {
          if (page) {
            setAllMedia((prev) => [...prev, ...res?.data]);
          } else {
            setAllMedia(res?.data);
          }
          setPages((prev) => ({
            ...prev,
            lastPage: res?.meta?.total_pages,
            currentPage: res?.meta?.current_page
          }));
          setIsFetchLoading(false);
          setIsPaginationLoading(false);
        }
      });
  };

  useEffect(() => {
    callMediaApi();

    return () => {
      return controller.abort()
    }
  }, []);

  return (
    <MeloukMediaList
      initialLoading={isFetchLoading}
      paginationLoading={isPaginationLoading}
      current_page={pages.currentPage}
      last_page={pages.lastPage}
      allMedia={allMedia}
      getByPage={getByPage}
      type={type}
    />
  );
}

export default AllMedia;