export const translateFormationToSquad = (formation, oldSquad) => {
    const squad = {...oldSquad}
    const fromDefenceToAttack = formation.split("-");
    const theCenter = Math.floor(fromDefenceToAttack.length/2)

    squad.gk = squad.gk ?? {id: 'gk'}
    
    squad.df = [...Array(Number(fromDefenceToAttack[0]))].map((x, i) => (squad.df?.[i] ?? {id: 'df' + i}))
    squad.cm = [...Array(Number(fromDefenceToAttack[theCenter]))].map((x, i) => (squad.cm?.[i] ?? {id: 'cm' + i}))
    squad.fw = [...Array(Number(fromDefenceToAttack[fromDefenceToAttack.length - 1]))].map((x, i) => (squad.fw?.[i] ?? {id: 'fw' + i}))
    
    if (fromDefenceToAttack.length === 4) {
        squad.cdm = [...Array(Number(fromDefenceToAttack[theCenter - 1]))].map((x, i) => (squad.cdm?.[i] ?? {id: 'cdm' + i}))
        squad.cam = []
    } else if (fromDefenceToAttack.length === 5) {
        squad.cam = [...Array(Number(fromDefenceToAttack[theCenter + 1]))].map((x, i) => (squad.cam?.[i] ?? {id: 'cam' + i}))
    } else {
        squad.cdm = []
        squad.cam = []
    }
    return squad
}