import React, { useContext } from "react";
import "react-multi-carousel/lib/styles.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import "./Competition.css";
import CompetitionLoader from "./CompetitionLoader";
import ErrorMsg from "../../components/ErrorPage/ErrorMsg";
import { ImgURL } from "../../globalData";
import { getServiceName, getSportRoute } from "../../Layout/helper";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import { sharedComponentsContext } from "../../context/shared-context";
import api from "../../apis";
import { useQuery } from "@tanstack/react-query";
import { logFailedApi } from "../../globalFn";

const apiFn = (sportName, params) => {
  return sportName === 'squash' ?
    api.squash.getTournaments() :
    api.tournament.getTournaments(params)
};

const customizedSquashDataFn = (data) => {
  const customizedSquashData = [...data].map((tournament) => {
    return {
      ...tournament,
      services: [10],
      sport_id: 7,
      title: tournament.name_ar,
    }
  })
  return customizedSquashData
}

function TournamentsPage() {
  const { pathname } = useLocation()
  const { serviceId, sportId, sportName } = useServiceAndSport();
  const { seasonInfo: { appActiveSeason } } = useContext(sharedComponentsContext);
  const { isLoading, isError, data = [] } = useQuery({
    queryKey: ["tournamets", appActiveSeason.id, serviceId, sportId],
    queryFn: () => apiFn(sportName, { sport_id: sportId, service_id: serviceId, season_id: appActiveSeason.id, priority: 1, items_count: 1000 }),
    select: (data) => {
      return sportName === 'squash' ?
        customizedSquashDataFn(data?.data) :
        data?.data?.data
    },
    onError: () => logFailedApi("tournamets"),
    enabled: sportId !== null && serviceId !== null && !!appActiveSeason.id,
    refetchOnWindowFocus: false,
  });


  return (
    <div>
      <h3 className="all-competions-header text-center p-4 text-light">
        <strong>المسابقات</strong>
        <span className="competitions-overlay"></span>
      </h3>
      <Container>
        {
          isLoading
            ? <CompetitionLoader />
            : isError || !data.length
              ? <ErrorMsg p={5} fs={"lg"} msg="لا يوجد بطولات" />
              : <div className="row all-competitions justify-content-around" data-testid="Competition-Component">
                {data.map((el) => (
                  <Link
                    to={{ pathname: `/${getServiceName(el.services?.[0])}${getSportRoute(el.sport_id?.id ?? sportId, el.services?.[0])}/tournament/${el.id}/overview`, state: { previousPathname: pathname } }}
                    className="competition-card d-flex flex-column col-4 col-md-2 border-radius-10 bg-black-color align-items-center mt-3 mx-2 py-3"
                    key={el.id}
                  >
                    <div className="img-rounded-circle text-center">
                      <img src={ImgURL(el.logo)} className="mt-3" alt="el.title" />
                    </div>
                    <div className="mt-2 text-center fs-xs">{el.title}</div>
                  </Link>
                ))}
              </div>
        }
      </Container>
    </div>
  );
}

export default TournamentsPage;
