import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Row, Col, Image } from "react-bootstrap";
import { convertFullDate } from "../../../globalFn";
import { AiOutlineClockCircle } from "react-icons/ai";
import SportsSectionHeader from "../../sportsSectionHeader/sportsSectionHeader.component";
import "./RelatedComponent.css";
import { getServiceName, getSportRoute } from "../../../Layout/helper";
import { ImgURL } from "../../../globalData";

function RelatedComponent(props) {
  const { pathname } = useLocation()

  return (
    <div className="bg-black-color p-2 related_container">
      <SportsSectionHeader title="اخبار متعلقة" />
      {props.relatedMedia.slice(0, 3).map((media) => (
        <Link
          to={{ pathname: `/${getServiceName(media.services?.[0])}${getSportRoute(media.sport_id, media.services?.[0])}/media/${media.id}`, state: { previousPathname: pathname } }}
          key={media.id}
        >
          <Row className="my-2" noGutters>
            <Col xs={4}>
              {
                media.type === 2 && <div className="play-icon-wrapper">
                  <div data-testid="MediaCard_video_play_icon" className="play-icon">
                    <i className="fa fa-play"></i>
                  </div>
                </div>
              }
              <Image src={ImgURL(media.type === 1 ? media.media : media.video_cover)} />
            </Col>
            <Col xs={7} className="mx-2">
              <small className="fs-xs">
                <AiOutlineClockCircle className="mx-1 fs-md" />
                {convertFullDate(media.created_at, 'ar')}
              </small>
              <p className="fs-sm text-cut-2 m-auto text-light">
                {media.title}
              </p>
            </Col>
          </Row>
        </Link>
      ))}
    </div>
  );
}

export default RelatedComponent;
