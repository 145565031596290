import { useState, useEffect, useContext } from "react";
import { Nav, Tab } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import RequireSubscription from "../../components/require-subscription";
import SeasonsDropdown from "../../components/SeasonsDropdown/SeasonsDropdown";
import { IDS, ImgURL } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import CupTournamentGroupsStanding from "../../components/TournamentStanding/CupTournamentGroupsStanding/CupTournamentGroupsStanding";
import UserPhoneNumModal from "../../components/Games/Modals/UserPhoneNumModal";
import { getUserPhoneNumbers } from "../../globalFn";
import TwistSettings from "../../TwistSettings.json";
import worldCupLogo from "../../assets/games/fan-club-logo.svg";
import UserPoints from "../../components/UserPoints/UserPoints";
import "./tournament.css";
import OtherSportsPlayers from "../../components/OtherSportsPlayer";
import TournamentOverview from '../../components/TournamentOverview/TournamentOverview';
import TournamentMatches from '../../components/TournamentMatches/TournamentMatches';
import TourStandingDetails from '../../components/TournamentStanding/TourStandingDetails';
import TournamentKnockOuts from '../../components/TournamentKnockOuts';
import TourStatsDetails from '../../components/TournamentStatistics/TourStatsDetails';
import TournamentHistory from '../../components/TournamentHistory/TournamentHistory';
import FanClub from '../../components/FanClub';
import { sharedComponentsContext } from "../../context/shared-context";
import api from "../../apis";
import { useQuery } from "@tanstack/react-query";
import handball from "../../assets/handballBanner.webp"
import tennis from "../../assets/tennisBanner.webp"
import squash from "../../assets/squashBanner.webp"
import basketball from "../../assets/basketballBanner.webp"
import football from "../../assets/matchBackground.png"
import { logFailedApi } from "../../globalFn";


const apiFn = (id, params, sportName) => {
  if (sportName !== 'squash') return api.tournament.getTournamentsDetails(id, params);
  return api.squash.getTournaments({ id })
}
const apiFnTwo = (params) => api.tournament.getTournamentsStanding(params);

const Tournament = ({ isSubscribed }) => {
  const id = useParams().id;
  const { pathname } = useLocation()
  const tab = useParams().tab || "overview";
  const [key, setKey] = useState(tab);
  const history = useHistory();
  const { seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);
  const { route, sportId, sportName } = useServiceAndSport();
  const [modalShow, setModalShow] = useState(true);
  const [foundUserPhoneNumber, setFoundUserPhoneNumber] = useState("");

  const othersportsBannersSrc = {
    handball,
    tennis,
    squash,
    basketball,
  }

  const { isLoading, isError, data = {} } = useQuery({
    queryKey: ["tournament-details", pathname],
    queryFn: () => apiFn(id, { sportId }, sportName),
    onError: () => logFailedApi("tournament-details"),
    enabled: sportName !== null,
    select: ({ data }) => {
      if (sportName !== 'squash') return data;
      const { start_date, image, name_ar } = data;
      const customizedSquashData = {
        ...data[0],
        service: {
          id: 10,
          title: "othersports",
          active: 1,
        },
        sport_id: {
          active: 1,
          created_at: start_date,
          id: 7,
          image: image,
          is_favourite: false,
          title: "Squash",
          type: "Individual",
        },
        title: name_ar
      };
      return customizedSquashData;
    },
    refetchOnWindowFocus: false,
  });

  const { isLoading: isLoadingTournamentStanding, isError: isErrorTournamentStanding, data: tournamentStanding = {} } = useQuery({
    queryKey: ["tournament-standing", sportId, activeSeason.id, id],
    queryFn: () => apiFnTwo({ sport_id: sportId, season_id: activeSeason.id, tournament_id: id }),
    onError: () => logFailedApi("tournament-standing"),
    select: ({ data, tournament }) => {
      const competitionType = data[0].competition_type.name;
      const isCup = competitionType === "cup";
      const standing = isCup ? Object.values(data[0]["Group Stage"] || []) :
        data[0].data.map(el => ({ ...el, checked: false }));
      return {
        data: standing,
        competitionType,
        tournamentDetails: tournament,
      }
    },
    enabled: sportId !== null && activeSeason.id !== null,
    refetchOnWindowFocus: false,
  });

  const isFootball = sportId === IDS.SPORTS.FOOTBALL || sportId === undefined

  const handelTabClick = (tab) => {
    setKey(tab)
    history.push(`${route}/tournament/${id}/${tab}`, {
      previousPathname: history.location.pathname,
    });
  };

  const addClassDependingOnTournamentID = (id) => {
    if (id === "726") document.body.classList.add("worldCup-styles");
  };

  useEffect(() => {
    if (!id) return;
    const userPhoneNumbers = getUserPhoneNumbers();
    if (userPhoneNumbers.length > 0) setFoundUserPhoneNumber(userPhoneNumbers[0]);
    addClassDependingOnTournamentID(id);

    return () => document.body.classList.remove("worldCup-styles");
  }, [id]);

  return (
    <div>
      {!foundUserPhoneNumber && id === "7940" && (
        <UserPhoneNumModal
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      )}
      {isLoading ? <HeaderInfoComponentLoader /> :
        !isLoading && isError ?
          <ErrorPage error="لا يوجد معلومات عن البطولة" /> :
          <div className="Tournament-details-header-container">
            {
              id === "726" &&
              TwistSettings["user-points"] && <div className="Tournament-user-points-conatiner">
                <UserPoints />
              </div>
            }
            <div style={{ backgroundImage: `url(${sportName ? othersportsBannersSrc[sportName] : football})` }} className="Tournament-details-header"></div>
            <div className="Tournament-details-header-desc text-center">
              <img src={id == "726" ? worldCupLogo : ImgURL(data?.logo)} alt="" />
              <div className="text-light text-center mt-3 fs-lg">
                <strong>{data?.title}</strong>
              </div>
            </div>
          </div>
      }
      <div className="container"></div>
      <Tab.Container
        id="left-tabs-example"
        activeKey={key}
        onSelect={(k) => setKey(k)}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Nav variant="tabs" className="mb-3  flex-nowrap fs-sm .tournament-nav">
          <div className="d-flex justify-content-center mx-auto">
            <Nav.Item>
              <Nav.Link
                eventKey="overview"
                onClick={() => handelTabClick("overview")}
              >
                نظرة عامة
              </Nav.Link>
            </Nav.Item>
            {sportId === 7 &&
              <Nav.Item>
                <Nav.Link
                  eventKey="players"
                  onClick={() => handelTabClick("players")}
                >
                  اللاعبون
                </Nav.Link>
              </Nav.Item>
            }
            <Nav.Item>
              <Nav.Link
                eventKey="matches"
                onClick={() => handelTabClick("matches")}
              >
                المباريات
              </Nav.Link>
            </Nav.Item>
            {
              // id === "7940" && TwistSettings["world-cup-games-and-fan-club"] && <Nav.Item>
              //   <Nav.Link
              //     eventKey="games"
              //     onClick={() => handelTabClick("games")}
              //   >
              //     الألعاب
              //   </Nav.Link>
              // </Nav.Item>
            }
            {isFootball && !data.has_group &&
              <Nav.Item>
                <Nav.Link
                  eventKey="standing"
                  onClick={() => handelTabClick("standing")}
                >
                  الترتيب
                </Nav.Link>
              </Nav.Item>
            }
            <RequireSubscription
              isSubscribed={id === "7940" ? true : isSubscribed}
            >
              {!!data.has_tree && !sportName && (
                <Nav.Item>
                  <Nav.Link
                    eventKey="knockouts"
                    onClick={() => handelTabClick("knockouts")}
                  >
                    الادوار الاقصائية
                  </Nav.Link>
                </Nav.Item>
              )}
              {
                (isFootball || sportId === 3) && <Nav.Item>
                  <Nav.Link
                    eventKey="stats"
                    onClick={() => handelTabClick("stats")}
                  >
                    احصائيات
                  </Nav.Link>
                </Nav.Item>
              }
              <Nav.Item>
                <Nav.Link
                  eventKey="history"
                  onClick={() => handelTabClick("history")}
                >
                  تاريخ البطولة
                </Nav.Link>
              </Nav.Item>
              {id === "726" && TwistSettings["world-cup-games-and-fan-club"] && (
                <>
                  <Nav.Item>
                    <Nav.Link
                      eventKey="fanClub"
                      onClick={() => handelTabClick("fanClub")}
                    >
                      نادي المشجعين
                    </Nav.Link>
                  </Nav.Item>

                </>
              )}
            </RequireSubscription>
          </div>
        </Nav>
        <Nav
          className={`container mt-3 ${tab === "games" || tab === "fanClub" ? "d-none" : ""}`}>
          <SeasonsDropdown tournament_id={data?.id} tournament_data={data} />
        </Nav>
        {!isLoadingTournamentStanding &&
          <Tab.Content>
            <Tab.Pane eventKey="overview">
              <TournamentOverview
                toLocalTab={handelTabClick}
                season_id={activeSeason.id}
                data={tournamentStanding.data}
                isLoading={isLoadingTournamentStanding}
                isError={isErrorTournamentStanding}
                competitionType={tournamentStanding.competitionType}
                tournamentDetails={tournamentStanding.tournamentDetails}
              />
            </Tab.Pane>
            {sportId === 7 &&
              <Tab.Pane eventKey="players">
                <OtherSportsPlayers
                  toLocalTab={handelTabClick}
                  season_id={activeSeason.id}
                  sportName={sportName}
                  id={id}
                />
              </Tab.Pane>
            }
            <Tab.Pane eventKey="matches">
              <TournamentMatches sport_id={sportId} tournament_id={id} />
            </Tab.Pane>
            {!data.has_group &&
              <Tab.Pane eventKey="standing">
                {tournamentStanding.competitionType === "cup" ? (
                  <CupTournamentGroupsStanding
                    sport_id={sportId}
                    season_id={activeSeason.id}
                    isLoading={isLoadingTournamentStanding}
                    isError={isErrorTournamentStanding}
                    data={tournamentStanding.data}
                    tournamentDetails={tournamentStanding.tournamentDetails}
                  />
                ) : (
                  <TourStandingDetails
                    sport_id={sportId}
                    season_id={activeSeason.id}
                    data={tournamentStanding.data}
                    isLoading={isLoadingTournamentStanding}
                    isError={isErrorTournamentStanding}
                  />
                )}
              </Tab.Pane>
            }
            <Tab.Pane eventKey="stats">
              <TourStatsDetails
                isLoading={isLoadingTournamentStanding}
                isError={isErrorTournamentStanding}
                data={tournamentStanding.data}
                tournamentDetails={tournamentStanding.tournamentDetails} />
            </Tab.Pane>
            <Tab.Pane eventKey="knockouts">
              <TournamentKnockOuts
                sport_id={sportId}
                season_id={activeSeason.id}
                id={id}
              />
            </Tab.Pane>
            <Tab.Pane eventKey="history">
              <TournamentHistory />
            </Tab.Pane>
            <Tab.Pane eventKey="fanClub">
              <FanClub
                isLoading={isLoadingTournamentStanding}
                isError={isErrorTournamentStanding}
                data={tournamentStanding.data}
                subscriptionModal={modalShow} />
            </Tab.Pane>
            {/* <Tab.Pane eventKey="games">
            <Games />
          </Tab.Pane> */}
          </Tab.Content>
        }
      </Tab.Container>
    </div>
  );
};

export default Tournament;
