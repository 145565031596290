import HttpHelper from './helper';

export const matchesEndpoints = {
  get: (params) => HttpHelper.baseAxios
    .get(`/matches`, { params })
    .then((res) => res?.data),

  getMatchLineUp: (match_id) => HttpHelper.baseAxios
    .get(`/lineup/${match_id}`)
    .then((res) => res?.data),

  getMatchStatistics: (params) => HttpHelper.baseAxios
    .get(`/matchStatistics`, { params })
    .then((res) => res?.data),

  getTournamentsMatches: (params) => HttpHelper.baseAxios
    .get(`/tournament_matches`, { params })
    .then((res) => res?.data),

  getMatchEvents: (params) => HttpHelper.baseAxios
    .get(`/match_events`, { params })
    .then((res) => res?.data),

  getBusyDays: (params) => HttpHelper.baseAxios
    .get(`/matches_dates`, { params })
    .then((res) => res?.data),
};