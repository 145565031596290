import { Container } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import TwistSettings from '../../../TwistSettings.json'
import "./FanClubLoader.css"

const FanClubLoader = () => {
    const skeletonArr = Array(8).fill("card")
    return (
        <Container fluid="md" data-testid="media-list-loading">
            <SkeletonTheme color={TwistSettings["twist-ui"] ? "#004F90" : "#323232"} highlightColor="#ecebeb">
                <div className='row fan-club-skeleton-container'>
                    <div className='col-md-12 col-12 d-flex flex-column justify-content-center align-items-center'>
                        {skeletonArr.map(item => (
                            <div className="row fan-club-skeleton-row">
                                <div className='team-skeleton-card col-md-6 col-6 pt-2'>
                                    <Skeleton height={'60px'} width={'100%'} className="p-2" />
                                </div>
                                <div className='team-skeleton-card col-md-6 col-6 pt-2'>
                                    <Skeleton height={'60px'} width={'100%'} className="p-2" />
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

            </SkeletonTheme>
        </Container >

    );
};

export default FanClubLoader;