import React, { useState, useCallback, useRef } from "react";
import { Container, Card, Row, Col, Image } from "react-bootstrap";
import { GoCalendar } from "react-icons/go";
import { convertFullDate } from "../../../../globalFn";
import ArticleCardComponentLoader from "./ArticleCardComponentLoader";
import ModalComponent from "../../components/modals/ModalComponent";
import { useBottomScrollListener } from "react-bottom-scroll-listener";
import tipsThumbnail from "../../assets/AliMAzharTipsThumbNail.png"
import "./ArticleCardComponent.css";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { logFailedApi } from "../../../../globalFn";

const apiFn = (contentType, pageNum) => api.fitness.aliMazharContent.getAliTipsOrVideos(contentType, pageNum)

const ArticleCardComponent = () => {
  const [openArticle, setArticleOpen] = useState(false);
  const [articleText, setArticleText] = useState();
  const [tips, setTips] = useState([]);
  const [maxPagesNum, setMaxPagesNum] = useState(1);

  const pageNum = useRef(1)
  const fitnessMediaBaseUrl = process.env.REACT_APP_FITNESS_BASE_URL


  const { data, isError, isFetching, isLoading, refetch } = useQuery({
    queryKey: ["ali-mazhar-nutrition-tips"],
    queryFn: () => apiFn("TIPS", pageNum.current),
    refetchOnWindowFocus: false,
    onSuccess: ({ results, pagesNumber }) => {
      setTips(prev => [...prev, ...results])
      setMaxPagesNum(pagesNumber)
    },
    onError: () => logFailedApi("ali-mazhar-nutrition-tips"),
  })
  const onOpenArticleModal = (articleText) => {
    setArticleText(articleText);
    setArticleOpen(prev => !prev);
  };

  const handleOnDocumentBottom = useCallback(() => {
    if (pageNum.current < maxPagesNum && !isFetching) {
      pageNum.current++
      refetch()
    }
  }, [maxPagesNum]);

  useBottomScrollListener(handleOnDocumentBottom);

  if (isLoading || isError) {
    return <ArticleCardComponentLoader />;
  } else {
    return (
      <Container className="articlesCardContainer" fluid="lg">
        <Row>
          {tips?.map((article, index) => (
            <Col xs={12} lg={6} key={index}>
              <Card
                className="my-2"
                onClick={() =>
                  onOpenArticleModal(article.description)
                }
              >
                <Row noGutters>
                  <Col xs={12} md={5}>
                    <Card.Img
                      variant="top p-1"
                      src={encodeURI(`${fitnessMediaBaseUrl}/${article.image}`)}
                    />
                  </Col>
                  <Col className="m-auto">
                    <Card.Body className="p-2">
                      <Card.Text className="fs-md">
                        {article.description.substring(0, 210) + "..."}
                      </Card.Text>
                    </Card.Body>
                    <Card.Footer>
                      <label className="fs-sm float-right">
                        <GoCalendar className="fs-md mx-1" color="#62D86E" />
                        {convertFullDate(article.date, 'ar')}
                      </label>
                    </Card.Footer>
                  </Col>
                </Row>
              </Card>
            </Col>
          ))}

          <p
            className={`fs-md w-50 mx-auto text-center my-3 ${isFetching ? "d-block" : "d-none"
              }`}
          >
            {"برجاء الإنتظار.."}
          </p>
          <ModalComponent
            content={
              <Container
                style={{ background: "rgb(33, 33, 33)" }}
                className="p-0"
              >
                <Row noGutters>
                  <Col xs={12} lg={5}>
                    <Image
                      src={tipsThumbnail}
                      className="img-fluid p-2"
                    />
                  </Col>
                  <Col className="m-auto">
                    <p className="m-2 text-center">{articleText}</p>
                  </Col>
                </Row>
              </Container>
            }
            open={openArticle}
            toggleModal={onOpenArticleModal}
          />
        </Row>
      </Container>
    );
  }
};

export default ArticleCardComponent;
