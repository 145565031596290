import HttpHelper from './helper';

export const squashEndpoints = {
  getTournaments: (params) => HttpHelper.baseSquashAxios
    .get('/squashTournaments', { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getSquashTournamentPlayers: (id) => HttpHelper.baseSquashAxios
    .get(`/squashTournaments/${id}/players`)
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getTournamentMatches: (params) => HttpHelper.baseSquashAxios
    .get('/squashMatches', { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getMatchCalender: () => HttpHelper.baseSquashAxios
    .get('/squashCalendar')
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getPlayerDetails: (params) => HttpHelper.baseSquashAxios
    .get('/squashPlayers', { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),

  getMatchDetails: (params) => HttpHelper.baseSquashAxios
    .get(`/squashMatches`, { params })
    .then((res) => res?.data)
    .catch((err) => err?.response?.data),
};
