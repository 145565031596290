import { useHistory } from "react-router-dom";
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImgURL } from '../../globalData';
import { getServiceName, getSportRoute } from '../../Layout/helper';

const TournamentOverviewStats = ({
    isLoading,
    isError,
    data,
    tournamentDetails,
    toLocalTab,
    competitionType
}) => {
    const history = useHistory();

    const handleRowClick = (serviceId, sportId, id) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${id}`, { previousPathname: history.location.pathname });
    }

    const standingTableRow = (standingData) => {
        return (
            standingData.sort((a, b) => b.point - a.point).map(
                (team, i) => i <= 7 &&
                    <tr key={team.id} onClick={() => handleRowClick(team.services[0], tournamentDetails.sport_id, team.team_id)} className='table-clickable-row-animated text-center'>
                        <td className="px-0" style={{ width: '20px' }}>{i + 1}</td>
                        <th scope="row" className={window.document.body.dir === 'rtl' ? 'px-0 text-right team-name' : 'text-left team-name'}><img src={ImgURL(team.team_logo)} style={{ width: '20px' }} alt="team logo" /><span className="mx-2 fs-xxs ">{team.team_name}</span></th>
                        <td className="px-0">{team.goals_in}</td>
                        <td className="px-0">{team.goals_out}</td>
                        <td className="px-0">{team.clean_sheet}</td>
                        <td className="px-0">{team.played}</td>
                        {/* <td>{team.attempts}</td> */}
                        {/* <td>{team.dribbles}</td> */}
                    </tr>
            ))
    }

    const handleRenderStanding = () => {
        if (competitionType === "cup") {
            const teams = Object.values(data).flat();
            return standingTableRow(teams)
        } else {
            return standingTableRow(data)
        }
    }

    return (
        <>
            {!!data.length &&
                <div className='bg-black-color white-color fs-xs'>
                    <div className='d-flex justify-content-between fs-md p-2'>
                        <div>احصائيات</div>
                        <div style={{ direction: 'rtl' }}>
                            <button className={`swipe-animation fa fa-chevron-left btn white-color`} aria-hidden="true" onClick={() => toLocalTab('stats')}></button>
                        </div>
                    </div>
                    <table className="table-borderless bg-black-color table-striped table-dark white-color">
                        <thead>
                            <tr className='text-center'>
                                <th className="px-0" scope="col">المركز</th>
                                <th className="px-1" scope="col" style={{ textAlign: 'start' }}>الفريق</th>
                                <th className="px-0" scope="col"> أهداف له</th>
                                <th className="px-1" scope="col"> أهداف عليه</th>
                                <th className="px-0" scope="col">شباك نظيفة</th>
                                <th className="px-1" scope="col">مباريات</th>
                                {/* <th scope="col">محاولات على المرمى</th> */}
                                {/* <th scope="col">المراوغات</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            {isLoading ?
                                <tr>
                                    <td colSpan={7} className='p-0'>
                                        <LoadingSpinnner p={5} fs={'md'} />
                                    </td>
                                </tr> :
                                isError ?
                                    <tr>
                                        <td colSpan={7} className='p-0'>
                                            <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={'lg'} />
                                        </td>
                                    </tr>
                                    : !data.length ?
                                        <tr>
                                            <td colSpan={7} className='p-0'>
                                                <ErrorMsg msg="لا يوجد احصائيات" p={5} fs={'lg'} />
                                            </td>
                                        </tr>
                                        : handleRenderStanding()
                            }
                        </tbody>
                    </table>
                </div>
            }
        </>
    )
}

export default TournamentOverviewStats;
