import React, { useEffect, useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { ImgURL } from "../../globalData";
import "./RadialTimer.scss";

const timerProps = () => {
  // if (window.matchMedia("(max-width: 500px)").matches) {
  //   return {
  //     isPlaying: true,
  //     size: 100,
  //     strokeWidth: 6
  //   }
  // } else {
  return {
    isPlaying: true,
    size: 120,
    strokeWidth: 6,
  };
  // }
};

const specificEventsForShowImage = [16, 24];


const getTotalTime = (event_time) => {
  const hasOverTime = !!event_time?.includes('+');
  const basicTime = hasOverTime ? +event_time.split('+')[0] : +event_time;
  const overTime = hasOverTime ? +event_time.split('+')[1] : 0;
  const totalTime = basicTime + overTime;
  return totalTime;
}

const getTimedEvents = (eventsArr, maxEventTime) => {
  const eventsMin = Array.from({ length: maxEventTime });
  eventsArr.map(event => {
    const totalTime = getTotalTime(event.event_time)
    return specificEventsForShowImage.includes(event?.event_id) ?
      eventsMin.splice(totalTime, 1, event) : eventsMin
  }
    // Number(event.event_time) > 45
    //   ? eventsMin.splice(Math.round(event.event_time - 45) - 1, 1, event)
    //   : eventsMin.splice(Number(event.event_time - 1), 1, event)

  );
  return eventsMin;
};

const RadialTimer = ({
  matchEvents,
  isFootball,
  initialStart,
  isLoading,
  child,
  basicRoundTime
}) => {

  const maxEventTime = matchEvents.reduce((prev, curr) => {
    const totalTime = getTotalTime(curr.event_time)
    if (totalTime > prev) prev = totalTime;
    return prev
  }, basicRoundTime)

  const [windowWidth, setWindowWidth] = useState(null);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  if (!isFootball) return child
  return (
    <>
      {isLoading ?
        <div className="match__radialTimerLoading w-100"></div>
        // <div className="match__radialTimerLoading w-100">... يتم التحميل</div>
        :
        <CountdownCircleTimer
          {...timerProps()}
          strokeLinecap="square"
          colors={[["#d9d9d9"]]}
          trailColor="#759409"
          duration={maxEventTime * 60}
          initialRemainingTime={initialStart / 1000}
          // duration={maxEventTime * 60}
          // initialRemainingTime={0}
          children={child}
        ></CountdownCircleTimer>
      }
      <div className="radial-timer">
        {isLoading ? (
          <div></div>
          // <div>...يتم التحميل</div>
        ) :
          (
            getTimedEvents(matchEvents, maxEventTime).map((event, index) =>
              specificEventsForShowImage.includes(event?.event_id) &&
              <>
                <img key={index}
                  className="radial-timer-event"
                  style={{ transform: `rotate(${index * (360 / maxEventTime)}deg) translate(${windowWidth > 767.98 ? '3.75em' : '4.85em'})` }}
                  src={ImgURL(event.event_image)} alt="event" />
              </>
            )
          )
          //  (
          //   getTimedEvents(matchEvents).map((event) =>
          //     specificEventsForShowImage.includes(event?.event_id) ? (
          //       <img className="radial-timer-event" src={ImgURL(event.event_image)} alt="event" />
          //     ) :
          //       <img className="radial-timer-event" style={{ height: 0 }} alt="" />
          //   ))
        }
      </div>
    </>
  );
};

export default RadialTimer;
