import { useContext, useEffect } from 'react'
import { Dropdown } from 'react-bootstrap'
import "./SeasonsDropdown.css"
import api from '../../apis'
import { sharedComponentsContext } from '../../context/shared-context'
import { useQuery } from '@tanstack/react-query'
import { logFailedApi } from '../../globalFn'

const apiFn = (params) => api.seasons.getSeasons(params);

const SeasonsDropdown = ({
    tournament_id,
    classes,
    onChange = () => { },
}) => {

    const { seasonInfo: { activeSeason }, setInitialSharedValues } = useContext(sharedComponentsContext);

    const { isLoading, data = [] } = useQuery({
        queryKey: ["seasons", tournament_id],
        queryFn: () => apiFn({ tournament_id }),
        select: (data) => data,
        onError: () => logFailedApi("seasons"),
        refetchOnWindowFocus: false,
    });


    return (
        isLoading
            ? <Dropdown>
                <Dropdown.Toggle variant="secondary" id="dropdown-basic" data-testid="seasons-dropdown-loading">
                    ...Loading
                </Dropdown.Toggle>
            </Dropdown>
            : <Dropdown className='season-dropdown m-1'>
                <Dropdown.Toggle
                    variant="secondary"
                    id={`dropdown-button-drop-down`} drop='down'
                    className={classes}
                    data-testid="seasons-dropdown-selected-item"
                >
                    {activeSeason.title ?? 'اختر موسم'}
                </Dropdown.Toggle>

                <Dropdown.Menu className='text-light'>
                    {
                        data?.data?.map((season) => <div key={season.id}>
                            <Dropdown.Item
                                data-testid="seasons-dropdown-item"
                                onClick={() => {
                                    setInitialSharedValues(prev => {
                                        return {
                                            ...prev,
                                            seasonInfo: {
                                                ...prev.seasonInfo,
                                                activeSeason: season
                                            }
                                        }
                                    })
                                    onChange(season)
                                }}
                            >{season.title}</Dropdown.Item>
                        </div>)
                    }
                </Dropdown.Menu>
            </Dropdown>
    )
}


export default SeasonsDropdown