import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { IDS } from '../globalData';
import { getServiceName } from '../Layout/helper';
import { getSubsCookiesInfo } from './helper';


export default function useServiceGuard(loading, itemServicesIds = []) {
    const history = useHistory();
    const { serviceName } = useParams();
    const routeServiceId = IDS.SERVICES[serviceName?.toUpperCase()]

    useEffect(() => {
        if (!loading) {
            const { servicesIdsHaveMsisdns } = getSubsCookiesInfo()
            const allowedServices = servicesIdsHaveMsisdns.filter(id => itemServicesIds.includes(id))

            if (allowedServices.length !== 0 || itemServicesIds.length === 0) {
                if (routeServiceId === allowedServices[0]) return;
                history.replace({
                    pathname: history.location.pathname.replace(getServiceName(allowedServices[0]), getServiceName(routeServiceId)),
                    state: { previousPathname: history.location.pathname }
                })
            } else {
                history.replace({
                    pathname: `/${getServiceName(routeServiceId)}/subscribe`,
                    state: { previousPathname: history.location.pathname }
                })
            }
        }
    }, [loading])
}

