import { useState, useContext } from "react";
import { Nav, Tab } from "react-bootstrap";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import HeaderInfoComponentLoader from "./HeaderInfoComponentLoader";
import "./player.css";
import PlayerOverview from "../../components/PlayerOverview/PlayerOverview";
import PlayerStatistics from "../../components/PlayerStatistics/PlayerStatistics";
import PlayerPrizes from "../../components/PlayerPrizes/PlayerPrizes";
import ErrorPage from "../../components/ErrorPage/ErrorPage";
import { useHistory } from "react-router-dom";
import LoadingSpinnner from "../../components/loadingSpinner/loadingSpinner";
import { ImgURL } from "../../globalData";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import useServiceGuard from "../../hooks/useServiceGuard";
import PrizesCarousel from "./PrizesCarousel";
import AllMedia from "../../components/News/AllMedia";
import { sharedComponentsContext } from "../../context/shared-context";
import { useQuery } from "@tanstack/react-query";
import api from "../../apis";
import { logFailedApi } from "../../globalFn";

const apiFn = (id, sportName) => {
  if (sportName === 'squash') return api.squash.getPlayerDetails({ id })
  const isIndividualSport = ["tennis"].includes(sportName);
  if (isIndividualSport) return api.teams.getTeamDetails(id);
  if (!isIndividualSport) return api.player.getPlayerDetails(id);
}

const Player = () => {
  const { seasonInfo: { activeSeason }, setInitialSharedValues } = useContext(sharedComponentsContext);
  const [key, setKey] = useState("Overview");
  const id = useParams().id;
  const tab = useParams().tab || "overview";
  const { serviceName, sportName, sportId } = useServiceAndSport();
  const isIndividualSports = ['squash', 'tennis'];
  const history = useHistory();
  const { isLoading, isError, data = {} } = useQuery({
    queryKey: ["player-details", id, sportName, serviceName],
    queryFn: () => apiFn(id, sportName),
    select: ({ data }) => {
      if (!sportName) return data;
      if (sportName === 'tennis') return { ...data, image: data?.logo };
      const { nameAr: name, country: { flag, nameAr: title } } = data[0];
      return {
        ...data[0],
        nationality: { flag, title },
        name,
        services: [10]
      }
    },
    onSuccess: ({ current_team, teams }) => {
      setInitialSharedValues(prev => {
        return {
          ...prev,
          playerDetails: {
            ...prev.playerDetails,
            currentTeam: current_team,
            teams,
            currentTeamTournaments: current_team?.tournaments,
            currentTeamActiveTournament: current_team?.tournaments?.sort((a, b) => a.type - b.type)[0],
          }
        }
      })
    },
    onError: () => logFailedApi("player-details"),
    enabled: sportName !== null,
    refetchOnWindowFocus: false,
  });
  useServiceGuard(isLoading, data?.services);

  const handelTabClick = (tab) => {
    const sportUrl = `${sportName ? `${sportName}/` : ""}`;
    history.push(`/${serviceName}/${sportUrl}player/${id}/${tab}`, { previousPathname: history.location.pathname });
  };

  const hasBackground = !!data.current_team?.background;


  return (
    <div>
      {isLoading ? (
        <HeaderInfoComponentLoader />
      ) :
        isError || !Object.keys(data).length ? (
          <ErrorPage p={5} fs={"md"} msg="لا يوجد معلومات عن الاعب" />
        ) : (
          <div className={`player-details-header-container ${hasBackground ? '' : 'without-team-bg'}`} data-testid="player-details-wrapper">
            <div className="overlay"></div>
            <div
              className="player-details-header"
              style={{ backgroundImage: `${hasBackground ? `url(${data.current_team?.background})` : ''}` }}
            ></div>
            <div className="player-details-img">
              <img src={ImgURL(data.image)} alt="player" />
            </div>
            <div className="player-details-header-info ps-sm-3 ps-md-0">
              <div>
                <div className="player-details-header-info-num ms-md-5">
                  {data.shirt_number}
                </div>
                <div className="player-details-name fs-lg ms-md-5 text-light">
                  <strong>{data.name}</strong>
                </div>
              </div>
              {
                data.current_team?.logo &&
                <img
                  src={
                    ImgURL(data.current_team?.logo)
                  }
                  className="player-details-current-team-img ms-2 ms-md-0"
                  alt="team"
                />
              }
            </div>
            <div className="player-details-header-champions-market d-flex justify-content-start">
              {/* <div className="d-none d-md-flex justify-content-around align-items-center player-details-header-champions p-3 mx-1">
                <PrizesCarousel PlayerDetails={data} />
              </div> */}
              {
                data.market_value && <div className="player-details-header-market mx-0 mx-md-4 p-sm-2 p-md-3 p-md-3 text-center fs-xl">
                  <span className="text-nowrap">{
                    data.market_value > 1000000
                      ? `${Number(data.market_value / 1000000)} م`
                      : `${Number(data.market_value / 1000)} ألف`
                  }</span>
                </div>
              }
            </div>
          </div>
        )}
      <Tab.Container
        id="left-tabs-example"
        defaultActiveKey={tab || "overview"}
        onSelect={(k) => setKey(k)}
        activeKey={tab || key}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Nav
          variant="tabs"
          className="mb-3 d-flex justify-content-center flex-nowrap fs-sm"
        >
          <Nav.Item>
            <Nav.Link
              eventKey="overview"
              onClick={() => handelTabClick("overview")}
            >
              نظرة عامة
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link eventKey="news" onClick={() => handelTabClick("news")}>
              أخر الأخبار
            </Nav.Link>
          </Nav.Item>
          {!isIndividualSports.includes(sportName) &&
            <Nav.Item>
              <Nav.Link eventKey="stats" onClick={() => handelTabClick("stats")}>
                احصائيات
              </Nav.Link>
            </Nav.Item>
          }
          <Nav.Item>
            <Nav.Link
              eventKey="prizes"
              onClick={() => handelTabClick("prizes")}
            >
              جوائز
            </Nav.Link>
          </Nav.Item>
        </Nav>

        <Tab.Content>
          <Tab.Pane eventKey="overview">
            <PlayerOverview
              toLocalTab={setKey}
              season_id={activeSeason.id}
              playerDetails={data}
              isFetchingPlayerDetails={isLoading}
              PlayerDetailsFailed={isError}
            />
          </Tab.Pane>
          <Tab.Pane eventKey="news">
            <Container>
              <AllMedia
                sport_id={sportId}
                season_id={activeSeason.id}
                player_id={id}
                items_count={16}
                latest={true}
                type={1}
              />
            </Container>
          </Tab.Pane>
          {!isIndividualSports.includes(sportName) &&
            <Tab.Pane eventKey="stats">
              <PlayerStatistics
                sport_id={sportId}
                season_id={activeSeason.id}
                player_id={id}
                isLoading={isLoading}
              />
            </Tab.Pane>
          }
          <Tab.Pane eventKey="prizes">
            {isLoading ? (
              <LoadingSpinnner fs={"lg"} p={5} />
            ) : (
              <PlayerPrizes prizes={data.champions} />
            )}
          </Tab.Pane>
        </Tab.Content>
      </Tab.Container>
    </div>
  );
};

export default Player;
