import HttpHelper from './helper';

export const playerEndpoints = {
  getTransferHistory: (player_id) => HttpHelper.baseAxios
    .get(`/player-transfer-history/${player_id}`)
    .then((res) => res?.data),

  getPlayerDetails: (player_id) => HttpHelper.baseAxios
    .get(`/players/${player_id}`)
    .then((res) => res?.data),

  getPlayerSeasonStatistics: (params) => HttpHelper.baseAxios
    .get(`/playerSeasons_statistics`, { params })
    .then((res) => res?.data),

  getPlayerStatistics: (params) => HttpHelper.baseAxios
    .get(`/playerStatistics`, { params })
    .then((res) => res?.data)
};