import React from "react";
import { Container, Card, Row, Col } from "react-bootstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

export default function NutritionTipsWebLoader() {
  return (
    <Container className="articlesCardContainer ">
      <SkeletonTheme color="#004f90" highlightColor="#fff">
        <Row>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={12} md={6}>
            <Card className="border-radius-10 my-2">
              <Row noGutters>
                <Col xs={12} md={5}>
                  <Skeleton height={200} />
                </Col>
                <Col className="m-auto">
                  <Card.Body className="p-2">
                    <Card.Text className="fs-16 color-ffffff">
                      <Skeleton count={1} width={100} />
                      <Skeleton count={5} />
                    </Card.Text>
                  </Card.Body>
                  <Card.Footer>
                    <label className="fs-14 color-7EBD19 float-right">
                      <Skeleton count={1} width={100} />
                    </label>
                  </Card.Footer>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </SkeletonTheme>
    </Container>
  );
}
