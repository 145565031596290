import { Children } from "react";
import { Container } from "reactstrap";
import privacyImg from "../../assets/privacy-img.png";
import { privacyTermsAR, privacyTermsEN } from "./helper";
import "./index.css";

function TwistPrivacy() {
  return (
    <div className='twist-privacy'>
      <figure className='privacy-header'>
        <img src={privacyImg} alt='' />
        <figcaption>Twist Sports Privacy Policy</figcaption>
      </figure>
      <Container>
        <ul className='privacy-body'>
          {Children.toArray(
            privacyTermsEN.map((term) => <li className='dirEn'>{term}</li>),
          )}
          {Children.toArray(privacyTermsAR.map((term) => <li>{term}</li>))}
        </ul>
      </Container>
    </div>
  );
}

export default TwistPrivacy;
