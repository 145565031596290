import HttpHelper from './helper';

export const subscriptionEndpoints = {
  getHeaderEnrichement: () => HttpHelper.etisalatsportsAxios
    .get('/HeaderEnrichemnt/')
    .then((res) => {
      if (res?.status === 200) return res?.data;
      throw new Error('Call Error!');
    }),



  // some times doesnt need to be call inside each service so i moved it our incase we need it alone
  // ===========================
  decryptSubParam: (param) => {
    const data = new FormData();
    data.append('headerParam', param);

    return HttpHelper.twistSubsAxios
      .post('rakam1/header-enrichment/decrypt', data)
      .then((res) => res.data)
      .catch((err) => err.response.data)
  },
  // ===========================

  local: {
    decryptSubParam: (param) => {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.twistSubsAxios
        .post('rakam1/header-enrichment/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.twistSubsAxios
        .post('rakam1/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    sendCode: (params) => HttpHelper.twistSubsAxios
      .post('rakam1/subscription/send-code', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkPin: (params) => HttpHelper.twistSubsAxios
      .post('rakam1/subscription/check/pin', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('rakam1/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('rakam1/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    }
  },

  international: {
    decryptSubParam: (param) => {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.twistSubsAxios
        .post('rakam1/header-enrichment/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.twistSubsAxios
        .post('elmal3ab/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    sendCode: (params) => HttpHelper.twistSubsAxios
      .post('elmal3ab/subscription/send-code', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkPin: (params) => HttpHelper.twistSubsAxios
      .post('elmal3ab/subscription/check/pin', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('elmal3ab/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('elmal3ab/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    }
  },

  africa: {
    decryptSubParam: (param) => {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.twistSubsAxios
        .post('rakam1/header-enrichment/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.twistSubsAxios
        .post('el3almy/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    sendCode: (params) => HttpHelper.twistSubsAxios
      .post('el3almy/subscription/send-code', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkPin: (params) => HttpHelper.twistSubsAxios
      .post('el3almy/subscription/check/pin', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('el3almy/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('el3almy/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    }
  },

  othersports: {
    decryptSubParam: (param) => {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.twistSubsAxios
        .post('rakam1/header-enrichment/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.twistSubsAxios
        .post('othersports/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    sendCode: (params) => HttpHelper.twistSubsAxios
      .post('othersports/subscription/send-code', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkPin: (params) => HttpHelper.twistSubsAxios
      .post('othersports/subscription/check/pin', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('othersports/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('othersports/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    }
  },

  meloukelkora: {
    decryptSubParam: (param) => {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.twistSubsAxios
        .post('rakam1/header-enrichment/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.twistSubsAxios
        .post('rakam1/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    sendCode: (params) => HttpHelper.twistSubsAxios
      .post('mlokElkora/subscription/send-code', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkPin: (params) => HttpHelper.twistSubsAxios
      .post('mlokElkora/subscription/check/pin', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('mlokElkora/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('mlokElkora/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    }
  },

  fitness: {
    decryptSubParam: (param) => {
      const data = new FormData();
      data.append('headerParam', param);

      return HttpHelper.twistSubsAxios
        .post('rakam1/header-enrichment/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    decryptSubId: (subId) => {
      const data = new FormData();
      data.append('subId', subId);

      return HttpHelper.twistSubsAxios
        .post('rakam1/sub-id/decrypt', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    sendCode: (params) => HttpHelper.twistSubsAxios
      .post('befit/subscription/send-code', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkPin: (params) => HttpHelper.twistSubsAxios
      .post('befit/subscription/check/pin', null, { params })
      .then((res) => res.data)
      .catch((err) => err.response.data),

    checkSubsByMsisdn: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('befit/subscription/checkSubscription', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    },

    updateSubsToken: (msisdn) => {
      const data = new FormData();
      data.append('msisdn', msisdn);

      return HttpHelper.twistSubsAxios
        .post('befit/subscription/updateToken', data)
        .then((res) => res.data)
        .catch((err) => err.response.data)
    }
  },
};
