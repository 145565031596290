import React, { useState, useRef } from "react";
import { Container, Col } from "react-bootstrap";
import { useHistory, useLocation, useParams } from "react-router-dom";
import HeaderComponent from "../../components/Subscription/HeaderComponent";
import PhoneComponent from "../../components/Subscription/PhoneComponent/PhoneComponent";
import PinComponent from "../../components/Subscription/PinComponent/PinComponent";
import api from "../../apis";
import Cookies from "universal-cookie";
import { useEffect } from "react";
import { checkedPinStatus, EtSubs, getPortalSubsName } from "./helper";
import useHeaderEnrichement from '../../hooks/useHeaderEnrichement'
import TwistSettings from '../../TwistSettings.json';
import { grantAccessToAllServices } from "../../hooks/helper";
import ChooseProvider from "../../components/Subscription/ChooseProvider";
import ChoosePlan from "../../components/Subscription/ChoosePlan";
import ChooseMethod from "../../components/Subscription/ChooseMethod";

export default function Subscription() {
  const history = useHistory();
  const location = useLocation();
  let { serviceName } = useParams();
  const previousPathname = location?.state?.previousPathname ?? '/';
  // disable multiple providers
  const [step, setStep] = useState('1');
  // disable multiple providers
  const userChoicesRef = useRef([])
  const [userPhoneNumber, setUserPhoneNumber] = useState(null);
  const [pinCheckStatus, setPinCheckStatus] = useState(null);
  const { isWifi, oneServiceLoading, isSubscribedToOneService } = useHeaderEnrichement(serviceName);
  const [redirectEtisalat, setRedirectEtisalat] = useState(true);
  useEffect(() => {
    if (!oneServiceLoading) {
      if (isSubscribedToOneService === true) {
        setRedirectEtisalat(false)
        history.replace(previousPathname, { previousPathname: history.location.pathname });
        // { oneServiceLoading, isWifi, isSubscribedToOneService, redirectEtisalat })
      }
      else if (isWifi && isSubscribedToOneService === false && TwistSettings['subscription']) {
        setRedirectEtisalat(false)
      }
      else if (!isWifi && isSubscribedToOneService === false && TwistSettings['subscription']) {
        window.location.href = EtSubs + getPortalSubsName(serviceName)
      }
    }
  }, [oneServiceLoading, isSubscribedToOneService, isWifi])

  const pinPage = async (type, phoneNumber) => {
    const msisdn = `20${phoneNumber}`;
    if (!TwistSettings['subscription']) {
      grantAccessToAllServices(msisdn)
      history.replace(previousPathname, { previousPathname: history.location.pathname });
    } else {
      ///////////////////////////////////////////////
      // when the rout is GAMES we make him subscribe to local because game is not a service
      if (serviceName == "games") serviceName = "local"
      ////////////////////////////////////////////////////
      const { status } = await api.subscription[serviceName].sendCode({ msisdn })
        .then((res) => res);
      if (status === 200) {
        setUserPhoneNumber(msisdn);
        type === "pin" ? setStep('2') : history.replace("/");
      }
    }
  };

  const handleCheckPin = (pinNumber) => {
    ///////////////////////////////////////////////
    // when the rout is GAMES we make him subscribe to local because game is not a service
    if (serviceName == "games") serviceName = "local"
    ////////////////////////////////////////////////////
    const num = `${pinNumber}`;
    const checkPin = async (num) => {
      const { status, token } = await api.subscription[serviceName]
        .checkPin({
          pinCode: num,
          msisdn: userPhoneNumber,
        })
        .then((res) => res);
      const cookies = new Cookies();
      if (status === 200) {
        cookies.set(`${serviceName}`, `${token},${userPhoneNumber}`, {
          path: "/",
          maxAge: `${60 * 60 * 12}`,
        });
        history.replace(previousPathname);
      }
      else if (status === -2 || status === -4) {
        setPinCheckStatus(checkedPinStatus[status]);
      } else if (status === -6) {
        window.location.href = EtSubs + getPortalSubsName(serviceName)
      }
    };
    checkPin(num);
  };

  // disable multiple providers
  useEffect(() => {
    userChoicesRef.current.push("we")
    // setStep("1")
  }, []);
  // disable multiple providers

  if (isWifi === null || oneServiceLoading) return <p className="text-center text-light" data-testid="subscription-loading">جاري فحص حالة اشتراكك...</p>

  return <Container>
    {!redirectEtisalat &&
      <Col xs={12} lg={7} className="mx-auto py-4">
        {(step === '1' || step === '2') && <HeaderComponent oneServiceLoading={oneServiceLoading} />}
        <Col xs={12} md={10} className="m-auto">
          {oneServiceLoading && <p className="text-center text-light" data-testid="subscription-loading">جاري فحص حالة اشتراكك...</p>}
          {step === '0' && <ChooseProvider userChoicesRef={userChoicesRef} setStep={setStep} />}
          {step === '1' && <PhoneComponent pinIsActive={pinPage} />}
          {step === '2' && <PinComponent handleCheckPin={handleCheckPin}>{pinCheckStatus}</PinComponent>}
          {step === '3' && <ChoosePlan userChoicesRef={userChoicesRef} setStep={setStep} />}
          {step === '4' && <ChooseMethod userChoicesRef={userChoicesRef} />}
        </Col>
      </Col>
    }
  </Container>
}
