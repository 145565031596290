import HttpHelper from "../../helper";

export const aliMazharContentEndpoints = {
    getAliTipsOrVideos: (contentType, pageNum = 1) =>
        HttpHelper.fitnessAxios
            .get(`/nutrition/get/all/tips/${contentType}/${pageNum}`)
            .then((res) => res?.data),

    getAliAnswers: (pageNum = 1) =>
        HttpHelper.fitnessAxios
            .get(`/episode/get/by-series/${pageNum}`)
            .then((res) => res?.data),
};