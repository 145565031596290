import React from "react";
import "react-multi-carousel/lib/styles.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Container } from "reactstrap";
import { ImgURL } from "../../../globalData";
import "./PrizesCarousel.css";

function SampleNextArrow(props) {
    const { style, onClick } = props;

    return (
        <button
            onClick={onClick}
            style={{
                ...style,
                fontSize: "20px",
                display: "block",
                background: "rgba(0,0,0,.4)",
                borderRadius: "6px",
                border: "unset",
                position: "absolute",
                top: "88px",
                height: "100%",
                right: "30px",
                zIndex: "15",
                opacity: "1",
                color: "white",
                transform: "rotate(90deg)"
            }}
        >
            <i className="swipe-animation fa fa-chevron-right" aria-hidden="true"></i>
        </button>
    );
}

function SamplePrevArrow(props) {
    const { style, onClick } = props;

    return (
        <button
            onClick={onClick}
            style={{
                ...style,
                fontSize: "20px",
                display: "block",
                background: "rgba(0,0,0,.4)",
                borderRadius: "6px",
                border: "unset",
                position: "absolute",
                top: "-88px",
                height: "100%",
                left: "30px",
                zIndex: "15",
                opacity: "1",
                color: "white",
                transform: "rotate(90deg)"
            }}
        >
            <i className="swipe-animation fa fa-chevron-left" aria-hidden="true"></i>
        </button>
    );
}

function PrizesCarousel({ PlayerDetails }) {

    const settings = {
        infinite: false,
        draggable: false,
        touchMove: false,
        speed: 900,
        slidesToShow: 3,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        nextArrow: <SampleNextArrow />,
        prevArrow: <SamplePrevArrow />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Container className="player-prizes-carousel-component start-carousel-at-right">
            <Slider {...settings}>
                {PlayerDetails.champions?.map((el) => (
                    <div className="prizes__info" key={el.id}>
                        <img src={ImgURL(el.logo)} alt="logo" />
                        <div className="text-light champions-times text-center">
                            x{el.seasons.split("-").length}
                        </div>
                    </div>
                ))}
            </Slider>
        </Container>
    );
}

export default PrizesCarousel;
