import React from "react";
import StarRatings from "react-star-ratings";
import "./iconsStyle.css";

const StarsComponent = ({ userRate }) => {
  return (
    <StarRatings
      starDimension="20px"
      starSpacing="0"
      rating={userRate === null ? 0 : userRate}
      starRatedColor="#7ebd19"
      numberOfStars={5}
      name="rating"
      svgIconPath="m25,1 6,17h18l-14,11 5,17-15-10-15,10 5-17-14-11h18z"
    />
  );
};

export default StarsComponent;
