import HttpHelper from "../../helper";

export const mediaEndpoints = {
  getMidoDiaries: (params, signal) =>
    HttpHelper.meloukAxios
      .get(`/mido-diaries`,{params, signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  getMidoAnswers: (params, signal) =>
    HttpHelper.meloukAxios
      .get(`/mido-answers`,{params, signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  getMidoTactics: (params, signal) =>
    HttpHelper.meloukAxios
      .get(`/analytics-studios`,{params, signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  getHappenedThisDay: (params, signal) =>
    HttpHelper.meloukAxios
      .get(`/happened-this-day`,{params, signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),

  getOneMidoDiaries: (id, signal) =>
    HttpHelper.meloukAxios
      .get(`/mido-diaries/${id}`,{signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  getOneMidoAnswers: (id, signal) =>
    HttpHelper.meloukAxios
      .get(`/mido-answers-details/${id}`,{signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  getOneMidoTactics: (id, signal) =>
    HttpHelper.meloukAxios
      .get(`/analytics-studios/${id}`,{signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
  
  getOneHappenedThisDay: (id, signal) =>
    HttpHelper.meloukAxios
      .get(`/happened-this-day/${id}`,{signal})
      .then((res) => res?.data)
      .catch((err) => err?.response?.data),
};