import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { convertFullDate } from "../../../../globalFn";
import "./BannerWebComponent.css"

const BannerMobComponent = (props) => {
  var today = new Date();
  return (
    <Container className="mb-4 p-3 banner-container" fluid>
      <Col>
        <Row className="">
          <Col xs={12} className="p-0 mb-1">
            <h4 className="text-uppercase fs-xxm text-center">
              {props.title}
            </h4>
          </Col>
          <Col>
            {props.show ? (
              <p className="fs-sm text-center m-0">
                {"تمرينات اليوم"}
              </p>
            ) : null}
            <p className="fs-xs mx-1 text-center m-0">
              {convertFullDate(today, props.lang)}
            </p>
            <p className="fs-xs text-center m-0">{props.subTitle}</p>
          </Col>
        </Row>
      </Col>
    </Container>
  );
};

export default BannerMobComponent;
