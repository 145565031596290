import HttpHelper from "../helper";
import { userPointsEndpoints } from "./userPoints";
import { userTotalPointsEndpoints } from "./userTotalPoints";

export const gamesEndpoints = {
    getSubmittedGames: (params, signal) =>
        HttpHelper.baseAxios
            .get(`/checkPlayedGames`, { params, signal })
            .then((res) => res?.data)
            .catch((err) => err?.response?.data),

    points: userPointsEndpoints,
    totalPoints: userTotalPointsEndpoints
};