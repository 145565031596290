import { Link, useLocation } from "react-router-dom";

function PageNotFound() {
    const {pathname} = useLocation()

    return (
        <div className="page__notFound text-center">
            <h1 className="text-white text-center">Sorry, we can't find this page</h1>
            <Link className="btn btn-outline-danger" to={{pathname: "/", state: {previousPathname: pathname}}}>الرئيسية</Link>
        </div>
    )
}

export default PageNotFound