import { useState, useEffect, useContext } from 'react';
import api from "../../apis"
import { GiTrophyCup } from "react-icons/gi";
import "./TournamentKnockOuts.css";
import { Col, Row } from 'react-bootstrap';
import { customizeRoundObject } from './helper';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { ImEarth } from 'react-icons/im';
import useServiceAndSport from '../../hooks/useServiceAndSport';
import { getServiceName, getSportRoute } from '../../Layout/helper';
import { useHistory } from 'react-router-dom';
import { handleShowPenaltyScore } from '../MatchCard/helper';
import { sharedComponentsContext } from '../../context/shared-context';

function TournamentKnockOuts({ id }) {
    const { seasonInfo: { activeSeason } } = useContext(sharedComponentsContext);
    const [isLoadingTournamentKnockOutMatches, setIsLoadingTournamentKnockOutMatches] = useState(false);
    const [tournamentKnockOutMatchesErrors, setTournamentKnockOutMatchesErrors] = useState("");
    const [round16, setRound16] = useState({});
    const [round8, setRound8] = useState({});
    const [round4, setRound4] = useState({});
    const [round2, setRound2] = useState({});
    const [round3_4, setRound3_4] = useState({});
    const roundsNum = {
        33: setRound16,
        7: setRound16,
        4: setRound8,
        3: setRound4,
        1: setRound2,
        2: setRound3_4,
    }
    const history = useHistory();
    const { serviceId, sportId } = useServiceAndSport();


    const handleRenderRounds = (round, matchResultWrapperNum, cup = false, specialRound = false) => {
        const { matches } = round;
        const matchResultWrapperِArr = [...new Array(matchResultWrapperNum)].map((_, i) => i * 2)
        return (
            <div className='main__wrapper d-flex flex-column justify-content-around'>
                {matchResultWrapperِArr.map((ele, index) => (
                    <div className='match__resultWrapper' key={ele}>
                        {specialRound ?
                            matches.slice(index, index + 1).map(match => {
                                const key = match.id ?? match;
                                return handleRenderMatchStructure(match, cup, key)
                            }) :
                            matches.slice(ele, ele + 2).map(match => {
                                const key = match.id ?? match;
                                return handleRenderMatchStructure(match, cup, key)
                            })
                        }
                    </div>
                ))}
            </div>
        )
    }

    const handleTeamClick = (teamId) => {
        history.push(`/${getServiceName(serviceId)}${getSportRoute(sportId, serviceId)}/team/${teamId}`, { previousPathname: history.location.pathname });
    }

    const handleMatchClick = (matchId) => {
        if (matchId) history.push(`/${getServiceName(serviceId)}/match/${matchId}`, { previousPathname: history.location.pathname });
    }

    const handleRenderTeam = (foundMatch, team) => <Col
        className='d-flex flex-column align-items-center justify-content-center clickable'
        onClick={() => handleTeamClick(team?.id)}
    >
        {
            foundMatch
                ? <img src={team?.logo} alt="" />
                : <ImEarth className='skeleton__teamFlag' />
        }
        <span className='mt-1 text-center'>{team?.name}</span>
    </Col>

    const handleRenderMatchStructure = (match, cup = false, key) => {
        const foundMatch = !!match.tree_position;
        return (
            <Row
                className={` match d-flex flex-nowrap justify-content-between align-items-center`}
                key={key}
            >
                {handleRenderTeam(foundMatch, match.team1)}
                <Col
                    className={`d-flex align-items-center clickable`}
                    onClick={() => handleMatchClick(match?.id)}
                >
                    <div className='d-flex flex-column align-items-center'>
                        <span>{match?.team1?.totalScore}</span>
                        {handleShowPenaltyScore(match?.team1, match?.team2) && <span>({match?.team1?.penalties_score})</span>}
                    </div>
                    <span className='result__score mx-2'>
                        {cup ? <GiTrophyCup className='cup__icon' /> : "-"}
                    </span>
                    <div className='d-flex flex-column align-items-center'>
                        <span>{match?.team2?.totalScore}</span>
                        {handleShowPenaltyScore(match?.team1, match?.team2) && <span>({match?.team2?.penalties_score})</span>}
                    </div>
                </Col>
                {handleRenderTeam(foundMatch, match.team2)}
            </Row>
        )
    }

    useEffect(() => {
        setIsLoadingTournamentKnockOutMatches(true);
        api.tournament.getTournamentTree({ season_id: activeSeason.id, tournament_id: id }).then(res => {
            setIsLoadingTournamentKnockOutMatches(false);
            if (res.status === 200) {
                setRound16({});
                setRound8({});
                setRound4({});
                setRound2({});
                setRound3_4({});
                for (let round of res.data) roundsNum[round?.id](round);
            } else {
                setTournamentKnockOutMatchesErrors(res.message);
            }
        })
    }, [activeSeason.id, id])


    return (
        <div className={`tournament__knockouts text-white container mt-5 ${id === '726' ? 'worldCup-styles' : ""}`} >
            {isLoadingTournamentKnockOutMatches &&
                <div className='my-2 white-color bg-light-black-color p-5 text-light text-center'>
                    <LoadingSpinnner p={3} fs={"md"} />
                </div>
            }
            {
                !isLoadingTournamentKnockOutMatches && tournamentKnockOutMatchesErrors &&
                <div className='my-2 white-color bg-light-black-color p-5 text-light text-center'>
                    حدث خطا يرجي اعادة المحاولة
                </div>
            }
            {
                !isLoadingTournamentKnockOutMatches && !tournamentKnockOutMatchesErrors &&
                <Row className="tournament__knockoutsWrapper flex-nowrap mb-sm-5">
                    <Col className='round__16'>
                        <h2 className='text-white text-center fs-sm'>دور ال16</h2>
                        {handleRenderRounds(customizeRoundObject(8, round16), 4)}
                    </Col>
                    <Col className='round__8'>
                        <h2 className='text-white text-center fs-sm '>ربع النهائي</h2>
                        {handleRenderRounds(customizeRoundObject(4, round8), 2)}
                    </Col>
                    <Col className='round__4'>
                        <h2 className='text-white text-center fs-sm'>نصف النهائي</h2>
                        {handleRenderRounds(customizeRoundObject(2, round4), 2, false, true)}
                    </Col>
                    <Col className='round__2'>
                        <h2 className='text-white text-center fs-sm'>النهائي</h2>
                        {handleRenderRounds(customizeRoundObject(1, round2), 1, true)}
                        <div className="round__3_4">
                            <h2 className='text-white text-center fs-sm'>الثالث والرابع</h2>
                            {handleRenderRounds(customizeRoundObject(1, round3_4), 1, true)}
                        </div>
                    </Col>
                </Row>
            }
        </div >
    )
}

export default TournamentKnockOuts;
