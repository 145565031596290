import React, { useState, useEffect } from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Modal from 'react-bootstrap/Modal';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import userImg from '../../../../assets/games/playstation-user.svg';
import vsImg from '../../../../assets/games/versus-game.svg';
import uploadImg from '../../../../assets/games/ios-cloud-upload.svg';
import api from '../../../../apis';
import { convertFullDate } from '../../../../globalFn';
import { sendGameReport } from '../helper';
import { ImgURL } from '../../../../globalData';

const PlaystationMatchDetailsModal = ({ userPlaystationInfoData, ...showAndHide }) => {
  const [formImageInputValue, setFormImageInputValue] = useState({
    imageName: '',
    imageFile: {}
  });
  const [isSendingScreenshot, setIsSendingScreenshot] = useState(false);
  const [sendingScreenshotSuccess, setSendingScreenshotSuccess] = useState(false);
  const [sendingScreenshotErrors, setSendingScreenshotErrors] = useState("");
  const [topTenPlayers, setTopTenPlayers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  let id, mobile, start_date, start_time, player_one_playstation_name, player_one_msisdn, player_one_screenshot, player_two_playstation_name, player_two_msisdn, player_two_screenshot;

  if (userPlaystationInfoData && userPlaystationInfoData.next_match) {
    ({ user: { mobile }, next_match: { id, start_date, start_time, player_one_playstation_name, player_one_msisdn, player_one_screenshot, player_two_playstation_name, player_two_msisdn, player_two_screenshot } } = userPlaystationInfoData);
  }

  useEffect(() => {
    const abortContoller = new AbortController();
    api.playsStationTopTenPLayers
      .getTopTenPlayers({}, abortContoller.signal)
      .then((res) => {
        if (res.status === 200) {
          setTopTenPlayers(res.data.data);
          setIsLoading(false);
        }
      });

    return () => {
      abortContoller.abort();
    };
  }, []);

  useEffect(() => {
    if (showAndHide.show) {
      sendGameReport("PlayStation")
    }
  }, [showAndHide.show])

  const handleChangeInputImage = (e) => {
    const { name, value } = e.target;
    setFormImageInputValue({
      [name]: value.replace(/C:\\fakepath\\/i, ''),
      imageFile: e.target.files[0]
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let formData = new FormData();
    const match_id = id;
    const screenshot = formImageInputValue.imageFile;
    formData.append('screenshot', screenshot);
    formData.append('phone', mobile);
    setIsSendingScreenshot(true);
    api.playstation.sendMatchResultScreenshot(match_id, formData).then(res => {
      setIsSendingScreenshot(false);
      if (res.status === 200) {
        setSendingScreenshotSuccess(true)
        setSendingScreenshotErrors("")
        setFormImageInputValue({
          imageName: '',
          imageFile: {}
        })
      } else {
        setSendingScreenshotErrors(res.message)
      }
    })
  };

  const convertTime = () => {
    let isAmOrPm = start_time.split(":")[0] >= "12" ? "PM" : "AM";
    return [start_time, isAmOrPm]
  }

  const checkIfUserHasUploadedScreenshot = () => {
    let isScreenshotUploaded;
    if (mobile === player_one_msisdn) isScreenshotUploaded = !!player_one_screenshot;
    if (mobile === player_two_msisdn) isScreenshotUploaded = !!player_two_screenshot;
    return isScreenshotUploaded;
  }


  return (
    <Modal
      {...showAndHide}
      size='xl'
      contentClassName={'modal__content'}
      dialogClassName='modal__dialog playstation__modal'
    >
      <Modal.Header className='modal__header' closeButton />
      <Modal.Body className='show-grid'>
        <Container>
          {userPlaystationInfoData && userPlaystationInfoData.next_match &&
            <Row>
              <Col xs={12} className='modal__bodyTitle text-center text-white'>
              تفاصيل المباراة
              </Col>
              <Col
                xs={12}
                sm={10}
                className='modal__bodyImage text-center text-white'
              >
                <div className='match__dateDetails--overlay'></div>
                <div className='match__dateDetails d-flex flex-column align-items-center'>
                  <div>
                    <span>{convertTime()[0]}</span>
                    <span className='text-capitalize ml-1'>{convertTime()[1]}</span>
                  </div>
                  <span>{convertFullDate(start_date)}</span>
                </div>
              </Col>
              <Col
                xs={10}
                className='modal__bodyPlayer--details d-flex algin-items-center justify-content-between'
              >
                <Row className='playstation__competitorsInfo flex-grow-1 w-100'>
                  <Col xs={12} md={4} className='playstation__playerOne--username d-flex align-items-center justify-content-end flex-row-reverse text-white'>
                    {/* <img src={userImg} alt='' /> */}
                    <span className='text-center'>{player_one_playstation_name}</span>
                    {/* <span><FaEdit /></span> */}
                  </Col>
                  <Col xs={12} md={4} className='d-flex justify-content-center'>
                    <img src={vsImg} alt='' />
                  </Col>
                  <Col xs={12} md={4} className='playstation__playerTwo--username d-flex align-items-center flex-row-reverse  text-white'>
                    {/* <img src={userImg} alt='' /> */}
                    <span className='text-center'>{player_two_playstation_name}</span>
                    {/* <span><FaEdit /></span> */}
                  </Col>
                </Row>
              </Col>
              <Col
                xs={11}
                className='modal__bodyDescription text-center text-white'
              >
                PlayStation competition lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod tempor incididunt cupidatat non
                proident, sunt in culpa qui officia deserunt mollit anim id est
                laborum.
              </Col>
              <Col
                xs={12}
                className='playstation__matchResult--image text-center text-white'
              >
                <Form
                  className='playstation__detailsForm d-flex flex-row-reverse justify-content-center'
                  onSubmit={handleSubmit}
                >
                  <Form.Group className='mb-1'>
                    <Form.Control
                      type='file'
                      accept='image/*'
                      className={`match__imageInput ${(checkIfUserHasUploadedScreenshot() || sendingScreenshotSuccess) ? 'disabled' : ''}`}
                      id='image-upload'
                      name='imageName'
                      onChange={handleChangeInputImage}
                      disabled={checkIfUserHasUploadedScreenshot() || sendingScreenshotSuccess}
                    />
                    <Form.Label
                      htmlFor='image-upload'
                      className='d-flex flex-row-reverse'
                    >
                      <img src={uploadImg} alt='' />
                      {formImageInputValue.imageName
                        ? formImageInputValue.imageName
                        : 'Upload the result image'}
                    </Form.Label>
                  </Form.Group>
                  <button disabled={formImageInputValue.imageName ? false : true} className='d-flex justify-content-center align-items-center'>
                    {isSendingScreenshot ?
                      <div className="spinner-border text-center text-danger fs-xs p-0" role="status" /> :
                      "ارسال"
                    }
                  </button>
                </Form>
              </Col>
              {!isSendingScreenshot && sendingScreenshotErrors &&
                <Col xs={12} className="text-danger d-flex justify-content-center mb-3">
                  حدث خطا يرجي اعادة المحاولة
                </Col>}
              {!isSendingScreenshot && (checkIfUserHasUploadedScreenshot() || sendingScreenshotSuccess) &&
                <Col xs={12} className="text-white d-flex justify-content-center mb-3">
                  تم الارسال بنجاح
                </Col>}
            </Row>
          }
          <Row className='d-flex flex-column align-items-center'>
            <Col className='playstation__TopTen--Header text-center d-flex flex-column align-items-center'>
              <h5>Top 10</h5>
              <h6>PlaysStation competition Lorem ipsum dolor sit amet.</h6>
            </Col>
            {isLoading ? (
              <h2>Loading... </h2>
            ) : (
              <Col className='playstation__TopTen--Table d-flex flex-column align-items-center mt-3 '>
                <div className='playstation__TopTen--TableRow d-flex w-100 '>
                  <div className='playstation__TopTen--coreTitle w-50 text-right mb-4 mr-3 pr-md-5'>
                    Score
                  </div>
                  <div className='playstation__TopTen--playerTitleS w-50 text-left mb-4 ml-4 pl-md-5'>
                    Player
                  </div>
                </div>

                {topTenPlayers.map((player) => (
                  <div
                    key={player.id}
                    className='playstation__TopTen--TableRow d-flex no-wrap w-100'
                  >
                    <div className='playstation__TopTen--Score w-50 text-right d-flex no-wrap justify-content-start align-items-center m-2 pr-md-5'>
                      {`${player.point} Points  `}
                    </div>
                    <div className='playstation__TopTen--player w-50 text-left d-flex no-wrap justify-content-end align-items-center m-2 pl-md-5'>
                      {player.name}
                      <img src={player.image ? ImgURL(player.image) : userImg} alt='player' />
                    </div>
                  </div>
                ))}
              </Col>
            )}
          </Row>
        </Container>
      </Modal.Body>
    </Modal>
  );
};

export default PlaystationMatchDetailsModal;
