import { Dropdown, Row, Col } from 'react-bootstrap';
import PlayerStatsChart from './PlayerStatsChart';
import PlayerStatsTable from './PlayerStatsTable';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import Comparison from '../Comparison/Comparison';
import TwistSettings from '../../TwistSettings.json'

const PlayerStatistics = ({
    sport_id,
    season_id,
    player_id,
    isLoading,
}) => {

    return (
        <div className='container white-color mt-2'>
            <Row>
                {sport_id !== 7 &&
                    <Col xs={12} md={8} className=' p-0'>
                        <PlayerStatsChart sport_id={sport_id} season_id={season_id} player_id={player_id} />
                    </Col>
                }
                {TwistSettings["comparison"] &&
                    <Col xs={12} md={4}>
                        <Comparison />
                    </Col>
                }
            </Row>
            {isLoading ?
                <>
                    <Row className='mt-3 d-flex justify-content-start'>
                        <Dropdown className='col-1'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                ...Loading
                            </Dropdown.Toggle>
                        </Dropdown>
                        <Dropdown className='mx-4 col-1'>
                            <Dropdown.Toggle variant="secondary" id="dropdown-basic">
                                ...Loading
                            </Dropdown.Toggle>
                        </Dropdown>
                    </Row>
                    <div className='mt-3'>
                        <div className='bg-black-color p-3'>احصائيات</div>
                        <LoadingSpinnner p={5} fs={'md'} />
                    </div>
                </>
                : <PlayerStatsTable player_id={player_id} />
            }
        </div>
    )
}

export default PlayerStatistics

