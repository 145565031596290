import { useEffect, useState } from 'react';
import Cookies from 'universal-cookie';


export default function useWalkthroughCookie() {
    const [walkthrough, setWalkthrough] = useState(true);

    useEffect(() => {
        const cookies = new Cookies();
        const cookieFound = cookies.get('walkthrough');
        if (cookieFound) {
            setWalkthrough(false);
        } else {
            cookies.set("walkthrough", `true`, {
                path: "/",
                maxAge: `${1000 * 60 * 60 * 24 * 365}`,
            });
        }
    }, [])
    return { walkthrough };
}
