import { useContext, useEffect, useRef, useState } from "react";
import "./Home.css";
import GeneralStandings from "../../components/generalStandings/generalStandings";
import GeneralStatistics from "../../components/generalStatistics/generalStatistics";
import MatchesCalendar from "../../components/MatchesCalendar/MatchesCalendar";
import LatestNews from "../../components/News/LatestNews";
import TopStories from "../../components/News/TopStories";
import SportsSectionHeader from "../../components/sportsSectionHeader/sportsSectionHeader.component";
import TournamentsCarousel from "../../components/TournamentsCarousel/TournamentsCarousel";
import NewsSearch from "../../components/News/NewsSearch";
import Comparison from "../../components/Comparison/Comparison";
import Joyride, { ACTIONS, EVENTS, STATUS } from "react-joyride";
import useWalkthroughCookie from "../../hooks/useWalkthroughCookie";
import TwistSettings from "../../TwistSettings.json";
import AllMedia from "../../components/News/AllMedia";
import OnDemand from "../../components/OnDemand";
import { sharedComponentsContext } from "../../context/shared-context";
import BasketBallEbentBanner from "../../assets/BasketBallEbentBanner.jpg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import TwistAds from "../../components/TwistAds";

const Home = () => {
  const history = useHistory();
  const {
    NewsSearchQuery,
    seasonInfo: { appActiveSeason },
  } = useContext(sharedComponentsContext);
  const { walkthrough } = useWalkthroughCookie();

  const matchesCalender = useRef();
  const topStories = useRef();
  const tournaments = useRef();
  const latestNews = useRef();
  const generalStatistics = useRef();
  const comparison = useRef();

  const [modalShow, setModalShow] = useState(true);
  const [joyrideState, setJoyrideState] = useState({
    run: false,
    stepIndex: 0,
    steps: [],
  });

  useEffect(() => {
    setJoyrideState({
      run: true,
      steps: [
        {
          target: matchesCalender.current,
          content: "لمتابعة مواعيد المباريات",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: topStories.current,
          content: "لمتابعة أحدث الأخبار",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: tournaments.current,
          content: "لمتابعة جدول الدوري",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: latestNews.current,
          content: "يمكنك البحث عن الخبر الذي تريدة",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: generalStatistics.current,
          content: "يمكنك من متابعة احصائيات الدوري",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
        {
          target: comparison.current,
          content: "يمكنك من المقارنة بين الاندية واللاعبين",
          placement: "top",
          spotlightClicks: true,
          disableBeacon: true,
          styles: {
            options: {
              zIndex: 10000,
            },
          },
        },
      ],
    });
  }, []);

  const locale = {
    back: "السابق",
    close: "تم",
    last: "إنهاء",
    next: "التالي",
    skip: "تخطي",
  };

  const handleJoyrideCallback = (data) => {
    const { action, index, status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setJoyrideState({ ...joyrideState, run: false, stepIndex: 0 });
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      const nextStepIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      setJoyrideState({
        ...joyrideState,
        stepIndex: nextStepIndex,
      });
    }
  };

  return (
    <div className='container home-page '>
      {/* {TwistSettings["twist-ui"] && TwistSettings["world-cup-pop"] &&
        <WorldCupPopUp
          show={modalShow}
          onHide={() => setModalShow(false)}
        />
      } */}

      {walkthrough && (
        <Joyride
          callback={handleJoyrideCallback}
          run={joyrideState.run}
          scrollOffset={230}
          showProgress
          showSkipButton
          continuous={true}
          hideCloseButton={true}
          stepIndex={joyrideState.stepIndex}
          steps={joyrideState.steps}
          locale={locale}
          styles={{
            options: {
              primaryColor: "var(--red)",
            },
          }}
        />
      )}
      <div className='d-flex justify-content-center row'>
        <div className='col-12'>
          {/* <img src={IphoneAdd} height={200} width={"100%"} /> */}
        </div>
      </div>
      <div className='home-page-calendar' ref={matchesCalender}>
        <div className='home-page-calendar-title'>
          <SportsSectionHeader
            arrowTitle={"كل المباريات"}
            navigateTo={"/matches"}
          />
        </div>
        <OnDemand>
          <MatchesCalendar season_id={null} tournament_id={null} />
        </OnDemand>
      </div>
      <SportsSectionHeader
        arrowTitle={"كل الأحداث"}
        title='أهم الأحداث'
        navigateTo='/news'
      />
      <div ref={topStories}>
        {/* <OnDemand> */}
        <TopStories
          season_id={null}
          items_count={4}
          media_type={1}
          tournament_id={null}
          media_id={null}
          team_id={null}
          player_id={null}
          top_stories={true}
          latest={null}
          popular={null}
        />
        {/* </OnDemand> */}
      </div>
      <hr></hr>

      {/* <section className="event-banner" onClick={() => history.push('/othersports/basketball/tournament/7475')}>
        <img src={BasketBallEbentBanner} alt="event banner" />
      </section> */}

      <div ref={tournaments} className='row align-items-baseline'>
        <div className='col-12 col-md-6'>
          <OnDemand>
            <SportsSectionHeader title='ترتيب الدوري' />
            <GeneralStandings sport_id={1} season_id={null} />
          </OnDemand>
        </div>
        <div className='col-12 col-md-6 fs-md'>
          <div className='text-light mt-5'>
            <SportsSectionHeader
              arrowTitle={"كل البطولات "}
              navigateTo={"/tournaments"}
              title={"أهم البطولات"}
            />
          </div>

          <OnDemand>
            <TournamentsCarousel />
          </OnDemand>
        </div>
      </div>

      {/* <TwistAds>
        <TwistAds.OnlineShopAds />
      </TwistAds> */}

      <div className='col-12'>
        {/* <img src={Cokebanner} height={200} width={"100%"} className="mt-3" /> */}
      </div>
      <SportsSectionHeader
        arrowTitle={"كل الأخبار"}
        title='أخر الأخبار'
        navigateTo='/news'
      />
      <NewsSearch />
      <div
        ref={latestNews}
        className={`${NewsSearchQuery.length > 0 ? "d-none" : ""} my-2`}>
        <OnDemand>
          <LatestNews
            season_id={appActiveSeason.id}
            items_count={8}
            media_type={1}
            latest={true}
          />
        </OnDemand>
      </div>
      <hr />
      {TwistSettings["videos"] && (
        <>
          <SportsSectionHeader
            arrowTitle={"كل الفيديوهات"}
            title='أحدث الفيديوهات'
            navigateTo='/videos'
          />
          <OnDemand>
            <AllMedia
              season_id={appActiveSeason.id}
              items_count={8}
              type={2}
              tournament_id={null}
              media_id={null}
              team_id={null}
              player_id={null}
              top_stories={null}
              latest={true}
              popular={null}
            />
          </OnDemand>
          <hr />
        </>
      )}
      {/* <TwistAds>
        <TwistAds.FlyDubaiAdImage />
      </TwistAds> */}

      <SportsSectionHeader title='الأكثر تداولا' navigateTo='/news' />
      <OnDemand>
        <AllMedia
          season_id={appActiveSeason.id}
          items_count={12}
          type={1}
          tournament_id={null}
          media_id={null}
          team_id={null}
          player_id={null}
          top_stories={null}
          latest={null}
          popular={true}
        />
      </OnDemand>
      <hr />
      <div className='row justify-content-center'>
        <div ref={generalStatistics} className='col-12 col-md-6 mt-4'>
          <OnDemand>
            <GeneralStatistics />
          </OnDemand>
        </div>
        {TwistSettings["comparison"] && (
          <div ref={comparison} className='col-12 col-md-6 mt-4'>
            <Comparison />
          </div>
        )}
      </div>
      {/* <TwistAds>
        <TwistAds.EmeraldAdImage />
      </TwistAds> */}
    </div>
  );
};

export default Home;
