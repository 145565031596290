import React, { useEffect, useState } from "react";
import { Col, Row } from "reactstrap";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

const ClubsCarouselLoading = () => {
  const [isMobileView, setIsMobileView] = useState("");
  useEffect(() => {
      let windowWidth = window.innerWidth;
      if (windowWidth < 767.98) {
        setIsMobileView("mobile_view") ;
      } else {
        setIsMobileView("web_view") ;
      }
  }, [])
  
  return (
    <div className="teams_card" data-testid="teams-carousel-loading">
      <SkeletonTheme color="rgba(255,255,255,0.0)" highlightColor="#ecebeb">
        <Row className="no-gutters text-center">
          {Array.from(Array(isMobileView=== "web_view"?20:5).keys()).map((i) => (
            <Col key={i} className="my-1">
              <div className="border-0">
                <Skeleton height={50} width={50} className="rounded-circle" />
              </div>
            </Col>
          ))}
        </Row>
      </SkeletonTheme>
      <style type="text/css" jsx="true">
        {`
          .teams_card {
            box-shadow: 0 3px 6px 0 rgba(164, 164, 164, 0.2);
          }

          @media (max-width: 575.98px) {
            .teams_card {
              border-radius: unset;
            }
          }
        `}
      </style>
    </div>
  );
};

export default ClubsCarouselLoading;
