import React from "react";
import AskComponent from "../../components/ask/AskComponent";
import { useQuery } from "@tanstack/react-query";
import api from "../../../../apis";
import { getUserPhoneNumbers } from "../../../../globalFn";
import { logFailedApi } from "../../../../globalFn";

const apiFn = (msisdn) => api.fitness.aliMazharAsk.getIsAsked(msisdn)

const AskAlyMazharPage = () => {
    const msisdn = getUserPhoneNumbers()?.[0];

    const { data: isAskedData, isFetching: isFetchingAsked, isError: isErrorAsked } = useQuery({
        queryKey: ["isAsked"],
        queryFn: () => apiFn(msisdn),
        onError: () => logFailedApi("isAsked"),
        refetchOnWindowFocus: false,
    })


    return (
        <div>
            <AskComponent
                isLoading={isFetchingAsked}
                errMess={isErrorAsked}
                isAsked={isAskedData?.asked}
            />
        </div>
    );
};

export default AskAlyMazharPage
