import React, { useRef } from "react";
import { Form, Button } from "react-bootstrap";
import { useFormik } from "formik";
import * as yup from "yup";
import "./PhoneComponent.css";
import { useEffect } from "react";
import TwistSettings from "../../../TwistSettings.json"

export default function PhoneComponent({
  pinIsActive,
  publicUserPhoneModal,
  handleSubmitPhoneNumber,
}) {
  const phoneRegExp = /^1[0125][0-9]{8}$/gm;
  const formik = useFormik({
    initialValues: {
      userPhoneNumber: "",
    },
    validationSchema: yup.object({
      userPhoneNumber: yup
        .string()
        .matches(phoneRegExp, "رقم الهاتف المحمول غير صحيح")
        .required("يرجي إدخال رقم الهاتف المحمول"),
    }),
    onSubmit: (values) => values.userPhoneNumber,
  });

  const phoneInputRef = useRef(null);
  useEffect(() => {
    phoneInputRef.current.focus();
  }, []);

  return (
    <Form
      className="phone__numberForm mt-5"
      onSubmit={formik.handleSubmit}
      data-testid="subscription-phone-form"
    >
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label className="fs-md white-color">
        "رقم الهاتف المحمول"
        </Form.Label>
        <Form.Control
          autoFocus
          ref={phoneInputRef}
          type="number"
          inputMode="numeric"
          className="fs-md"
          autoComplete="off"
          pattern="[0-9]*"
          placeholder="01xxxxxxxxx"
          name="userPhoneNumber"
          data-testid="subscription-phone-input"
          {...formik.getFieldProps("userPhoneNumber")}
        />
        {formik.touched.userPhoneNumber && formik.errors.userPhoneNumber && (
          <Form.Text
            className="text-danger"
            data-testid="subscription-phone-error-message"
          >
            {formik.errors.userPhoneNumber}
          </Form.Text>
        )}
      </Form.Group>
      {publicUserPhoneModal ? (
        <Button
          type="submit"
          variant="danger"
          onClick={() => handleSubmitPhoneNumber(formik.values.userPhoneNumber)}
          className="cust-btn-primary"
          disabled={
            Object.keys(formik.errors).length || !formik.values.userPhoneNumber
              ? true
              : false
          }
        >
          {TwistSettings["twist-ui"]?"متابعة":"ارسال"}
        </Button>
      ) : (
        <Button
          type="submit"
          variant="danger"
          onClick={() => pinIsActive("pin", formik.values.userPhoneNumber)}
          className="cust-btn-primary  w-100"
          data-testid="subscription-send-pin"
          disabled={
            Object.keys(formik.errors).length || !formik.values.userPhoneNumber
              ? true
              : false
          }
        >
          {TwistSettings["twist-ui"]?"متابعة":"ارسال"}
        </Button>
      )}
    </Form>
  );
}
