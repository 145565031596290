import { Row, Col, Container } from 'react-bootstrap'
import { ImgURL } from '../../globalData'
import ErrorMsg from '../ErrorPage/ErrorMsg'

const PlayerPrizes = ({ prizes }) => {
    return (
        <Container>
            <Row className='justify-content-start '>
                {
                    !!prizes?.length && prizes?.map(prize =>
                        <div className='col-12 col-md-4 p-3' height="200px" width="400px">
                            <div className='text-light mb-3'>x{prize.count} {prize.title}</div>
                            <div className='d-flex flex-row justify-content-start align-items-center'>
                                <img src={ImgURL(prize.logo)} width="70px" height="70px" alt="prize logo" />
                                <div className='white-color'>{prize.seasons}</div>
                            </div>
                        </div>)
                }
                {!prizes?.length &&
                    <Col>
                        <ErrorMsg p={5} fs={'lg'} msg="لا يوجد جوائز" />
                    </Col>
                }
            </Row>
        </Container>
    )
}

export default PlayerPrizes