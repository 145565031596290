import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'react-bootstrap'
import Loader from './loader';
import api from '../../../../apis';
import SportsSectionHeader from '../../../../components/sportsSectionHeader/sportsSectionHeader.component'
import Riddle from './riddle';
import './index.css'

function MidoRiddles({ isSubscribed }) {

  const [loading, setLoading] = useState(true)

  const [questions, setQuestions] = useState([])
  const [question, setQuestion] = useState({})

  useEffect(() => {
    api.melouk.questions.getMidoQuestions()
      .then(res => {
        if (res.status === 200) {
          setQuestion(res.data?.[0])
          setQuestions(res.data?.slice(1))
          setLoading(false)
        }
      })
  }, [])

  return <Container className="mido-riddles mb-3">
    <SportsSectionHeader title="لغز ميدو" />
    {loading && <Loader />}
    <Riddle question={question} isSubscribed={isSubscribed} />
    <Row className='justify-content-center' noGutters>
      {
        questions.map(r => <Col key={r.id} md={5} className="riddle">
          <p className='text-light'>
            {r.riddle}
          </p>
          <span className='right_answer'>
            الاجابة: {r.right_answer.choice}
          </span>
        </Col>)
      }
    </Row>
  </Container>
}

export default MidoRiddles