import React from 'react';
import ErrorMsg from '../ErrorPage/ErrorMsg';
import LoadingSpinnner from '../loadingSpinner/loadingSpinner';
import { useQuery } from '@tanstack/react-query';
import api from "../../apis";
import { logFailedApi } from '../../globalFn';

const apiFn = (params) => { return api.teams.getTeamStatistics(params) }

const TeamOverviewStats = ({ sport_id, season_id, tournament_id, team_id }) => {

    const { data: teamStatistics, isFetching: isFetchingTeamStatistics, isLoading: isLoadingTeamStatistics, isError: isErrorTeamStatistics } = useQuery({
        queryKey: ["teams-statistics", sport_id, tournament_id, team_id, season_id],
        queryFn: () => apiFn({ season_id, sport_id, team_id, tournament_id }),
        onError: () => logFailedApi("teams-statistics"),
        refetchOnWindowFocus: false,
    })

    const statisticsKeys = {
        played: "لعب",
        win: "فوز",
        lose: "خساره",
        draw: "تعادل",
        goals_in: "أهداف له",
        goals_out: "عليه",
        goals_penalty: "أهداف الجزاء",
        clean_sheet: "شباك نظيفة",
        yellow_cards: "كروت صفراء",
        red_cards: "كروت حمراء",
        difference: "فارق الأهداف",
        point: "نقاط",
        passes: "تمريرات ناجحة",
        lost_balls: "كرات ضائعه",
        attempts: "محاولات على المرمى",
        chances: "فرص",
        dribbles: "المراوغات",
        fouls_in: "أخطاء عليه",
        fouls_out: "أخطاء له",
        ball_win: "استعاد الكرة",
        ball_lose: "خسر الكرة",
    }
    return (
        <div className='bg-black-color white-color fs-xs mt-2'>
            <div className='d-flex justify-content-between fs-md p-2'>
                <div>احصائيات</div>
                <div style={{ direction: 'rtl' }}>
                    <button className={`swipe-animation fa fa-chevron-left btn white-color`} aria-hidden="true"></button>
                </div>
            </div>
            <table className="table-borderless bg-black-color table-striped table-dark white-color">
                <thead>
                </thead>
                <tbody>
                    {
                        isErrorTeamStatistics ?
                            <tr>
                                <td>
                                    <ErrorMsg p={5} fs={'md'} msg="لا يوجد احصائيات" />
                                </td>
                            </tr>
                            :
                            isFetchingTeamStatistics ?
                                <tr>
                                    <td>
                                        <LoadingSpinnner p={5} fs={'md'} />
                                    </td>
                                </tr>
                                :
                                Object.keys(teamStatistics?.data)
                                    .filter(k => k !== "scoringIntervals")
                                    .map((key, i) =>
                                        <tr key={i}>
                                            <td>
                                                <div className='d-flex justify-content-between mx-1 p-2'>
                                                    <div>{statisticsKeys[key]}</div>
                                                    <div>{Object.values(teamStatistics?.data)[i]}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                    }

                </tbody>
            </table>
        </div>
    )
}

export default TeamOverviewStats