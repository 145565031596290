import { Link, useLocation } from "react-router-dom";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import TeamsCarouselLoading from "./TeamsCarouselLoading";
import { Container } from "react-bootstrap";
import "./ClubsComponent.css";
import { ImgURL } from "../../globalData";
import { getServiceName } from "../HeaderNav/serviceUtil";
import useServiceAndSport from "../../hooks/useServiceAndSport";
import SampleArrowBtn from "../../components/SampleArrowBtn/SampleArrowBtn";
import ErrorMsg from "../../components/ErrorPage/ErrorMsg";
import api from "../../apis";
import { useQuery } from '@tanstack/react-query';
import { logFailedApi } from "../../globalFn";

const apiFn = (params) => api.teams.getAllTeams(params)

function TeamsCarousel() {

  const { serviceId, sportId } = useServiceAndSport();
  const { pathname } = useLocation();
  const isWorldCupPage = pathname.split("/").includes("726");

  const params = { sportId, service_id: serviceId, in_carousel: true, team_type: 1 }

  const { data: allTeams, isFetching, isError } = useQuery({
    queryKey: ["all-teams", sportId, serviceId, isWorldCupPage],
    queryFn: () => apiFn(worldCupUpdatesToParams(params)),
    onError: () => logFailedApi("all-teams"),
    refetchOnWindowFocus: false,
    enabled: serviceId !== null,
  })

  const worldCupUpdatesToParams = (params) => {
    if (isWorldCupPage) {
      return {
        ...params,
        team_type: 2,
        tournament_id: 726
      }
    } else {
      return params
    }
  }

  const settings = {
    infinite: false,
    draggable: false,
    touchMove: false,
    speed: 900,
    slidesToShow: 24,
    slidesToScroll: 12,
    rtl: false,
    // accessibility: true,
    nextArrow: <SampleArrowBtn arrowDirection="left"
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        borderRadius: "0px",
        border: "unset",
        position: "absolute",
        top: "-5px",
        height: "115%",
        right: "0px",
        zIndex: "15",
        width: "20px",
        opacity: "1",
        color: "white"
      }}
    />,
    prevArrow: <SampleArrowBtn
      styles={{
        fontSize: "20px",
        background: " var(--heavy-metal)",
        border: "unset",
        borderRadius: "0px",
        position: "absolute",
        top: "-5px",
        height: "115%",
        left: "0px",
        zIndex: "15",
        width: "20px",
        opacity: "1",
        color: "white"
      }}
    />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 15,
          slidesToScroll: 12,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 10,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 8,
          slidesToScroll: 4,
        },
      },
    ],
  };

  if (serviceId === 10 || pathname === '/fitness') {
    return <></>;
  } else {
    return (
      <Container fluid className="px-0">
        {isFetching ? (
          <TeamsCarouselLoading />
        ) : (
          <div className="slick-carousel start-carousel-at-right">
            <Slider {...settings} arrows={true}>
              {allTeams?.data[0] === ""
                ? <ErrorMsg msg={"لا يوجد فرق"} p={1} fs={'sm'} />
                : allTeams?.data.map((team) => (
                  <div key={team.id} data-testid="teams-carousel-team">
                    <Link
                      data-testid="teams-carousel-team-link"
                      to={{
                        pathname: `/${getServiceName(team.services?.[0])}/team/${team.id
                          }`,
                        state: { previousPathname: pathname },
                      }}
                    >
                      <div>
                        <img
                          className="mt-1"
                          src={ImgURL(team.logo)}
                          style={{ width: "50px" }}
                          alt={team.name}
                        />
                      </div>
                    </Link>
                  </div>
                ))}
            </Slider>
          </div>
        )}
      </Container>
    );
  }
}

export default TeamsCarousel
