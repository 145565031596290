import React from 'react';
import LineupPlayer from '../lineupPlayer/lineupPlayer.component';
import './lineup.css';
require('velocity-animate');
require('velocity-animate/velocity.ui');


const Lineup = ({ teamAway, teamHome, home, away, secondary }) => {

    const makeFieldLine = (team, fieldLine) => {
        return (
            <div className={`d-flex ${team.team_id === 1 || away ? ' flex-row-reverse' : ''}  ${fieldLine === 'midp3' ? 'justify-content-around' : 'justify-content-around'} `} >
                {
                    team.pitch[fieldLine]
                        .reverse()
                        .map(player => <LineupPlayer key={player.player_id} home={home} away={away} el={player} secondary={`${team.team_id === 1 ? secondary : ''}`} />)
                }
            </div>
        )
    }
    return (
        <div style={{ width: '100%', height: '100%', direction: "ltr" }}>
            <div className={`px-4 py-2 ${away ? 'd-none ' : ''} ${home ? 'home-team-formation-mini ' : 'home-team-formation'}  d-lg-block`}>
                <div className={`${home ? 'home-players-mini flex-column-reverse' : 'home-players'} d-flex flex-column justify-content-between text-light`}>
                    {makeFieldLine(teamHome, 'goal')}
                    {makeFieldLine(teamHome, 'defence')}
                    {makeFieldLine(teamHome, 'midp1')}
                    {makeFieldLine(teamHome, 'midp2')}
                    {makeFieldLine(teamHome, 'midp3')}
                    {makeFieldLine(teamHome, 'attack')}
                </div>
            </div>
            <div className={`px-4 py-2 ${home ? 'd-none' : ''}  ${away ? 'away-team-formation-mini' : 'away-team-formation'}  d-lg-block`}>
                <div className={`${away ? 'away-players-mini ' : 'away-players'}   d-flex flex-column justify-content-between text-light`}>
                    {makeFieldLine(teamAway, 'attack')}
                    {makeFieldLine(teamAway, 'midp3')}
                    {makeFieldLine(teamAway, 'midp2')}
                    {makeFieldLine(teamAway, 'midp1')}
                    {makeFieldLine(teamAway, 'defence')}
                    {makeFieldLine(teamAway, 'goal')}
                </div>
            </div>
        </div>
    )
}

export default Lineup