export const isActive = (bool) => bool ? 'active' : '';

export const getServiceName = (serviceId) => {
    switch (serviceId) {
        case 8:
            return 'international'
        case 9:
            return 'africa'
        case 10:
            return 'othersports'
        default:
            return 'local'
    }
}

export const getSportRoute = (sportId, serviceId) => {
    if (serviceId !== 10) return '';
    switch (sportId) {
        case 3:
            return '/basketball'
        case 2:
            return '/tennis'
        case 6:
            return '/handball'
        case 7:
            return '/squash'
        default:
            return ''
    }
}