import React, { useState } from "react";
import {
  Image,
  Col,
  Form,
  Alert,
} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import api from "../../../../../apis";
import RequireSubscription from "../../../../../components/require-subscription";
import { getUserPhoneNumbers } from "../../../../../globalFn";
import midoPic from "../../../assets/img/ask.png";
import "./ask-mido.css";

function AskMido({ isSubscribed }) {
  const history = useHistory()
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isSent, setIsSent] = useState(false);
  const [question, setQuestion] = useState('');
  const [msisdn] = useState(() => getUserPhoneNumbers()?.[0]);

  const sendQ = () => {
    setLoading(true);
    api.melouk.questions.sendMidoQuestion({ phone: msisdn, question })
      .then((res) => {
        if (res.status === 200) {
          setIsSent(true)
          setError(null)
        } else {
          setIsSent(false)
          setError(JSON.stringify(res.result))
        }
        setLoading(false)
      })
  }

  return (
    <div className="ask-mido border-radius-10 py-2  my-1 my-lg-4">
      <Col xs={12} className="m-auto text-center p-0">
        <Image src={midoPic} width={150} className="rounded-circle mb-4" />
        <p className="text-center text-light fs-md">
          ارسل كل أسئلتك واستفساراتك والعالمى هيجاوب عليك
        </p>
        <p className="fs-sm text-center text-light color-tertiary-dark">
          تابع صفحه تويست سبورتس عشان يوصلك كل جديد
        </p>
        <RequireSubscription isSubscribed={isSubscribed}>
          <Form.Group className={`d-block`}>
            <Form.Control
              as="textarea"
              type="text"
              placeholder="اكتب سؤالك هنا"
              className="border-radius-15 fs-sm p-3 w-75 m-auto"
              onChange={(e) => setQuestion(e.target.value)}
              value={question}
            />
          </Form.Group>
          <button
            className="btn bg-active-color text-light fs-sm mx-1"
            onClick={() => !msisdn ? history.push("/local/subscribe") : sendQ()}
            disabled={loading}
          >
            {loading ? (
              <div className="text-center">
                <span className="mr-1">جاري الارسال ...</span>
              </div>
            ) : (
              <span>إرسل</span>
            )}
          </button>
        </RequireSubscription>
        {isSent && <Alert variant="success" className="fs-sm mt-2">تم ارسال سؤال لميدو</Alert>}
        {error && <Alert variant="danger" className="fs-sm mt-2">{error}</Alert>}
      </Col>
    </div>
  );
}

export default AskMido;
